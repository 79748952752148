import { NodeViewWrapper } from '@tiptap/react'
import React, { useState } from 'react'
import { Dropdown, Menu, Input } from 'antd';
import { cloneDeep } from 'lodash';

export default props => {

  const tags = [
    {
      category: "Current Date",
      items: [
        { tag: "current_date_short", displayName: "Current Date (Short)" },
        { tag: "current_date_medium", displayName: "Current Date (Medium)" },
        { tag: "current_date_long", displayName: "Current Date (Long)" }
      ]
    },
    {
      category: "Company",
      items: [
        { tag: "company_name", displayName: "Company Name" },
        { tag: "company_website", displayName: "Company Website" }
      ]
    },
    {
      category: "Client",
      items: [
        { tag: "client_full_name", displayName: "Client Full Name" },
        { tag: "client_first_name", displayName: "Client First Name" },
        { tag: "client_last_name", displayName: "Client Last Name" },
        { tag: "client_email", displayName: "Client Email" },
        { tag: "client_phone", displayName: "Client Phone" }
      ]
    },
    {
      category: "Event",
      items: [
        { tag: "event_name", displayName: "Event Name" },
        { tag: "event_type", displayName: "Event Type" },
        { tag: "event_date_short", displayName: "Event Date (Short)" },
        { tag: "event_date_medium", displayName: "Event Date (Medium)" },
        { tag: "event_date_long", displayName: "Event Date (Long)" },
        { tag: "event_day", displayName: "Event Day" },
        { tag: "event_month", displayName: "Event Month" },
        { tag: "event_year", displayName: "Event Year" },
        { tag: "event_start_time", displayName: "Event Start Time" },
        { tag: "event_end_time", displayName: "Event End Time" }
      ]
    },
    {
      category: "Venue",
      items: [
        { tag: "venue_name", displayName: "Venue Name" },
        { tag: "venue_address_line_1", displayName: "Venue Address Line 1" },
        { tag: "venue_address_line_2", displayName: "Venue Address Line 2" },
        { tag: "venue_city", displayName: "Venue City" },
        { tag: "venue_state", displayName: "Venue State" },
        { tag: "venue_zip", displayName: "Venue Zip" },
        { tag: "venue_contact", displayName: "Venue Contact Name" },
        { tag: "venue_email", displayName: "Venue Email" },
        { tag: "venue_phone", displayName: "Venue Phone" }
      ]
    },
    {
      category: "Financials",
      items: [
        { tag: "deposit", displayName: "Deposit Amount" },
        { tag: "discount", displayName: "Discount Amount" },
        { tag: "package_total", displayName: "Package Total" },
        { tag: "add_ons_total", displayName: "Add-Ons Total" },
        { tag: "subtotal_price", displayName: "Subtotal Price" },
        { tag: "tax_rate", displayName: "Tax Rate" },
        { tag: "tax_total", displayName: "Tax Total" },
        { tag: "total_price", displayName: "Total Price" },
        { tag: "total_payments", displayName: "Total Payments" },
        { tag: "balance", displayName: "Balance" }
      ]
    }
  ]

  const [searchText, setSearchText] = useState("");
  const [mergeTags, setMergeTags] = useState(tags);
  const [filteredMergeTags, setFilteredMergeTags] = useState(tags);

  const searchOnChange = (e) => {
    const searchValue = e.target.value.toLowerCase()
    setSearchText(searchValue)
    var tagCategories = cloneDeep(mergeTags)
    for (var category of tagCategories) {
      const filteredItems = category.items.filter(x => x.displayName.toLowerCase().includes(searchValue))
      category.items = filteredItems
    }
    tagCategories = tagCategories.filter(x => x.items.length > 0)
    setFilteredMergeTags(tagCategories)
  }

  const onVisibleChange = (open) => {
    if (open) {
      setSearchText("")
      setFilteredMergeTags(mergeTags)
    }
  }

  const updateMergeTag = (displayName, tag) => {
    props.updateAttributes({
      displayName: displayName,
      tag: tag
    })
  }

  const renderMenuGroupItem = (item, index) => {
    return (
      <Menu.Item key={item.tag}>
        <div onClick={() => updateMergeTag(item.displayName, item.tag)}>
          { item.displayName }
        </div>
      </Menu.Item>
    )
  }

  const renderMenuGroup = (group, index) => {
    return (
      <Menu.ItemGroup title={group.category} key={index}>
        { group.items.map((x,i) => renderMenuGroupItem(x,i))}
      </Menu.ItemGroup>
    )
  }

  const menu = () => {
    return (
      <>
        <Menu>
          <div className="p-10"><Input value={searchText} onChange={searchOnChange}/></div>
          { filteredMergeTags.map((x,i) => renderMenuGroup(x,i))}
        </Menu>
      </>
    )
  };

  return (
    <NodeViewWrapper className="merge-tag" as="span">
      <Dropdown 
        overlay={menu()} 
        placement="bottomLeft" 
        trigger="click" 
        overlayClassName="scrollable-dropdown" 
        disabled={!props.editor.view.editable}
        onVisibleChange={onVisibleChange}>
        <button className={`editor-variable-field ${props.editor.view.editable ? "" : "disabled"}`}>{props.node.attrs.displayName}</button>
      </Dropdown>
    </NodeViewWrapper>
  )
}