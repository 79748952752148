import React from "react";
import moment from 'moment';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { isArray, isEmpty, get, isUndefined } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import caveatRegular from '../fonts/Caveat/Caveat-Regular.ttf'
import { getClientSigners, getAdminSigners, getDiscountMessage, getNumericCurrency } from '../helpers/contractHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

 Font.register({ family: 'Caveat', fonts: [
  { src: caveatRegular },
 ]});

const ContractPDF = (props) => {

  const { contract = {} } = props

  const styles = StyleSheet.create({
    page: {
      paddingTop: 25,
      paddingBottom: 65,
      paddingHorizontal: 25
    },
    blockContainer: {
      marginBottom: 10
    },
    header1: {
      fontSize: 18,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    header2: {
      fontSize: 16,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    header3: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    header4: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    paragraph: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
    signatureSection: {
      marginTop: 10
    },
    signatureRow: {
      display: "flex",
      flexDirection: 'row'
    },
    signatureNameCol: {
      flex: 1,
      paddingLeft: 5
    },
    signatureDateCol: {
      flex: 1,
      paddingRight: 5
    },
    signatureName: {
      fontSize: 10,
      fontFamily: "OpenSans"
    },
    signatureEmail: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
      paddingLeft: 5
    },
    signatureDate: {
      fontSize: 8,
      fontFamily: "OpenSans",
      textAlign: "right",
      color: "#777777"
    },
    signatureLine: {
      borderBottom: "1px solid #CCC",
      marginBottom: 4,
      marginTop: 20,
      height: 24,
      paddingLeft: 5
    },
    signatureText: {
      fontSize: 16,
      fontFamily: "Caveat"
    },
    row: {
      display: "flex",
      flexDirection: 'row'
    },
    flex0: {
      flex: 0
    },
    flex1: {
      flex: 1
    },
    orderedListNumber: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
    servicesBorder: {
      borderBottom: "1px solid #e8e8e8",
      marginBottom: 10
    },
    servicesRow: {
      display: "flex",
      flexDirection: 'row',
      paddingHorizontal: 5,
      borderTop: "1px solid #e8e8e8",
      paddingVertical: 10
    },
    summaryRow: {
      display: "flex",
      flexDirection: 'row',
      marginHorizontal: 5,
      paddingBottom: 10
    },
    servicesNameText: {
      fontSize: 10,
      fontFamily: "OpenSans"
    },
    servicesText: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
    addOnQuantityText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
    },
    discountAmountText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
      textDecoration: "line-through"
    },
    discountText: {
      fontSize: 9,
      fontFamily: "OpenSans",
      color: "#777777",
      marginLeft: 5
    },
    servicesSummaryLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      flex: 1,
      textAlign: "right"
    },
    servicesSummaryAmount: {
      fontSize: 10,
      fontFamily: "OpenSans",
      flexGrow: 0, 
      width: 120,
      textAlign: "right"
    },
    servicesSummaryAmountBold: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      flexGrow: 0, 
      width: 120,
      textAlign: "right"
    }
  });

  const renderNode = (node, index) => {
    if (node.type == "text") {
      var boldClass = {}
      var italicClass = {}
      var underlineClass = {}
      var strikeClass = {}
      if (isArray(node.marks)) {
        boldClass = !isEmpty(node.marks.find(x => x.type == "bold")) ? { fontWeight: 700 } : {}
        italicClass = !isEmpty(node.marks.find(x => x.type == "italic")) ? { fontStyle: 'italic' } : {}
        underlineClass = !isEmpty(node.marks.find(x => x.type == "underline")) ? { textDecoration: 'underline' } : {}
        strikeClass = !isEmpty(node.marks.find(x => x.type == "strike")) ? { textDecoration: 'underline' } : {}
      }
      return <Text style={[boldClass, italicClass, underlineClass, strikeClass]} key={index}>{node.text}</Text>;
    } else if (node.type == "merge-tag") {
      const value = get(node, "attrs.value", "")
      return <Text>{value}</Text>
    }
    return null;
  }

  const renderInlineContent = (content) => {
    if (isArray(content)) {
      return content.map((x,i) => renderNode(x,i))
    }
    return null;
  }

  const renderHeading = (block, index) => {
    const level = get(block, "attrs.level", 1)
    const textAlign = get(block, "attrs.textAlign", "left")

    var headerStyle = styles.header1
    if (level == 1) {
      headerStyle = styles.header1;
    } else if (level == 2) {
      headerStyle = styles.header2;
    } else if (level == 3) {
      headerStyle = styles.header3;
    } else if (level == 4) {
      headerStyle = styles.header4;
    }

    return (
      <View style={styles.blockContainer} key={index}>
        <Text style={[headerStyle, { textAlign: textAlign }]}>
          { renderInlineContent(block.content) }
        </Text>
      </View>
    )
  }

  const renderParagraph = (block, index) => {
    if (!isUndefined(block.content)) {
      const textAlign = get(block, "attrs.textAlign", "left")
      return (
        <View style={styles.blockContainer} key={index}>
          <Text style={[{ textAlign: textAlign }, styles.paragraph]}>
            { renderInlineContent(block.content) }
          </Text>
        </View>
      )
    } else {
      return <View style={{ padding: 10 }}></View>
    }
  }

  const renderOrderedListItem = (item, index) => {
    if (isArray(item.content)) {
      const listItem = item.content[0]
      return (
        <View style={[styles.blockContainer, styles.row]} key={index} wrap={false}>
          <View style={{ flexGrow: 0, width: 30, textAlign: "right", paddingRight: 5 }}>
            <Text style={styles.orderedListNumber}>{index + 1}.</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={[styles.paragraph]}>
              { renderInlineContent(listItem.content) }
            </Text>
          </View>
        </View>
      )
    }
  }

  const renderOrderedList = (block, index) => {
    if (isArray(block.content)) {
      return (
        <View style={styles.blockContainer} key={index}>
          { block.content.map((x,i) => renderOrderedListItem(x,i))}
        </View>
      )
    }
  }

  const renderBulletListItem = (item, index) => {
    if (isArray(item.content)) {
      const listItem = item.content[0]
      return (
        <View style={[styles.blockContainer, styles.row]} key={index} wrap={false}>
          <View style={{ flexGrow: 0, width: 30, textAlign: "right", paddingRight: 5 }}>
            <Text style={styles.orderedListNumber}>•</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={[styles.paragraph]}>
              { renderInlineContent(listItem.content) }
            </Text>
          </View>
        </View>
      )
    }
  }

  const renderBulletList = (block, index) => {
    if (isArray(block.content)) {
      return (
        <View style={styles.blockContainer} key={index}>
          { block.content.map((x,i) => renderBulletListItem(x,i))}
        </View>
      )
    }
  }
  
  const renderEventServices = (block) => {
    const data = !isUndefined(block.data) ? block.data : {};
    const packages = isArray(data.packages) ? data.packages : [];
    const addOns = isArray(data.add_ons) ? data.add_ons : [];
    const hasTax = getNumericCurrency(data.tax) != 0
    
    return (
      <View>
        { packages.map((x,i) => (
          <View style={styles.servicesRow} key={i}>
            <View style={styles.flex1}>
              <Text style={[styles.servicesText]}>
                {x.package_name}
              </Text>
              { x.discount && (
                <Text style={styles.discountText}>{getDiscountMessage(x)}</Text>
              )}
            </View>
            { x.discount ? (
              <View>
                <Text style={styles.discountAmountText}>{x.price}</Text>
                <Text style={styles.servicesText}>{x.total}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.servicesText}>{x.price}</Text>
              </View>
            )}
          </View>
        ))}
        { addOns.map((x,i) => (
           <View style={styles.servicesRow} key={i}>
            <View style={styles.flex1}>
              <Text style={[styles.servicesText]}>
                {x.add_on_name} <Text style={[styles.addOnQuantityText]}> ({x.quantity} x {x.price})</Text>
              </Text>
              { x.discount && (
                <Text style={styles.discountText}>{getDiscountMessage(x)}</Text>
              )}
            </View>
            { x.discount ? (
              <View>
                <Text style={styles.discountAmountText}>{x.sub_total}</Text>
                <Text style={styles.servicesText}>{x.total}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.servicesText}>{x.total}</Text>
              </View>
            )}
          </View>
        ))}
        { hasTax && (
          <>
            <View style={styles.servicesBorder}></View>
            <View style={styles.summaryRow}>
              <Text style={styles.servicesSummaryLabel}>Subtotal:</Text>
              <Text style={styles.servicesSummaryAmount}>{data.subtotal}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.servicesSummaryLabel}>Tax:</Text>
              <Text style={styles.servicesSummaryAmount}>{ data.tax }</Text>
            </View>
          </>
        )}
        <View style={styles.servicesRow}>
          <Text style={styles.servicesSummaryLabel}>Total:</Text>
          <Text style={styles.servicesSummaryAmountBold}>{data.total_price}</Text>
        </View>
      </View>
    )
  }

  const renderBlock = (block, index) => {
    if (block.type == "heading") {
      return renderHeading(block, index)
    } else if (block.type == "paragraph") {
      return renderParagraph(block, index)
    } else if (block.type == "orderedList") {
      return renderOrderedList(block, index)
    } else if (block.type == "bulletList") {
      return renderBulletList(block, index)
    } else if (block.type == "event-services") {
      return renderEventServices(block)
    }
    return null
  }

  const renderContract = () => {
    if (!isEmpty(contract.content) && isArray(contract.content.content)) {
      return contract.content.content.map((x,i) => renderBlock(x,i))
    }
    return null
  }

  const renderSigner = (signer, index) => {
    if (signer.signed) {
      return (
        <View key={index}>
          <View style={styles.signatureLine}>
            <Text style={styles.signatureText}>{signer.signature}</Text>
          </View>
          <View style={styles.signatureRow}>
            <View style={styles.signatureNameCol}>
              <Text style={styles.signatureName}>
                {signer.first_name} {signer.last_name}
              </Text>
            </View>
            <View style={styles.signatureDateCol}>
              <Text style={styles.signatureDate}>
                Signed on {moment.utc(signer.signed_datetime).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
          <View>
            <Text style={styles.signatureEmail}>
              {signer.email}
            </Text>
          </View>
        </View>
      )
    }
    return (
      <View key={index}>
        <View style={styles.signatureLine}></View>
        <View style={styles.signatureRow}>
          <View style={styles.signatureNameCol}>
            <Text style={styles.signatureName}>
              {signer.first_name} {signer.last_name}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.signatureEmail}>
            {signer.email}
          </Text>
        </View>
      </View>
    )
  }

  const renderSignatures = () => {
    return (
      <View style={styles.signatureSection} wrap={false}>
        { getClientSigners(contract.signers).map((x,i) => renderSigner(x,i))}
        { getAdminSigners(contract.signers).map((x,i) => renderSigner(x,i))}
      </View>
    )
  }

  const renderDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          { renderContract() }
          { renderSignatures() }
        </Page>
      </Document>
    )
  }

  return renderDocument()
}

export default ContractPDF;
