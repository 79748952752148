import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import { isEmpty, isNull, cloneDeep, startsWith } from "lodash";
import useApi from '../../hooks/useApi';
import { getRank, getNewRank, sortByRank } from '../../helpers/rankHelper';
import { 
  renderFormLabel,
  renderInputField,
  renderNumberField,
  renderSearchSelectField
} from '../../components/formFields'
import { Row, Col, Input, Select, Modal, Button, Dropdown, Menu, Form } from 'antd';
import {
  getSystemRecommendedPlaylists, 
  getAccountSpecialEventTemplate, 
  createAccountSpecialEventSection, 
  updateAccountSpecialEventSection, 
  getAccountSpecialEventSections, 
  createAccountSpecialEvent, 
  updateAccountSpecialEventRank,
  updateAccountSpecialEvent,
  deleteAccountSpecialEvent,
  deleteAccountSpecialEventSection,
  getRecommendedPlaylists,
  updateAccountSpecialEventTemplate
} from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { PlusOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdArrowForwardIos } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import emptyStateImage from '../../images/empty-document-icon.png';

const SpecialEventTemplatePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isAddEditSectionModalVisible, setAddEditSectionModalVisible] = useState(false);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [sectionName, setSectionName] = useState(null);
  const [sectionDescription, setSectionDescription] = useState(null);
  const [isNewSpecialEvent, setNewSpecialEvent] = useState(false);
  const [sections, setSections] = useState([]);
  const [specialEventTemplate, setSpecialEventTemplate] = useState({});
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedSpecialEventType, setSelectedSpecialEventType] = useState("");
  const [selectedSpecialEvent, setSelectedSpecialEvent] = useState({});
  const [recommendedPlaylists, setRecommendedPlaylists] = useState([]);
  const [systemRecommendedPlaylists, setSystemRecommendedPlaylists] = useState([]);
  const [selectedRecommendedPlaylist, setSelectedRecommendedPlaylist] = useState("");
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [templateName, setTemplateName] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);

  const [form] = Form.useForm();
  const [sendRequest] = useApi()

  const templateId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getAccountSpecialEventTemplate(templateId));
      setSpecialEventTemplate(results)
      setTemplateName(results.template_name)

      const sectionResults = await sendRequest(getAccountSpecialEventSections(templateId));
      setSections(sectionResults)

      const recPlaylistResults = await sendRequest(getRecommendedPlaylists());
      setRecommendedPlaylists(recPlaylistResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchSystemRecommendedPlaylists = async () => {
    try {
      const recPlaylistResults = await sendRequest(getSystemRecommendedPlaylists());
      setSystemRecommendedPlaylists(recPlaylistResults)
    } catch {}
  }

  const onSubmitSpecialEvent = async (values) => {
    var songLimit = 0;
    if (selectedSpecialEventType == "playlist" || selectedSpecialEventType == "single") {
      songLimit = values["song_limit"]
    }

    var recPlaylistId = null;
    if (values["recommended_playlist_id"]) {
      recPlaylistId = values["recommended_playlist_id"] == "system" ? values["system_recommended_playlist_id"] : values["recommended_playlist_id"]
    }

    try {
      if (isNewSpecialEvent) {
        const body = {
          special_event_name: values["special_event_name"],
          song_limit: songLimit,
          songs_allowed: selectedSpecialEventType == "playlist" || selectedSpecialEventType == "single",
          is_playlist: selectedSpecialEventType == "playlist",
          recommended_playlist_id: recPlaylistId,
          rank: getRank(selectedSection.special_events)
        }
        await sendRequest(createAccountSpecialEvent(templateId, selectedSection.account_special_event_section_id, body));
        await refreshPage()
        setAddEditModalVisible(false)
      } else {
        const body = {
          special_event_name: values["special_event_name"],
          song_limit: songLimit,
          songs_allowed: selectedSpecialEventType == "playlist" || selectedSpecialEventType == "single",
          is_playlist: selectedSpecialEventType == "playlist",
          recommended_playlist_id: recPlaylistId,
          rank: selectedSpecialEvent.rank
        }
        await sendRequest(updateAccountSpecialEvent(selectedSpecialEvent.account_special_event_id, body));
        await refreshPage()
        setAddEditModalVisible(false)
      }
    } catch {}
  }

  const onSpecialEventTypeChange = (value) => {
    setSelectedSpecialEventType(value)
    setSelectedRecommendedPlaylist(null)
    form.setFieldsValue({ "song_limit": value == "single" ? 1 : null, "recommended_playlist_id": null, "system_recommended_playlist_id": null })
  }

  const onRecommendedPlaylistChange = (value) => {
    setSelectedRecommendedPlaylist(value)
    form.setFieldsValue({"system_recommended_playlist_id": null })
    if (value == "system" && systemRecommendedPlaylists.length == 0) {
      fetchSystemRecommendedPlaylists()
    }
  }

  const menu = (section, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSectionName(section, index)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Section
          </div>
        </Menu.Item>
        { index > 0 && (
          <Menu.Item>
            <div onClick={() => moveSection("up", index)}>
              <UpOutlined style={{ marginRight: 8}}/> Move Section Up
            </div>
          </Menu.Item>
        )}
        { index < sections.length - 1 && (
          <Menu.Item>
            <div onClick={() => moveSection("down", index)}>
              <DownOutlined style={{ marginRight: 8}}/> Move Section Down
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => handleSectionDelete(section)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Section
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const specialEventMenu = (event, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSpecialEvent(event)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDeleteSpecialEvent(event)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  const handleNewSectionCancel = () => {
    setSectionName("")
    setSectionDescription("")
    setAddEditSectionModalVisible(false);
  };

  const handleAddEditSectionSave = async () => {
    try {
      if (isEmpty(selectedSection)) {
        const body = {
          section_name: sectionName,
          rank: getRank(sections),
          description: sectionDescription
        }
        await sendRequest(createAccountSpecialEventSection(templateId, body));
      } else {
        const body = {
          section_name: sectionName,
          rank: selectedSection.rank,
          description: sectionDescription
        }
        await sendRequest(updateAccountSpecialEventSection(templateId, selectedSection.account_special_event_section_id, body));
      }
      await refreshPage()
    } finally {
      setAddEditSectionModalVisible(false);
    }
  };

  const handleSectionDelete = async (section) => {
    try {
      await sendRequest(deleteAccountSpecialEventSection(templateId, section.account_special_event_section_id));
      await refreshPage()
    } catch {}
  }

  const handleAddSection = () => {
    setSelectedSection({})
    setSectionName("")
    setSectionDescription("")
    setAddEditSectionModalVisible(true)
  }

  const handleEditSectionName = (section, index) => {
    setSelectedSection(section)
    setSectionName(section.section_name)
    setSectionDescription(section.description)
    setAddEditSectionModalVisible(true)
  }

  const handleAddNewSpecialEvent = (section) => {
    form.resetFields()
    form.setFieldsValue({ song_limit: 1 })
    setSelectedRecommendedPlaylist(null)
    setSelectedSpecialEventType("single")
    setNewSpecialEvent(true)
    setSelectedSection(section)
    setAddEditModalVisible(true)
  }

  const handleEditSpecialEvent = (event) => {
    const isSystemPlaylist = startsWith(event.recommended_playlist_id, "rec_playlist")
    const fields = {
      special_event_name: event.special_event_name,
      song_limit: event.song_limit,
      recommended_playlist_id: isSystemPlaylist ? "system" : event.recommended_playlist_id,
      system_recommended_playlist_id: isSystemPlaylist ? event.recommended_playlist_id: null
    }
    setSelectedRecommendedPlaylist(isSystemPlaylist ? "system" : event.recommended_playlist_id)
    form.setFieldsValue(fields)
    setSelectedSpecialEvent(event)
    if (event.is_playlist) {
      setSelectedSpecialEventType("playlist")
    } else if (event.songs_allowed) {
      setSelectedSpecialEventType("single")
    } else {
      setSelectedSpecialEventType("none")
    }
    setNewSpecialEvent(false)
    setAddEditModalVisible(true)
  }

  const handleDeleteSpecialEvent = async (event) => {
    try {
      await sendRequest(deleteAccountSpecialEvent(event.account_special_event_id));
      await refreshPage()
    } catch {}
  }

  const onSortEnd = async (oldIndex, newIndex, sectionIndex) => {
    if (oldIndex !== newIndex) {
      var newSections = cloneDeep(sections)
      var newSpecialEvents = cloneDeep(sections[sectionIndex].special_events);
      const rank = getNewRank(newSpecialEvents, oldIndex, newIndex)
      const specialEventId = newSpecialEvents[oldIndex].account_special_event_id
      newSpecialEvents[oldIndex].rank = rank;
      const sortedSpecialEvents = sortByRank(newSpecialEvents);
      newSections[sectionIndex].special_events = sortedSpecialEvents;
      setSections(newSections)
      try {
        await sendRequest(updateAccountSpecialEventRank(specialEventId, { rank: rank }))
      } catch {}
    }
  };

  const moveSection = async (direction, index) => {
    var newSections = cloneDeep(sections);
    var newIndex = direction == "up" ? index - 1 : index + 1
    const rank = getNewRank(newSections, index, newIndex)
    const sectionId = newSections[index].account_special_event_section_id
    newSections[index].rank = rank;
    const sortedSections = sortByRank(newSections);
    setSections(sortedSections)
    try {
      const body = {
        section_name: newSections[index].section_name,
        rank: rank,
        description: newSections[index].description
      }
      await sendRequest(updateAccountSpecialEventSection(templateId, sectionId, body));
    } catch {}
  }

  const getSpecialEventType = (event) => {
    if (event.is_playlist) {
      return "PLAYLIST";
    } else if (event.songs_allowed) {
      return "SPECIAL SONG"
    } else {
      return "NO SONGS"
    }
  }

  const getSpecialEventSubText = (event) => {
    return `Song Limit: ${event.song_limit == 0 ? "N/A" : event.song_limit}`
  }

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }

  const saveName = async () => {
    setEditingTitle(false)
    try {
      await sendRequest(updateAccountSpecialEventTemplate(templateId, { template_name: templateName, active: false }));
    } catch {}
  }

  const onKeyPress = (e) => {
   if (e.keyCode == 13) {
    saveName()
   }
  }

  const SortableItem = sortableElement(({value, index}) => (
    <li className="">
      <div className="border radius-8 mb-10">
        <Row align="middle" gutter={[15]} className="p-20">
          <Col flex={1} onClick={() => handleEditSpecialEvent(value)}>
            <div className="fw-600 fs-16">{ value.special_event_name }</div>
            <div className="c-text-gray fs-12 mt-2"> <span className="bg-light-blue ph-5 c-blue fw-700 fs-10 mr-5" style={{ paddingTop: 2, paddingBottom: 2}}>{getSpecialEventType(value)}</span>{getSpecialEventSubText(value)}</div>
          </Col>
          <Col flex={0}>
            <Dropdown overlay={specialEventMenu(value, index)} placement="bottomRight" trigger="click">
              <div className="admin-icon-circle"><EllipsisOutlined/></div>
            </Dropdown>
          </Col>
          <Col flex={0}>
            <div>
              <BsThreeDotsVertical/>
            </div>
          </Col>
        </Row>
      </div>
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <div className="">
        <ul className="special-event-row-container">
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} questionIndex={index} sectionIndex={sectionIndex} value={value} />
          ))}
        </ul>
      </div>
    );
  });

  const renderSection = (section, index) => {
    const specialEventRecords = !isNull(section.special_events) ? section.special_events : [];
    return (
      <div key={index} className="mb-20">
        <div className="shadow-card ph-20 pv-15">
          <Row align="middle" gutter={[15]} className="mb-15">
            <Col xs={20} lg={22}>
              <div className="fw-700 fs-18">{ section.section_name }</div>
              { section.description && (
                <div className="c-text-gray line-breaks">{ section.description }</div>
              )}
            </Col>
            <Col xs={4} lg={2} className="text-right">
              <div style={{ display: 'inline-block', marginRight: 5}}>
                <Dropdown overlay={menu(section, index)} placement="bottomRight" trigger="click">
                  <div className="admin-icon-circle"><EllipsisOutlined/></div>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <SortableList items={specialEventRecords} sectionIndex={index} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, index)} helperClass="special-event-row-dragging" pressDelay={200} lockAxis="y" />
          <div className="admin-link mt-10" onClick={() => handleAddNewSpecialEvent(section)}><PlusOutlined/> Add Special Event</div>
        </div>
      </div>
    )
  }

  const renderAddEditSectionModal = () => {
    return (
      <Modal visible={isAddEditSectionModalVisible} footer={null} onCancel={handleNewSectionCancel} width={600} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isEmpty(selectedSection) ? "New Section" : "Edit Section"} </div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleNewSectionCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            <div className="bg-gray radius-8 p-10 fs-12"><b>Note:</b> Sections are a way to logically group special events together. This could be something like "Ceremony" or "Reception" if the event is a wedding. Once a section is created, special events can then be configured inside of it. Each section should always have at least one special event, otherwise clients will not be able to select music.</div>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Section Name") }
            <Input size="large" value={sectionName} onChange={(e) => setSectionName(e.target.value)}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Description (optional)") }
            <Input.TextArea rows={3} size="large" value={sectionDescription} onChange={(e) => setSectionDescription(e.target.value)}/>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <Button className="admin-small-button secondary" onClick={handleNewSectionCancel}>Cancel</Button>
          <Button className="admin-small-button ml-10" onClick={handleAddEditSectionSave}>
            { isEmpty(selectedSection) ? "Add Section" : "Save" }
          </Button>
        </div>
      </Modal>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/special-event-templates")}>
            Special Event Templates
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className={`header-editable-title mt-5 ${isEditingTitle ? "editing" : ""}`}>
          <div className="header-editable-title--label" onClick={() => startEditingTitle()}>{ templateName }</div>
          <div className="header-editable-title--input">
            <Input 
              size="large" 
              placeholder="Email" 
              ref={inputRef}
              onBlur={() => saveName()} 
              value={templateName} 
              onChange={(e) => setTemplateName(e.target.value)} 
              onKeyUp={onKeyPress}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="200"/>
        <div className="fs-14 c-text-gray mt-30">Get started by creating your first section.</div>
        <button className="page-title-button mt-20" onClick={handleAddSection}>Add Section</button>
      </div>
    )
  }

  const renderSections = () => {
    if (sections && sections.length == 0) {
      return renderEmptyState()
    }
    return (
      <>
        { sections && sections.map((x, i) => renderSection(x, i))}
        <div className="text-center">
          <button className="page-title-button" onClick={handleAddSection}>Add Section</button>
        </div>
      </>
    )
  }

  const getRecommendedPlaylistOptions = () => {
    return systemRecommendedPlaylists.map(playlist => {
      return {
        value: playlist.system_recommended_playlist_id,
        text: playlist.playlist_name
      }
    })
  }

  const renderAddEditSpecialEvent = () => {
    return (
      <Modal visible={isAddEditModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Form form={form} layout="vertical" name="client" onFinish={onSubmitSpecialEvent}>
          <Row align="middle">
            <Col flex={1}>
              <div className="fw-700 fs-18">{ isNewSpecialEvent ? "New Special Event" : "Edit Special Event" }</div>
            </Col>
            <Col>
              <div className="display-flex" onClick={() => setAddEditModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
            </Col>
          </Row>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Special Event Name", "special_event_name", true)}
            </Col>
            <Col xs={24}>
              { renderFormLabel("Type") }
              <Select
                placeholder={"Select Type"}
                value={selectedSpecialEventType}
                size='large'
                onChange={onSpecialEventTypeChange}
                style={{ width: '100%'}}
              >
                 <Select.Option value={"single"}>Special Song</Select.Option>
                 <Select.Option value={"playlist"}>Playlist</Select.Option>
                 <Select.Option value={"none"}>No Songs</Select.Option>
              </Select>
            </Col>
            { (selectedSpecialEventType == "playlist" || selectedSpecialEventType == "single") && (
              <Col xs={24}>
                {renderNumberField("Song Limit", "song_limit", 0, true, "", false)}
              </Col>
            )}
            { (selectedSpecialEventType == "single") && (
              <Col xs={24}>
                <div className="bg-gray radisu-8 p-10 fs-12"><b>Note:</b> The song limit for a "Special Song" should typically be <b>1</b>, however you have the option to increase this number if you would like the client to select more than one song. For anything over 2-3 songs, it is recommended to use the "Playlist" type.</div>
              </Col>
            )}
            { (selectedSpecialEventType == "single" || selectedSpecialEventType == "playlist") && (
              <Col xs={24}>
                { renderFormLabel("Recommended Playlist") }
                <Form.Item 
                  name={"recommended_playlist_id"}
                >
                  <Select
                    style={{ width: '100%'}}
                    allowClear
                    placeholder={"Select a playlist"}
                    size='large'
                    onChange={(value) => onRecommendedPlaylistChange(value)}
                  >
                    <Select.OptGroup label="DJ Planning Center Playlists">
                     <Select.Option value={"system"}>Select a default playlist...</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Your Playlists">
                      {recommendedPlaylists.map((playlist) => (
                        <Select.Option value={playlist.recommended_playlist_id} key={playlist.recommended_playlist_id}>{playlist.playlist_name}</Select.Option>
                      ))}
                      { recommendedPlaylists.length == 0 && (
                        <Select.Option value={null} disabled={true}>No recommened playlists created yet</Select.Option>
                      )}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              </Col>
            )}
            { selectedRecommendedPlaylist == "system" && (
              <Col xs={24}>
                {renderSearchSelectField("", "system_recommended_playlist_id", "Select a playlist", getRecommendedPlaylistOptions(), false, null, false)}
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <Button className="admin-small-button secondary" onClick={() => setAddEditModalVisible(false)}>Cancel</Button>
            <Button className="admin-small-button ml-10" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        { renderSections() }
        { renderAddEditSectionModal() }
        { renderAddEditSpecialEvent() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SpecialEventTemplatePage;