import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { DocArrowLink } from '../../../components/docComponents'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';
import { FiMusic, FiFileText, FiCheckCircle, FiMail, FiUser, FiLock, FiDollarSign, FiCalendar, FiCreditCard } from "react-icons/fi";
import { FaSignature } from "react-icons/fa";
import { HiOutlineTemplate } from "react-icons/hi";
import { AiOutlineTag } from "react-icons/ai";
import { TbTemplate } from "react-icons/tb";

const DocsHomePage = () => {

  const [isNewInvoicesEnabled, setNewInvoicesEnabled] = useState(false);

  useDocumentTitle("Documentation")
  const navigate = useNavigate();
  const [hasBetaFeature] = useBetaFeatures()

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const betaResults = await hasBetaFeature(BetaFeatures.INVOICES)
      setNewInvoicesEnabled(FeatureFlags.INVOICES || betaResults)
    } catch {}
  }

  const renderQuickLink = (icon, subtitle, title, link) => {
    return (
      <Col xs={24} md={24} lg={12} xl={8}>
        <div className="gray-hover-card p-20" onClick={() => navigate(link)}>
          <div className="quick-link-row">
            <div className="quick-link-col-1">
              <div className="quick-link-icon">{icon}</div>
            </div>
            <div className="quick-link-col-2">
              <div className="fs-10 fw-600 c-text-gray">{subtitle}</div>
              <div className="fs-16 fw-700">{title}</div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={40} maxWidth={1000}>
          <div className="fw-700 fs-24">Documentation</div>
          <div className="fw-500 fs-16 c-text-gray">Explore our guides to learn how to use DJ Planning Center.</div>
          <div className="b-border mt-20 mb-50"></div>
          <div className="fw-700 fs-20 mb-10">Getting Started</div>
          <div className="fw-500 fs-14 c-text-gray mb-10">Learn how to setup your account to get the most out of your experience.</div>
          <DocArrowLink to="/admin/docs/setup" text="Let's go"/>
          <div className="b-border mt-50 mb-50"></div>
          <div className="fw-700 fs-20 mb-10">Explore our features</div>
          <Row gutter={[15, 15]}>
            { FeatureFlags.EVENT_TEMPLATES && renderQuickLink(<HiOutlineTemplate/>, "FEATURE", "Event Templates", "/admin/docs/event-templates") }
            { renderQuickLink(<TbTemplate/>, "FEATURE", "Special Event Templates", "/admin/docs/special-event-templates") }
            { renderQuickLink(<FiFileText/>, "FEATURE", "Questionnaires", "/admin/docs/questionnaires") }
            { renderQuickLink(<FiCheckCircle/>, "FEATURE", "Workflows", "/admin/docs/workflows") }
            { renderQuickLink(<FiMusic/>, "FEATURE", "Recommended Playlists", "/admin/docs/recommended-playlists") }
            { renderQuickLink(<FaSignature/>, "FEATURE", "Contracts", "/admin/docs/contracts") }
            { !isNewInvoicesEnabled && renderQuickLink(<FiDollarSign/>, "FEATURE", "Invoices", "/admin/docs/invoices") }
            { renderQuickLink(<AiOutlineTag/>, "FEATURE", "Pricing Guides", "/admin/docs/pricing-guides") }
            { renderQuickLink(<FiLock/>, "FEATURE", "Client Permissions", "/admin/docs/client-permissions") }
            { renderQuickLink(<FiCalendar/>, "FEATURE", "iCalendar Links", "/admin/docs/calendar-links") }
          </Row>
          <div className="b-border mt-20 mv-50"></div>
          { isNewInvoicesEnabled && (
            <>
              <div className="fw-700 fs-20 mb-10">Invoices & Payments</div>
              <Row gutter={[15, 15]}>
                { renderQuickLink(<FiDollarSign/>, "INVOICES", "Invoices", "/admin/docs/invoices-payments") }
                { renderQuickLink(<HiOutlineTemplate/>, "INVOICES", "Invoice Templates", "/admin/docs/invoice-templates") }
                { renderQuickLink(<FiCreditCard/>, "PAYMENTS", "Payment Methods", "/admin/docs/payment-methods") }
              </Row>
              <div className="b-border mt-20 mv-50"></div>
            </>
          )}
          <div className="fw-700 fs-20 mb-10 mt-50">Learn more</div>
          <Row gutter={[15, 15]}>
            { renderQuickLink(<FiMail/>, "LEARN", "Inviting Clients", "/admin/docs/inviting-clients") }
            { renderQuickLink(<FiUser/>, "LEARN", "What Clients See", "/admin/docs/what-clients-see") }
          </Row>
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsHomePage;
