import React, { useEffect, useState } from "react";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import { useNavigate } from "react-router-dom";
import { LexoRank } from "lexorank";
import { isEmpty, cloneDeep, isNull, isUndefined } from "lodash";
import { Row, Col, Form, Modal, Dropdown, Menu, notification } from 'antd';
import { 
  getAccountEventTypes,
  createAccountEventType, 
  updateAccountEventType, 
  deleteAccountEventType,
  getAccountAdminEventRoles,
  createAccountAdminEventRole,
  updateAccountAdminEventRole,
  deleteAccountAdminEventRole,
  getAccountClientEventRoles,
  getAccountClientEventRole,
  createAccountClientEventRole,
  updateAccountClientEventRole,
  deleteAccountClientEventRole
} from '../../../api';
import useApi from '../../../hooks/useApi';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import ErrorCard from '../../../components/errorCard';
import FloatingContainer from '../../../components/floatingContainer'
import { MdDragIndicator, MdOutlineClose, MdArrowForwardIos, MdOutlineEdit, MdOutlineMoreHoriz } from "react-icons/md";
import { renderInputField } from '../../../components/formFields'
import { getRank, getNewRank, sortByRank } from '../../../helpers/rankHelper'
import { FiTrash } from "react-icons/fi";

const EventSettingsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  // Event Types
  const [isEventTypeModalVisible, setEventTypeModalVisible] = useState(false);
  const [isRemoveEventTypeModalVisible, setRemoveEventTypeModalVisible] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState({});
  const [deleteEventTypeError, setDeleteEventTypeError] = useState(null);
  const [newEventTypeError, setNewEventTypeError] = useState(null);

   // Staff Event Roles
   const [isStaffRoleModalVisible, setStaffRoleModalVisible] = useState(false);
   const [isRemoveStaffRoleModalVisible, setRemoveStaffRoleModalVisible] = useState(false);
   const [staffEventRoles, setStaffEventRoles] = useState([]);
   const [selectedStaffRole, setSelectedStaffRole] = useState({});
   const [deleteStaffRoleError, setDeleteStaffRoleError] = useState(null);
   const [newStaffRoleError, setNewStaffRoleError] = useState(null);

   // Client Event Roles
   const [isClientRoleModalVisible, setClientRoleModalVisible] = useState(false);
   const [isRemoveClientRoleModalVisible, setRemoveClientRoleModalVisible] = useState(false);
   const [clientEventRoles, setClientEventRoles] = useState([]);
   const [selectedClientRole, setSelectedClientRole] = useState({});
   const [deleteClientRoleError, setDeleteClientRoleError] = useState(null);
   const [newClientRoleError, setNewClientRoleError] = useState(null);

  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [eventTypeForm] = Form.useForm();
  const [staffRoleForm] = Form.useForm();
  const [clientRoleForm] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getAccountEventTypes())
      setEventTypes(results)

      const adminEventRoleResults = await sendRequest(getAccountAdminEventRoles())
      setStaffEventRoles(adminEventRoleResults)

      const clientEventRoleResults = await sendRequest(getAccountClientEventRoles())
      setClientEventRoles(clientEventRoleResults)
    } catch {
      setError(true)
    }
    finally {
      setLoading(false)
    }
  }

  const printLexo = () => {
    var runningRank = LexoRank.middle()
    var array = []
    for (var x = 0; x < 20; x++) {
      array.push(runningRank.toString())
      runningRank = runningRank.genNext()
    }
    console.log(array)
  }

  // --------------------------
  // EVENT TYPE FUNCTIONS
  // --------------------------
  const onSubmitEventType = async (values) => {
    try {
      const eventTypesCopy = cloneDeep(eventTypes)
      if (isEmpty(selectedEventType)) {
        // Check if name already exists
        const foundName = eventTypesCopy.find(x => x.event_type_name == values.event_type_name)
        if (!isUndefined(foundName)) {
          setNewEventTypeError("An event type with this name already exists.")
          return
        }
        // New Event Type
        const body = {
          event_type_name: values.event_type_name,
          rank: getRank(eventTypes)
        }
        await sendRequest(createAccountEventType(body))
      } else {
        // Check if name already exists
        const foundName = eventTypesCopy.find(x => x.event_type_name == values.event_type_name && x.event_type_id != selectedEventType.event_type_id)
        if (!isUndefined(foundName)) {
          setNewEventTypeError("An event type with this name already exists.")
          return
        }
        // Existing Event Type
        const body = {
          event_type_name: values.event_type_name,
          rank: selectedEventType.rank
        }
        await sendRequest(updateAccountEventType(selectedEventType.event_type_id, body))
      }
      await refreshPage()
      setEventTypeModalVisible(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an issue adding/editing your event type. Please try again.',
        duration: 3
      });
    }
  }

  const handleCancelEventType = () => {
    setEventTypeModalVisible(false);
  };

  const handleNewEventType = () => {
    setNewEventTypeError(null)
    eventTypeForm.resetFields()
    setSelectedEventType({})
    setEventTypeModalVisible(true);
  };

  const handleEditEventType = (item) => {
    setNewEventTypeError(null)
    eventTypeForm.resetFields()
    eventTypeForm.setFieldsValue(item)
    setSelectedEventType(item)
    setEventTypeModalVisible(true);
  };

  const handleDeleteEventType = (item) => {
    setDeleteEventTypeError(null)
    setSelectedEventType(item)
    setRemoveEventTypeModalVisible(true);
  };

  const confirmDeleteEventType = async () => {
    try {
      const results = await sendRequest(deleteAccountEventType(selectedEventType.event_type_id))
      if (results.status == "in-use") {
        setDeleteEventTypeError("This event type is currently assigned to one or more events. Please change the event type for those events before deleting this option.")
        return
      }
      await refreshPage()
      setRemoveEventTypeModalVisible(false)
    } catch {
      notification.error({
        message: 'Error',
        description: 'There was an issue deleting your event type. Please try again.',
        duration: 3
      });
    }
  }

  const onEventTypeSortEnd = async (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      var newEventTypes = cloneDeep(eventTypes)
      const currentEventType = newEventTypes[oldIndex]
      const rank = getNewRank(newEventTypes, oldIndex, newIndex)
      newEventTypes[oldIndex].rank = rank;
      const sortedEventTypes = sortByRank(newEventTypes);
      setEventTypes(sortedEventTypes)

      try {
        const body = {
          event_type_name: currentEventType.event_type_name,
          rank: rank
        }
        await sendRequest(updateAccountEventType(currentEventType.event_type_id, body))
      } catch {
        notification.error({
          message: 'Error',
          description: 'An issue occured updating your event type',
          duration: 3
        });
      }
    }
  };

  // --------------------------
  // STAFF EVENT ROLE FUNCTIONS
  // --------------------------
  const onSubmitStaffRole = async (values) => {
    try {
      const staffEventRolesCopy = cloneDeep(staffEventRoles)
      if (isEmpty(selectedStaffRole)) {
        // Check if name already exists
        const foundName = staffEventRolesCopy.find(x => x.role_name == values.role_name)
        if (!isUndefined(foundName)) {
          setNewStaffRoleError("A staff role with this name already exists.")
          return
        }
        // New Role
        const body = {
          role_name: values.role_name,
          rank: getRank(staffEventRoles)
        }
        await sendRequest(createAccountAdminEventRole(body))
      } else {
        // Check if name already exists
        const foundName = staffEventRolesCopy.find(x => x.role_name == values.role_name && x.admin_event_role_id != selectedStaffRole.admin_event_role_id)
        if (!isUndefined(foundName)) {
          setNewStaffRoleError("A staff role with this name already exists.")
          return
        }
        // Existing Event Type
        const body = {
          role_name: values.role_name,
          rank: selectedStaffRole.rank
        }
        await sendRequest(updateAccountAdminEventRole(selectedStaffRole.admin_event_role_id, body))
      }
      await refreshPage()
      setStaffRoleModalVisible(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an issue adding/editing your staff role. Please try again.',
        duration: 3
      });
    }
  }

  const handleCancelStaffRole = () => {
    setStaffRoleModalVisible(false);
  };

  const handleNewStaffRole = () => {
    setNewStaffRoleError(null)
    staffRoleForm.resetFields()
    setSelectedStaffRole({})
    setStaffRoleModalVisible(true);
  };

  const handleEditStaffRole = (item) => {
    setNewStaffRoleError(null)
    staffRoleForm.resetFields()
    staffRoleForm.setFieldsValue(item)
    setSelectedStaffRole(item)
    setStaffRoleModalVisible(true);
  };

  const handleDeleteStaffRole= (item) => {
    setDeleteStaffRoleError(null)
    setSelectedStaffRole(item)
    setRemoveStaffRoleModalVisible(true);
  };

  const confirmDeleteStaffRole = async () => {
    try {
      const results = await sendRequest(deleteAccountAdminEventRole(selectedStaffRole.admin_event_role_id))
      if (results.status == "in-use") {
        setDeleteStaffRoleError("This role is currently assigned to one or more events. Please update the roles for these events before deleting this option.")
        return
      }
      await refreshPage()
      setRemoveStaffRoleModalVisible(false)
    } catch {
      notification.error({
        message: 'Error',
        description: 'There was an issue deleting your staff role. Please try again.',
        duration: 3
      });
    }
  }

  const onStaffEventRoleSortEnd = async (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      var newStaffRoles = cloneDeep(staffEventRoles)
      const currentStaffRole = newStaffRoles[oldIndex]
      const rank = getNewRank(newStaffRoles, oldIndex, newIndex)
      newStaffRoles[oldIndex].rank = rank;
      const sortedStaffRoles = sortByRank(newStaffRoles);
      setStaffEventRoles(sortedStaffRoles)

      try {
        const body = {
          role_name: currentStaffRole.role_name,
          rank: rank
        }
        await sendRequest(updateAccountAdminEventRole(currentStaffRole.admin_event_role_id, body))
      } catch {
        notification.error({
          message: 'Error',
          description: 'There was an issue updating your staff role.',
          duration: 3
        });
      }
    }
  };

  // --------------------------
  // CLIENT EVENT ROLE FUNCTIONS
  // --------------------------
  const onSubmitClientRole = async (values) => {
    try {
      const clientEventRolesCopy = cloneDeep(clientEventRoles)
      if (isEmpty(selectedClientRole)) {
        // Check if name already exists
        const foundName = clientEventRolesCopy.find(x => x.role_name == values.role_name)
        if (!isUndefined(foundName)) {
          setNewClientRoleError("A client event role with this name already exists.")
          return
        }
        // New Role
        const body = {
          role_name: values.role_name,
          rank: getRank(clientEventRoles)
        }
        await sendRequest(createAccountClientEventRole(body))
      } else {
        // Check if name already exists
        const foundName = clientEventRolesCopy.find(x => x.role_name == values.role_name && x.client_event_role_id != selectedClientRole.client_event_role_id)
        if (!isUndefined(foundName)) {
          setNewClientRoleError("A client event role with this name already exists.")
          return
        }
        // Existing Role
        const body = {
          role_name: values.role_name,
          rank: selectedClientRole.rank
        }
        await sendRequest(updateAccountClientEventRole(selectedClientRole.client_event_role_id, body))
      }
      await refreshPage()
      setClientRoleModalVisible(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an issue adding/editing your client event role. Please try again.',
        duration: 3
      });
    }
  }

  const handleCancelClientRole = () => {
    setClientRoleModalVisible(false);
  };

  const handleNewClientRole = () => {
    setNewClientRoleError(null)
    clientRoleForm.resetFields()
    setSelectedClientRole({})
    setClientRoleModalVisible(true);
  };

  const handleEditClientRole = (item) => {
    setNewClientRoleError(null)
    clientRoleForm.resetFields()
    clientRoleForm.setFieldsValue(item)
    setSelectedClientRole(item)
    setClientRoleModalVisible(true);
  };

  const handleDeleteClientRole= (item) => {
    setDeleteClientRoleError(null)
    setSelectedClientRole(item)
    setRemoveClientRoleModalVisible(true);
  };

  const confirmDeleteClientRole = async () => {
    try {
      const results = await sendRequest(deleteAccountClientEventRole(selectedClientRole.client_event_role_id))
      if (results.status == "in-use") {
        setDeleteClientRoleError("This role is currently assigned to one or more events. Please update the roles for these events before deleting this option.")
        return
      }
      await refreshPage()
      setRemoveClientRoleModalVisible(false)
    } catch {
      notification.error({
        message: 'Error',
        description: 'There was an issue deleting your client event role. Please try again.',
        duration: 3
      });
    }
  }

  const onClientEventRoleSortEnd = async (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      var newClientRoles = cloneDeep(clientEventRoles)
      const currentClientRole = newClientRoles[oldIndex]
      const rank = getNewRank(newClientRoles, oldIndex, newIndex)
      newClientRoles[oldIndex].rank = rank;
      const sortedClientRoles = sortByRank(newClientRoles);
      setClientEventRoles(sortedClientRoles)

      try {
        const body = {
          role_name: currentClientRole.role_name,
          rank: rank
        }
        await sendRequest(updateAccountClientEventRole(currentClientRole.client_event_role_id, body))
      } catch {
        notification.error({
          message: 'Error',
          description: 'There was an issue updating your client event role.',
          duration: 3
        });
      }
    }
  };

  const renderMenuItem = (icon, title, action = () => {}) => {
    return (
      <Menu.Item>
        <Row className="pv-5" gutter={[10,10]}align="middle" onClick={() => action()}>
          <Col flex={0}>
            <div className="display-flex">{icon}</div>
          </Col>
          <Col flex={1}>{title}</Col>
        </Row>
      </Menu.Item>
    )
  }

  const eventTypeMenu = (item) => {
    return (
      <Menu>
        { renderMenuItem(<MdOutlineEdit/>, "Edit Event Type", () => handleEditEventType(item)) }
        { renderMenuItem(<FiTrash/>, "Delete Event Type", () => handleDeleteEventType(item)) }
      </Menu>
    )
  };

  const staffEventRoleMenu = (item) => {
    return (
      <Menu>
        { renderMenuItem(<MdOutlineEdit/>, "Edit Role", () => handleEditStaffRole(item)) }
        { renderMenuItem(<FiTrash/>, "Delete Role", () => handleDeleteStaffRole(item)) }
      </Menu>
    )
  };

  const clientEventRoleMenu = (item) => {
    return (
      <Menu>
        { renderMenuItem(<MdOutlineEdit/>, "Edit Role", () => handleEditClientRole(item)) }
        { renderMenuItem(<FiTrash/>, "Delete Role", () => handleDeleteClientRole(item)) }
      </Menu>
    )
  };

  const EventTypeSortableItem = sortableElement(({value, itemIndex}) => (
    <li className="border mb-5 mh-20">
      {renderEventType(value, itemIndex)}
    </li>
  ));

  const StaffEventRoleSortableItem = sortableElement(({value, itemIndex}) => (
    <li className="border mb-5 mh-20">
      {renderStaffEventRole(value, itemIndex)}
    </li>
  ));

  const ClientEventRoleSortableItem = sortableElement(({value, itemIndex}) => (
    <li className="border mb-5 mh-20">
      {renderClientEventRole(value, itemIndex)}
    </li>
  ));

  const renderEventType = (value, index) => {
    return (
      <Row align="middle" gutter={[10,10]} key={index} className="pr-10 pl-20 pv-10">
        <Col flex={1}>
          <div className="fs-14 fw-700 line-1-2">{value.event_type_name}</div>
        </Col>
        <Col>
          <div className="display-flex mr-10">
            <Dropdown overlay={eventTypeMenu(value)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderStaffEventRole = (value, index) => {
    return (
      <Row align="middle" gutter={[10,10]} key={index} className="pr-10 pl-20 pv-10">
        <Col flex={1}>
          <div className="fs-14 fw-700 line-1-2">{value.role_name}</div>
        </Col>
        <Col>
          <div className="display-flex mr-10">
            <Dropdown overlay={staffEventRoleMenu(value)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderClientEventRole = (value, index) => {
    return (
      <Row align="middle" gutter={[10,10]} key={index} className="pr-10 pl-20 pv-10" style={{ userSelect: 'none'}}>
        <Col flex={1}>
          <div className="fs-14 fw-700 line-1-2">{value.role_name}</div>
        </Col>
        <Col>
          <div className="display-flex mr-10">
            <Dropdown overlay={clientEventRoleMenu(value)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          </div>
        </Col>
      </Row>
    )
  }

  const EventTypeSortableList = SortableContainer(({items}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <EventTypeSortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const StaffEventRoleSortableList = SortableContainer(({items}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <StaffEventRoleSortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const ClientEventRoleSortableList = SortableContainer(({items}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <ClientEventRoleSortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Event Settings
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Event Settings</div>
      </div>
    )
  }

  const renderEventTypeModal = () => {
    return (
      <Modal visible={isEventTypeModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isEmpty(selectedEventType) ? "New" : "Edit"} Event Type</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancelEventType}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={eventTypeForm} layout="vertical" name="event-type" onFinish={onSubmitEventType}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Event Type Name", "event_type_name", true, false, "Enter a name...")}
            </Col>
            { !isNull(newEventTypeError) && (
              <Col xs={24}>
                <div className="c-red text-center">{newEventTypeError}</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">{isEmpty(selectedEventType) ? "Add" : "Edit"} Event Type</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancelEventType}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveEventTypeModal = () => {
    return (
      <Modal visible={isRemoveEventTypeModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveEventTypeModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Are you sure?</div>
          <div className="fw-500 fs-14 mt-20 text-center">Are you sure you would like to delete <span className="fw-700">{selectedEventType.event_type_name}</span> from your list of event types?</div>
          { !isNull(deleteEventTypeError) && (
            <div className="c-red text-center p-10 mt-10 bg-gray">{deleteEventTypeError}</div>
          )}
          <button className="primary-button warning mt-20" type="button" onClick={() => confirmDeleteEventType()}>Delete Event Type</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setRemoveEventTypeModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderStaffRoleModal = () => {
    return (
      <Modal visible={isStaffRoleModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isEmpty(selectedStaffRole) ? "New" : "Edit"} Staff Event Role</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancelStaffRole}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={staffRoleForm} layout="vertical" name="event-type" onFinish={onSubmitStaffRole}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Staff Event Role", "role_name", true, false, "Enter a name...")}
            </Col>
            { !isNull(newStaffRoleError) && (
              <Col xs={24}>
                <div className="c-red text-center">{newStaffRoleError}</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">{isEmpty(selectedStaffRole) ? "Add" : "Edit"} Staff Event Role</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancelStaffRole}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveStaffRoleeModal = () => {
    return (
      <Modal visible={isRemoveStaffRoleModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveStaffRoleModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Are you sure?</div>
          <div className="fw-500 fs-14 mt-20 text-center">Are you sure you would like to delete <span className="fw-700">{selectedStaffRole.role_name}</span> from your list of staff event roles?</div>
          { !isNull(deleteStaffRoleError) && (
            <div className="c-red text-center p-10 mt-10 bg-gray">{deleteStaffRoleError}</div>
          )}
          <button className="primary-button warning mt-20" type="button" onClick={() => confirmDeleteStaffRole()}>Delete Role</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setRemoveStaffRoleModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderClientRoleModal = () => {
    return (
      <Modal visible={isClientRoleModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isEmpty(selectedClientRole) ? "New" : "Edit"} Client Event Role</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancelClientRole}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={clientRoleForm} layout="vertical" name="event-type" onFinish={onSubmitClientRole}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Client Event Role", "role_name", true, false, "Enter a name...")}
            </Col>
            { !isNull(newClientRoleError) && (
              <Col xs={24}>
                <div className="c-red text-center">{newClientRoleError}</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">{isEmpty(selectedClientRole) ? "Add" : "Edit"} Client Event Role</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancelStaffRole}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveClientRoleeModal = () => {
    return (
      <Modal visible={isRemoveClientRoleModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveClientRoleModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Are you sure?</div>
          <div className="fw-500 fs-14 mt-20 text-center">Are you sure you would like to delete <span className="fw-700">{selectedClientRole.role_name}</span> from your list of client event roles?</div>
          { !isNull(deleteClientRoleError) && (
            <div className="c-red text-center p-10 mt-10 bg-gray">{deleteClientRoleError}</div>
          )}
          <button className="primary-button warning mt-20" type="button" onClick={() => confirmDeleteClientRole()}>Delete Role</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setRemoveClientRoleModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderEventTypeSection = () => {
    return (
      <div className="shadow-card pb-15 mb-20">
        <Row className="b-border pb-15 pt-20 mh-20 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Event Types</div>
          </Col>
          { eventTypes.length > 0 && (
            <Col>
              <div className="blue-link" onClick={() => handleNewEventType()}>Add</div>
            </Col>
          )}
          <Col xs={24}>
            <div className="bg-gray p-10 mt-10">The event types below will display as options when creating or modifying an event. Drag and drop the items to arrange the order in which they will display in a list.</div>
          </Col>
        </Row>
        { eventTypes.length == 0 ? (
          <div className="p-15 text-center">
            <div className="fs-14 fw-500 c-text-gray">
              No event types have been added yet.
            </div>
            <button className="small-primary-button mt-30" onClick={handleNewEventType}>Add Event Type</button>
          </div>
        ) : (
          <EventTypeSortableList 
          items={eventTypes} 
          onSortEnd={({ oldIndex, newIndex }) => onEventTypeSortEnd(oldIndex, newIndex)} 
          helperClass="question-row-dragging" 
          pressDelay={100} 
          lockAxis="y"/>
        )}
      </div>
    )
  }

  const renderStaffEventRolesSection = () => {
    return (
      <div className="shadow-card pb-15 mb-20">
        <Row className="b-border pb-15 pt-20 mh-20 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Staff Event Roles</div>
          </Col>
          { staffEventRoles.length > 0 && (
            <Col>
              <div className="blue-link" onClick={() => handleNewStaffRole()}>Add</div>
            </Col>
          )}
          <Col xs={24}>
            <div className="bg-gray p-10 mt-10">The roles below will display as options when assigning staff members to an event. Drag and drop the items to arrange the order in which they will display in a list.</div>
          </Col>
        </Row>
        { staffEventRoles.length == 0 ? (
          <div className="p-15 text-center">
            <div className="fs-14 fw-500 c-text-gray">
              No staff event roles have been added yet.
            </div>
            <button className="small-primary-button mt-30" onClick={handleNewStaffRole}>Add Staff Event Role</button>
          </div>
        ) : (
          <StaffEventRoleSortableList 
            items={staffEventRoles} 
            onSortEnd={({ oldIndex, newIndex }) => onStaffEventRoleSortEnd(oldIndex, newIndex)} 
            helperClass="question-row-dragging" 
            pressDelay={100} 
            lockAxis="y"/>
        )}
      </div>
    )
  }

  const renderClientEventRolesSection = () => {
    return (
      <div className="shadow-card pb-15 mb-20">
        <Row className="b-border pb-15 pt-20 mh-20 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Client Event Roles</div>
          </Col>
          { clientEventRoles.length > 0 && (
            <Col>
              <div className="blue-link" onClick={() => handleNewClientRole()}>Add</div>
            </Col>
          )}
          <Col xs={24}>
            <div className="bg-gray p-10 mt-10">The roles below will display as options when assigning clients to an event. Drag and drop the items to arrange the order in which they will display in a list.</div>
          </Col>
        </Row>
        { clientEventRoles.length == 0 ? (
          <div className="p-15 text-center">
            <div className="fs-14 fw-500 c-text-gray">
              No client event roles have been added yet.
            </div>
            <button className="small-primary-button mt-30" onClick={handleNewClientRole}>Add Client Event Role</button>
          </div>
        ) : (
          <ClientEventRoleSortableList 
            items={clientEventRoles} 
            onSortEnd={({ oldIndex, newIndex }) => onClientEventRoleSortEnd(oldIndex, newIndex)} 
            helperClass="question-row-dragging" 
            pressDelay={100} 
            lockAxis="y"/>
        )}
      </div>
    )
  }

  const renderContent = () => {
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <>
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
          { renderEventTypeSection() }
          { renderStaffEventRolesSection() }
          { renderClientEventRolesSection() }
        </FloatingContainer>
        { renderEventTypeModal() }
        { renderStaffRoleModal() }
        { renderClientRoleModal() }
        { renderConfirmRemoveEventTypeModal() }
        { renderConfirmRemoveStaffRoleeModal() }
        { renderConfirmRemoveClientRoleeModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EventSettingsPage;
