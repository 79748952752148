import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { BackToHomeLink, DocLink, DocArrowLink } from '../../../components/docComponents'
import { Image } from 'antd';
import addClientModalImage from '../../../images/add-client-modal.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsInvitingClients = () => {

  useDocumentTitle("Inviting Clients | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Inviting Clients</div>
        <div className="fw-500 fs-16 c-text-gray">How to send an invitation so clients can plan their event.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">How it works</div>
        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          Clients can only access the website and mobile app if you send them an invitation. Once they accept the invite, they will receive information on how to download the app.
        </div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select <b>New Event</b>.
          </li>
          <li className="mb-15">
           While creating the event, click the <b>Add Client</b> button under the Client section.
          </li>
          <li className="mb-15">
           Enter the client's details and check the <b>Send invitation when event is created</b> checkbox.
            <div className="bg-gray pv-10 ph-15 mt-10 radius-8">
              Note: If you plan on creating a contract before allowing your client to access the planning center, leave this checkbox unchecked and send an invitation later on the event details page after your event is officially booked.
            </div>
           {/* <div className="mt-10">
            <i>Note: If you plan on creating a contract before allowing your client to access the planning center, leave this checkbox unchecked and send an invitation later on the event details page after your event is officially booked.</i>
           </div> */}
           <div className="mt-15">
              <Image src={addClientModalImage} style={{ width: '100%', maxWidth: 400 }}/>
            </div>
          </li>
          <li className="mb-15">
           Finish entering the details of the event and click <b>Save</b>.
           <div className="mt-10">
           <div className="bg-gray pv-10 ph-15 mt-10 radius-8">Note: You can also send or resend an invitation through the event details page after the event is created.</div>
           </div>
          </li>
          <li className="mb-15">
           You can now monitor the client's invitation status on the event details page.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/events/new")}>Create an event</button>
        </div>
        <div className="b-border mv-50"></div>
        <div className="fw-700 fs-18">What will my clients see?</div>
        <DocArrowLink to="/admin/docs/what-clients-see"/>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }
  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsInvitingClients;
