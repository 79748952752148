import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash";
import moment from "moment-timezone";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { 
  renderEmailField,
  renderInputField,
  renderPhoneField,
  renderSearchSelectField,
  renderGlobalAddressField,
  renderGlobalSecondAddressField,
  renderGlobalCityField,
  renderGlobalStateField,
  renderGlobalZipField
} from '../../../components/formFields'
import { Row, Col, notification, Form, Button, Modal, Upload } from 'antd';
import { createClient, getClient, sendClientInvitation, updateClient, getClientEvents, uploadInternalClientPhoto } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import EventCard from '../../../components/eventCard';
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdArrowForwardIos,MdPhotoCamera } from "react-icons/md";
import { LoadingOutlined } from '@ant-design/icons';
import { getTimezoneOptions } from '../../../helpers/timezoneHelper';
import { formatDateTimeLong } from '../../../helpers/dateHelper';
import { resizeFile, isValidFileType, getBase64 } from '../../../helpers/imageHelper';
import userPlaceholderImage from '../../../images/user-placeholder.png';
import AppContext from '../../../app/context';
import { 
  getDefaultCountryCode, 
  setDefaultCountryCode, 
  getDefaultState, 
  getCountryOptions, 
  getDefaultPhoneCountryCode,
  getFormattedPhoneNumber,
  getPhoneNumber,
  getCountryCodeForPhoneNumber
} from '../../../helpers/addressHelper';

const NewClientPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [phoneId, setPhoneId] = useState("");
  const [addressId, setAddressId] = useState("");
  const [isConfirmInvitationModalVisible, setConfirmInvitationModalVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [isPhotoMessageVisible, setPhotoMessageVisible] = useState(false);
  const [isUpdateEmailErrorModalVisible, setUpdateEmailErrorModalVisible] = useState(false);
  const [isUpdateEmailConfirmationModalVisible, setUpdateEmailConfirmationModalVisible] = useState(false);
  const [requestBody, setRequestBody] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("US");
  const [phoneCountryCode, setPhoneCountryCode] = useState("US");

  useDocumentTitle("Client Details")
  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const userId = params.id;
  const isNew = isUndefined(userId);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadCountries()
    if (!isNew) {
      refreshPage();
    } else {
      setCountryField()
      setPhoneCountryCode(getDefaultPhoneCountryCode())
      form.setFieldsValue({ timezone: moment.tz.guess() })
      setStateField()
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      // Fetch user
      const userResults = await sendRequest(getClient(userId))
      if (isEmpty(userResults) || userResults.user_type_id == 1) {
        navigate("/admin/clients")
        return
      }
      // Set form fields
      var phone = ""
      if (userResults.phone_numbers && userResults.phone_numbers.length > 0) {
        phone = userResults.phone_numbers[0].phone_number
        setPhoneId(userResults.phone_numbers[0].phone_id)
      }
      var country = ""
      var line_1 = ""
      var line_2 = ""
      var city = ""
      var state = ""
      var zip = ""
      if (userResults.addresses && userResults.addresses.length > 0) {
        country = userResults.addresses[0].country
        line_1 = userResults.addresses[0].line_1
        line_2 = userResults.addresses[0].line_2
        city = userResults.addresses[0].city
        state = userResults.addresses[0].state
        zip = userResults.addresses[0].zip
        setAddressId(userResults.addresses[0].address_id)
      }
      const formValues = {
        first_name: userResults.first_name,
        last_name: userResults.last_name,
        email: userResults.email,
        phone: getFormattedPhoneNumber(phone),
        timezone: userResults.timezone ?? moment.tz.guess(),
        country: country,
        line_1: line_1,
        line_2: line_2,
        city: city,
        state: state,
        zip: zip
      }
      setUser(userResults)
      form.setFieldsValue(formValues)

      // Set default country
      if (isEmpty(country)) {
        setCountryField()
      } else {
        setCountryCode(country)
      }

      // Set default state if not populated
      if (isEmpty(state)) {
        setStateField()
      }

      // Set default phone country code
      if (isEmpty(phone)) {
        setPhoneCountryCode(getDefaultPhoneCountryCode())
      } else {
        setPhoneCountryCode(getCountryCodeForPhoneNumber(phone))
      }

      const clientEventsResults = await sendRequest(getClientEvents(userId))
      setEvents(clientEventsResults)
      
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const loadCountries = async () => {
    const data = getCountryOptions()
    setCountries(data);
  }

  const setCountryField = async () => {
    const defaultCountryCode = getDefaultCountryCode()
    const countryCode = !isEmpty(defaultCountryCode) ? defaultCountryCode : "US"
    setCountryCode(countryCode)
    form.setFieldsValue({ country: countryCode})
  }

  const setStateField = async () => {
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      form.setFieldsValue({ state: state })
    }
  }

  const onCountryChange = (value) => {
    setCountryCode(value)
    setDefaultCountryCode(value)
    setStateField()
  }

  const onSubmit = async (values) => {
    try {
      if (isNew) {
        var body = {
          ...values,
          phone: getPhoneNumber(values.phone, phoneCountryCode)
        }
        setRequestBody(body)
        setSaving(true)
        const clientResults = await sendRequest(createClient(body))
        if (clientResults.status == "email-in-use") {
          setUpdateEmailErrorModalVisible(true)
          setSaving(false)
          return
        }
        // Upload photo if needed
        if (clientResults.user_id && imageFile) {
          // Resize photo
          const newFile = await resizeFile(imageFile)

          // Get blob from URI
          let response = await fetch(newFile);
          let blob = await response.blob()

          // Upload photo
          const formData = new FormData();
          formData.append('photo', blob);
          formData.append('account_id', auth.user.account_id);
          await sendRequest(uploadInternalClientPhoto(clientResults.user_id, formData))
        }
        displaySuccess()
      } else {
        var body = {
          ...values,
          phone: getPhoneNumber(values.phone, phoneCountryCode),
          phone_id: phoneId,
          address_id: addressId
        }
        setRequestBody(body)
        if (user.email != values.email) {
          setUpdateEmailConfirmationModalVisible(true)
        } else {
          confirmUpdate(body)
        }
      }
    } catch {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const confirmEmailUpdate = () => {
    setUpdateEmailConfirmationModalVisible(false)
    confirmUpdate(requestBody)
  }

  const confirmUpdate = async (body) => {
    try {
      setSaving(true)
      const updateResponse = await sendRequest(updateClient(user.user_id, body))
      if (updateResponse.status == "email-in-use") {
        setUpdateEmailErrorModalVisible(true)
        setSaving(false)
        return
      }
      // Upload photo (if needed)
      if (imageFile) {
        // Resize photo
        const newFile = await resizeFile(imageFile)

        // Get blob from URI
        let response = await fetch(newFile);
        let blob = await response.blob()
        
        const formData = new FormData();
        formData.append('photo', blob);
        formData.append('account_id', auth.user.account_id);
        await sendRequest(uploadInternalClientPhoto(user.user_id, formData))
      }
      displaySuccess()
    } catch {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your changes have been saved.',
      duration: 3
    });
    navigate("/admin/clients");
  }

  const onSendInvitation = () => {
    setConfirmInvitationModalVisible(true)
  }

  const sendInvitation = () => {
    setConfirmInvitationModalVisible(false)
    sendRequest(sendClientInvitation({ email: user.email, type: "client" })).then(() => {
      refreshPage()
    })
  }

  const handleImageChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }
      getBase64(info.file, url => setImageUrl(url))
      setImageFile(info.file)
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <button className="page-title-button small-100"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button small-100" onClick={() => form.submit()}>Save</button>
      )
    }
  }

  const renderConfirmInvitationModal = () => {
    return (
      <Modal visible={isConfirmInvitationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmInvitationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Send Invitation</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to send an invitation to <span className="fw-700">{user.email}</span>?</div>
          <button className="primary-button" type="button" onClick={() => sendInvitation()}>Send</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmInvitationModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/clients")}>
            Clients
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Detail
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{isNew ? "New Client" : user.first_name + " " + user.last_name}</div>
      </div>
    )
  }

  const renderActivity = () => {
    if (isNew) {
      return null
    }
    var lastLogin = null
    var invitationStatus = null
    var lastInvitationSent = null
    var appDownload = user.login_with_app ? "Yes" : "No"
    var showInvitationButton = true
    var invitationButtonText = ""

    if (user.last_login_datetime) {
      lastLogin = formatDateTimeLong(user.last_login_datetime, accountSettings)
      invitationStatus = "Accepted"
      showInvitationButton = false
    } else if (user.last_invitation_datetime) {
      lastLogin = "N/A"
      invitationStatus = "Pending"
      lastInvitationSent = formatDateTimeLong(user.last_invitation_datetime, accountSettings)
      showInvitationButton = true
      invitationButtonText = "Re-send Invitation"
    } else {
      lastLogin = "N/A"
      invitationStatus = "Not Sent"
      showInvitationButton = true
      invitationButtonText = "Send Invitation"
    }
    return (
      <>
        <div className="shadow-card p-20">
          <div className="fs-20 fw-700 mb-20 line-1">Activity</div>
          <div className="bg-gray radius-8 p-10">
            <div className="fs-12 fw-700">Invitation Status: <span className="fw-500">{invitationStatus}</span></div>
            { lastInvitationSent && (
              <div className="fs-12 fw-700">Invitation Sent: <span className="fw-500">{lastInvitationSent}</span></div>
            )}
            <div className="fs-12 fw-700">Downloaded the app?: <span className="fw-500">{appDownload}</span></div>
            <div className="fs-12 fw-700">Last Login: <span className="fw-500">{lastLogin}</span></div>
          </div>
          { showInvitationButton && (
            <Button className="admin-small-button mt-15" onClick={() => onSendInvitation()}>
              {invitationButtonText}
            </Button>
          )}
        </div>
      </>
    )
  }

  const renderEventCard = (event, index) => {
    return <EventCard event={event} accountSettings={accountSettings} rightIcon={null} className={index == events.length - 1 ? "" : "b-border"} onClick={() => navigate(`/admin/events/${event.event_id}`)} key={index}/>;
  }

  const renderClientEvents = () => {
    if (isNew || events.length == 0) {
      return null;
    }
    return (
      <div className="shadow-card mt-20 ph-10 pb-10">
        <div className="fs-20 fw-700 line-1 ph-10 pt-20 pb-10">Events</div>
        { events.map((event,i) => renderEventCard(event,i))}
      </div>
    )
  }

  const renderProfileHeader = () => {
    var image = userPlaceholderImage
    var borderClass = ""
    if (imageUrl) {
      image = imageUrl
      borderClass = "gray-border"
    } else if (user.photo) {
      image = user.photo
      borderClass = "gray-border"
    } else if (user.internal_photo) {
      image = user.internal_photo
      borderClass = "gray-border"
    }
    return (
      <div className="shadow-card ph-20 pv-30 mb-15">
        <div className="text-center">
          <div className={`profile-photo-container ${borderClass}`}>
            { user.photo ? (
              <div className="profile-photo" style={{ backgroundImage: `url(${image})`}} onClick={() => setPhotoMessageVisible(true)}>
                <div className="photo-overlay">
                  <MdPhotoCamera/>
                </div>
              </div>
            ) : (
              <Upload
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                onChange={handleImageChange}
                maxCount={1}
              >
                <div className="profile-photo" style={{ backgroundImage: `url(${image})`}}>
                  <div className="photo-overlay">
                    <MdPhotoCamera/>
                  </div>
                </div>
              </Upload>
            )}
          </div>
          <div className="fs-18 fw-700 mt-5">{ user.first_name } { user.last_name }</div>
          <div className="fs-14 fw-300">{ user.email }</div>
        </div>
      </div>
    )
  }

  const renderPhotoMessageModal = () => {
    return (
      <Modal visible={isPhotoMessageVisible} footer={null} closable={false} width={400} onCancel={() => setPhotoMessageVisible(false)} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setPhotoMessageVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Client Photo</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">This photo has been uploaded by the client and cannot be changed. You are only able to upload an internal photo if the client has not selected a profile picture.</div>
          <button className="primary-button" type="button" onClick={() => setPhotoMessageVisible(false)}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderUpdateEmailError = () => {
    return (
      <Modal visible={isUpdateEmailErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setUpdateEmailErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Email Already Exists</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">A user with the email address <span className="fw-700">{requestBody.email}</span> already exists. Please enter a different email address and try again.</div>
          <button className="primary-button" type="button" onClick={() => setUpdateEmailErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderUpdateEmailConfirmation = () => {
    return (
      <Modal visible={isUpdateEmailConfirmationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setUpdateEmailConfirmationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Email Update</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to update this client's email address to <span className="fw-700">{requestBody.email}</span>? They will need to re-verify their email before signing in again.</div>
          <button className="primary-button" type="button" onClick={() => confirmEmailUpdate()}>Continue</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setUpdateEmailConfirmationModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        { renderProfileHeader() }
        <Form form={form} layout="vertical" name="employee" onFinish={onSubmit}>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Client Details</div>
              <Row gutter={[10,10]}>
                <Col xs={24} md={12}>
                  {renderInputField("First Name", "first_name", true)}
                </Col>
                <Col xs={24} md={12}>
                  {renderInputField("Last Name", "last_name", true)}
                </Col>
                <Col xs={24}>
                  {renderEmailField("Email", "email", true)}
                </Col>
                <Col xs={24}>
                  {renderPhoneField("Phone", "phone", false, false, phoneCountryCode, setPhoneCountryCode)}
                </Col>
                <Col xs={24}>
                  {renderSearchSelectField("Timezone", "timezone", "Select Timezone", getTimezoneOptions(), true)}
                </Col>
              </Row>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Address</div>
            <Row align="middle" gutter={[15,15]}>
              <Col xs={24} md={24}>
                { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, false, onCountryChange) }
              </Col>
              <Col xs={24} md={24}>
                { renderGlobalAddressField(countryCode, "line_1", false) }
              </Col>
              <Col xs={24} md={24}>
                { renderGlobalSecondAddressField(countryCode, "line_2", false) }
              </Col>
              <Col xs={24} md={24}>
                { renderGlobalCityField(countryCode, "city", false) }
              </Col>
              <Col xs={24} md={16}>
                { renderGlobalStateField(countryCode, "state", false) }
              </Col>
              <Col xs={12} md={8}>
                { renderGlobalZipField(countryCode, "zip", false) }
              </Col>
            </Row>
          </div>
        </Form>
        { renderActivity() }
        { renderClientEvents() }
        <div className="sticky-footer p-0">
          <div className="p-15 bg-white t-border">
            { renderSaveButton() }
          </div>
        </div>
        { renderConfirmInvitationModal() }
        { renderPhotoMessageModal() }
        { renderUpdateEmailError() }
        { renderUpdateEmailConfirmation() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={96}/>
  );
}

export default NewClientPage;
