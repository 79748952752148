import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Row, Col, Input, DatePicker, Select, Checkbox, Modal, notification } from 'antd';
import NumberFormat from 'react-number-format';
import useApi from '../../../../hooks/useApi';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import { getEventInvoice, updateEventInvoice, getEventClients } from '../../../../api';
import LoadingSpinner from '../../../../components/loading';
import FloatingContainer from '../../../../components/floatingContainer'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import InvoiceSummary from '../../../../components/invoiceSummary';
import { getNumericCurrency, getCurrencySymbol } from '../../../../helpers/contractHelper';
import { getDatePickerFormat } from '../../../../helpers/dateHelper';
import InvoiceStatus from '../../../../constants/invoiceStatus';
import { isEmpty } from "lodash";
import moment from "moment";

const EventInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventFinancialSummary, setEventFinancialSummary] = useState({});

  const [notes, setNotes] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [includePaymentLink, setIncludePaymentLink] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [dueDate, setDueDate] = useState(moment());
  const [amountDueType, setAmountDueType] = useState("DEPOSIT");
  const [amountDue, setAmountDue] = useState("");
  const [eventInvoice, setEventInvoice] = useState({});
  const [clients, setClients] = useState([]);
  const [selectedBillTo, setSelectedBillTo] = useState("");
  const [selectedBillToFirstName, setSelectedBillToFirstName] = useState("");
  const [selectedBillToLastName, setSelectedBillToLastName] = useState("");
  const [selectedBillToEmail, setSelectedBillToEmail] = useState("");
  const [isPayNowErrorVisible, setPayNowErrorVisible] = useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const eventId = params.event_id;
  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getEventInvoice(eventId, invoiceId))
      if (results.status == InvoiceStatus.PAID) {
        navigate(`/admin/events/${eventId}?tab=documents`)
        return
      }
      setEventInvoice(results)
      setAmountDueType(results.amount_due_type)
      setAmountDue(results.amount_due)
      setNotes(results.notes)
      setIncludePaymentLink(results.include_payment_link == true)
      setPaymentLink(results.payment_link)
      setInvoiceDate(moment.utc(results.invoice_date))
      setDueDate(moment.utc(results.due_date))
      setEventFinancialSummary(results.summary)

      if (results.bill_to_user_id) {
        setSelectedBillTo(results.bill_to_user_id)
      } else {
        setSelectedBillTo(1)
        setSelectedBillToFirstName(results.bill_to_first_name)
        setSelectedBillToLastName(results.bill_to_last_name)
        setSelectedBillToEmail(results.bill_to_email)
      }

      const clientResults = await sendRequest(getEventClients(eventId))
      setClients(clientResults)
    } finally {
      setLoading(false)
    }
  }

  const onSave = async (refresh = false) => {
    try {
      const body = {
        bill_to_user_id: selectedBillTo != 1 ? selectedBillTo : null,
        bill_to_first_name: selectedBillToFirstName,
        bill_to_last_name: selectedBillToLastName,
        bill_to_email: selectedBillToEmail,
        invoice_date: invoiceDate.startOf("day").format(),
        due_date: dueDate.startOf("day").format(),
        amount_due_type: amountDueType,
        amount_due: getNumericCurrency(amountDue),
        notes: notes,
        include_payment_link: includePaymentLink,
        refresh: refresh
      }
      await sendRequest(updateEventInvoice(eventId, invoiceId, body))
      if (refresh) {
        notification.success({
          message: 'Success!',
          description: 'The line items for this invoice have been refreshed.',
          duration: 3
        });
      }
    } catch {}
  }

  const onNext = async () => {
    try {
      await onSave(false)
      navigate(`/admin/events/${eventId}/invoices/${invoiceId}/preview`)
    } catch {}
  }

  const onRefresh = async () => {
    try {
      await onSave(true)
      await refreshPage()
    } catch {}
  }

  const onBillToChange = (value) => {
    setSelectedBillTo(value)
    setSelectedBillToFirstName("")
    setSelectedBillToLastName("")
    setSelectedBillToEmail("")
  }

  const onIncludePayNowButton = (checked) => {
    if (isEmpty(paymentLink) && checked) {
      setPayNowErrorVisible(true)
      return
    }
    setIncludePaymentLink(checked)
  }

  const onAmountDueTypeChange = (value) => {
    setAmountDueType(value)
    if (value == "DEPOSIT") {
      setAmountDue(eventFinancialSummary.deposit)
    } else if (value == "BALANCE") {
      setAmountDue(eventFinancialSummary.balance)
    } else {
      setAmountDue("")
    }
  }

  const renderHeaderButton = () => {
    return <button className="small-primary-button" style={{ }} onClick={() => onNext()}>Next: Save & Preview</button>;
  }

  const renderHeader = () => {
    var backPath = `/admin/events/${eventId}?tab=documents`

    if (screens.md) {
      return (
        <div className="event-contract--header p-20"> 
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(eventInvoice.status) ? eventInvoice.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600">{!isEmpty(eventInvoice.status) ? eventInvoice.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-15">
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    }
  }

  const renderPayNowErrorModal = () => {
    return (
      <Modal visible={isPayNowErrorVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setPayNowErrorVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Payment Link Needed</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">In order to include a <b>Pay Invoice</b> button, you will need to configure a Payment Link by going to the <b>Invoices</b> page under Account Setup.</div>
          <button className="primary-button" type="button" onClick={() => setPayNowErrorVisible(false)}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderLogo = () => {
    if (eventInvoice.logo) {
      return (
        <img src={eventInvoice.logo} style={{ width: 200 }}/>
      )
    } else {
      return (
        <div className="text-center bg-gray p-20 radius-8" style={{ border: "1px dashed #CCC"}}>
          <div className="fw-700 mb-5">Company Logo</div>
          <div className="fs-12">Upload a logo on the <span className="blue-link fs-12" onClick={() => navigate("/admin/setup/branding")}>Branding</span> page to display it here.</div>
        </div>
      )
    }
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    var clientData = clients.map((client) => {
      return {
        value: client.user_id,
        text: client.first_name + " " + client.last_name
      }
    })
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={850}>
          <div className="shadow-card p-30 mt-15 text-center">
            <div className="fs-14 fw-700 mb-5">Instructions</div>
            An invoice is generated based on the current state of the financials. If the line items below are incorrect, you will need to go back and adjust the packages, add-ons, discounts, and/or payments before continuing. Now that the invoice is created, the line items and totals will not change automatically. You will need to select "Refresh Line Items" below to pick up any new changes.
          </div>
          <div className={`shadow-card ${paddingClass} mt-15`}>
            { screens.md ? (
              <div className="flex-row">
                <div className="flex-1">
                  { renderLogo() }
                </div>
                <div className="flex-1">
                  <div className="fs-32 fw-500 text-right">INVOICE</div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <div className="mt-10">
                  { renderLogo() }
                </div>
                <div className="mt-10">
                  <div className="fs-32 fw-500 text-center">INVOICE</div>
                </div>
              </div>
            )}
            <Row className="mt-50" gutter={[30,30]}>
              <Col xs={24} md={12}>
                <div className="fs-14 fw-700">Bill To:</div>
                <Select value={selectedBillTo} style={{ width: 220 }} onChange={(value) => onBillToChange(value)}>
                  { clientData.map((option) => (
                    <Select.Option value={option.value} key={option.value}>{option.text}</Select.Option>
                  ))}
                  <Select.Option value={1}>Other</Select.Option>
                </Select>
                { selectedBillTo == 1 && (
                  <Row gutter={[15,15]} className="mt-15">
                    <Col xs={24} sm={12}>
                      <Input placeholder={"First Name"} value={selectedBillToFirstName} onChange={(e) => setSelectedBillToFirstName(e.target.value)}/>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Input placeholder={"Last Name"} value={selectedBillToLastName} onChange={(e) => setSelectedBillToLastName(e.target.value)}/>
                    </Col>
                    <Col xs={24}>
                      <Input placeholder={"Email"} value={selectedBillToEmail} onChange={(e) => setSelectedBillToEmail(e.target.value)}/>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xs={24} md={12}>
                <div className="flex-row">
                  <div className="flex-0" style={{ minWidth: 120 }}>
                    <div className="fs-14 fw-700">Invoice #:</div>
                  </div>
                  <div className="flex-1">
                    <div className="">{ eventInvoice.invoice_number }</div>
                  </div>
                </div>
                <div className="flex-row flex-middle mt-5">
                  <div className="flex-0" style={{ minWidth: 120 }}>
                    <div className="fs-14 fw-700">Invoice Date:</div>
                  </div>
                  <div className="flex-1">
                    <DatePicker format={getDatePickerFormat(accountSettings)} value={invoiceDate} onChange={(date) => setInvoiceDate(date)}/>
                  </div>
                </div>
                <div className="flex-row flex-middle mt-5">
                  <div className="flex-0" style={{ minWidth: 120 }}>
                    <div className="fs-14 fw-700">Due Date:</div>
                  </div>
                  <div className="flex-1">
                    <DatePicker format={getDatePickerFormat(accountSettings)} value={dueDate} onChange={(date) => setDueDate(date)}/>
                  </div>
                </div>
              </Col>
            </Row>
            
            <div className="mt-50 mb-5 mr-15 display-flex">
              <div className="flex-1"></div>
              <div className="blue-link display-flex flex-middle flex-0" onClick={() => onRefresh()}>
                <div className="display-flex flex-0"><FiRefreshCw size={14} style={{ marginRight: 8 }}/></div> 
                Refresh Line Items
              </div>
            </div>
            <InvoiceSummary summary={eventFinancialSummary}/>

            <Row className="mt-30" gutter={[20,20]}>
              <Col xs={24} md={14}>
                <div className="fs-14 fw-700 mb-5">Notes</div>
                <Input.TextArea rows={4} placeholder={"Add optional notes, terms, etc."} value={notes} onChange={(e) => setNotes(e.target.value)}/>
              </Col>
              <Col xs={24} md={10}>
                <div className="text-center p-20 bg-gray radius-8">
                  <div className="fs-14 fw-700 mb-5">Amount Due</div>
                  <Select size="large" value={amountDueType} style={{ width: 220 }} onChange={(value) => onAmountDueTypeChange(value)}>
                    <Select.Option value="DEPOSIT">{accountSettings.holding_fee_term} ({eventFinancialSummary.deposit})</Select.Option>
                    <Select.Option value="BALANCE">Balance ({eventFinancialSummary.balance})</Select.Option>
                    <Select.Option value="CUSTOM">Custom</Select.Option>
                  </Select>
                  { amountDueType == "CUSTOM" && (
                    <div className="mt-10">
                      <NumberFormat 
                        className="ant-input ant-input-lg" 
                        displayType={'input'} 
                        thousandSeparator={true} 
                        prefix={getCurrencySymbol(eventInvoice.currency)} 
                        decimalScale={2} 
                        fixedDecimalScale={true}
                        style={{ width: 220 }}
                        placeholder={`${getCurrencySymbol(eventInvoice.currency)}0.00`}
                        value={amountDue}
                        onChange={(e) => setAmountDue(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="mt-20 text-center">
                    <div className="fs-14 fw-700 mb-5">
                      <Checkbox checked={includePaymentLink} value={true} onChange={(e) => onIncludePayNowButton(e.target.checked)}>Include Pay Button</Checkbox>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="shadow-card p-15 text-center mt-15">
            <div className="mt-5"><b>Note:</b> A payment link and default invoice notes can be configured on the <div className="blue-link" onClick={() => navigate("/admin/setup/payments")}>Invoice Settings</div> page.</div>
          </div>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
      { renderPayNowErrorModal() }
    </div>
  );
}

export default EventInvoicePage;
