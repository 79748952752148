import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import moment from 'moment';
import { cloneDeep, toLower, isArray, chain } from 'lodash';
import AppContext from '../../../app/context';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { Row, Col, Dropdown, Menu, Input, Select, Grid, Divider, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import { MdOutlineMoreHoriz, MdOutlineFilterList, MdOutlineClose } from "react-icons/md";
import { getEvents, getAdminUsers, deleteEvent } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import ErrorCard from '../../../components/errorCard';
import EventCard from '../../../components/eventCard';
import Permissions from '../../../constants/permissions';
import DateFormatTypes from '../../../constants/dateFormatTypes';
import { hasPermission } from '../../../helpers/permissionHelper';
import { getEventFilters, setEventFilters } from '../../../helpers/eventHelper';
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'
import FloatingContainer from '../../../components/floatingContainer'
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select; 

const EventsPage = () => {

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}
  const canViewAllEvents = hasPermission(user, Permissions.VIEW_ALL_EVENTS);

  useDocumentTitle("Events")
  const navigate = useNavigate();
  const location = useLocation()
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [events, setEvents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("upcoming");
  const [selectedAssigned, setSelectedAssigned] = useState();
  const [searchText, setSearchText] = useState("");
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isDeleting, setDeleting] = useState(false);
  const [isDeleteError, setDeleteError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const eventFilters = getEventFilters()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })

    var appliedFilter = null;
    if (queryStrings.filter) {
      appliedFilter = queryStrings.filter;
    } else if (eventFilters.filter) {
      appliedFilter = eventFilters.filter;
    } else {
      appliedFilter = "upcoming"
    }
    var appliedAssigned = null;
    if (queryStrings.assigned) {
      appliedAssigned = Number(queryStrings.assigned);
    } else if (eventFilters.assigned) {
      appliedAssigned = eventFilters.assigned;
    }
    var appliedSearch = null;
    if (queryStrings.q) {
      appliedSearch = queryStrings.q
    }
    setSelectedFilter(appliedFilter)
    setSelectedAssigned(appliedAssigned)
    setSearchText(appliedSearch)
    updateUrl(appliedFilter, appliedSearch, appliedAssigned)
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getEvents());
      const mappedEventsWithMonthAndYear = eventResults.map(x => {
        const eventDate = moment(x.event_date, "YYYY-MM-DD")
        var monthYear = eventDate.format("MMMM YYYY").toUpperCase()
        return {
          ...x,
          month_year: monthYear
        }
      })
      setEvents(mappedEventsWithMonthAndYear)

      if (canViewAllEvents) {
        const employeeResults = await sendRequest(getAdminUsers());
        setEmployees(employeeResults)
      }
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const removeEvent = (event) => {
    setConfirmRemoveVisible(true)
    setDeleteError(false)
    setSelectedEvent(event)
  }

  const confirmDeleteEvent = async () => {
    setDeleteError(false)
    try {
      if (selectedEvent.event_id) {
        setDeleting(true)
        const deleteResults = await sendRequest(deleteEvent(selectedEvent.event_id));
        if (deleteResults.success) {
          await refreshPage()
          setConfirmRemoveVisible(false)
        } else {
          setDeleteError(true)
        }
        setDeleting(false)
      } else {
        setDeleteError(true)
      }
    } catch (error) {
      setDeleteError(true)
      setDeleting(false)
    }
  }

  const getUpcomingEvents = () => {
    const today = moment().format("YYYY-MM-DD")
    const allEvents = cloneDeep(events)
    return allEvents.filter(x => x.event_date >= today)
  }

  const getPastEvents = () => {
    const today = moment().format("YYYY-MM-DD")
    const allEvents = cloneDeep(events)
    return allEvents.filter(x => x.event_date < today)
  }
 
  const getFilteredEvents = () => {
    const today = moment().format("YYYY-MM-DD")
    const allEvents = cloneDeep(events)
    const searchTerm = toLower(searchText)
    var filteredEvents = [];

    // Apply filter
    if (selectedFilter == "upcoming") {
      filteredEvents = allEvents.filter(x => x.event_date >= today)
    } else if (selectedFilter == "past") {
      filteredEvents = allEvents.filter(x => x.event_date < today)
    } else {
      filteredEvents = allEvents;
    }

    // Apply assigned to
    if (selectedAssigned) {
      filteredEvents = filteredEvents.filter(x => {
        if (x.employees) {
          const foundEmployee = x.employees.filter(e => e.user_id == selectedAssigned).length > 0
          return foundEmployee;
        }
        return true
      })
    }

    // Apply search text
    if (searchText != "") {
      filteredEvents = filteredEvents.filter(x => {
        // Check event date
        const eventDate = moment(x.event_date, "YYYY-MM-DD")
        var eventDateShort = eventDate.format("MM/DD/YYYY")
        var eventDateShort2 = eventDate.format("M/D/YYYY")
        var eventDateMedium = eventDate.format("MMM D, YYYY")
        var eventDateLong = eventDate.format("MMMM D, YYYY")
        if (accountSettings.date_format == DateFormatTypes.DMY) {
          eventDateShort = eventDate.format("DD/MM/YYYY")
          eventDateShort2 = eventDate.format("D/M/YYYY")
          eventDateMedium = eventDate.format("D MMM, YYYY")
          eventDateLong = eventDate.format("D MMMM, YYYY")
        }

        // Check clients
        var matchClients = false
        if (isArray(x.clients)) {
          const filteredClients = x.clients.filter(y => {
            const fullName = y.first_name + " " + y.last_name;
            return toLower(fullName).includes(searchTerm)
          })
          matchClients = filteredClients.length > 0
        }

        const matchesEventDateShort = toLower(eventDateShort).includes(searchTerm)
        const matchesEventDateShort2 = toLower(eventDateShort2).includes(searchTerm)
        const matchesEventDateMedium = toLower(eventDateMedium).includes(searchTerm)
        const matchesEventDateLong = toLower(eventDateLong).includes(searchTerm)
        const matchesEventName = toLower(x.event_name).includes(searchTerm)

        return matchesEventDateShort || matchesEventDateShort2 || matchesEventDateMedium || matchesEventDateLong || matchesEventName || matchClients
      })
    }

    const groupedFilteredEvents = chain(filteredEvents).groupBy("month_year").map((value, key) => ({ label: key, events: value})).value()

    return groupedFilteredEvents;
  }

  const onFilterChange = (filter) => {
    setSelectedFilter(filter)
    updateUrl(filter, searchText, selectedAssigned)
  }

  const onAssignedChanged = (assigned) => {
    setSelectedAssigned(assigned)
    updateUrl(selectedFilter, searchText, assigned)
  }

  const onSearchTextChange = (text) => {
    setSearchText(text)
    updateUrl(selectedFilter, text, selectedAssigned)
  }

  const updateUrl = (filter, search, assigned) => {
    var url = "/admin/events"
    var params = []
    if (filter) {
      params.push(`filter=${filter}`)
    }
    if (assigned) {
      params.push(`assigned=${assigned}`)
    }
    if (search) {
      params.push(`q=${search}`)
    }
    if (params.length > 0) {
      url += `?${params.join("&")}`
    }
    navigate(url, { replace: true })
    setEventFilters(filter, assigned)
  }

  const isFilterApplied = () => {
    return selectedAssigned || (selectedFilter && selectedFilter != "all")
  }

  const menu = (event) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/events/${event.event_id}`)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => removeEvent(event)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderRightIcon = (event) => {
    return (
      <div className="display-flex mr-5">
        <Dropdown overlay={menu(event)} placement="bottomRight" trigger="click">
          <div className="dots-container">
            <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
          </div>
        </Dropdown>
      </div>
    )
  }

  const renderEventGroups = (group, index) => {
    return (
      <div key={index}>
        <div className="bg-gray fs-12 fw-600 pv-5 ph-10 b-border">{group.label}</div>
        { group.events.map((item, i) => renderEventCard(item, i)) }
      </div>
    )
  }

  const renderEventCard = (event, index) => {
    return <EventCard event={event} accountSettings={accountSettings} rightIcon={renderRightIcon(event)} className={"b-border"} onClick={() => navigate(`/admin/events/${event.event_id}`)} showType={true} key={index}/>;
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Events</div>
        </Col>
        { hasPermission(user, Permissions.CREATE_EVENTS) && (
          <Col flex={0}>
            <button className="page-title-button" onClick={() => navigate("/admin/events/new")}>New Event</button>
          </Col>
        )}
      </Row>
    )
  }

  const renderEventsResults = () => {
    if (getFilteredEvents().length > 0) {
      return getFilteredEvents().map((group,i) => renderEventGroups(group,i));
    } else {
      return (
        <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
          <div>
            <img src={emptySearchImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No events found
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Adjust your filter or search again!
          </div>
        </FadeWhileInView>
      )
    }
  }

  const renderTab = (text, value) => {
    const isSelected = selectedFilter == value
    const selectedStyle = isSelected ? { borderBottom: "2px solid #536DFE", color: "#536DFE", userSelect: 'none' } : { userSelect: 'none' }
    return (
      <div className={`ph-10 pv-10 mt-15 mr-20 fw-600`} style={selectedStyle} onClick={() => onFilterChange(value)}>
        { text }
      </div>
    )
  }

  const renderEventsBox = () => {
    var filterIconColor = "#999"
    if (isFilterOpen) {
      filterIconColor = "#FFF"
    } else if (isFilterApplied()) {
      filterIconColor = "#536DFE"
    }
    return (
      <div className="shadow-card">
        <div className="display-flex ph-20 b-border">
          { renderTab("All Events", "all") }
          { renderTab("Upcoming", "upcoming") }
          { renderTab("Past", "past") }
        </div>
        <div className="p-15 b-border">
          <div className="display-flex flex-row flex-middle">
            <div className="flex-1">
              <Input className="list-search-input-square" size="large" placeholder="Event name, date, or client" value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
            </div>
            <div className="flex-0 ml-10">
              <div className="dots-container" style={{ backgroundColor: isFilterOpen ? "#536DFE" : "#F2F2F2"}} onClick={() => setFilterOpen(!isFilterOpen)}>
                <MdOutlineFilterList style={{ fontSize: 24, color: filterIconColor}}/>
              </div>
            </div>
          </div>
        </div>
        { renderEventsResults() }
      </div>
    )
  }

  const renderEvents = () => {
    if (events.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={300}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No events yet
          </div>
          { hasPermission(user, Permissions.CREATE_EVENTS) ? (
            <>
              <div className="fs-14 fw-500 c-text-gray">
                Create an event to get started!
              </div>
              <button className="primary-button mt-30" style={{ width: 200 }} onClick={() => navigate("/admin/events/new")}>New Event</button>
            </>
          ) : (
            <div className="fs-14 fw-500 c-text-gray">
              There are no events currently assigned to you.
            </div>
          )}
          
        </div>
      )
    } else {
      return renderEventsBox()
    }
  }

  const renderFilterModal = () => {
    return (
      <Modal visible={isFilterOpen} footer={null} onCancel={() => setFilterOpen(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Filters</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setFilterOpen(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Row gutter={[15,15]} className="mt-20">
            <Col xs={24} md={24}>
              <div className="fw-700 mb-5">Date Range</div>
              <Select className="list-select-filter" size="large" defaultValue="upcoming" value={selectedFilter} style={{ width: "100%" }} onChange={(value) => onFilterChange(value)}>
                <Option value="upcoming">Upcoming Events</Option>
                <Option value="past">Past Events</Option>
                <Option value="all">All Events</Option>
              </Select>
            </Col>
            { canViewAllEvents && (
              <Col xs={24} md={24}>
                <div className="fw-700 mb-5">Assigned to</div>
                <Select 
                  showSearch
                  className="list-select-filter" 
                  size="large" 
                  placeholder="Select staff..."
                  value={selectedAssigned} 
                  allowClear 
                  style={{ width: "100%" }} 
                  onChange={(value) => onAssignedChanged(value)}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  { employees.map(x => (
                    <Option key={x.user_id} value={x.user_id}>{x.first_name + " " + x.last_name}</Option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
          <button className="primary-button mt-30" type="button" onClick={() => setFilterOpen(false)}>Apply Filters</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setFilterOpen(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Delete Event</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to delete the event <span className="fw-700">{selectedEvent.event_name}</span>? All data will be removed and any clients that are assigned to this event will no longer have access.</div>
          { isDeleting ? (
            <button className="primary-button warning" type="button"><LoadingOutlined/></button>
          ) : (
            <button className="primary-button warning" type="button" onClick={() => confirmDeleteEvent()}>Delete Event</button>
          )}
          { isDeleteError && (
            <div className="text-center mt-5 c-red">Something went wrong. Please try again.</div>
          )}
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }
  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>;
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        { events.length > 0 && screens.sm && (
          <div className="">
            <Row gutter={[15,15]}>
              <Col xs={24} sm={8}>
                <div className="shadow-card-square mb-15 pv-20 text-center">
                  <div className="fw-700 c-text-gray">Total Events</div>
                  {/* <div className="c-text-gray fs-10">THIS YEAR</div> */}
                  <div className="fw-300 fs-40">{ events.length }</div>
                </div>
              </Col>
              <Col xs={24} sm={8}>
                <div className="shadow-card-square mb-15 pv-20 text-center">
                  <div className="fw-700 c-text-gray">Upcoming</div>
                  <div className="fw-300 fs-40">{ getUpcomingEvents().length }</div>
                </div>
              </Col>
              <Col xs={24} sm={8}>
                <div className="shadow-card-square mb-15 pv-20 text-center">
                  <div className="fw-700 c-text-gray">Past</div>
                  <div className="fw-300 fs-40">{ getPastEvents().length }</div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        { renderEvents() }
        { renderFilterModal() }
        { renderConfirmRemoveModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default EventsPage;
