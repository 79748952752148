import React, { useEffect, useState, useRef, useContext } from "react";
import { isEmpty, isNull, cloneDeep, startsWith } from 'lodash';
import { Row, Col, Input, Grid, Menu, Dropdown, Modal, Button, Tooltip } from 'antd';
import uniqid from 'uniqid';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import AdminContent from '../../components/adminContent';
import MusicModal from '../../components/musicModal';
import PlatformSelectionModal from '../../modals/platformSelectionModal';
import ImportPlaylistModal from '../../modals/importPlaylistModal';
import CustomSongModal from '../../modals/customSongModal';
import LoadingSpinner from '../../components/loading';
import { DeleteOutlined } from '@ant-design/icons';
import { MdOutlineStickyNote2, MdOutlineMoreHoriz, MdRemoveCircleOutline, MdOutlineClose, MdArrowForwardIos, MdLaunch, MdPlayArrow, MdPause, MdOutlineInfo } from "react-icons/md";
import { FiMusic, FiPlus } from "react-icons/fi";
import { getEventPlaylist, getEventPlaylistSongs, createEventPlaylistSongs, deleteEventPlaylistSongs, updateEventPlaylistSongs, getRecommendedPlaylistGroupList, createEventPlaylistSongsBulk, getClientEvent } from '../../api';
import emptyMusicImage from '../../images/empty-music-icon.png';
import { getPlaylistLengthAndTime } from '../../helpers/musicHelper';
import FloatingContainer from '../../components/floatingContainer'
import AppContext from '../../app/context';
import EventPermissions from '../../constants/eventPermissions';
import { hasEventPermission } from '../../helpers/permissionHelper';
import { isEventLocked } from '../../helpers/eventHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ClientPlaylistPage = () => {

  useDocumentTitle("Playlist")
  const timerRef = useRef(null);
  const audioRef = useRef(null);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { auth } = useContext(AppContext);

  const [isLoading, setLoading] = useState(true);
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNotesModalVisible, setNotesModalVisible] = useState(false);
  const [isPlatformSelectionVisible, setPlatformSelectionVisible] = useState(false);
  const [isCustomSongModalVisible, setCustomSongModalVisible] = useState(false);
  const [isImportPlaylistModalVisible, setImportPlaylistModalVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedSong, setSelectedSong] = useState({});
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);
  const [recommendedPlaylistGroupList, setRecommendedPlaylistGroupList] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("spotify");
  const [isImporting, setImporting] = useState(false);
  const [isSongLimitModalVisible, setSongLimitModalVisible] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [isClientPage, setClientPage] = useState(false);
  const [eventLocked, setEventLocked] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [playlist, setPlaylist] = useState([]);

  const [sendRequest] = useApi()

  const eventId = params.event_id;
  const playlistId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (startsWith(location.pathname, "/client/")) {
      setClientPage(true)
    }
    if (playlistId) {
      refreshPage();
    }
    return () => {
      if (!isNull(audioRef.current)) {
        audioRef.current.pause()
        audioRef.current.removeEventListener('ended', () => setPlaying(false));
      }
    }
  }, []);

  const refreshPage = async () => {
    try {
      const playlistResults = await sendRequest(getEventPlaylist(eventId, playlistId));
      setPlaylist(playlistResults);

      const playlistSongsResults = await sendRequest(getEventPlaylistSongs(eventId, playlistId));
      setPlaylistSongs(playlistSongsResults);

      const playlistGroupList = await sendRequest(getRecommendedPlaylistGroupList("SEARCH"))
      setRecommendedPlaylistGroupList(playlistGroupList)

      if (startsWith(location.pathname, "/client/")) {
        const eventResults = await sendRequest(getClientEvent(auth.user.user_id, eventId));
        setEventLocked(isEventLocked(eventResults, auth.user))
        setCanEdit(hasEventPermission(auth.user, EventPermissions.EDIT_MUSIC))
      } else {
        setEventLocked(false)
        setCanEdit(true)
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const startAddSongs = () => {
    if (playlist.song_limit > 0 && playlistSongs.length >= playlist.song_limit) {
      setSongLimitModalVisible(true)
    } else {
      setPlatformSelectionVisible(true)
    }
  }

  const addSong = (song) => {

    if (playlist.song_limit > 0 && playlistSongs.length >= playlist.song_limit) {
      setSongLimitModalVisible(true)
      return
    }

    const newPlaylistSongs = cloneDeep(playlistSongs);
    const existingSong = newPlaylistSongs.find((x) => x.song_id == song.song_id);

    if (existingSong) {
      return;
    }

    const body = {
      ...song,
      notes: "",
      playlist_name: playlist.playlist_name
    }

    sendRequest(createEventPlaylistSongs(params.event_id, playlistId, body)).then(response => {
      newPlaylistSongs.push(response)
      setPlaylistSongs(newPlaylistSongs)
    })
  }

  const removeSong = (song, index) => {
    sendRequest(deleteEventPlaylistSongs(params.event_id, playlistId, song.event_playlist_song_id)).then((response) => {
      const newPlaylistSongs = [].concat(playlistSongs);
      newPlaylistSongs.splice(index, 1);
      setPlaylistSongs(newPlaylistSongs)
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onAddEditNote = (item, index) => {
    setSelectedSong(item)
    setSelectedSongIndex(index)
    setSelectedNote(item.notes)
    setNotesModalVisible(true)
  }

  const onSaveNote = () => {
    setNotesModalVisible(false)
    if (isNull(selectedSongIndex)) {
      return
    }
    const newSongs = cloneDeep(playlistSongs);
    newSongs[selectedSongIndex].notes = selectedNote
    const body = {
      ...newSongs[selectedSongIndex],
      playlist_name: playlist.playlist_name
    }
    sendRequest(updateEventPlaylistSongs(params.event_id, playlistId, selectedSong.event_playlist_song_id, body)).then(response => {
      setPlaylistSongs(newSongs)
    }).catch(() => {})
  }

  const onClearNote = (item, index) => {
    setNotesModalVisible(false)
    const newSongs = cloneDeep(playlistSongs);
    newSongs[index].notes = ""
    const body = {
      ...newSongs[index],
      playlist_name: playlist.playlist_name
    }
    sendRequest(updateEventPlaylistSongs(params.event_id, playlistId, item.event_playlist_song_id, body)).then(response => {
      setPlaylistSongs(newSongs)
    }).catch(() => {})
  }

  const selectPlatform = (platform) => {
    setSelectedPlatform(platform)
    setPlatformSelectionVisible(false)
    setIsModalVisible(true)
  }

  const displayCustomSong = () => {
    setPlatformSelectionVisible(false)
    setCustomSongModalVisible(true)
  }

  const saveCustomSong = async (values) => {
    const newPlaylistSongs = cloneDeep(playlistSongs);

    const body = {
      song_id: uniqid(),
      title: values.title,
      artist: values.artist,
      image: "",
      notes: values.notes ?? "",
      duration: 0,
      url: "",
      preview_url: "",
      source: "custom",
      rank: null,
      playlist_name: playlist.playlist_name
    }

    try {
      const songResponse = await sendRequest(createEventPlaylistSongs(params.event_id, playlistId, body))
      newPlaylistSongs.push(songResponse)
      setPlaylistSongs(newPlaylistSongs)
      setCustomSongModalVisible(false)
    } catch(error) {
      setCustomSongModalVisible(false)
    }
  }

  const startImportPlaylist = () => {
    setIsModalVisible(false)
    setImportPlaylistModalVisible(true)
  }

  const saveImportedSongs = async (songs) => {
    setImporting(true)
    if (songs.length > 0) {
      try {
        const body = {
          songs: songs,
          playlist_name: playlist.playlist_name
        }
        await sendRequest(createEventPlaylistSongsBulk(params.event_id, playlistId, body))
        await refreshPage()
        setImporting(false)
        setImportPlaylistModalVisible(false)
      } catch(error) {
        setImporting(false)
        setImportPlaylistModalVisible(false)
      }
    } else {
      setImportPlaylistModalVisible(false)
    }
  }

  const playPauseSong = (song) => {
    if (!song.preview_url) {
      return
    }
    if (isNull(audioRef.current)) {
      // No song has been played yet, so set the song and start playing
      setCurrentSong(song)
      var audio = new Audio(song.preview_url)
      audio.addEventListener('ended', () => setPlaying(false));
      audio.play();
      audioRef.current = audio
      setPlaying(true)
    } else if (isPlaying && song.song_id == currentSong.song_id) {
      // Current song is playing, so pause it
      audioRef.current.pause()
      setPlaying(false)
    } else if (!isPlaying  && song.song_id == currentSong.song_id) {
      // Current song was paused, so play it
      audioRef.current.play()
      setPlaying(true)
    } else if (isPlaying && song.song_id != currentSong.song_id) {
      // New song is selected while another one is playing, so pause song, replace it, and play it
      audioRef.current.pause()
      audioRef.current.removeEventListener('ended', () => setPlaying(false));
      var audio = new Audio(song.preview_url)
      audio.addEventListener('ended', () => setPlaying(false));
      audio.play();
      audioRef.current = audio
      setCurrentSong(song)
      setPlaying(true)
    } else if (!isPlaying && song.song_id != currentSong.song_id) {
      // New song is selected while another one is playing, so pause song, replace it, and play it
      audioRef.current.removeEventListener('ended', () => setPlaying(false));
      var audio = new Audio(song.preview_url)
      audio.addEventListener('ended', () => setPlaying(false));
      audio.play();
      audioRef.current = audio
      setCurrentSong(song)
      setPlaying(true)
    }
  }

  const songMenu = (item, index) => {
    return (
      <Menu>
        { !eventLocked && canEdit && (
          <Menu.Item>
            <div onClick={() => onAddEditNote(item, index)}>
              <MdOutlineStickyNote2 style={{ marginRight: 8}}/> Add / Edit Note
            </div>
          </Menu.Item>
        )}
        { !isEmpty(item.notes) && !eventLocked && canEdit && (
          <Menu.Item>
            <div onClick={() => onClearNote(item, index)}>
              <MdRemoveCircleOutline style={{ marginRight: 8}}/> Clear Note
            </div>
          </Menu.Item>
        )}
        { item.source == "spotify" && (
          <Menu.Item>
            <a href={item.url} target="_blank">
              <MdLaunch style={{ marginRight: 8}}/> Open in Spotify
            </a>
          </Menu.Item>
        )}
         { item.source == "apple" && (
          <Menu.Item>
            <a href={item.url} target="_blank">
              <MdLaunch style={{ marginRight: 8}}/> Open in Apple Music
            </a>
          </Menu.Item>
        )}
        { !eventLocked && canEdit && (
          <Menu.Item>
            <div onClick={() => removeSong(item,index)}>
              <DeleteOutlined style={{ marginRight: 8}}/> Remove Song
            </div>
          </Menu.Item>
        )}
      </Menu>
    )
  };

  const renderPlayButton = (song) => {
    if (!isNull(song.preview_url)) {
      return (
        <div className="song-card--dots-container" onClick={() => playPauseSong(song)}>
          { isPlaying && song.song_id == currentSong.song_id ? (
            <MdPause style={{ fontSize: 24, color: "#536DFE"}}/>
          ) : (
            <MdPlayArrow style={{ fontSize: 24, color: "#536DFE"}}/>
          )}
        </div>
      )
    } else {
      return (
        <Tooltip title="A preview of this song is not available.">
          <div className="song-card--dots-container">
            <MdPlayArrow style={{ fontSize: 24, color: "#CCC"}}/>
          </div>
        </Tooltip>
      )
    }
  }

  const renderPlaylistSong = (item, index) => {
    const borderClass = index < playlistSongs.length - 1 ? "b-border" : "";
    return (
      <div className={`song-card song-card--playlist-page no-shadow ${borderClass}`} key={index}>
        <div className="song-card--row-1">
          <div className="song-card--image" onClick={() => playPauseSong(item)}>
            { item.image ? (
              <img src={item.image} style={{ width: 50 }}/>
            ) : (
              <div className="song-image-icon"><FiMusic/></div>
            )}
          </div>
          <div className="song-card--title-artist" onClick={() => playPauseSong(item)}>
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
          <div className="song-card--icon" style={{ marginRight: 0 }}>
            {renderPlayButton(item)}
          </div>
          <div className="song-card--icon">
            <Dropdown overlay={songMenu(item, index)} placement="bottomRight" trigger="click">
              <div className="song-card--dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        { !isEmpty(item.notes) && (
          <div className="song-card--row-2">
            { item.notes }
          </div>
        )}
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            { isClientPage ? "Music" : "Event Details" }
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Playlist
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{playlist.playlist_name}</div>
        <div className="fw-500 fs-14 c-text-gray">{getPlaylistLengthAndTime(playlistSongs)}</div>
      </div>
    )
  }

  const renderPlaylist = () => {
    if (playlistSongs.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyMusicImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No songs yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Add songs to get started.
          </div>
          { !eventLocked && canEdit && (
            <button className="primary-button mt-30" style={{ width: 200 }} onClick={() => startAddSongs(true)}>Add Songs</button>
          )}
        </div>
      )
    }
    return (
      <div className="">
        <div className="shadow-card">
          { !eventLocked && canEdit && (
            <div className="display-flex flex-middle p-10 b-border" onClick={() => startAddSongs(true)}>
              <div className="flex-0">
                <div className="song-image-icon"><FiPlus/></div>
              </div>
              <div className="flex-1 fw-700 ml-15">Add Songs</div>
            </div>
          )}
          { playlistSongs.map((x,i) => renderPlaylistSong(x,i))}
        </div>
      </div>
    )
  }

  const renderSongLimitModal = () => {
    return (
      <Modal visible={isSongLimitModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setSongLimitModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Song Limit Reached</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">You are allowed to add a maximum of <span className="fw-700">{playlist.song_limit} songs</span> to this playlist. If you would like to add additional songs, please remove some songs first.</div>
          <button className="primary-button" type="button" onClick={() => setSongLimitModalVisible(false)}>Got it!</button>
          {/* <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setSongLimitModalVisible(false)}>Cancel</div>
          </div> */}
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
        { eventLocked && canEdit && (
          <div className="message-box mb-15">
            <div className="message-icon"><MdOutlineInfo/></div>
            <div className="message-text">Changes are no longer allowed</div>
          </div>
        )}
        {renderPlaylist()}
        <MusicModal 
          isVisible={isModalVisible} 
          title="Add Songs" 
          eventName={playlist.playlist_name} 
          onCancel={handleCancel}
          onSelect={addSong}
          selectedEvent={{}}
          selectedSongs={playlistSongs}
          recommendedPlaylistGroupList={recommendedPlaylistGroupList}
          selectedPlatform={selectedPlatform}
          startImportPlaylist={startImportPlaylist}
        />
        <Modal visible={isNotesModalVisible} footer={null} onCancel={() => setNotesModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
          <Row align="middle">
            <Col flex={1}>
              <div className="fw-700 fs-18">Add / Edit Note</div>
            </Col>
            <Col>
              <div className="display-flex" onClick={() => setNotesModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
            </Col>
          </Row>
          <div className="">
            <div className="pt-10 pb-15">
              <Input.TextArea 
                rows={3}
                value={selectedNote}
                onChange={(e) => setSelectedNote(e.target.value)}
                size="large"/>
            </div>
            <div className="text-right">
              <Button className="admin-small-button secondary mr-10" onClick={() => setNotesModalVisible(false)}>Cancel</Button>
              <Button className="admin-small-button" onClick={() => onSaveNote()}>Save</Button>
            </div>
          </div>
        </Modal>
        <PlatformSelectionModal 
          isVisible={isPlatformSelectionVisible}
          setVisible={setPlatformSelectionVisible}
          selectPlatform={selectPlatform}
          addCustomSong={displayCustomSong}
        />
        <ImportPlaylistModal 
          isVisible={isImportPlaylistModalVisible}
          setVisible={setImportPlaylistModalVisible}
          selectedPlatform={selectedPlatform}
          saveImportedSongs={saveImportedSongs}
        />
        <CustomSongModal 
          isVisible={isCustomSongModalVisible}
          setVisible={setCustomSongModalVisible}
          saveCustomSong={saveCustomSong}
        />
        { renderSongLimitModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ClientPlaylistPage;
