import React, { useEffect, useState, useRef, useContext } from "react";
import moment from "moment";
import dayjs from 'dayjs';
import qs from "qs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { Dropdown, Menu, Row, Col, Select, Modal, DatePicker, Switch, Form, Input, Grid } from 'antd';
import { 
  getContractTemplate,
  getEventContract, 
  updateEventContractTemplate, 
  updateEventContractName,
  updateEventContractExpiration, 
  getContractTemplates, 
  createContractSigner, 
  deleteContractSigner, 
  getEvent, 
  getAdminUsers 
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import { HiOutlineChevronDown, HiOutlineArrowLeft } from "react-icons/hi";
import { FiRefreshCw, FiCheckCircle, FiCheck, FiInfo } from "react-icons/fi";
import { MdOutlineMoreHoriz, MdOutlineClose, MdMenuOpen } from "react-icons/md";
import { UserOutlined, DeleteOutlined } from '@ant-design/icons';
import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineStrikethrough, AiOutlineAlignLeft, AiOutlineAlignRight, AiOutlineAlignCenter, AiOutlineUnorderedList, AiOutlineOrderedList, AiOutlineUndo, AiOutlineRedo, AiOutlineGroup, AiOutlineFontSize } from "react-icons/ai";
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import MergeTagExtension from '../../../components/tiptap/mergeTagExtension';
import EventServicesExtension from '../../../components/tiptap/eventServicesExtension';
import { getClientSigners, getAdminSigners} from '../../../helpers/contractHelper';
import { getDatePickerFormat, formatDate, formatDateMedium } from '../../../helpers/dateHelper';
import ContractStatus from '../../../constants/contractStatus';
import SignerTypes from '../../../constants/signerTypes';
import { isEmpty, isNull, isUndefined, isArray } from "lodash";
import AppContext from '../../../app/context';
import { 
  renderInputField,
  renderEmailField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const MenuBar = ({ editor }) => {

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  if (!editor) {
    return null
  }

  const menuClass = "display-flex flex-middle justify-content-center fs-18 mr-5"
  
  const menu = () => {
    return (
      <Menu>
        <Menu.Item className="p-0">
          <div className={`ph-15 pv-10 ${editor.isActive("paragraph") ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setParagraph().run()}>
            Paragraph
          </div>
        </Menu.Item>
        <Menu.Item className="p-0">
          <div className={`ph-15 pv-10 ${editor.isActive('heading', { level: 1 }) ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setHeading({ level: 1 }).run()}>
            Heading 1
          </div>
        </Menu.Item>
        <Menu.Item className="p-0">
          <div className={`ph-15 pv-10 ${editor.isActive('heading', { level: 2 }) ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setHeading({ level: 2 }).run()}>
            Heading 2
          </div>
        </Menu.Item>
        <Menu.Item className="p-0">
          <div className={`ph-15 pv-10 ${editor.isActive('heading', { level: 3 }) ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setHeading({ level: 3 }).run()}>
            Heading 3
          </div>
        </Menu.Item>
        <Menu.Item className="p-0">
          <div className={`ph-15 pv-10 ${editor.isActive('heading', { level: 4 }) ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setHeading({ level: 4 }).run()}>
            Heading 4
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const alignmentMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive({ textAlign: 'left' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("left").run()}><AiOutlineAlignLeft/></div>
        </Menu.Item>
        <Menu.Item>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive({ textAlign: 'center' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("center").run()}><AiOutlineAlignCenter/></div>
        </Menu.Item>
        <Menu.Item>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive({ textAlign: 'right' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("right").run()}><AiOutlineAlignRight/></div>
        </Menu.Item>
      </Menu>
    )
  };

  const listMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive('bulletList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBulletList().run()}><AiOutlineUnorderedList/></div>
        </Menu.Item>
        <Menu.Item>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive('orderedList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleOrderedList().run()}><AiOutlineOrderedList/></div>
        </Menu.Item>
      </Menu>
    )
  };

  const insertMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => editor.chain().insertContent({ type: "merge-tag"}).focus().run()}>
            Contract Variable
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().insertContent({ type: "event-services"}).focus().run()}>
            Services
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const getActiveTextType = () => {
    if (editor.isActive('paragraph')) {
      return "Paragraph"
    } else if (editor.isActive('heading', { level: 1 })) {
      return "Heading 1"
    } else if (editor.isActive('heading', { level: 2 })) {
      return "Heading 2"
    } else if (editor.isActive('heading', { level: 3 })) {
      return "Heading 3"
    } else if (editor.isActive('heading', { level: 4 })) {
      return "Heading 4"
    } else {
      return "Paragraph"
    }
  }

  const getActiveTextAlignment = () => {
    if (editor.isActive({ textAlign: 'left' })) {
      return <AiOutlineAlignLeft/>
    } else if (editor.isActive({ textAlign: 'right' })) {
      return <AiOutlineAlignRight/>
    } else if (editor.isActive({ textAlign: 'center' })) {
      return <AiOutlineAlignCenter/>
    } else {
      return <AiOutlineAlignLeft/>
    }
  }

  const getActiveListType = () => {
    if (editor.isActive("bulletList")) {
      return <AiOutlineUnorderedList/>
    } else if (editor.isActive("orderedList")) {
      return <AiOutlineOrderedList/>
    } else if (editor.isActive({ textAlign: 'center' })) {
      return <AiOutlineAlignCenter/>
    } else {
      return <AiOutlineUnorderedList/>
    }
  }

  return (
    <FloatingContainer className="" verticalPadding={0} maxWidth={850}>
      { screens.md ? (
        <div className="flex-row justify-content-center">
          <div className="display-flex flex-middle mh-10 cursor-pointer">
            <Dropdown overlay={menu()} placement="bottomRight" trigger="click">
              <div className="display-flex flex-middle">{getActiveTextType()} <HiOutlineChevronDown style={{ marginLeft: 5}}/></div>
            </Dropdown>
          </div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive('bold') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBold().run()}><AiOutlineBold/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('italic') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleItalic().run()}><AiOutlineItalic/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('underline') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleUnderline().run()}><AiOutlineUnderline/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('strike') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleStrike().run()}><AiOutlineStrikethrough/></div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'left' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("left").run()}><AiOutlineAlignLeft/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'center' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("center").run()}><AiOutlineAlignCenter/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'right' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("right").run()}><AiOutlineAlignRight/></div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('bulletList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBulletList().run()}><AiOutlineUnorderedList/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('orderedList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleOrderedList().run()}><AiOutlineOrderedList/></div>
          { screens.lg && (
            <>
              <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
              <div style={{ height: 30, width: 30 }} className={`${menuClass}`} onClick={() => editor.chain().focus().undo().run()}><AiOutlineUndo/></div>
              <div style={{ height: 30, width: 30 }} className={`${menuClass}`} onClick={() => editor.chain().focus().redo().run()}><AiOutlineRedo/></div>
            </>
          )}
          {/* <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}`} onClick={() => editor.chain().insertContent({ type: "merge-tag"}).focus().run()}><AiOutlineGroup/></div> */}
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
          <div className="display-flex flex-middle mh-10 cursor-pointer">
            <Dropdown overlay={insertMenu()} placement="bottomRight" trigger="click">
              <div className="display-flex flex-middle">Insert <HiOutlineChevronDown style={{ marginLeft: 5}}/></div>
            </Dropdown>
          </div>
        </div>
      ) : (
        <div className="flex-row justify-content-center">
          <div className="display-flex flex-middle mh-10 cursor-pointer">
            <Dropdown overlay={menu()} placement="bottomRight" trigger="click">
              <div className="display-flex flex-middle">
                <div style={{ height: 30, width: 30 }} className={`display-flex flex-middle justify-content-center fs-18`}><AiOutlineFontSize/></div>
                <HiOutlineChevronDown/></div>
            </Dropdown>
          </div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 0, marginRight: 10 }}></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive('bold') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBold().run()}><AiOutlineBold/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('italic') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleItalic().run()}><AiOutlineItalic/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('underline') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleUnderline().run()}><AiOutlineUnderline/></div>
          <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('strike') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleStrike().run()}><AiOutlineStrikethrough/></div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 5 }}></div>
          <div className="display-flex flex-middle cursor-pointer">
            <Dropdown overlay={alignmentMenu()} placement="bottomRight" trigger="click">
              <div className="display-flex flex-middle">
                <div style={{ height: 30, width: 30 }} className={`display-flex flex-middle justify-content-center fs-18`}>{getActiveTextAlignment()}</div>
                <HiOutlineChevronDown />
              </div>
            </Dropdown>
          </div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 5 }}></div>
          <div className="display-flex flex-middle cursor-pointer">
            <Dropdown overlay={listMenu()} placement="bottomRight" trigger="click">
              <div className="display-flex flex-middle">
                <div style={{ height: 30, width: 30 }} className={`display-flex flex-middle justify-content-center fs-18`}>{getActiveListType()}</div>
                <HiOutlineChevronDown/>
              </div>
            </Dropdown>
          </div>
          <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
          <div className="display-flex flex-middle mh-10 cursor-pointer">
            <Dropdown overlay={insertMenu()} placement="bottomRight" trigger="click">
              <div className="display-flex flex-middle">Insert <HiOutlineChevronDown style={{ marginLeft: 5}}/></div>
            </Dropdown>
          </div>
        </div>
      )}
    </FloatingContainer>
  )
}

const EditEventContractPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEditable, setEditable] = useState(true);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [contract, setContract] = useState({});
  const [selectedContractTemplate, setSelectedContractTemplate] = useState(null);
  const [selectedSigner, setSelectedSigner] = useState({});
  const [isConfirmTemplateModalVisible, setConfirmTemplateModalVisible] = useState(false);
  const [isConfirmRemoveSignerModalVisible, setConfirmRemoveSignerModalVisible] = useState(false);
  const [isAddSignerModalVisible, setAddSignerModalVisible] = useState(false);
  const [isAdminSigned, setAdminSigned] = useState(false);
  const [hasExpiration, setHasExpiration] = useState(false);
  const [isEditExpiration, setEditExpiration] = useState(false);
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedSignerUser, setSelectedSignerUser] = useState({});
  const [expirationDate, setExpirationDate] = useState(null);
  const [addSignerMessage, setAddSignerMessage] = useState(null);
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [contractName, setContractName] = useState("");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [fromPage, setFromPage] = useState("");

  useDocumentTitle("Edit Contract")
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const { auth, setAuth } = useContext(AppContext);
  const saveTimerRef = useRef(null);
  const firstLoadRef = useRef(true);
  const titleInputRef = useRef(null);
  const [addSignerForm] = Form.useForm();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const eventId = params.event_id;
  const contractId = params.id;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: "Start typing..."
      }),
      MergeTagExtension,
      EventServicesExtension
    ],
    content: '',
    editable: isEditable,
    onUpdate({ editor }) {
      if (saveTimerRef.current) {
        clearTimeout(saveTimerRef.current)
      }
      if (firstLoadRef.current == true) {
        firstLoadRef.current = false
        return
      }
      setSaving(true)
      saveTimerRef.current = setTimeout(() => saveChanges(editor.getJSON()), 1500)
    },
    onCreate({ editor }) {
      refreshPage(editor)
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.from) {
      setFromPage(queryStrings.from)
    }
  }, []);

  const saveChanges = async (json) => {
    try {
      const body = {
        template: json
      }
      await sendRequest(updateEventContractTemplate(eventId, contractId, body))
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
    }
  }

  const refreshPage = async (editorInstance) => {
    try {
      const results = await sendRequest(getEventContract(eventId, contractId))
      setContract(results)
      setContractName(results.event_contract_name)
      if (!isUndefined(results.expiration_datetime) && !isNull(results.expiration_datetime)) {
        setExpirationDate(moment.utc(results.expiration_datetime))
        setHasExpiration(true)
      }
      const adminHasSigned = results.signers.filter(x => x.user_id == auth.user.user_id && x.signed)
      const anyoneSigned = results.signers.filter(x => x.signed).length > 0
      setAdminSigned(adminHasSigned.length > 0)
      const editable = results.status == ContractStatus.DRAFT || !anyoneSigned
      setEditable(editable)
      if (editorInstance) {
        editorInstance.setEditable(editable)
        editorInstance.commands.setContent(results.template)
      }

      const contractTemplateResults = await sendRequest(getContractTemplates())
      setContractTemplates(contractTemplateResults)

      const eventResults = await sendRequest(getEvent(eventId, "detail"));
      setClients(eventResults.clients)

      const employeeResults = await sendRequest(getAdminUsers())
      setEmployees(employeeResults)

    } finally {
      setLoading(false)
    }
  }

  const replaceContentsWithTemplate = (template_id) => {
    if (template_id) {
      setSelectedContractTemplate(template_id)
      setConfirmTemplateModalVisible(true)
    } else {
      setSelectedContractTemplate(null)
    }
  }

  const replaceContent = async () => {
    setConfirmTemplateModalVisible(false)
    if (selectedContractTemplate) {
      const template = await sendRequest(getContractTemplate(selectedContractTemplate))
      const body = {
        template: template.content
      }
      await sendRequest(updateEventContractTemplate(eventId, contractId, body))
      editor.commands.setContent(template.content)
    }
  }

  const getTemplateName = () => {
    if (contractTemplates.length > 0) {
      const template = contractTemplates.find(x => x.contract_template_id == selectedContractTemplate)
      if (!isEmpty(template)) {
        return template.contract_template_name
      }
    }
    return "";
  }

  const onAddSigner = () => {
    addSignerForm.resetFields()
    setSelectedSignerUser(null)
    setAddSignerMessage("")
    setAddSignerModalVisible(true)
  }

  const onAddSignerSubmit = async (values) => {
    const clientUser = clients.find(x => x.user_id == values["user_id"])
    const employeeUser = employees.find(x => x.user_id == values["user_id"])
    try {
      if (values["user_id"] == "other") {
        const body = {
          user_id: null,
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          signer_type: SignerTypes.EXTERNAL
        }
        await sendRequest(createContractSigner(contractId, body))
      } else if (!isEmpty(clientUser)) {
        const body = {
          user_id: clientUser.user_id,
          first_name: clientUser.first_name,
          last_name: clientUser.last_name,
          email: clientUser.email,
          signer_type: SignerTypes.CLIENT
        }
        await sendRequest(createContractSigner(contractId, body))
      } else if (!isEmpty(employeeUser)) {
        const body = {
          user_id: employeeUser.user_id,
          first_name: employeeUser.first_name,
          last_name: employeeUser.last_name,
          email: employeeUser.email,
          signer_type: SignerTypes.ADMIN
        }
        await sendRequest(createContractSigner(contractId, body))
      }
      await refreshPage()
    } finally {
      setAddSignerModalVisible(false)
    }
  }

  const onRemoveSigner = (signer) => {
    const isDisabled = (isArray(contract.signers) && contract.signers.length == 1) || signer.signed
    if (isDisabled) {
      return;
    }
    setSelectedSigner(signer)
    setConfirmRemoveSignerModalVisible(true)
  }

  const removeSigner = async () => {
    try {
      await sendRequest(deleteContractSigner(contractId, selectedSigner.event_contract_signer_id))
      await refreshPage()
    } finally {
      setConfirmRemoveSignerModalVisible(false)
    }
  }

  const onSetExpirationChange = async (value) => {
    setHasExpiration(value)
    try {
      if (!value) {
        setExpirationDate(null)
        const body = {
          expiration_datetime: null
        }
        await sendRequest(updateEventContractExpiration(eventId, contractId, body))
      } else {
        setEditExpiration(true)
      }
    } catch {}
  }

  const onEditExpiration = () => {
    setEditExpiration(true)
  }

  const onSaveExpiration = async () => {
    setEditExpiration(false)
    try {
      var expDate;
      if (!isNull(expirationDate)) {
        expDate = expirationDate.format("YYYY-MM-DD")
      } else {
        expDate = null;
        setHasExpiration(false)
      }
      const body = {
        expiration_datetime: expDate
      }
      await sendRequest(updateEventContractExpiration(eventId, contractId, body))
    } catch {}
  }

  const onSelectSignerChange = (signer) => {
    setSelectedSignerUser(signer)

    const isExists = !isEmpty(contract.signers.find(x => x.user_id == signer))
    const isEmployee = !isEmpty(employees.find(x => x.user_id == signer))
    const isClient = !isEmpty(clients.find(x => x.user_id == signer)) || signer == "other"
    const numClientSigners = getClientSigners(contract.signers).length
    const numAdminSigners = getAdminSigners(contract.signers).length

    if (isExists) {
      setAddSignerMessage("This signer has already been added.")
    } else if (isClient && numClientSigners > 1) {
      setAddSignerMessage("A maximum of two clients can be added to the contract. Please remove one before adding any others.")
    } else if (isEmployee && numAdminSigners > 0) {
      setAddSignerMessage("Only one staff member can be added as a signer. Please remove the existing staff member in order to add another one.")
    } else {
      setAddSignerMessage("")
    }
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      titleInputRef.current.focus()
    }, 100)
  }

  const onTitleKeyPress = (e) => {
    if (e.keyCode == 13) {
      saveContractName()
    }
  }

  const saveContractName = async () => {
    var newContractName = ""
    if (!isEmpty(contractName)) {
      newContractName = contractName
    } else {
      newContractName = "Contract"
      setContractName(newContractName)
    }
    try {
      setSaving(true)
      const body = {
        event_contract_name: newContractName
      }
      await sendRequest(updateEventContractName(eventId, contractId, body))
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
      setEditingTitle(false)
    }
  }

  const signerMenu = (signer) => {
    const isDisabled = (isArray(contract.signers) && contract.signers.length == 1) || signer.signed
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10" disabled={isDisabled}>
          <div onClick={() => onRemoveSigner(signer)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove signer
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderSubHeader = () => {
    if (!isEditable) {
      return <div className="flex-1"></div>;
    } else if (isSaving) {
      return (
        <div className="flex-row flex-middle c-text-gray flex-1 nowrap">
          <FiRefreshCw style={{ marginRight: 5}}/> Saving changes...
        </div>
      )
    } else {
      return (
        <div className="flex-row flex-middle c-text-gray flex-1 nowrap">
          <FiCheckCircle style={{ marginRight: 5}}/> All changes saved!
        </div>
      )
    }
  }

  const renderSignatureLine = (signer, index) => {
    return (
      <div key={index}>
        <div className="b-border mt-50"></div>
        <div className="mt-5 ml-10 fw-600">
          { signer.first_name } { signer.last_name }
        </div>
        <div className="c-text-gray ml-10">
          { signer.email }
        </div>
     </div>
    )
  }

  const renderHeader = () => {
    const isAdminSigner = isArray(contract.signers) && !isEmpty(contract.signers.filter(x => x.user_id == auth.user.user_id))
    var buttonText = "Next: Preview & Sign"
    if (isAdminSigned || !isAdminSigner) {
      buttonText = "Next: Preview"
    }

    var backPath = `/admin/events/${eventId}?tab=documents`
    var previewLink = `/admin/events/${eventId}/contracts/${contractId}/preview`
    if (fromPage == "contracts") {
      backPath = "/admin/contracts"
      previewLink = `/admin/events/${eventId}/contracts/${contractId}/preview?from=edit-contracts`
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20"> 
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className={`header-editable-title small mr-10 flex-ellipsis ${isEditingTitle ? "editing" : ""}`}>
              <div className="header-editable-title--label flex-ellipsis" onClick={() => startEditingTitle()}>{ contractName }</div>
              <div className="header-editable-title--input">
                <Input 
                  size="medium" 
                  placeholder="Email" 
                  ref={titleInputRef}
                  onBlur={() => saveContractName()} 
                  value={contractName} 
                  onChange={(e) => setContractName(e.target.value)} 
                  onKeyUp={onTitleKeyPress}
                />
              </div>
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap mr-10">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
            { renderSubHeader() }
            <div className="ml-10">
              <button className="small-primary-button" style={{ }} onClick={() => navigate(previewLink)}>{buttonText}</button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}><HiOutlineArrowLeft/></div>
            <div className={`header-editable-title small mr-10 flex-ellipsis ${isEditingTitle ? "editing" : ""}`}>
              <div className="header-editable-title--label flex-ellipsis" onClick={() => startEditingTitle()}>{ contractName }</div>
              <div className="header-editable-title--input">
                <Input 
                  size="medium" 
                  placeholder="Email" 
                  ref={titleInputRef}
                  onBlur={() => saveContractName()} 
                  value={contractName} 
                  onChange={(e) => setContractName(e.target.value)} 
                  onKeyUp={onTitleKeyPress}
                />
              </div>
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
          </div>
          <div className="flex-col flex-middle mt-10">
            <div className=" mb-10">
              <button className="small-primary-button" style={{ }} onClick={() => navigate(previewLink)}>{buttonText}</button>
            </div>
            { renderSubHeader() }
          </div>
        </div>
      )
    }
  }

  const renderInfoBanner = () => {
    if (isEditable) {
      return null;
    }
    return (
      <div className="bg-white border radius-8 mb-20 p-20 c-text-gray hide-print text-center">
        <FiInfo style={{ marginRight: 8, color: "#536DFE"}}/> This document can no longer be updated since one or more parties have signed the contract.
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        { isEditable && (
          <div className="bg-white b-border p-10" style={{ position: "fixed", right: 0, left: screens.md ? 300 : 0, zIndex: 2}}>
            <MenuBar editor={editor} />
          </div>
        )}
        <FloatingContainer className={`ph-20 mb-20 bg-gray ${isEditable ? "mt-40" : ""}`} verticalPadding={20} maxWidth={850}>
          { !screens.md && (
            <div className="blue-link display-flex flex-middle" onClick={() => setMenuOpen(true)}><MdMenuOpen style={{ marginRight: 5, fontSize: 20 }}/>Contract Settings</div>
          )}
          { renderInfoBanner() }
          <div className="shadow-card p-30 mt-15">
            <div style={{ minHeight: 400 }}>
              <EditorContent editor={editor} />
            </div>
            <div className="bg-gray radius-8 p-20 mt-20 border">
              <div className="c-text-gray">
                <i>Signature lines will automatically be added to the end of the document.</i>
              </div>
              { getClientSigners(contract.signers).map((x,i) => renderSignatureLine(x,i))}
              { getAdminSigners(contract.signers).map((x,i) => renderSignatureLine(x,i))}
            </div>
          </div>
        </FloatingContainer>
      </>
    )
  }

  const renderSigner = (signer) => {
    var clientPhoto = null;
    var signerSuffix = signer.user_id == auth.user.user_id ? " (You)" : "";
    return (
      <div className="mb-20">
        <div className="flex-row flex-middle">
          <div className="flex-0 mr-10">
            {/* { clientPhoto ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}} onClick={() => displayPhoto(clientPhoto)}></div>
            ) : ( */}
              <div className="display-flex">
                <div className="user-profile-circle small">{ signer.signed ? <FiCheck/> : <UserOutlined/>}</div>
              </div>
            {/* )} */}
          </div>
          <div className="flex-1 flex-ellipsis">
            <div className="fs-14 fw-700 flex-ellipsis">{signer.first_name} {signer.last_name}{signerSuffix}</div>
            <div className="fs-12 fw-500 c-text-gray flex-ellipsis" style={{ marginTop: -2 }}>{signer.email}</div>
          </div>
          { isEditable && (
            <div className="flex-0 ml-10">
              <div className="display-flex mr-5">
                <Dropdown overlay={signerMenu(signer)} placement="bottomRight" trigger="click">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
        { signer.signed && !isEmpty(signer.signed_datetime) && (
          <div className="mt-5">
            <div className="bg-gray radius-8 ph-10 pv-5 fs-12 c-text-gray">Signed on {formatDate(signer.signed_datetime, accountSettings, true)}</div>
          </div>
        )}
      </div>
    )
  }

  const renderConfirmTemplateModal = () => {
    return (
      <Modal visible={isConfirmTemplateModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmTemplateModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Confirm Template Change</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to apply the template <span className="fw-700">{ getTemplateName() }</span> to this contract? All contents of the current contract will be erased. This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => replaceContent()}>Apply Template</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmTemplateModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveSignerModal = () => {
    return (
      <Modal visible={isConfirmRemoveSignerModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveSignerModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Signer</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{ selectedSigner.first_name } { selectedSigner.last_name }</span> from this contract? This signer will no longer have access to sign the document.</div>
          <button className="primary-button warning" type="button" onClick={() => removeSigner()}>Remove Signer</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveSignerModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderAddSignerModal = () => {
    const isDisabled = !isEmpty(addSignerMessage)
    return (
      <Modal visible={isAddSignerModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Signer</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddSignerModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={addSignerForm} layout="vertical" name="new-questionnaire" onFinish={onAddSignerSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              <Form.Item 
                name={"user_id"}
                rules={[{ required: true, message: `Signer is required!` }]}
              >
                <Select
                  style={{ width: '100%'}}
                  allowClear
                  placeholder={"Select a signer..."}
                  size='large'
                  onChange={(value) => onSelectSignerChange(value)}
                >
                  <Select.OptGroup label="Clients">
                    {clients.map((client) => (
                      <Select.Option value={client.user_id} key={client.user_id}>{client.first_name} {client.last_name}</Select.Option>
                    ))}
                  </Select.OptGroup>
                  <Select.OptGroup label="Staff">
                    {employees.map((employee) => (
                      <Select.Option value={employee.user_id} key={employee.user_id}>{employee.first_name} {employee.last_name}</Select.Option>
                    ))}
                  </Select.OptGroup>
                  <Select.OptGroup label="Other">
                    <Select.Option value={"other"}>Someone Else</Select.Option>
                  </Select.OptGroup>
                </Select>
              </Form.Item>
            </Col>
            { !isEmpty(addSignerMessage) && (
              <Col xs={24}>
                <div className="bg-gray radius-8 p-15 text-center">{addSignerMessage}</div>
              </Col>
            )}
            { selectedSignerUser == "other" && !isDisabled && (
              <>
                <Col xs={24}>
                  { renderInputField("First Name", "first_name", true) }
                </Col>
                <Col xs={24}>
                  { renderInputField("Last Name", "last_name", true) }
                </Col>
                <Col xs={24}>
                  { renderEmailField("Email", "email", true) }
                </Col>
              </>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className={`primary-button ${isDisabled ? "disabled" : ""}`} type="submit" disabled={isDisabled}>Add Signer</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setAddSignerModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderExpirationSection = () => {
    if (hasExpiration) {
      if (isEditExpiration) {
        return (
          <Row align="middle" className="mt-15">
            <Col flex={1}>
              <DatePicker 
                value={expirationDate}
                onChange={setExpirationDate} 
                disabledDate={disabledDate}
                size="large" 
                format={getDatePickerFormat(accountSettings)}/>
            </Col>
            <Col flex={0}>
              <div className="blue-link" onClick={() => onSaveExpiration()}>Save</div>
            </Col>
          </Row>
        )
      } else {
        return (
          <Row className="mt-15">
            <Col flex={1}>
              <div>{!isNull(expirationDate) ? formatDateMedium(expirationDate.format(), accountSettings) : "--"}</div>
            </Col>
            <Col flex={0}>
              <div className="blue-link" onClick={() => onEditExpiration()}>Edit</div>
            </Col>
          </Row>
        )
      }
    }
    return null;
  }

  const renderSideBarContent = () => {
    return (
      <>
        { !screens.md && (
          <div className="b-border pb-15 mb-15">
            <div className="blue-link display-flex flex-middle" onClick={() => setMenuOpen(false)}><HiOutlineArrowLeft style={{ marginRight: 5, fontSize: 18 }}/>Close</div>
          </div>
        )}
        { isEditable && (
          <>
            <div className="fs-16 fw-700 mb-5">Contract Template</div>
            <div className="mb-20">
              <Select
                style={{ width: "100%" }}
                showSearch
                allowClear
                placeholder={"Select template..."}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                size='large'
                value={selectedContractTemplate}
                onChange={(value) => replaceContentsWithTemplate(value)}
              >
                { contractTemplates.map((x,i) => (
                  <Select.Option value={x.contract_template_id}>{x.contract_template_name}</Select.Option>
                ))}
              </Select>
            </div>
          </>
        )}
        <div className="fs-16 fw-700 mb-15">Signers</div>
        { getClientSigners(contract.signers).map((x,i) => renderSigner(x,i))}
        { getAdminSigners(contract.signers).map((x,i) => renderSigner(x,i))}
        { isEditable && (
          <div className="blue-link ml-5" onClick={() => onAddSigner()}>+ Add signer</div>
        )}
        <div className="mt-20 pt-20 t-border">
          <div className="fs-16 fw-700 mb-15">Contract Expiration</div>
          <div className="display-flex flex-middle">
            <Switch checked={hasExpiration} onChange={(value) => onSetExpirationChange(value)} /> <span className="ml-10 fw-600">Set expiration date</span>
          </div>
          { renderExpirationSection() }
        </div>
      </>
    )
  }

  const renderSideBar = () => {
    // if (!screens.md) {
    //   return null;
    // }
    return (
      <div className={`event-contract--sider p-20 ${isMenuOpen ? "open" : ""}`}>
        { renderSideBarContent() }
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        { renderSideBar() }
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
      { renderConfirmTemplateModal() }
      { renderConfirmRemoveSignerModal() }
      { renderAddSignerModal() }
    </div>
  );
}

export default EditEventContractPage;
