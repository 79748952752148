import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep, isArray, isEmpty, isUndefined, isNull } from "lodash";
import useApi from '../../../hooks/useApi';
import uniqid from 'uniqid';
import moment from 'moment';
import { Row, Col, Input, Select, Modal, Form, Grid, notification } from 'antd';
import {             
  getEventTemplate,
  getNewEventData,
  getVenues,
  createEventTemplate,
  modifyEventTemplate
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useCurrency from '../../../hooks/useCurrency';
import NewEventServices from '../events/newEvent/newEventServices'
import { MdArrowForwardIos, MdOutlineClose } from "react-icons/md";
import { FiPlus, FiMusic, FiEdit, FiTrash } from "react-icons/fi";
import { BsFileEarmarkText } from "react-icons/bs";
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { getNumericCurrency, formatCurrencyString } from '../../../helpers/contractHelper';
import { getFormattedPhoneNumber } from '../../../helpers/addressHelper';
import useAccountSettings from '../../../hooks/useAccountSettings';
import {
  renderFormLabel,
  renderInputField,
  renderTimeField,
  renderSearchSelectField,
  renderTextAreaField,
  renderNumberField,
  renderCurrencyField,
  renderCheckboxField
} from '../../../components/formFields'
const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventTemplatePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [eventTemplateName, setEventTemplateName] = useState("");
  const [eventTemplate, setEventTemplate] = useState({});
  const [eventTypes, setEventTypes] = useState([]);
  const [venues, setVenues] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [adminEventRoles, setAdminEventRoles] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [systemSpecialEventTemplates, setSystemSpecialEventTemplates] = useState([]);
  const [specialEventTemplates, setSpecialEventTemplates] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedDeposit, setSelectedDeposit] = useState("");
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [isPlaylistModalVisible, setPlaylistModalVisible] = useState(false);
  const [isEmployeeSelectionModalVisible, setEmployeeSelectionModalVisible] = useState(false);
  const [staffClientVisible, setStaffClientVisible] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [currency] = useCurrency()
  const [accountSettings] = useAccountSettings()

  const [form] = Form.useForm();
  const [employeeForm] = Form.useForm();
  const [playlistForm] = Form.useForm();
  const [sendRequest] = useApi()

  const templateId = params.id;
  const isNewTemplate = params.id == "new";

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const newEventData = await sendRequest(getNewEventData());

      // Event Types
      const eventTypeData = newEventData.account_event_types.map((eventType) => {
        return {
          value: eventType.event_type_id,
          text: eventType.event_type_name
        }
      })
      setEventTypes(eventTypeData);

      // Staff
      setEmployees(isArray(newEventData.employees) ? newEventData.employees : []);
      const adminEventRolesData = newEventData.admin_event_roles.map((role) => {
        return {
          value: role.admin_event_role_id,
          text: role.role_name
        }
      })
      setAdminEventRoles(adminEventRolesData)

      // Questionnaires
      const questionnaireList = isArray(newEventData.questionnaires) ? newEventData.questionnaires : []
      setQuestionnaires(questionnaireList);

      // Workflows
      setWorkflows(isArray(newEventData.workflows) ? newEventData.workflows : []);

      // Special Event Templates
      setSystemSpecialEventTemplates(isArray(newEventData.system_special_event_templates) ? newEventData.system_special_event_templates : []);
      setSpecialEventTemplates(isArray(newEventData.account_special_event_templates) ? newEventData.account_special_event_templates : []);

      // Venues
      const venueResults = await sendRequest(getVenues());
      const venueData = venueResults.map((venue) => {
        return {
          value: venue.venue_id,
          text: `${venue.venue_name} (${venue.city}, ${venue.state})`
        }
      })
      setVenues(venueData);

      if (isNewTemplate) {
        const initialPlaylists = [
          { id: uniqid(), playlist_name: "Must Play", song_limit: 30 },
          { id: uniqid(), playlist_name: "Play if Possible", song_limit: 30 },
          { id: uniqid(), playlist_name: "Do Not Play", song_limit: 30 }
        ]
        setSelectedPlaylists(initialPlaylists)
      } else {
        const eventTemplateData = await sendRequest(getEventTemplate(templateId));
        setEventTemplate(eventTemplateData)
        setEventTemplateName(eventTemplateData.event_template_name)
        setSelectedPackages(eventTemplateData.packages)
        setSelectedAddOns(eventTemplateData.add_ons)
        setSelectedDeposit(eventTemplateData.booking_fee)
        setSelectedWorkflow(eventTemplateData.workflow_id)
        setSelectedPlaylists(eventTemplateData.playlists)
        const filteredQuestionnaires = questionnaireList.filter(x => eventTemplateData.questionnaires.includes(x.questionnaire_id))
        setSelectedQuestionnaires(filteredQuestionnaires)
        setSelectedEmployees(eventTemplateData.assigned_staff)

        console.log(eventTemplateData)

        const fields = {
          event_template_name: eventTemplateData.event_template_name,
          event_type_id: eventTemplateData.event_type_id,
          arrival_time: populateTime(eventTemplateData.arrival_time),
          start_time: populateTime(eventTemplateData.start_time),
          end_time: populateTime(eventTemplateData.end_time),
          internal_notes: eventTemplateData.internal_notes,
          lockdown_days: eventTemplateData.lockdown_days,
          venue_id: eventTemplateData.venue_id,
          special_event_template_id: eventTemplateData.special_event_template_id,
          workflow_id: eventTemplateData.workflow_id
        }

        form.setFieldsValue(fields)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const optionalValue = (value) => {
    return !isUndefined(value) ? value : null
  }

  const populateTime = (time) => {
    return !isNull(time) ? moment(time, "H:mm") : null
  }

  const formatTimeForDB = (date) => {
    if (!isEmpty(date)) {
      return date.format("H:mm")
    }
    return null;
  }

  const onSubmit = async (values) => {
    const assignedStaff = selectedEmployees.map(x => {
      return {
        user_id: x.user_id,
        admin_event_role_id: x.admin_event_role_id,
        client_visible: x.client_visible,
        display_contact_info: x.display_contact_info,
        wage: x.wage
      }
    })

    try {
      const body = {
        event_template_name: values.event_template_name,
        event_type_id: optionalValue(values.event_type_id),
        arrival_time: formatTimeForDB(values.arrival_time),
        start_time: formatTimeForDB(values.start_time),
        end_time: formatTimeForDB(values.end_time),
        internal_notes: optionalValue(values.internal_notes),
        lockdown_days: optionalValue(values.lockdown_days),
        venue_id: optionalValue(values.venue_id),
        assigned_staff: assignedStaff,
        packages: selectedPackages,
        add_ons: selectedAddOns,
        booking_fee: getNumericCurrency(selectedDeposit),
        special_event_template_id: optionalValue(values.special_event_template_id),
        playlists: selectedPlaylists,
        workflow_id: optionalValue(selectedWorkflow),
        questionnaires: selectedQuestionnaires.map(x => x.questionnaire_id)
      }

      if (isNewTemplate) {
        await sendRequest(createEventTemplate(body))
        notification.success({
          message: 'Success!',
          description: 'Your event template has been created.',
          duration: 3
        });
      } else {
        await sendRequest(modifyEventTemplate(eventTemplate.event_template_id, body))
        notification.success({
          message: 'Success!',
          description: 'Your event template has been updated.',
          duration: 3
        });
      }

      navigate(`/admin/event-templates`)
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const getAdminEventRoleDesc = (id) => {
    const role = adminEventRoles.find(x => x.value == id)
    return role.text ?? "";
  }

  const onSelectEmployeeButton = () => {
    employeeForm.resetFields()
    employeeForm.setFieldsValue({ client_visible: true, display_contact_info: true })
    setStaffClientVisible(true)
    setEmployeeSelectionModalVisible(true)
  }

  const onStaffVisibleToClientChange = (e) => {
    setStaffClientVisible(e.target.checked)
    employeeForm.setFieldsValue({ display_contact_info: e.target.checked })
  }

  const onAddPlaylist = () => {
    setSelectedPlaylist({})
    playlistForm.resetFields()
    playlistForm.setFieldsValue({ song_limit: 30 })
    setPlaylistModalVisible(true)
  }

  const onEditPlaylist = (playlist) => {
    setSelectedPlaylist(playlist)
    playlistForm.resetFields()
    playlistForm.setFieldsValue(playlist)
    setPlaylistModalVisible(true)
  }

  const onRemovePlaylist = (playlist) => {
    var newPlaylists = cloneDeep(selectedPlaylists)
    newPlaylists = newPlaylists.filter(x => x.id != playlist.id)
    setSelectedPlaylists(newPlaylists)
  }

  const onPlaylistSubmit = (values) => {
    var newPlaylists = cloneDeep(selectedPlaylists)

    if (isEmpty(selectedPlaylist)) {
      // Add new playlist
      const playlist = newPlaylists.find((x) => x.playlist_name == values.playlist_name)
      if (isUndefined(playlist)) {
        const playlistRecord = {
          id: uniqid(),
          playlist_name: values.playlist_name,
          song_limit: values.song_limit
        }
        newPlaylists.push(playlistRecord)
        setSelectedPlaylists(newPlaylists)
      }
    } else {
      // Edit playlist
      const playlist = newPlaylists.find((x) => x.playlist_name == values.playlist_name && x.id != selectedPlaylist.id)
      if (isUndefined(playlist)) {
        for (var list of newPlaylists) {
          if (list.id == selectedPlaylist.id) {
            list.playlist_name = values.playlist_name
            list.song_limit = values.song_limit
          }
        }
        setSelectedPlaylists(newPlaylists)
      }
    }
    setPlaylistModalVisible(false)
  }

  const onQuestionnaireSelect = (id) => {
    if (!id) { return }
    const newQuestionnaires = [].concat(selectedQuestionnaires);
    const questionnaire = newQuestionnaires.find((x) => x.questionnaire_id == id)
    if (isUndefined(questionnaire)) {
      const lookupQuestionnaire = questionnaires.find((x) => x.questionnaire_id == id)
      newQuestionnaires.push(lookupQuestionnaire)
    }
    setSelectedQuestionnaires(newQuestionnaires)
  }

  const removeSelectedQuestionnaire = (id) => {
    const newQuestionnaires = cloneDeep(selectedQuestionnaires);
    const newFilteredQuestionnaires = newQuestionnaires.filter((x) => x.questionnaire_id != id)
    setSelectedQuestionnaires(newFilteredQuestionnaires)
  }

  const getQuestionnaireOptionName = (questionnaire) => {
    if (!isNull(questionnaire.internal_questionnaire_name) && questionnaire.internal_questionnaire_name != "") {
      return questionnaire.internal_questionnaire_name
    }
    return questionnaire.questionnaire_name
  }

  const removeSelectedEmployee = (id) => {
    const newSelectedEmployees = cloneDeep(selectedEmployees);
    const newFilteredEmployees = newSelectedEmployees.filter((x) => x.user_id != id)
    setSelectedEmployees(newFilteredEmployees)
  }

  const onEmployeeSubmit = (values) => {
    // Make sure employee isn't already added
    const newSelectedEmployees = cloneDeep(selectedEmployees);
    const employee = newSelectedEmployees.find((x) => x.user_id == values.user_id)
    if (isUndefined(employee)) {
      // If employee hasn't been added, look up the employee record and add them
      const existingEmployee = cloneDeep(employees.find((x) => x.user_id == values.user_id))
      const newData = {
        ...existingEmployee,
        admin_event_role_id: values.admin_event_role_id,
        client_visible: values.client_visible ?? false,
        display_contact_info: values.display_contact_info ?? false,
        wage: getNumericCurrency(values.wage)
      }
      newSelectedEmployees.push(newData)
      setSelectedEmployees(newSelectedEmployees)
    }
    setEmployeeSelectionModalVisible(false)
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/event-templates")}>
            Event Templates
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { isNewTemplate ? "New" : "Edit"}
          </span>
        </div>
        <Row align="middle" gutter={[15,15]}>
          <Col flex={1}>
            <div className="fw-700 fs-24 mt-5">{ isNewTemplate ? "New Event Template" : eventTemplateName }</div>
          </Col>
          <Col>
            { isSaving ? (
              <button className="page-title-button" style={{ width: 120 }}><LoadingOutlined/></button>
            ) : (
              <button className="page-title-button" style={{ width: 120 }} onClick={() => form.submit()}>Save</button>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderSectionHeader = (title, showAddButton = false, showLearnMore = false, action = () => {}) => {
    return (
      <Row align="middle" className="pb-10 mb-15 b-border" wrap={false}>
        <Col flex={1}>
          <div className="fs-18 fw-700">{title}</div>
        </Col>
        { showAddButton && (
          <Col>
            <div className="plus-container mr-5" onClick={() => action()}>
              <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
            </div>
          </Col>
        )}
        { showLearnMore && (
          <Col>
            <div className="blue-link" onClick={() => action()}>Learn more</div>
          </Col>
        )}
      </Row>
    )
  }

  const renderSelectedEmployees = (employee, index) => {
    return (
      <div className="border radius-8 p-10 mb-10 mh-0">
        <Row key={index} gutter={[15,15]} align="middle" wrap={false}>
          <Col>
              { employee.photo ? (
                <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${employee.photo})`}}></div>
              ) : (
                <div className="display-flex">
                  <div className="user-profile-circle"><UserOutlined/></div>
                </div>
              )}
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-600 line-1-4">{employee.first_name} {employee.last_name} ({getAdminEventRoleDesc(employee.admin_event_role_id)})</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{employee.email}</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{getFormattedPhoneNumber(employee.phone)}</div>
          </Col>
          <Col>
            <div className="display-flex mr-5">
              <FiTrash size={20} color="#ccc" onClick={() => removeSelectedEmployee(employee.user_id)}/>
            </div>
          </Col>
        </Row>
        <div className="fs-12 bg-gray ph-15 pv-10 radius-8 mt-10">
          <div><b>Visible to client:</b> {employee.client_visible ? "Yes" : "No" }  {employee.client_visible && !employee.display_contact_info ? "(hide contact info)" : ""}</div>
          { employee.wage > 0 && (
            <div><b>Wage:</b> {formatCurrencyString(employee.wage, currency.code)}</div>
          )}
        </div>
      </div>
    )
  }

  const renderSelectedPlaylist = (playlist, index) => {
    return (
      <Row key={index} gutter={[15,15]} align="middle" className="border radius-8 pv-10 ph-5 mb-10 mh-0" wrap={false}>
        <Col onClick={() => onEditPlaylist(playlist)}>
          <div className="music-icon"><FiMusic/></div>
        </Col>
        <Col flex={1} onClick={() => onEditPlaylist(playlist)}>
          <div className="fs-14 fw-600 line-1-2">{playlist.playlist_name}</div>
          <div className="fs-12 c-text-gray line-1-2">Song Limit: {playlist.song_limit == 0 ? "None" : playlist.song_limit}</div>
        </Col>
        <Col>
          <div className="display-flex">
            <FiEdit size={20} color="#ccc" onClick={() => onEditPlaylist(playlist)}/>
          </div>
        </Col>
        <Col>
          <div className="display-flex mr-5">
            <FiTrash size={20} color="#ccc" onClick={() => onRemovePlaylist(playlist)}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderSelectedQuestionnaires = (questionnaire, index) => {
    return (
      <Row key={index} gutter={[15,15]} align="middle" className="border radius-8 pv-10 ph-5 mb-10 mh-0" wrap={false}>
        <Col>
          <div className="music-icon"><BsFileEarmarkText/></div>
        </Col>
        <Col flex={1}>
          <div className="fs-14 fw-600 line-1-2">{getQuestionnaireOptionName(questionnaire)}</div>
        </Col>
        <Col>
          <div className="display-flex mr-5">
            <FiTrash size={20} color="#ccc" onClick={() => removeSelectedQuestionnaire(questionnaire.questionnaire_id)}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderPlaylistModal = () => {
    return (
      <Modal visible={isPlaylistModalVisible} footer={null} closable={false} onCancel={() => setPlaylistModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isEmpty(selectedPlaylist) ? "Add Playlist" : "Edit Playlist"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPlaylistModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={playlistForm} layout="vertical" name="employeeForm" onFinish={onPlaylistSubmit}>
          <Row align="middle" gutter={[{ xs: 0, md: 20 },{ xs: 0, md: 10 }]} className="mt-15">
            <Col xs={24}>
              { renderInputField("Playlist Name", "playlist_name", true, false, "Enter a playlist name...") }
            </Col>
            <Col xs={24}>
              { renderNumberField("Song Limit", "song_limit", 0, true) }
              <div className="bg-gray p-10 mt-10 radius-8"><b>Note:</b> Entering a value of "0" will remove the song limit.</div>
            </Col>
          </Row>
        </Form>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => playlistForm.submit()}>{isEmpty(selectedPlaylist) ? "Add Playlist" : "Save"}</button>
        </div>
      </Modal>
    )
  }

  const renderEmployeeSelectionModal = () => {
    const employeeData = employees.map((user) => {
      return {
        value: user.user_id,
        text: user.first_name + " " + user.last_name
      }
    })
    return (
      <Modal visible={isEmployeeSelectionModalVisible} footer={null} closable={false} onCancel={() => setEmployeeSelectionModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{"Add Staff"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEmployeeSelectionModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={employeeForm} layout="vertical" name="employeeForm" onFinish={onEmployeeSubmit}>
          <Row align="middle" gutter={[20,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Staff", "user_id", "Select staff member", employeeData, true) }
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Event Role", "admin_event_role_id", "Select event role", adminEventRoles, true) }
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Wage (optional)", "wage", false, currency.code) }
            </Col>
            <Col xs={24}>
              { renderFormLabel("Client Portal") }
              <div className="border radius-8 pv-10 ph-15">
                { renderCheckboxField("Visible to client", "client_visible", onStaffVisibleToClientChange, "Determines whether or not this assigned staff member is visible in the client portal.") }
                { renderCheckboxField("Display contact information", "display_contact_info", null, "Determines whether or not this staff member's phone number and email address is visible to the client.", !staffClientVisible) }
              </div>
            </Col>
          </Row>
        </Form>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => employeeForm.submit()}>Add Staff</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "ph-30 pv-20" : "p-20"
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <Form form={form} layout="vertical" name="template" onFinish={onSubmit} scrollToFirstError={true}>
          <div className="mh-10 mb-10 text-center">This template is designed to set default values for a specific type of event. All the fields below are <b>OPTIONAL</b>, allowing you to only fill out the information you would like to automatically populate when you create an event.</div>
          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            { renderSectionHeader("Template Name") }
            <Row className="mt-10" gutter={[20, 20]}>
              <Col xs={24} md={24}>
                <Form.Item
                  name={"event_template_name"}
                  rules={[{ required: true, message: `Template Name is required!`, validateTrigger: "onBlur" }]}
                >
                  <Input size="large" placeholder={"Enter a name for your template..."} onChange={(e) => setEventTemplateName(e.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            { renderSectionHeader("Event Details") }
            <Row gutter={[20,10]}>
              <Col xs={24} md={24}>
                { renderSearchSelectField("Event Type", "event_type_id", "", eventTypes, false) }
              </Col>
              <Col xs={12} md={8}>
                { renderTimeField("Arrival Time", "arrival_time", false, accountSettings) }
              </Col>
              <Col xs={12} md={8}>
                { renderTimeField("Start Time", "start_time", false, accountSettings) }
              </Col>
              <Col xs={12} md={8}>
                { renderTimeField("End Time", "end_time", false, accountSettings) }
              </Col>
              <Col xs={24} md={24}>
                { renderTextAreaField("Internal Notes", "internal_notes", 5) }
              </Col>
              <Col xs={24} md={24}>
                { renderNumberField("Lockdown Days", "lockdown_days", 0, false, "The number of days before the event when the client can no longer make changes.") }
              </Col>
            </Row>
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            { renderSectionHeader("Venue") }
            { renderSearchSelectField("", "venue_id", "Select a venue", venues, false) }
          </div>
          
          <div className={`shadow-card ${paddingClass} mt-15`} id="employees">
            { renderSectionHeader("Assigned Staff", selectedEmployees.length > 0, false, () => {}) }
            <Row align="middle">
              <Col xs={24} md={24}>
                { selectedEmployees.map((x,i) => renderSelectedEmployees(x,i)) }
              </Col>
              <Col xs={24}>
                <div className="text-center">
                  { selectedEmployees.length == 0 && (
                   <div className="bg-gray radius-8 p-20 text-center">
                    <div>Select staff member(s) to assign to this event.</div>
                    <button className="page-title-button mt-15" type="button" onClick={() => onSelectEmployeeButton()}>Add Staff</button>
                  </div>
                  )}            
                </div>
              </Col>
            </Row>
          </div>

          <NewEventServices
            selectedPackages={selectedPackages}
            selectedAddOns={selectedAddOns}
            setSelectedPackages={setSelectedPackages}
            setSelectedAddOns={setSelectedAddOns}
            selectedDeposit={selectedDeposit}
            setSelectedDeposit={setSelectedDeposit}
            fromPage={"event-template"}
          />

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            { renderSectionHeader("Special Event Template", false, true, () => window.open(`${BASE_URL}/admin/docs/special-event-templates`, "_blank"))}
            <Form.Item 
              name={"special_event_template_id"}
            >
              <Select
                style={{ width: '100%'}}
                allowClear
                placeholder={"Select a template"}
                size='large'
              >
                <Select.OptGroup label="My Templates">
                  {specialEventTemplates.map((template) => (
                    <Select.Option value={template.account_special_event_template_id} key={template.account_special_event_template_id}>{template.template_name}</Select.Option>
                  ))}
                  { specialEventTemplates.length == 0 && (
                    <Select.Option value={null} disabled={true}>No templates created yet</Select.Option>
                  )}
                </Select.OptGroup>
                <Select.OptGroup label="DJ Planning Center Templates">
                  {systemSpecialEventTemplates.map((template) => (
                    <Select.Option value={template.system_special_event_template_id} key={template.system_special_event_template_id}>{template.template_name}</Select.Option>
                  ))}
                </Select.OptGroup>
              </Select>
            </Form.Item>
          </div>

          <div className={`shadow-card ${paddingClass} mt-15`}>
            { renderSectionHeader("Playlists", true, false, onAddPlaylist) }
            { selectedPlaylists.map((x,i) => renderSelectedPlaylist(x,i)) }
            { selectedPlaylists.length == 0 && (
              <div className="mb-5">
                <div className="bg-gray radius-8 p-20 text-center">Add optional playlists to this event.</div>  
              </div>
            )}
          </div>

          <div className={`shadow-card ${paddingClass} mt-15`}>
            { renderSectionHeader("Workflow", false, true, () => window.open(`${BASE_URL}/admin/docs/workflows`, "_blank"))}
            <Row align="middle" gutter={[20,10]}>
              <Col flex={1}>
                <div className="c-text-gray mb-15">Select an optional workflow to assign to this template.</div>
                <Select
                  style={{ width: '100%'}}
                  showSearch
                  allowClear
                  placeholder={"Select a workflow"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  size='large'
                  value={selectedWorkflow}
                  onChange={(value) => setSelectedWorkflow(value)}
                >
                  {workflows.map((workflow) => (
                    <Select.Option value={workflow.workflow_id} key={workflow.workflow_id}>{workflow.workflow_name}</Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>

          <div className={`shadow-card ${paddingClass} mt-15`}>
            { renderSectionHeader("Questionnaires", false, true, () => window.open(`${BASE_URL}/admin/docs/questionnaires`, "_blank"))}
            <Row align="middle" gutter={[20,10]}>
              <Col flex={1}>
                <div className="c-text-gray mb-15">Assign one or more questionnaires to this template.</div>
                <Select
                  style={{ width: '100%'}}
                  allowClear
                  placeholder={"Select a questionnaire"}
                  size='large'
                  value={selectedQuestionnaire}
                  onChange={onQuestionnaireSelect}
                >
                  {questionnaires.map((questionnaire) => (
                    <Select.Option value={questionnaire.questionnaire_id} key={questionnaire.questionnaire_id}>{getQuestionnaireOptionName(questionnaire)}</Select.Option>
                  ))}
                </Select>
              </Col>
              { selectedQuestionnaires.length > 0 && (
                <Col xs={24} className="mt-10">
                  { selectedQuestionnaires.map((q,i) => renderSelectedQuestionnaires(q,i)) }
                </Col>
                )}
            </Row>
          </div>

        </Form>
        { renderPlaylistModal() }
        { renderEmployeeSelectionModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} />
  );
}

export default EventTemplatePage;