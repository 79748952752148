import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Input } from 'antd';
import { cloneDeep } from 'lodash';
import useApi from '../hooks/useApi';
import { MdOutlineClose, MdCheck } from "react-icons/md";
import { LoadingOutlined } from '@ant-design/icons';
import { getExternalPlaylist } from '../api';
import spotifyLogo from '../images/spotify-logo.png';
import appleMusicLogo from '../images/apple-music-logo.png';

const ImportPlaylistModal = props => {

  const { isVisible, setVisible = () => {}, selectedPlatform, saveImportedSongs = () => {}, isLimited = false, songLimit = 0 } = props;

  const [playlistLink, setPlaylistLink] = useState("");
  const [importedPlaylistSongs, setImportedPlaylistSongs] = useState([]);
  const [importedPlaylistError, setImportedPlaylistError] = useState("");
  const [isImporting, setImporting] = useState(false);

  const [sendRequest] = useApi()

  useEffect(() => {
    if (isVisible) {
      setImportedPlaylistSongs([])
      setPlaylistLink("")
      setImportedPlaylistError("")
    }
  }, [isVisible]);

  const importPlaylist = () => {
    setImportedPlaylistError("")
    if (playlistLink == "" || isImporting) {
      return
    }
    setImporting(true)
    sendRequest(getExternalPlaylist({ playlist_link: playlistLink, platform: selectedPlatform })).then(response => {
      if (response && response.length > 0) {
        const songs = response.map((x,i) => {
          return {
            ...x,
            selected: true
          }
        })
        setImportedPlaylistSongs(songs)
      } else {
        setImportedPlaylistError("The playlist could not be found.")
      }
      setImporting(false)
    })
  }

  const importSongs = () => {
    var newImportedSongs = cloneDeep(importedPlaylistSongs)
    var filteredImportedSongs = newImportedSongs.filter(x => x.selected)
    if (filteredImportedSongs.length == 0) {
      return
    }
    saveImportedSongs(filteredImportedSongs)
  }

  const getSelectedSongsCount = () => {
    var newImportedSongs = cloneDeep(importedPlaylistSongs)
    var filteredImportedSongs = newImportedSongs.filter(x => x.selected)
    return filteredImportedSongs.length
  }

  const toggleSelectedImportedSong = (song_id) => {
    var newImportedSongs = cloneDeep(importedPlaylistSongs)
    for (const song of newImportedSongs) {
      if (song.song_id == song_id) {
        song.selected = !song.selected
      }
    }
    setImportedPlaylistSongs(newImportedSongs)
  }

  const setSelected = (value) => {
    var newImportedSongs = cloneDeep(importedPlaylistSongs)
    for (const song of newImportedSongs) {
      song.selected = value
    }
    setImportedPlaylistSongs(newImportedSongs)
  }

  const renderImportPlaylistSong = (item, index) => {
    const borderClass = (index + 1) < importedPlaylistSongs.length ? "b-border" : ""
    return (
      <div className={`song-card no-shadow ${borderClass}`} key={index} onClick={() => toggleSelectedImportedSong(item.song_id)}>
        <div className="song-card--row-1">
          <div className="song-card--image">
            <img src={item.image} style={{ width: 50 }}/>
          </div>
          <div className="song-card--title-artist">
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
          <div className="song-card--icon">
            { item.selected ? (
              <div className="plus-container mr-5">
                <MdCheck style={{ fontSize: 24, color: '#536DFE'}}/>
              </div>
            ) : (
              <div className="plus-container mr-5">
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderImportPlaylistBody = () => {
    if (importedPlaylistSongs.length == 0) {
      return (
        <div>
          <div className="bg-gray p-20 radius-8 mt-20 text-center">
            <div className="fs-14 fw-500 mb-5">1) Navigate to a playlist in { selectedPlatform == "apple" ? "Apple Music" : "Spotify"}</div>
            <div className="fs-14 fw-500 mb-5">2) Select <b>"Share"</b> in the options menu</div>
            <div className="fs-14 fw-500 mb-5">3) Select <b>"Copy link to playlist"</b></div>
            <div className="fs-14 fw-500">4) Enter the link in the box below</div>
          </div>
          <div className="text-center mt-20 mb-10">
            { selectedPlatform == "apple" ? (
              <img src={appleMusicLogo} style={{ width: 120}}/>
            ) : (
              <img src={spotifyLogo} style={{ width: 120}}/>
            )}
          </div>
          <div className="pt-15 pb-15">
            <Input 
              value={playlistLink}
              onChange={(e) => setPlaylistLink(e.target.value)}
              placeholder="Playlist Link"
              size="large"/>
          </div>
          <div className="text-center c-red">
              { importedPlaylistError }
          </div>
          <div>
            <button className="primary-button" onClick={() => importPlaylist()}>
              { isImporting ? (
                <LoadingOutlined style={{ fontSize: 20, color: "#FFF" }}/>
              ) : (
                <span>Import Playlist</span>
              )}
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="bg-gray p-20 radius-8 mt-20 text-center">
            <div className="fs-14 fw-500">Select the songs you would like to import</div>
          </div>
          <div className="mt-10 b-border t-border p-10">
            <Row>
              { isLimited && (
                <Col flex={0}>
                  <div className={`fw-600 ${getSelectedSongsCount() > songLimit ? "c-red" : ""}`}>{ getSelectedSongsCount() } / { songLimit } songs</div>
                </Col>
              )}
              <Col flex={1}></Col>
              <Col flex={0}>
                <span className="blue-link" onClick={() => setSelected(true)}>Select All</span> | <span className="blue-link" onClick={() => setSelected(false)}>Deselect All</span>
              </Col>
            </Row>
          </div>
          { importedPlaylistSongs.map((x,i) => renderImportPlaylistSong(x,i))}
          <div>
            { isLimited && (getSelectedSongsCount() > songLimit) && (
              <div className="c-red p-10 text-center">You are allowed to add a maximum of 30 songs to this playlist.</div>
            )}
            <button className="primary-button" onClick={() => importSongs()}>
              { isImporting ? (
                <LoadingOutlined style={{ fontSize: 20, color: "#FFF" }}/>
              ) : (
                <span>Import Songs</span>
              )}
            </button>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal visible={isVisible} footer={null} onCancel={() => setVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
      <Row align="middle">
        <Col flex={1}>
          <div className="text-center" style={{ marginRight: -30 }}>
            <div className="fw-700 fs-18">Import Playlist</div>
          </div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => setVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        </Col>
      </Row>
      { renderImportPlaylistBody() }
    </Modal>
  );
};

export default ImportPlaylistModal;
