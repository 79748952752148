import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { FiMusic, FiFileText, FiCheckCircle, FiGift, FiPlusSquare, FiAward, FiSettings, FiUser, FiDollarSign, FiCalendar, FiExternalLink } from "react-icons/fi";
import { HiOutlineTemplate, HiOutlineClipboardList } from "react-icons/hi";
import { AiOutlineTag } from "react-icons/ai";
import { VscSettings } from "react-icons/vsc";
import { TbTemplate } from "react-icons/tb";
import Permissions from '../../../constants/permissions';
import { hasPermission } from '../../../helpers/permissionHelper';
import AppContext from '../../../app/context';
import Roles from '../../../constants/roles';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';

const SettingsPage = () => {

  const [isNewInvoicesEnabled, setNewInvoicesEnabled] = useState(false);
  const [isSpotifyExportEnabled, setSpotifyExportEnabled] = useState(false);

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  useDocumentTitle("Setup")
  const navigate = useNavigate();
  const [hasBetaFeature] = useBetaFeatures()

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const betaResults = await hasBetaFeature(BetaFeatures.INVOICES)
      setNewInvoicesEnabled(FeatureFlags.INVOICES || betaResults)
      const spotifyExportbetaResults = await hasBetaFeature(BetaFeatures.SPOTIFY_EXPORT)
      setSpotifyExportEnabled(FeatureFlags.SPOTIFY_EXPORT || spotifyExportbetaResults)
    } catch {}
  }

  const renderQuickLink = (icon, subtitle, title, link) => {
    return (
      <Col xs={24} md={24} lg={12} xl={8}>
        <div className="shadow-card p-20" onClick={() => navigate(link)}>
          <div className="quick-link-row">
            <div className="quick-link-col-1">
              <div className="quick-link-icon">{icon}</div>
            </div>
            <div className="quick-link-col-2">
              <div className="fs-16 fw-700">{title}</div>
              <div className="fs-10 fw-600 c-text-gray">{subtitle}</div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Setup</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    // Templates
    const canManageEventTemplates = hasPermission(user, Permissions.MANAGE_EVENT_TEMPLATES)
    const canManageSpecialEventTemplates = hasPermission(user, Permissions.MANAGE_SPECIAL_EVENTS)
    const canManageContractTemplates = hasPermission(user, Permissions.MANAGE_CONTRACT_TEMPLATES)
    const canManageInvoiceTemplates = hasPermission(user, Permissions.MANAGE_INVOICE_TEMPLATES) && isNewInvoicesEnabled
    const canManageQuestionnaires = hasPermission(user, Permissions.MANAGE_QUESTIONNAIRES)
    const canManageWorkflows = hasPermission(user, Permissions.MANAGE_WORKFLOWS)
    const canManageRecPlaylists = hasPermission(user, Permissions.MANAGE_REC_PLAYLISTS)
    const canManageTemplates = canManageEventTemplates || canManageSpecialEventTemplates || canManageContractTemplates || canManageInvoiceTemplates || canManageQuestionnaires || canManageWorkflows || canManageRecPlaylists

    // Services
    const canManagePackages = hasPermission(user, Permissions.MANAGE_PACKAGES)
    const canManageAddOns = hasPermission(user, Permissions.MANAGE_ADD_ONS)
    const canManagePricingGuides = hasPermission(user, Permissions.MANAGE_PRICING_GUIDES)
    const canManageServices = canManagePackages || canManageAddOns || canManagePricingGuides

    // Company
    const canManageCompany = user.role == Roles.OWNER

    return (
      <>
        <FloatingContainer className="ph-20 bg-gray" verticalPadding={30} maxWidth={1000}>
          { canManageCompany && (
            <>
              <div className="fw-700 fs-20 mb-10">Application</div>
              <Row gutter={[15, 15]}>
                { renderQuickLink(<FiSettings/>, "APPLICATION", "General Settings", "/admin/setup/general") }
                { renderQuickLink(<VscSettings/>, "APPLICATION", "Event Settings", "/admin/setup/event-settings") }
                { renderQuickLink(<FiAward/>, "APPLICATION", "Branding", "/admin/setup/branding") }
                { renderQuickLink(<FiUser/>, "APPLICATION", "Client Portal", "/admin/setup/client-portal") }
                { canManageCompany && renderQuickLink(<FiDollarSign/>, "APPLICATION", "Invoices & Payments", "/admin/setup/payments") }
                { isSpotifyExportEnabled && renderQuickLink(<FiMusic/>, "APPLICATION", "Music Integration", "/admin/setup/music-integration") }
                { renderQuickLink(<FiCalendar/>, "APPLICATION", "Calendar", "/admin/setup/calendar") }
                { renderQuickLink(<FiExternalLink/>, "APPLICATION", "Export Data", "/admin/setup/export") }
              </Row>
              <div className="b-border mt-40 mb-30"></div>
            </>
          )}
          { canManageTemplates && (
            <>
              <div className="fw-700 fs-20 mb-10">Templates</div>
              <Row gutter={[15, 15]}>
                { FeatureFlags.EVENT_TEMPLATES && canManageEventTemplates && renderQuickLink(<HiOutlineTemplate/>, "TEMPLATE", "Event Templates", "/admin/event-templates")}
                { canManageSpecialEventTemplates && renderQuickLink(<TbTemplate/>, "TEMPLATE", "Special Event Templates", "/admin/special-event-templates")}
                { canManageContractTemplates && renderQuickLink(<FiFileText/>, "TEMPLATE", "Contract Templates", "/admin/contract-templates") }
                { canManageInvoiceTemplates && renderQuickLink(<FiDollarSign/>, "TEMPLATE", "Invoice Templates", "/admin/invoice-templates") }
                { canManageQuestionnaires && renderQuickLink(<HiOutlineClipboardList/>, "TEMPLATE", "Questionnaires", "/admin/questionnaires") }
                { canManageWorkflows && renderQuickLink(<FiCheckCircle/>, "TEMPLATE", "Workflows", "/admin/workflows") }
                { canManageRecPlaylists && renderQuickLink(<FiMusic/>, "TEMPLATE", "Recommended Playlists", "/admin/recommended-playlists") }
              </Row>
              <div className="b-border mt-40 mb-30"></div>
            </>
          )}
          { canManageServices && (
            <>
              <div className="fw-700 fs-20 mb-10">Services</div>
              <Row gutter={[15, 15]}>
                { canManagePackages && renderQuickLink(<FiGift/>, "SERVICE", "Packages", "/admin/packages") }
                { canManageAddOns && renderQuickLink(<FiPlusSquare/>, "SERVICE", "Add-Ons", "/admin/addons") }
                { canManagePricingGuides && renderQuickLink(<AiOutlineTag/>, "SERVICE", "Pricing Guides", "/admin/pricing-guides") }
              </Row>
              <div className="b-border mt-40 mb-30"></div>
            </>
          )}
          {/* { canManageCompany && (
            <>
              <div className="fw-700 fs-20 mb-10">Lists</div>
              <Row gutter={[15, 15]}>
                { renderQuickLink(<AiOutlineTag/>, "LISTS", "Event Types", "/admin/setup/event-types") }
                { renderQuickLink(<AiOutlineTag/>, "LISTS", "Staff Event Roles", "/admin/setup/invoices") }
                { renderQuickLink(<AiOutlineTag/>, "LISTS", "Client Event Roles", "/admin/setup/invoices") }
                { renderQuickLink(<AiOutlineTag/>, "LISTS", "Favorite Genres", "/admin/setup/invoices") }
              </Row>
              <div className="b-border mt-40 mb-30"></div>
            </>
          )} */}
          {/* { canManageCompany && (
            <>
              <div className="fw-700 fs-20 mb-10">Payments</div>
              <Row gutter={[15, 15]}>
                { renderQuickLink(<TbFileDollar/>, "PAYMENTS", "Invoices", "/admin/setup/invoices") }
              </Row>
            </>
          )} */}
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default SettingsPage;
