import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';
import qs from "qs";
import uniqid from 'uniqid';
import { Row, Col, Form, Modal, Select, notification, Collapse, Grid } from 'antd';
import { isUndefined, cloneDeep, isNull, startsWith, isEmpty, isArray, pad } from "lodash";
import { 
  renderFormLabel,
  renderInputField,
  renderDateField,
  renderTimeField,
  renderSearchSelectField,
  renderTextAreaField,
  renderNumberField,
  renderPhoneField,
  renderCheckboxField,
  renderEmailField,
  renderCurrencyField,
  renderGlobalAddressField,
  renderGlobalSecondAddressField,
  renderGlobalCityField,
  renderGlobalStateField,
  renderGlobalZipField
} from '../../../components/formFields'
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { 
  createEvent, 
  getVenues,
  getSystemSpecialEventSections,
  getPricingGuideRequest,
  getNewEventData,
  getEventTemplate
 } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import SelectionModal from '../../../components/selectionModal';
import FloatingContainer from '../../../components/floatingContainer'
import { UserOutlined } from '@ant-design/icons';
import { BsFileEarmarkText } from "react-icons/bs";
import { MdOutlineClose, MdArrowForwardIos, MdOutlineRemoveCircleOutline, MdOutlineInfo } from "react-icons/md";
import { FiPlus, FiMusic, FiTrash, FiEdit } from "react-icons/fi";
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import NewEventServices from './newEvent/newEventServices'
import { getNumericCurrency, formatCurrencyString } from '../../../helpers/contractHelper';
import { getDefaultCountryCode, setDefaultCountryCode, getDefaultState, getCountryOptions, getDefaultPhoneCountryCode, getPhoneNumber, getFormattedPhoneNumber, getCountryCodeForPhoneNumber } from '../../../helpers/addressHelper';
import EventPermissions from '../../../constants/eventPermissions';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import FeatureFlags from '../../../constants/featureFlags';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const NewEventPage = () => {
  useDocumentTitle("New Event")
  const location = useLocation();
  const navigate = useNavigate();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const [accountSettings] = useAccountSettings()

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isSelectionModalVisible, setIsSelectionModalVisible] = useState(false);
  const [isClientSelectionModalVisible, setIsClientSelectionModalVisible] = useState(false);
  const [isEmployeeSelectionModalVisible, setEmployeeSelectionModalVisible] = useState(false);
  const [isPlaylistModalVisible, setPlaylistModalVisible] = useState(false);
  const [isPreviewEventTemplateModalVisible, setPreviewEventTemplateModalVisible] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [clientEventRoles, setClientEventRoles] = useState([]);
  const [adminEventRoles, setAdminEventRoles] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedClientTab, setSelectedClientTab] = useState("new");
  const [selectedVenueTab, setSelectedVenueTab] = useState("new");
  const [venues, setVenues] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isClientExistsErrorModalVisible, setClientExistsErrorModalVisible] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isEventTemplateConfirmModalVisible, setEventTemplateConfirmModalVisible] = useState(false);
  const [errorMetadata, setErrorMetadata] = useState({});
  const [specialEventTemplates, setSpecialEventTemplates] = useState([]);
  const [systemSpecialEventTemplates, setSystemSpecialEventTemplates] = useState([]);
  const [selectedEventTemplateId, setSelectedEventTemplateId] = useState("");
  const [selectedEventTemplate, setSelectedEventTemplate] = useState({});
  const [selectedEventTemplateSections, setSelectedEventTemplateSections] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [selectedFinancials, setSelectedFinancials] = useState({});
  const [selectedDeposit, setSelectedDeposit] = useState("");
  const [isSendInvitation, setSendInvitation] = useState(false);
  const [isClientMissing, setClientMissing] = useState(false);
  const [isEmployeeMissing, setEmployeeMissing] = useState(false);
  const [fromBookingRequest, setFromBookingRequest] = useState(false);
  const [venueCountryCode, setVenueCountryCode] = useState("US");
  const [venuePhoneCountryCode, setVenuePhoneCountryCode] = useState("US");
  const [newClientCountryCode, setNewClientCountryCode] = useState("US");
  const [newClientPhoneCountryCode, setNewClientPhoneCountryCode] = useState("US");
  const [newClientActiveAddressKey, setNewClientActiveAddressKey] = useState([]);
  const [staffClientVisible, setStaffClientVisible] = useState(false);
  const [eventTemplates, setEventTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isTemplateApplied, setTemplateApplied] = useState(false);

  const [form] = Form.useForm();
  const [newClientForm] = Form.useForm();
  const [existingClientForm] = Form.useForm();
  const [employeeForm] = Form.useForm();
  const [playlistForm] = Form.useForm();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
    form.setFieldsValue({ lockdown_days: 3 })
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.date) {
      form.setFieldsValue({ event_date: moment(queryStrings.date)})
    }
  }, [])

  const getData = async () => {
    try {
      const newEventData = await sendRequest(getNewEventData());

      const eventTypeData = newEventData.account_event_types.map((eventType) => {
        return {
          value: eventType.event_type_id,
          text: eventType.event_type_name
        }
      })
      setEventTypes(eventTypeData);
      setClients(isArray(newEventData.clients) ? newEventData.clients : []);
      setEmployees(isArray(newEventData.employees) ? newEventData.employees : []);
      const adminEventRolesData = newEventData.admin_event_roles.map((role) => {
        return {
          value: role.admin_event_role_id,
          text: role.role_name
        }
      })
      setAdminEventRoles(adminEventRolesData)
      setQuestionnaires(isArray(newEventData.questionnaires) ? newEventData.questionnaires : []);
      const clientEventRolesData = newEventData.client_event_roles.map((role) => {
        return {
          value: role.client_event_role_id,
          text: role.role_name
        }
      })
      setClientEventRoles(clientEventRolesData)
      setWorkflows(isArray(newEventData.workflows) ? newEventData.workflows : []);
      const systemPlaylistData = newEventData.system_playlists.map((playlist) => {
        return {
          ...playlist,
          selected: true
        }
      })
      setPlaylists(systemPlaylistData)
      setSystemSpecialEventTemplates(isArray(newEventData.system_special_event_templates) ? newEventData.system_special_event_templates : []);
      setSpecialEventTemplates(isArray(newEventData.account_special_event_templates) ? newEventData.account_special_event_templates : []);
      setEventTemplates(isArray(newEventData.event_templates) ? newEventData.event_templates : [])

      loadCountries()
      loadStates()
      setVenuePhoneCountryCode(getDefaultPhoneCountryCode())
      setNewClientPhoneCountryCode(getDefaultPhoneCountryCode())

      const initialPlaylists = [
        { id: uniqid(), playlist_name: "Must Play", song_limit: 30 },
        { id: uniqid(), playlist_name: "Play if Possible", song_limit: 30 },
        { id: uniqid(), playlist_name: "Do Not Play", song_limit: 30 }
      ]
      setSelectedPlaylists(initialPlaylists)

      // Populate Booking Request (if needed)
      await populateBookingRequest()

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const loadVenues = async () => {
    try {
      const venueResults = await sendRequest(getVenues());
      const venueData = venueResults.map((venue) => {
        return {
          value: venue.venue_id,
          text: `${venue.venue_name} (${venue.city}, ${venue.state})`
        }
      })
      setVenues(venueData);
    } catch (error) {
      console.log(error)
    }
  }

  const loadCountries = () => {
    // Load list of countries
    const data = getCountryOptions()
    setCountries(data);

    // Default form to specific country
    const defaultCountryCode = getDefaultCountryCode()
    const countryCode = !isEmpty(defaultCountryCode) ? defaultCountryCode : "US"
    setVenueCountryCode(countryCode)
    form.setFieldsValue({ venue_country: countryCode})
  }

  const loadStates = () => {
    // Default form to specific state
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      form.setFieldsValue({ venue_state: state})
    }
  }

  const populateBookingRequest = async () => {
    try {
      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (queryStrings.request) {
        setFromBookingRequest(true)
        const requestResults = await sendRequest(getPricingGuideRequest(queryStrings.request));
        const requestPackages = requestResults.selected_services.packages
        const requestAddOns = requestResults.selected_services.add_ons
        // Add selected packages
        if (isArray(requestPackages)) {
          var selectedPackagesClone = cloneDeep(selectedPackages)
          for (const eventPackage of requestPackages) {
            const record = {
              package_name: eventPackage.package_name,
              package_id: eventPackage.package_id,
              price: getNumericCurrency(eventPackage.price)
            }
            selectedPackagesClone.push(record)
          }
          setSelectedPackages(selectedPackagesClone)
        }
        // Add selected add-ons
        if (isArray(requestAddOns)) {
          var selectedAddOnsClone = cloneDeep(selectedAddOns)
          for (const eventAddOn of requestAddOns) {
            const record = {
              add_on_name: eventAddOn.add_on_name,
              add_on_id: eventAddOn.add_on_id,
              price: getNumericCurrency(eventAddOn.price),
              quantity: (isUndefined(eventAddOn.quantity) || isNull(eventAddOn.quantity)) ? 1 : eventAddOn.quantity,
            }
            selectedAddOnsClone.push(record)
          }
          setSelectedAddOns(selectedAddOnsClone)
        }
        const fields = {
          event_date: moment(requestResults.event_date, "YYYY-MM-DD"),
          account_event_type_id: requestResults.account_event_type_id,
          start_time: !isEmpty(requestResults.start_time) ? moment(requestResults.start_time, "H:mm") : null,
          end_time: !isEmpty(requestResults.end_time) ? moment(requestResults.end_time, "H:mm") : null,
          venue_name: requestResults.venue_name
        }
        form.setFieldsValue(fields)
        onBookingRequestPopulateClient(requestResults)
      }
    } catch {}
  }

  const changeVenueTab = (tab) => {
    if (venues.length == 0) {
      loadVenues()
    }
    setSelectedVenueTab(tab)
  }

  const onClientSelect = () => {
    if (selectedClientTab == "new") {
      newClientForm.submit()
    } else {
      existingClientForm.submit()
    }
  }

  const onVenueCountryChange = (value) => {
    setVenueCountryCode(value)
    setDefaultCountryCode(value)
    setVenueStateField()
  }

  const setVenueStateField = async () => {
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      form.setFieldsValue({ venue_state: state })
    }
  }

  const onNewClientCountryChange = (value) => {
    setNewClientCountryCode(value)
    setDefaultCountryCode(value)
    setNewClientStateField()
  }

  const setNewClientStateField = async () => {
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      newClientForm.setFieldsValue({ state: state })
    }
  }

  const removeSelectedClient = (id) => {
    const newSelectedClients = cloneDeep(selectedClients);
    const newFilteredClients = newSelectedClients.filter((x) => x.user_id != id)
    setSelectedClients(newFilteredClients)
  }

  const removeSelectedEmployee = (id) => {
    const newSelectedEmployees = cloneDeep(selectedEmployees);
    const newFilteredEmployees = newSelectedEmployees.filter((x) => x.user_id != id)
    setSelectedEmployees(newFilteredEmployees)
  }

  const removeNewClient = (email) => {
    const newNewClients = cloneDeep(newClients);
    const newFilteredClients = newNewClients.filter((x) => x.email != email)
    setNewClients(newFilteredClients)
  }

  const onQuestionnaireSelect = (id) => {
    if (!id) { return }
    const newQuestionnaires = [].concat(selectedQuestionnaires);
    const questionnaire = newQuestionnaires.find((x) => x.questionnaire_id == id)
    if (isUndefined(questionnaire)) {
      const lookupQuestionnaire = questionnaires.find((x) => x.questionnaire_id == id)
      newQuestionnaires.push(lookupQuestionnaire)
    }
    setSelectedQuestionnaires(newQuestionnaires)
  }

  const removeSelectedQuestionnaire = (id) => {
    const newQuestionnaires = cloneDeep(selectedQuestionnaires);
    const newFilteredQuestionnaires = newQuestionnaires.filter((x) => x.questionnaire_id != id)
    setSelectedQuestionnaires(newFilteredQuestionnaires)
  }

  const onSubmit = async (values) => {

    try {

      setClientMissing(false)
      setEmployeeMissing(false)

      if (selectedEmployees.length == 0) {
        scrollWithOffset("employees")
        setEmployeeMissing(true)
        return
      }

      setSaving(true)
    
      const existingClients = selectedClients.map((x) => {
        return {
          user_id: x.user_id,
          client_event_role_id: x.client_event_role_id,
          permissions: x.permissions
        }
      })
      const questionnaireIds = selectedQuestionnaires.map((x) => x.questionnaire_id)
  
      var venue = null;
      if (selectedVenueTab == "new") {
        venue = {
          venue_name: values['venue_name'],
          country: values['venue_country'],
          address_line_1: values['venue_address_line_1'],
          address_line_2: values['venue_address_line_2'],
          city: values['venue_city'],
          state: values['venue_state'],
          zip: values['venue_zip'],
          contact_name: values['venue_contact_name'] ?? "",
          contact_email: values['venue_contact_email'] ?? "",
          contact_phone: getPhoneNumber(values['venue_contact_phone'], venuePhoneCountryCode),
          notes: values['venue_notes'] ?? "",
          one_time: values['venue_one_time'] ?? false,
          website: values['venue_website'],
          wifi: values['venue_wifi']
        }
      }

      const financials = {
        deposit: getNumericCurrency(selectedDeposit)
      }
  
      const body = {
        'event_name': values['event_name'],
        'account_event_type_id': values['account_event_type_id'],
        'event_date': formatDateForDB(values['event_date']),
        'arrival_time': formatTimeForDB(values['arrival_time']),
        'start_time': formatTimeForDB(values['start_time']),
        'end_time': formatTimeForDB(values['end_time']),
        'internal_notes': !isUndefined(values['internal_notes']) ? values['internal_notes'] : "",
        'lockdown_days': values['lockdown_days'],
        'existing_clients': existingClients,
        'new_clients': newClients,
        'questionnaire_ids': questionnaireIds,
        'assigned_employees': selectedEmployees,
        'workflow_id': selectedWorkflow,
        'venue_id': !isUndefined(values['venue_id']) ? values['venue_id'] : null,
        'venue': venue,
        "special_event_template_id": values['special_event_template_id'],
        "packages": selectedPackages,
        "add_ons": selectedAddOns,
        "financials": financials,
        "playlists": selectedPlaylists
      }

      const eventResponse = await sendRequest(createEvent(body))
      if (eventResponse.status == "success") {
        displaySuccess(eventResponse.event_id)
      } else if (eventResponse.status == "failed") {
        if (eventResponse.code == "client-exists") {
          setClientExistsErrorModalVisible(true)
          setErrorMetadata(eventResponse.metadata ?? {})
        } else {
          setErrorModalVisible(true)
        }
        setSaving(false)
      }
    } catch (error) {
      setSaving(false)
      setErrorModalVisible(true)
    }
  }

  const buildPermissionsArray = (values) => {
    var permissionsArray = []
      if (values["view_music"]) {
        permissionsArray.push(EventPermissions.VIEW_MUSIC)
      }
      if (values["edit_music"]) {
        permissionsArray.push(EventPermissions.EDIT_MUSIC)
      }
      if (values["view_questionnaires"]) {
        permissionsArray.push(EventPermissions.VIEW_QUESTIONNAIRES)
      }
      if (values["edit_questionnaires"]) {
        permissionsArray.push(EventPermissions.EDIT_QUESTIONNAIRES)
      }
      if (values["view_timeline"]) {
        permissionsArray.push(EventPermissions.VIEW_TIMELINE)
      }
      if (values["edit_timeline"]) {
        permissionsArray.push(EventPermissions.EDIT_TIMELINE)
      }
      if (values["view_financials"]) {
        permissionsArray.push(EventPermissions.VIEW_FINANCIALS)
      }
      if (values["view_services"]) {
        permissionsArray.push(EventPermissions.VIEW_SERVICES)
      }
      if (values["view_documents"]) {
        permissionsArray.push(EventPermissions.VIEW_DOCUMENTS)
      }
      return permissionsArray
  }

  const onNewClientSubmit = (values) => {
     // Make sure client isn't already added
    const newNewClients = cloneDeep(newClients);
    const client = newNewClients.find((x) => x.email == values.email)
    if (isUndefined(client)) {
      // If client hasn't been added, add them
      const newClient = {
        client_event_role_id: values["client_event_role_id"],
        first_name: values["first_name"],
        last_name: values["last_name"],
        email: values["email"],
        phone: getPhoneNumber(values["phone"], newClientPhoneCountryCode),
        permissions: buildPermissionsArray(values),
        send_invitation: values["send_invitation"],
        country: values["country"],
        line_1: values["line_1"],
        line_2: values["line_2"],
        city: values["city"],
        state: values["state"],
        zip: values["zip"]
      }
      newNewClients.push(newClient)
      setNewClients(newNewClients)
    }
    setIsClientSelectionModalVisible(false)
  }

  const onExistingClientSubmit = (values) => {
    // Make sure client isn't already added
    const newSelectedClients = cloneDeep(selectedClients);
    const client = newSelectedClients.find((x) => x.user_id == values.user_id)
    if (isUndefined(client)) {
      // If client hasn't been added, look up the client record and add them
      const existingClient = cloneDeep(clients.find((x) => x.user_id == values.user_id))
      const newData = {
        ...existingClient,
        client_event_role_id: values.client_event_role_id,
        permissions: buildPermissionsArray(values)
      }
      newSelectedClients.push(newData)
      setSelectedClients(newSelectedClients)
    }
    setIsClientSelectionModalVisible(false)
  }

  const onEmployeeSubmit = (values) => {
    // Make sure employee isn't already added
    const newSelectedEmployees = cloneDeep(selectedEmployees);
    const employee = newSelectedEmployees.find((x) => x.user_id == values.user_id)
    if (isUndefined(employee)) {
      // If employee hasn't been added, look up the employee record and add them
      const existingEmployee = cloneDeep(employees.find((x) => x.user_id == values.user_id))
      const newData = {
        ...existingEmployee,
        admin_event_role_id: values.admin_event_role_id,
        client_visible: values.client_visible ?? false,
        display_contact_info: values.display_contact_info ?? false,
        wage: getNumericCurrency(values.wage)
      }
      newSelectedEmployees.push(newData)
      setSelectedEmployees(newSelectedEmployees)
    }
    setEmployeeSelectionModalVisible(false)
  }

  const formatDateForDB = (date) => {
    return date.format("YYYY-MM-DD")
  }

  const formatTimeForDB = (date) => {
    if (!isEmpty(date)) {
      return date.format("H:mm")
    }
    return null;
  }

  const displaySuccess = (event_id) => {
    notification.success({
      message: 'Success!',
      description: 'Your event has been created.',
      duration: 3
    });
    navigate(`/admin/events/${event_id}`);
  }

  const onSelectClientButton = () => {
    newClientForm.resetFields()
    existingClientForm.resetFields()
    const permissionsValues = {
      view_music: true,
      edit_music: true,
      view_questionnaires: true,
      edit_questionnaires: true,
      view_timeline: true,
      edit_timeline: true,
      view_financials: true,
      view_services: true,
      view_documents: true
    }
    newClientForm.setFieldsValue({ ...permissionsValues, send_invitation: false })
    existingClientForm.setFieldsValue(permissionsValues)
    setClientMissing(false)
    setSendInvitation(false)
    setSelectedClientTab("new")
    setNewClientActiveAddressKey([])
    setIsClientSelectionModalVisible(true)
    
    // Default form to specific country
    const defaultCountryCode = getDefaultCountryCode()
    const countryCode = !isEmpty(defaultCountryCode) ? defaultCountryCode : "US"
    setNewClientCountryCode(countryCode)
    newClientForm.setFieldsValue({ country: countryCode })
    // Default state
    setNewClientStateField()
  }

  const onBookingRequestPopulateClient = (results) => {
    newClientForm.resetFields()
    existingClientForm.resetFields()
    const permissionsValues = {
      view_music: true,
      edit_music: true,
      view_questionnaires: true,
      edit_questionnaires: true,
      view_timeline: true,
      edit_timeline: true,
      view_financials: true,
      view_services: true,
      view_documents: true
    }
    const fields = {
      ...permissionsValues,
      first_name: results.first_name,
      last_name: results.last_name,
      email: results.email,
      phone: getFormattedPhoneNumber(results.phone),
      send_invitation: false,
      country: results.country,
      line_1: results.address_line_1,
      line_2: results.address_line_2,
      city: results.city,
      state: results.state,
      zip: results.zip
    }
    newClientForm.setFieldsValue(fields)
    existingClientForm.setFieldsValue(permissionsValues)
    setClientMissing(false)
    setSendInvitation(false)
    setSelectedClientTab("new")
    if (!isNull(results.address_line_1)) {
      setNewClientActiveAddressKey(["0"])
    }
    setIsClientSelectionModalVisible(true)
    setNewClientCountryCode(results.country)
    setNewClientPhoneCountryCode(getCountryCodeForPhoneNumber(results.phone))
  }

  const onSelectEmployeeButton = () => {
    employeeForm.resetFields()
    employeeForm.setFieldsValue({ client_visible: true, display_contact_info: true })
    setStaffClientVisible(true)
    setEmployeeMissing(false)
    setEmployeeSelectionModalVisible(true)
  }

  const getEventRoleDesc = (id) => {
    const role = clientEventRoles.find(x => x.value == id)
    return role.text ?? "";
  }

  const getAdminEventRoleDesc = (id) => {
    const role = adminEventRoles.find(x => x.value == id)
    return role.text ?? "";
  }

  const previewEventTemplate = async () => {
    try {
      if (startsWith(selectedEventTemplateId, "sys-")) {
        const newSystemSpecialEventTemplates = cloneDeep(systemSpecialEventTemplates)
        const foundTemplate = newSystemSpecialEventTemplates.find(x => x.system_special_event_template_id == selectedEventTemplateId)
        if (!isEmpty(foundTemplate)) {
          const sectionResults = await sendRequest(getSystemSpecialEventSections(selectedEventTemplateId));
          setSelectedEventTemplateSections(sectionResults)
          setSelectedEventTemplate(foundTemplate)
          setPreviewEventTemplateModalVisible(true)
        } else {
          setSelectedEventTemplate({})
          setSelectedEventTemplateSections([])
        }
      } else {
        setSelectedEventTemplate({})
        setSelectedEventTemplateSections([])
      }
    } catch {}
  }

  const onSendInvitationToggle = (e) => {
    setSendInvitation(e.target.checked)
  }

  const scrollWithOffset = (id) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("admin-content--body-main")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("admin-content--body-main")[0].scrollTop
    const elementRect = document.getElementById(id).getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect + bodyScrollTop;
    const offsetPosition = elementPosition - offset;
    document.getElementsByClassName("admin-content--body-main")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const onStaffVisibleToClientChange = (e) => {
    setStaffClientVisible(e.target.checked)
    employeeForm.setFieldsValue({ display_contact_info: e.target.checked })
  }

  const onAddPlaylist = () => {
    setSelectedPlaylist({})
    playlistForm.resetFields()
    playlistForm.setFieldsValue({ song_limit: 30 })
    setPlaylistModalVisible(true)
  }

  const onEditPlaylist = (playlist) => {
    setSelectedPlaylist(playlist)
    playlistForm.resetFields()
    playlistForm.setFieldsValue(playlist)
    setPlaylistModalVisible(true)
  }

  const onRemovePlaylist = (playlist) => {
    var newPlaylists = cloneDeep(selectedPlaylists)
    newPlaylists = newPlaylists.filter(x => x.id != playlist.id)
    setSelectedPlaylists(newPlaylists)
  }

  const onPlaylistSubmit = (values) => {
    var newPlaylists = cloneDeep(selectedPlaylists)

    if (isEmpty(selectedPlaylist)) {
      // Add new playlist
      const playlist = newPlaylists.find((x) => x.playlist_name == values.playlist_name)
      if (isUndefined(playlist)) {
        const playlistRecord = {
          id: uniqid(),
          playlist_name: values.playlist_name,
          song_limit: values.song_limit
        }
        newPlaylists.push(playlistRecord)
        setSelectedPlaylists(newPlaylists)
      }
    } else {
      // Edit playlist
      const playlist = newPlaylists.find((x) => x.playlist_name == values.playlist_name && x.id != selectedPlaylist.id)
      if (isUndefined(playlist)) {
        for (var list of newPlaylists) {
          if (list.id == selectedPlaylist.id) {
            list.playlist_name = values.playlist_name
            list.song_limit = values.song_limit
          }
        }
        setSelectedPlaylists(newPlaylists)
      }
    }
    setPlaylistModalVisible(false)
  }

  const isValid = (value) => {
    return !isUndefined(value) && !isNull(value) && value != ""
  }

  const onChangeTemplate = (value) => {
    setSelectedTemplate(value)
    setTemplateApplied(false)
  }

  const onApplyEventTemplate = () => {
    setEventTemplateConfirmModalVisible(true)
  }

  const applyEventTemplate = async () => {
    try {
      // Get event template
      const templateResults = await sendRequest(getEventTemplate(selectedTemplate));

      var fields = {}
      if (isValid(templateResults.event_type_id)) {
        fields.account_event_type_id = templateResults.event_type_id
      }
      if (isValid(templateResults.arrival_time)) {
        fields.arrival_time = moment(templateResults.arrival_time, "H:mm")
      }
      if (isValid(templateResults.start_time)) {
        fields.start_time = moment(templateResults.start_time, "H:mm")
      }
      if (isValid(templateResults.end_time)) {
        fields.end_time = moment(templateResults.end_time, "H:mm")
      }
      if (isValid(templateResults.internal_notes)) {
        fields.internal_notes = templateResults.internal_notes
      }
      if (isValid(templateResults.lockdown_days)) {
        fields.lockdown_days = templateResults.lockdown_days
      }
      if (isValid(templateResults.venue_id)) {
        if (venues.length == 0) {
          await loadVenues()
        }
        setSelectedVenueTab("existing")
        fields.venue_id = templateResults.venue_id
      }
      if (isArray(templateResults.packages)) {
        setSelectedPackages(templateResults.packages)
      }
      if (isArray(templateResults.add_ons)) {
        setSelectedAddOns(templateResults.add_ons)
      }
      if (isValid(templateResults.booking_fee) && templateResults.booking_fee > 0) {
        setSelectedDeposit(templateResults.booking_fee)
      }
      if (isValid(templateResults.special_event_template_id)) {
        fields.special_event_template_id = templateResults.special_event_template_id
      }
      if (isArray(templateResults.playlists)) {
        setSelectedPlaylists(templateResults.playlists)
      }
      if (isValid(templateResults.workflow_id)) {
        setSelectedWorkflow(templateResults.workflow_id)
      }
      if (isArray(templateResults.questionnaires)) {
        const clonedQuestionnaires = cloneDeep(questionnaires)
        const filteredQuestionnaires = clonedQuestionnaires.filter((x) => templateResults.questionnaires.includes(x.questionnaire_id))
        setSelectedQuestionnaires(filteredQuestionnaires)
      }
      if (isArray(templateResults.assigned_staff)) {
        setSelectedEmployees(templateResults.assigned_staff)
      }

      form.setFieldsValue(fields)
      setEventTemplateConfirmModalVisible(false)
      setTemplateApplied(true)
    } catch {
      notification.error({
        message: 'Error',
        description: 'There was an issue applying your event template.',
        duration: 3
      });
    }
  }

  const renderSelectedClients = (client, index) => {
    return (
      <div key={index} className="border radius-8 p-10 mb-10 mh-0">
        <Row gutter={[15,15]} align="middle"  wrap={false}>
          <Col>
            { client.photo ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${client.photo})`}}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><UserOutlined/></div>
              </div>
            )}
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-600 line-1-4">{client.first_name} {client.last_name} ({getEventRoleDesc(client.client_event_role_id)})</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{client.email}</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{getFormattedPhoneNumber(client.phone)}</div>
          </Col>
          {/* <Col>
            <div className="display-flex">
              <FiEdit size={20} color="#ccc" onClick={() => removeSelectedClient(client.user_id)}/>
            </div>
          </Col> */}
          <Col>
            <div className="display-flex mr-5">
              <FiTrash size={20} color="#ccc" onClick={() => removeSelectedClient(client.user_id)}/>
            </div>
          </Col>
      </Row>
      </div>
    )
  }

  const renderSelectedEmployees = (employee, index) => {
    return (
      <div key={index} className="border radius-8 p-10 mb-10 mh-0">
        <Row gutter={[15,15]} align="middle" wrap={false}>
          <Col>
              { employee.photo ? (
                <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${employee.photo})`}}></div>
              ) : (
                <div className="display-flex">
                  <div className="user-profile-circle"><UserOutlined/></div>
                </div>
              )}
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-600 line-1-4">{employee.first_name} {employee.last_name} ({getAdminEventRoleDesc(employee.admin_event_role_id)})</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{employee.email}</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{getFormattedPhoneNumber(employee.phone)}</div>
          </Col>
          <Col>
            <div className="display-flex mr-5">
              <FiTrash size={20} color="#ccc" onClick={() => removeSelectedEmployee(employee.user_id)}/>
            </div>
          </Col>
        </Row>
        <div className="fs-12 bg-gray ph-15 pv-10 radius-8 mt-10">
          <div><b>Visible to client:</b> {employee.client_visible ? "Yes" : "No" }  {employee.client_visible && !employee.display_contact_info ? "(hide contact info)" : ""}</div>
          { employee.wage > 0 && (
            <div><b>Wage:</b> {formatCurrencyString(employee.wage, currency.code)}</div>
          )}
        </div>
      </div>
    )
  }

  const renderNewClients = (client, index) => {
    return (
      <div key={index} className="border radius-8 p-10 mb-10 mh-0">
        <Row gutter={[15,15]} align="middle" wrap={false}>
          <Col>
            <div className="display-flex">
              <div className="user-profile-circle"><UserOutlined/></div>
            </div>
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-600 line-1-4">{client.first_name} {client.last_name} ({getEventRoleDesc(client.client_event_role_id)})</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{client.email}</div>
            <div className="fs-12 fw-400 c-text-gray line-1-4">{getFormattedPhoneNumber(client.phone)}</div>
          </Col>
          {/* <Col>
            <div className="display-flex">
              <FiEdit size={20} color="#ccc" onClick={() => removeNewClient(client.email)}/>
            </div>
          </Col> */}
          <Col>
            <div className="display-flex mr-5">
              <FiTrash size={20} color="#ccc" onClick={() => removeNewClient(client.email)}/>
            </div>
          </Col>
        </Row>
        { client.send_invitation && (
          <div className="bg-gray ph-15 pv-10 radius-8 fs-12 mt-10">Send invitation when event is created</div>
        )}
      </div>
    )
  }

  const renderSelectedQuestionnaires = (questionnaire, index) => {
    return (
      <Row key={index} gutter={[15,15]} align="middle" className="border radius-8 pv-10 ph-5 mb-10 mh-0" wrap={false}>
        <Col>
          <div className="music-icon"><BsFileEarmarkText/></div>
        </Col>
        <Col flex={1}>
          <div className="fs-14 fw-600 line-1-2">{getQuestionnaireOptionName(questionnaire)}</div>
        </Col>
        <Col>
          <div className="display-flex mr-5">
            <FiTrash size={20} color="#ccc" onClick={() => removeSelectedQuestionnaire(questionnaire.questionnaire_id)}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/events")}>
            Events
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            New
          </span>
        </div>
        <Row align="middle" gutter={[15,15]}>
          <Col flex={1}>
            <div className="fw-700 fs-24 mt-5">New Event</div>
          </Col>
          <Col>
            { isSaving ? (
              <button className="page-title-button" style={{ width: 120 }}><LoadingOutlined/></button>
            ) : (
              <button className="page-title-button" style={{ width: 120 }} onClick={() => form.submit()}>Save</button>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderClientSelectionModal = () => {
    return (
      <Modal visible={isClientSelectionModalVisible} footer={null} closable={false} onCancel={() => setIsClientSelectionModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{"Add Client"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setIsClientSelectionModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="tab-slider mt-10 mb-20">
          <div className={`tab-slider--tab ${selectedClientTab == "new" ? "selected" : ""}`} onClick={() => setSelectedClientTab("new")}>
            New Client
          </div>
          <div className={`tab-slider--tab ${selectedClientTab == "existing" ? "selected" : ""}`} onClick={() => setSelectedClientTab("existing")}>
            Existing Client
          </div>
        </div>
        <div style={ { display: selectedClientTab == "new" ? "block" : "none" } }>
          { renderNewClientTab() }
        </div>
        <div style={ { display: selectedClientTab == "existing" ? "block" : "none" } }>
          { renderExistingClientTab() }
        </div>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => onClientSelect()}>Add Client</button>
        </div>
      </Modal>
    )
  }

  const renderEmployeeSelectionModal = () => {
    const employeeData = employees.map((user) => {
      return {
        value: user.user_id,
        text: user.first_name + " " + user.last_name
      }
    })
    return (
      <Modal visible={isEmployeeSelectionModalVisible} footer={null} closable={false} onCancel={() => setEmployeeSelectionModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{"Add Staff"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEmployeeSelectionModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={employeeForm} layout="vertical" name="employeeForm" onFinish={onEmployeeSubmit}>
          <Row align="middle" gutter={[20,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Staff", "user_id", "Select staff member", employeeData, true) }
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Event Role", "admin_event_role_id", "Select event role", adminEventRoles, true) }
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Wage (optional)", "wage", false, currency.code) }
            </Col>
            <Col xs={24}>
              { renderFormLabel("Client Portal") }
              <div className="border radius-8 pv-10 ph-15">
                { renderCheckboxField("Visible to client", "client_visible", onStaffVisibleToClientChange, "Determines whether or not this assigned staff member is visible in the client portal.") }
                { renderCheckboxField("Display contact information", "display_contact_info", null, "Determines whether or not this staff member's phone number and email address is visible to the client.", !staffClientVisible) }
              </div>
            </Col>
          </Row>
        </Form>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => employeeForm.submit()}>Add Staff</button>
        </div>
      </Modal>
    )
  }
  
  const renderPlaylistModal = () => {
    return (
      <Modal visible={isPlaylistModalVisible} footer={null} closable={false} onCancel={() => setPlaylistModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isEmpty(selectedPlaylist) ? "Add Playlist" : "Edit Playlist"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPlaylistModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={playlistForm} layout="vertical" name="employeeForm" onFinish={onPlaylistSubmit}>
          <Row align="middle" gutter={[{ xs: 0, md: 20 },{ xs: 0, md: 10 }]} className="mt-15">
            <Col xs={24}>
              { renderInputField("Playlist Name", "playlist_name", true, false, "Enter a playlist name...") }
            </Col>
            <Col xs={24}>
              { renderNumberField("Song Limit", "song_limit", 0, true) }
              <div className="bg-gray p-10 mt-10 radius-8"><b>Note:</b> Entering a value of "0" will remove the song limit.</div>
            </Col>
          </Row>
        </Form>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => playlistForm.submit()}>{isEmpty(selectedPlaylist) ? "Add Playlist" : "Save"}</button>
        </div>
      </Modal>
    )
  }

  const renderEventPermissionCheckboxes = (title, view_id, edit_id) => {
    return (
      <Row gutter={[10]} align="middle">
        <Col xs={12} sm={16}>
          <div className="fw-500">{title}</div>
        </Col>
        <Col xs={6} sm={4}>
          { renderCheckboxField("View", view_id) }
        </Col>
        <Col xs={6} sm={4}>
          { !isUndefined(edit_id) && renderCheckboxField("Edit", edit_id) }
        </Col>
      </Row>
    )
  }

  const renderNewClientTab = () => {
    return (
      <Form form={newClientForm} layout="vertical" name="contact" onFinish={onNewClientSubmit}>
        <Row gutter={[20,10]}>
          { fromBookingRequest && (
            <Col xs={24}>
              <div className="message-box mb-15">
                <div className="message-icon"><MdOutlineInfo/></div>
                <div className="message-text">The client details from your booking request have been automatically populated below. Confirm the information before proceeding.</div>
              </div>
            </Col>
          )}
          <Col xs={24} md={24}>
            <div className="fw-700">Client Details</div>
          </Col>
          <Col xs={24}>
            { renderSearchSelectField("Role", "client_event_role_id", "Select a role", clientEventRoles, true) }
          </Col>
          <Col xs={24} md={12}>
            { renderInputField("First Name", "first_name", true) }
          </Col>
          <Col xs={24} md={12}>
            { renderInputField("Last Name", "last_name", true) }
          </Col>
          <Col xs={24}>
            { renderEmailField("Email", "email", true) }
          </Col>
          <Col xs={24}>
            <div className="bg-gray radius-8 p-10 fs-12"><b>Note:</b> If creating a test event, we recommend entering another email address you have access to, otherwise you will not be able to login with the client.</div>
          </Col>
          <Col xs={24}>
            { renderPhoneField("Phone", "phone", false, false, newClientPhoneCountryCode, setNewClientPhoneCountryCode) }
          </Col>
          <Col xs={24} md={24}>
            <Collapse ghost style={{ marginLeft: -15, marginRight: -15 }} activeKey={newClientActiveAddressKey} onChange={(e) => setNewClientActiveAddressKey(e)} destroyInactivePanel={true}>
              <Collapse.Panel header={<div className="fw-600 c-blue">Address (optional)</div>}>
                <Row align="middle" gutter={[15,15]}>
                  <Col xs={24} md={24}>
                    { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, true, onNewClientCountryChange) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderGlobalAddressField(newClientCountryCode, "line_1") }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderGlobalSecondAddressField(newClientCountryCode, "line_2") }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderGlobalCityField(newClientCountryCode, "city") }
                  </Col>
                  <Col xs={24} md={16}>
                    { renderGlobalStateField(newClientCountryCode, "state") }
                  </Col>
                  <Col xs={12} md={8}>
                    { renderGlobalZipField(newClientCountryCode, "zip") }
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col xs={24} md={24}>
            <div className="fw-700">Permissions</div>
          </Col>
          <Col xs={24}>
            <div className="border radius-8 pv-10 ph-15">
              { renderEventPermissionCheckboxes("Music", "view_music", "edit_music")}
              { renderEventPermissionCheckboxes("Questionnaires", "view_questionnaires", "edit_questionnaires")}
              { renderEventPermissionCheckboxes("Timeline", "view_timeline", "edit_timeline")}
              { renderEventPermissionCheckboxes("Services", "view_services")}
              { renderEventPermissionCheckboxes("Financials", "view_financials")}
              { renderEventPermissionCheckboxes("Documents", "view_documents")}
            </div>
          </Col>
          <Col xs={24} md={24}>
            <div className="fw-700">Invitation</div>
          </Col>
          <Col xs={24}>
            { renderCheckboxField("Send invitation when event is created", "send_invitation", onSendInvitationToggle) }
          </Col>
          { isSendInvitation && (
            <Col xs={24}>
              <div className="bg-gray radius-8 p-10 fs-12"><b>Note:</b> Sending an invitation before creating a contract will give the client access to the planning center before they might be officially booked. If you would like to create a contract first, you can send an invitation later from the event details page.</div>
            </Col>
          )}
        </Row>
      </Form>
    )
  }

  const renderExistingClientTab = () => {
    const clientData = clients.map((client) => {
      return {
        value: client.user_id,
        text: client.first_name + " " + client.last_name
      }
    })
    return (
      <Form form={existingClientForm} layout="vertical" name="existingClient" onFinish={onExistingClientSubmit}>
        <Row gutter={[20,10]}>
          <Col xs={24} md={24}>
            <div className="fw-700">Client Details</div>
          </Col>
          <Col xs={24}>
            { renderSearchSelectField("Role", "client_event_role_id", "Select a role", clientEventRoles, true) }
          </Col>
          <Col xs={24}>
            { renderSearchSelectField("Client", "user_id", "Select client", clientData, true) }
          </Col>
          <Col xs={24} md={24}>
            <div className="fw-700">Permissions</div>
          </Col>
          <Col xs={24}>
            <div className="border radius-8 pv-10 ph-15">
              { renderEventPermissionCheckboxes("Music", "view_music", "edit_music")}
              { renderEventPermissionCheckboxes("Questionnaires", "view_questionnaires", "edit_questionnaires")}
              { renderEventPermissionCheckboxes("Timeline", "view_timeline", "edit_timeline")}
              { renderEventPermissionCheckboxes("Services", "view_services")}
              { renderEventPermissionCheckboxes("Financials", "view_financials")}
              { renderEventPermissionCheckboxes("Documents", "view_documents")}
            </div>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderServicesSection = () => {
    return (
      <NewEventServices
        selectedPackages={selectedPackages}
        selectedAddOns={selectedAddOns}
        selectedFinancials={selectedFinancials}
        setSelectedPackages={setSelectedPackages}
        setSelectedAddOns={setSelectedAddOns}
        selectedDeposit={selectedDeposit}
        setSelectedDeposit={setSelectedDeposit}
      />
    )
  }

  const renderClientExistsErrorModal = () => {
    return (
      <Modal visible={isClientExistsErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setClientExistsErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Client Exists</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">A user with the email address <span className="fw-700">{ errorMetadata.email}</span> already exists.</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Please select an existing client or create a new client with a different email address.</div>
          <button className="primary-button" type="button" onClick={() => setClientExistsErrorModalVisible(false)}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderErrorModal = () => {
    return (
      <Modal visible={isErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Oops!</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">There was an error saving your event. If this error keeps persisting, please submit a support ticket.</div>
          <button className="primary-button" type="button" onClick={() => setErrorModalVisible(false)}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderEventTemplateConfirmModal = () => {
    return (
      <Modal visible={isEventTemplateConfirmModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setEventTemplateConfirmModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Apply Template</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to apply this template to your event? This will overwrite any data you have entered below.</div>
          <button className="primary-button" type="button" onClick={() => applyEventTemplate()}>Apply Template</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setEventTemplateConfirmModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const getSpecialEventType = (event) => {
    if (event.is_playlist) {
      return "PLAYLIST";
    } else if (event.songs_allowed) {
      return "SPECIAL SONG"
    } else {
      return "NO SONGS"
    }
  }

  const getSpecialEventSubText = (event) => {
    return `Song Limit: ${event.song_limit == 0 ? "N/A" : event.song_limit}`
  }

  const getQuestionnaireOptionName = (questionnaire) => {
    if (!isNull(questionnaire.internal_questionnaire_name) && questionnaire.internal_questionnaire_name != "") {
      return questionnaire.internal_questionnaire_name
    }
    return questionnaire.questionnaire_name
  }
  
  const renderEventTemplateEvent = (specialEvent, index) => {
    return (
      <div key={index} className="b-border pv-10 pl-10">
        <div className="fw-600 fs-16">{ specialEvent.special_event_name }</div>
        <div className="c-text-gray fs-12 mt-2"> <span className="bg-light-blue ph-5 c-blue fw-700 fs-10 mr-5" style={{ paddingTop: 2, paddingBottom: 2}}>{getSpecialEventType(specialEvent)}</span>{getSpecialEventSubText(specialEvent)}</div>
      </div>
    )
  }

  const renderEventTemplateSections = (section, index) => {
    return (
      <div className="border radius-8 p-15 mb-10" key={index}>
        <div className="fw-700 fs-16 b-border pb-10">{ section.section_name}</div>
        { isArray(section.special_events) && section.special_events.map((x,i) => renderEventTemplateEvent(x,i))}
      </div>
    )
  }

  const renderPreviewEventTemplateModal = () => {
    return (
      <Modal visible={isPreviewEventTemplateModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{selectedEventTemplate.template_name}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPreviewEventTemplateModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          <div className="bg-gray radius-8 p-15 mb-15">This special event template represents all the possible special events the client can choose from. They will have the opportunity to select the ones that apply to them and/or create custom special events that are not listed here.</div>
          { selectedEventTemplateSections.map((x,i) => renderEventTemplateSections(x,i))}
          <button className="primary-button" type="button" onClick={() => setPreviewEventTemplateModalVisible(false)}>Close</button>
        </div>
      </Modal>
    )
  }

  const renderSelectedPlaylist = (playlist, index) => {
    return (
      <Row key={index} gutter={[15,15]} align="middle" className="border radius-8 pv-10 ph-5 mb-10 mh-0" wrap={false}>
        <Col onClick={() => onEditPlaylist(playlist)}>
          <div className="music-icon"><FiMusic/></div>
        </Col>
        <Col flex={1} onClick={() => onEditPlaylist(playlist)}>
          <div className="fs-14 fw-600 line-1-2">{playlist.playlist_name}</div>
          <div className="fs-12 c-text-gray line-1-2">Song Limit: {playlist.song_limit == 0 ? "None" : playlist.song_limit}</div>
        </Col>
        <Col>
          <div className="display-flex">
            <FiEdit size={20} color="#ccc" onClick={() => onEditPlaylist(playlist)}/>
          </div>
        </Col>
        <Col>
          <div className="display-flex mr-5">
            <FiTrash size={20} color="#ccc" onClick={() => onRemovePlaylist(playlist)}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderSectionHeader = (title, showAddButton = false, showLearnMore = false, action = () => {}) => {
    return (
      <Row align="middle" className="pb-10 mb-15 b-border" wrap={false}>
        <Col flex={1}>
          <div className="fs-18 fw-700">{title}</div>
        </Col>
        { showAddButton && (
          <Col>
            <div className="plus-container mr-5" onClick={() => action()}>
              <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
            </div>
          </Col>
        )}
        { showLearnMore && (
          <Col>
            <div className="blue-link" onClick={() => action()}>Learn more</div>
          </Col>
        )}
      </Row>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "ph-30 pv-25" : "p-20"
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        <Form form={form} layout="vertical" name="contact" onFinish={onSubmit} scrollToFirstError={true}>
          <Row>
            { fromBookingRequest && (
              <Col xs={24}>
                <div className="message-box mb-15">
                  <div className="message-icon"><MdOutlineInfo/></div>
                  <div className="message-text">The details from your booking request have been automatically populated below. Confirm the information is correct and fill in the missing details before saving.</div>
                </div>
              </Col>
            )}
            { FeatureFlags.EVENT_TEMPLATES && (
              <Col xs={24}>
                <div className={`shadow-card ${paddingClass} mb-30`}>
                  { renderSectionHeader("Event Template", false, true, () => window.open(`${BASE_URL}/admin/docs/event-templates`, "_blank"))}
                  { isTemplateApplied ? (
                    <div className="bg-light-blue c-blue p-10 text-center mb-10">The fields below has been automatically populated with the information from your template. Confirm everything is correct and fill in the missing details before saving.</div>
                  ) : (
                    <div className="bg-gray p-10 text-center mb-10">Select a template to automatically populate default values for your event.</div>
                  )}
                  <Row gutter={[20,10]}>
                    <Col xs={24} md={24}>
                      <Select
                        style={{ width: '100%'}}
                        showSearch
                        allowClear
                        placeholder={"Select an optional template..."}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        size='large'
                        value={selectedTemplate}
                        onChange={(value) => onChangeTemplate(value)}
                      >
                        {eventTemplates.map((template) => (
                          <Select.Option value={template.event_template_id} key={template.event_template_id}>{template.event_template_name}</Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={24} md={24} className="text-center">
                        <button className="small-primary-button mt-10" type="button" onClick={() => onApplyEventTemplate()}>Apply Template</button>
                    </Col>
                  </Row>
                </div>
                <div className="mb-30 b-border"></div>
              </Col>
            )}
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass}`}>
                { renderSectionHeader("Event Details") }
                <Row gutter={[20,10]}>
                  <Col xs={24} md={24}>
                    { renderInputField("Event Name", "event_name", true) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderSearchSelectField("Event Type", "account_event_type_id", "", eventTypes, true) }
                  </Col>
                  <Col xs={12} md={24}>
                    { renderDateField("Event Date", "event_date", accountSettings) }
                  </Col>
                  <Col xs={12} md={8}>
                    { renderTimeField("Arrival Time", "arrival_time", false, accountSettings) }
                  </Col>
                  <Col xs={12} md={8}>
                    { renderTimeField("Start Time", "start_time", true, accountSettings) }
                  </Col>
                  <Col xs={12} md={8}>
                    { renderTimeField("End Time", "end_time", true, accountSettings) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderTextAreaField("Internal Notes", "internal_notes", 5) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderNumberField("Lockdown Days", "lockdown_days", 0, false, "The number of days before the event when the client can no longer make changes.") }
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24}>
              <div className={`shadow-card mt-15 ${paddingClass}`}>
                <div className="content-box-section-title mb-15">Venue</div>
                <div className="tab-slider mb-20">
                  <div className={`tab-slider--tab ${selectedVenueTab == "new" ? "selected" : ""}`} onClick={() => changeVenueTab("new")}>
                    New Venue
                  </div>
                  <div className={`tab-slider--tab ${selectedVenueTab == "existing" ? "selected" : ""}`} onClick={() => changeVenueTab("existing")}>
                    Existing Venue
                  </div>
                </div>
                { selectedVenueTab == "new" ? (
                  <Row align="middle" gutter={[15,15]}>
                    <Col xs={24} md={24}>
                      { renderInputField("Venue Name", "venue_name", true) }
                    </Col>
                    <Col xs={24} md={24}>
                      { renderSearchSelectField("Country/Region", "venue_country", "Select a country/region...", countries, true, onVenueCountryChange) }
                    </Col>
                    <Col xs={24} md={24}>
                      { renderGlobalAddressField(venueCountryCode, "venue_address_line_1") }
                    </Col>
                    <Col xs={24} md={24}>
                      { renderGlobalSecondAddressField(venueCountryCode, "venue_address_line_2") }
                    </Col>
                    <Col xs={24} md={24}>
                      { renderGlobalCityField(venueCountryCode, "venue_city") }
                    </Col>
                    <Col xs={24} md={16}>
                      { renderGlobalStateField(venueCountryCode, "venue_state") }
                    </Col>
                    <Col xs={12} md={8}>
                      { renderGlobalZipField(venueCountryCode, "venue_zip") }
                    </Col>
                    <Col xs={24}>
                     { renderCheckboxField("One Time Venue", "venue_one_time", null, "Checking this box will hide this venue from the venue list when creating events.") }
                    </Col>
                    <Col xs={24} md={24}>
                      <Collapse ghost style={{ marginLeft: -15, marginRight: -15 }}>
                        <Collapse.Panel header={<div className="fw-600 c-blue">Additional Fields</div>}>
                          <Row align="middle" gutter={[15,15]}>
                            <Col xs={24} md={24}>
                              { renderInputField("Contact Name", "venue_contact_name") }
                            </Col>
                            <Col xs={24} md={24}>
                              { renderEmailField("Email", "venue_contact_email") }
                            </Col>
                            <Col xs={24} md={24}>
                              {renderPhoneField("Phone", "venue_contact_phone", false, false, venuePhoneCountryCode, setVenuePhoneCountryCode)}
                            </Col>
                            <Col xs={24} md={24}>
                              <div className="b-border mb-15 mt-10"></div>
                              { renderInputField("Website", "venue_website") }
                            </Col>
                            <Col xs={24} md={24}>
                              { renderInputField("Wi-Fi", "venue_wifi") }
                            </Col>
                            <Col xs={24} md={24}>
                              { renderTextAreaField("Notes", "venue_notes", 4) }
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </Col>
                  </Row>
                ) : (
                  <div>
                    {renderSearchSelectField("", "venue_id", "Select a venue", venues, true)}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass} mt-15`} id="clients">
                { renderSectionHeader("Clients", selectedClients.length > 0 || newClients.length > 0, false, onSelectClientButton) }
                <Row align="middle">
                  <Col xs={24} md={24}>
                    { newClients.map((c,i) => renderNewClients(c,i)) }
                    { selectedClients.map((c,i) => renderSelectedClients(c,i)) }
                  </Col>
                  <Col xs={24}>
                    <div className="text-center">
                      { selectedClients.length == 0 && newClients.length == 0 && (
                        <div className="bg-gray radius-8 ph-30 pv-20 mb-10 text-center">
                          <div>Select at least one client to assign to this event. <br/>Each client with have their own unique access to the planning center.</div>
                          <button className="page-title-button mt-15" type="button" onClick={() => onSelectClientButton()}>Add Client</button>
                        </div>
                      )}
                      { isClientMissing && (
                        <div className="c-red mb-10">At least one client is required.</div>
                      )}
                    </div>
                </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass} mt-15`} id="employees">
                { renderSectionHeader("Assigned Staff", selectedEmployees.length > 0, false, onSelectEmployeeButton) }
                <Row align="middle">
                  <Col xs={24} md={24}>
                    { selectedEmployees.map((x,i) => renderSelectedEmployees(x,i)) }
                  </Col>
                  <Col xs={24}>
                    <div className="text-center">
                      { selectedEmployees.length == 0 && (
                        <div className="bg-gray radius-8 ph-30 pv-20 mb-10 text-center">
                          <div>Select at least one staff member to assign to this event.</div>
                          <button className="page-title-button mt-15" type="button" onClick={() => onSelectEmployeeButton()}>Add Staff</button>
                        </div>
                      )}
                      { isEmployeeMissing && (
                        <div className="c-red mb-10">At least one staff member is required.</div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24}>
              { renderServicesSection() }
            </Col>
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass} mt-15`}>
                { renderSectionHeader("Special Event Template", false, true, () => window.open(`${BASE_URL}/admin/docs/special-event-templates`, "_blank"))}
                <Row align="middle" gutter={[20,10]}>
                  <Col flex={1}>
                    <div className="c-text-gray mb-15">Select a special event template to apply to this event. This is required in order for clients to select songs and create a timeline during the event planning process.
                    </div>
                    <Form.Item 
                      name={"special_event_template_id"}
                      rules={[{ required: true, message: `Special Event Template is required!`, validateTrigger: "onBlur" }]}
                    >
                      <Select
                        style={{ width: '100%'}}
                        allowClear
                        placeholder={"Select a template"}
                        size='large'
                        onChange={(value) => setSelectedEventTemplateId(value)}
                      >
                        <Select.OptGroup label="My Templates">
                          {specialEventTemplates.map((template) => (
                            <Select.Option value={template.account_special_event_template_id} key={template.account_special_event_template_id}>{template.template_name}</Select.Option>
                          ))}
                          { specialEventTemplates.length == 0 && (
                            <Select.Option value={null} disabled={true}>No templates created yet</Select.Option>
                          )}
                        </Select.OptGroup>
                        <Select.OptGroup label="DJ Planning Center Templates">
                          {systemSpecialEventTemplates.map((template) => (
                            <Select.Option value={template.system_special_event_template_id} key={template.system_special_event_template_id}>{template.template_name}</Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                    { !isEmpty(selectedEventTemplateId) && startsWith(selectedEventTemplateId, "sys-") && (
                      <div className="blue-link mt-10 ml-10" onClick={() => previewEventTemplate()}>Preview Template</div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass} mt-15`}>
                { renderSectionHeader("Playlists", true, false, onAddPlaylist) }
                { selectedPlaylists.map((x,i) => renderSelectedPlaylist(x,i)) }
                { selectedPlaylists.length == 0 && (
                  <div className="mb-5">
                    <div className="bg-gray radius-8 p-20 text-center">Add optional playlists to this event.</div>  
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass} mt-15`}>
                { renderSectionHeader("Workflow", false, true, () => window.open(`${BASE_URL}/admin/docs/workflows`, "_blank"))}
                <Row align="middle" gutter={[20,10]}>
                  <Col flex={1}>
                    <div className="c-text-gray mb-15">Select an optional workflow to assign to this event.</div>
                    <Select
                      style={{ width: '100%'}}
                      showSearch
                      allowClear
                      placeholder={"Select a workflow"}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      size='large'
                      value={selectedWorkflow}
                      onChange={(value) => setSelectedWorkflow(value)}
                    >
                      {workflows.map((workflow) => (
                        <Select.Option value={workflow.workflow_id} key={workflow.workflow_id}>{workflow.workflow_name}</Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24}>
              <div className={`shadow-card ${paddingClass} mt-15`}>
                { renderSectionHeader("Questionnaires", false, true, () => window.open(`${BASE_URL}/admin/docs/questionnaires`, "_blank"))}
                <Row align="middle" gutter={[20,10]}>
                  <Col flex={1}>
                    <div className="c-text-gray mb-15">Assign one or more questionnaires to this event.</div>
                    <Select
                      style={{ width: '100%'}}
                      allowClear
                      placeholder={"Select a questionnaire"}
                      size='large'
                      value={selectedQuestionnaire}
                      onChange={onQuestionnaireSelect}
                    >
                      {questionnaires.map((questionnaire) => (
                        <Select.Option value={questionnaire.questionnaire_id} key={questionnaire.questionnaire_id}>{getQuestionnaireOptionName(questionnaire)}</Select.Option>
                      ))}
                    </Select>
                  </Col>
                  { selectedQuestionnaires.length > 0 && (
                    <Col xs={24} className="mt-10">
                      { selectedQuestionnaires.map((q,i) => renderSelectedQuestionnaires(q,i)) }
                    </Col>
                    )}
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
        { renderClientSelectionModal() }
        { renderEmployeeSelectionModal() }
        { renderClientExistsErrorModal() }
        { renderErrorModal() }
        { renderPreviewEventTemplateModal() }
        { renderPlaylistModal() }
        { renderEventTemplateConfirmModal() }
        <SelectionModal title={"Select Client"} isVisible={isSelectionModalVisible} data={clients} onSelect={onClientSelect} onCancel={() => setIsSelectionModalVisible(false)}/>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

NewEventPage.propTypes = {};

export default NewEventPage;
