import React from "react";
import { isNull, isUndefined } from "lodash";
import { Form, Input, DatePicker, Checkbox, InputNumber, TimePicker, Select, Tooltip } from 'antd';
import { MdInfoOutline } from "react-icons/md";
import NumberFormat from 'react-number-format';
import { setDefaultState, getStateOptions, setDefaultPhoneCountryCode } from '../../helpers/addressHelper';
import { getCurrencySymbol, getNumericCurrency } from '../../helpers/contractHelper';
import { getDatePickerFormat, getDatePickerFormatMedium } from '../../helpers/dateHelper';
import TimeFormatTypes from '../../constants/timeFormatTypes';
import { phone } from 'phone';
import { Country }  from 'country-state-city';

const widthStyle = (value) => {
  return {
    width: '100%',
    maxWidth: value + 'px'
  }
}

const renderFormLabel = (name, tooltip = "") => {
  if (name != "") {
    if (tooltip != "") {
      return (
        <div className="display-flex flex-middle">
          <div className="form-label">{name}</div>
          <Tooltip title={tooltip}>
            <MdInfoOutline style={{ fontSize: 14, marginLeft: 4, marginBottom: 4, color: '#777'}}/>
          </Tooltip>
        </div>
      )
    } else {
      return <div className="form-label">{name}</div>;
    }
  }
  return null;
}

const renderInputField = (label, name, required = false, disabled = false, placeholder = "") => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: required, message: `${label} is required!`, validateTrigger: "onBlur" }]}
      >
        <Input size="large" placeholder={placeholder} disabled={disabled}/>
      </Form.Item>
    </>
  )
}

const renderTextAreaField = (label, name, rows = 3, required = false, placeholder = "") => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: required, message: `${label != "" ? label : "Field"} is required!` }]}
      >
        <Input.TextArea rows={rows} placeholder={placeholder} size="large"/>
      </Form.Item>
    </>
  )
}

const renderDateField = (label, name, accountSettings) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <DatePicker size="large" format={getDatePickerFormat(accountSettings)}/>
      </Form.Item>
    </>
  )
}

const renderMediumDateField = (label, name, accountSettings) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <DatePicker size="large" format={getDatePickerFormatMedium(accountSettings)}/>
      </Form.Item>
    </>
  )
}

const renderTimeField = (label, name, required = true, accountSettings) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        { !isUndefined(accountSettings) && accountSettings.time_format == TimeFormatTypes.HOUR_24 ? (
          <TimePicker size="large" use12Hours={false} format="HH:mm"/>
        ) : (
          <TimePicker size="large" use12Hours format="h:mm A"/>
        )} 
      </Form.Item>
    </>
  )
}

const renderCheckboxField = (label, name, onChange = () => {}, tooltip = null, disabled = false) => {
  return (
    <div className="flex-row flex-middle">
      <Form.Item
        name={name}
        valuePropName="checked"
      >
        <Checkbox value={true} onChange={(e) => onChange(e)} disabled={disabled}>{label}</Checkbox>
      </Form.Item>
      { !isNull(tooltip) && (
        <div className="display-flex">
          <Tooltip title={tooltip}>
            <MdInfoOutline style={{ fontSize: 14, marginLeft: -2, color: '#777'}}/>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

const renderCurrencyField = (label, name, required = true, code) => {
  const symbol = getCurrencySymbol(code)

  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <NumberFormat 
          className="ant-input ant-input-lg" 
          displayType={'input'} 
          thousandSeparator={true} 
          prefix={symbol} 
          decimalScale={2} 
          fixedDecimalScale={true}
          style={widthStyle(200)}
          placeholder={`${symbol}0.00`}
        />
      </Form.Item>
    </>
  )
}

const renderCurrencyFieldWithValidator = (label, name, required = true, code, validator = (_a,_b,cb) => { cb() }) => {
  const symbol = getCurrencySymbol(code)

  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[
          { required: required, message: `${label} is required!` }, 
          { validator: validator }]}
      >
        <NumberFormat 
          className="ant-input ant-input-lg" 
          displayType={'input'} 
          thousandSeparator={true} 
          prefix={symbol} 
          decimalScale={2} 
          fixedDecimalScale={true}
          style={widthStyle(200)}
          placeholder={`${symbol}0.00`}
        />
      </Form.Item>
    </>
  )
}

const renderEmailField = (label, name, required = false, disabled = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[
          { required: required, message: `${label} is required!` },
          { type: "email", message: `Please enter a valid email address` }
        ]}
      >
        <Input size="large" disabled={disabled}/>
      </Form.Item>
    </>
  )
}

const renderSelectOptions = (options) => {
  if (options) {
    return options.map((option) => (
      <Select.Option value={option.value} key={option.value}>{option.text}</Select.Option>
    ))
  }
  return null;
}

const renderSearchSelectField = (label, name, placeholder, options, required = false, onChange = () => {}, disabled = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item 
        name={name}
        rules={[{ required: required, message: `${label != "" ? label : "Field"} is required!` }]}
      >
        <Select
          showSearch
          allowClear
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
          onChange={onChange}
          disabled={disabled}
        >
          {renderSelectOptions(options)}
        </Select>
      </Form.Item>
    </>
  )
}

const renderSelectField = (label, name, placeholder, options, required = false, onChange = () => {}, disabled = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item 
        name={name}
        rules={[{ required: required, message: `${label != "" ? label : "Field"} is required!` }]}
      >
        <Select
          allowClear
          placeholder={placeholder}
          size='large'
          onChange={onChange}
          disabled={disabled}
        >
          {renderSelectOptions(options)}
        </Select>
      </Form.Item>
    </>
  )
}

const renderNumberField = (label, name, precision = 0, required = false, tooltip = "", disabled = false) => {
  var rules = required ? [{ required: required, message: `${label} is required!`}] : []
  return (
    <>
      {renderFormLabel(label, tooltip)}
      <Form.Item
        name={name}
        rules={rules}
      >
        <InputNumber 
          size="large"
          style={widthStyle(200)}
          precision={precision}
          disabled={disabled}
          />
      </Form.Item>
    </>
  )
}

const renderPercentageField = (label, name, required = false, disabled = false) => {
  var rules = required ? [{ required: required, message: `${label} is required!`}] : []
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={rules}
      >
        <InputNumber
          size="large"
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          placeholder="0%"
          disabled={disabled}
        />
      </Form.Item>
    </>
  )
}

const phoneValidator = (value, country) => {
  const results = phone(value, { country: country, validateMobilePrefix: false })
  const hasChars = /[A-Za-z]/.test(value)
  if ((results.isValid && !hasChars) || value == "" || isUndefined(value)) {
    return Promise.resolve()
  }
  return Promise.reject()
}

const getPhoneCode = (value) => {
  if (!isUndefined(value) && !isNull(value)) {
    return value.includes("+") ? value : "+" + value
  }
}

const phonePrefixSelector = (countryValue, setCountryValue) => {
  const countries = Country.getAllCountries()
  return (
      <Select 
        size="large"
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        value={countryValue}
        onChange={(option) => {
          setDefaultPhoneCountryCode(option)
          setCountryValue(option)}
        }
      >
        { countries.map(country => (
          <Select.Option value={country.isoCode} label={`${getPhoneCode(country.phonecode)}`}>
            <span>{country.flag} {country.name} {getPhoneCode(country.phonecode)}</span>
          </Select.Option>
        ))}
      </Select>

  )
};

const renderPhoneField = (label, name, required = false, disabled = false, countryValue, setCountryValue) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        style={widthStyle(350)}
        rules={[
          { required: required, message: `${label} is required!`},
          { validator: (_, value) => phoneValidator(value, countryValue), message: "Please enter a valid phone number"}
        ]}
      >
        <Input size="large" disabled={disabled} addonBefore={phonePrefixSelector(countryValue, setCountryValue)}/>
      </Form.Item>
    </>
  )
}

const renderURLField = (label, name, required = false, disabled = false, placeholder = "") => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[
          { required: required, message: `${label} is required!` },
          { type: "url", message: `Please enter a valid URL` }
        ]}
      >
        <Input size="large" disabled={disabled} placeholder={placeholder}/>
      </Form.Item>
    </>
  )
}

const renderGlobalAddressField = (country, name, required = true, disabled = false) => {
  var label = ""
  var placeholder = ""
  switch (country) {
    case "US":
      label = "Address"
      placeholder = "Street address or P.O. Box"
      break
    case "GB":
      label = "Address Line 1"
      break
    default:
      label = "Street Address"
      placeholder = "Street address, P.O. Box, company name, c/o"
      break
  }
  return renderInputField(label, name, required, disabled, placeholder)
}

const renderGlobalSecondAddressField = (country, name, required = false, disabled = false) => {
  var label = ""
  var placeholder = ""
  switch (country) {
    case "US":
      label = ""
      placeholder = "Apt, suite, unit, building, floor, etc."
      break
    case "GB":
      label = "Address Line 2 (optional)"
      break
    default:
      label = ""
      placeholder = "Apt, suite, unit, building, floor, etc."
      break
  }
  return renderInputField(label, name, required, disabled, placeholder)
}

const renderGlobalCityField = (country, name, required = true) => {
  var label = ""
  switch (country) {
    case "GB":
      label = "Town/City"
      break
    default:
      label = "City"
      break
  }
  return renderInputField(label, name, required)
}

const renderGlobalStateField = (country, name, required) => {
  const fieldRequired = isUndefined(required) ? true : required
  switch (country) {
    case "US":
      const states = getStateOptions()
      return renderSearchSelectField("State", name, "Select a state...", states, fieldRequired, (value) => setDefaultState(value))
    case "GB":
      return renderInputField("County (if applicable)", name, false)
    default:
      return renderInputField("State / Province / Region", name, fieldRequired)
  }
}

const renderGlobalZipField = (country, name, required = true) => {
  switch (country) {
    case "GB":
      return renderInputField("Postcode", name, required)
    default:
      return renderInputField("Zip Code", name, required)
  }
}

export { 
  renderInputField,
  renderTextAreaField,
  renderDateField,
  renderMediumDateField,
  renderTimeField,
  renderCheckboxField,
  renderCurrencyField,
  renderCurrencyFieldWithValidator,
  renderEmailField,
  renderFormLabel,
  renderSearchSelectField,
  renderSelectField,
  renderNumberField,
  renderPercentageField,
  renderPhoneField,
  renderURLField,
  renderGlobalAddressField,
  renderGlobalSecondAddressField,
  renderGlobalCityField,
  renderGlobalStateField,
  renderGlobalZipField
};