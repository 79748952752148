import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Modal, Dropdown, Menu, Input, Grid, notification, Form, Select, DatePicker } from 'antd';
import { cloneDeep, isArray, toLower, isEmpty, isUndefined, isNull, sortBy } from 'lodash';
import qs from "qs";
import moment from 'moment';
import { 
  getContracts,
  getEvents, 
  deleteEventContract,
  getContractTemplates,
  getContractTemplate,
  createEventContract,
  getAccountEventTypes,
  revertEventContract
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import StatusTag from '../../../components/statusTag';
import FloatingContainer from '../../../components/floatingContainer'
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { EditOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import DateFormatTypes from '../../../constants/dateFormatTypes';
import { getContractFilters, setContractFilters } from '../../../helpers/contractHelper';
import { MdOutlineMoreHoriz, MdOutlineClose, MdOutlineUndo, MdOutlineKeyboardArrowDown, MdAdd } from "react-icons/md";
import { FiSend, FiTrash, FiCalendar, FiChevronDown, FiCheck, FiArrowUp, FiArrowDown } from "react-icons/fi";
import { FaSignature } from "react-icons/fa";
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'
import { formatEventDateShort, formatDateShort, getDatePickerFormatMedium } from "../../../helpers/dateHelper";
import ContractStatus from "../../../constants/contractStatus";
import ErrorCard from '../../../components/errorCard';
import { BsArrowRight, BsSortAlphaDown, BsSortAlphaUpAlt, BsSortDown, BsSortUp } from "react-icons/bs";
import AppContext from '../../../app/context';
import { 
  renderInputField,
  renderSearchSelectField,
  renderFormLabel
} from '../../../components/formFields'

const { RangePicker } = DatePicker;

const ContractsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [selectedContract, setSelectedContract] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [events, setEvents] = useState([]);
  const [eventClients, setEventClients] = useState([]);
  const [isNewContractModalVisible, setNewContractModalVisible] = useState(false);
  const [selectedContractEvent, setSelectedContractEvent] = useState("");
  const [contractError, setContractError] = useState("");
  const [isFilterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [selectedSortKey, setSelectedSortKey] = useState("event-date");
  const [eventTypes, setEventTypes] = useState([]);
  const [dateRangeType, setDateRangeType] = useState("all");
  const [isConfirmRevertContractModalVisible, setConfirmRevertContractModalVisible] = useState(false);

  useDocumentTitle("Contracts")
  const [sendRequest] = useApi()
  const navigate = useNavigate();
  const location = useLocation()
  const [accountSettings] = useAccountSettings()
  const { auth, setAuth } = useContext(AppContext);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [newContractForm] = Form.useForm();
  const [filterForm] = Form.useForm();

  var primaryTabs = [
    { name: "All Contracts", key: "all" },
    { name: "Draft", key: "draft" },
    { name: "Outstanding", key: "outstanding" },
    { name: "Completed", key: "completed" }
  ]

  var sortKeys = [
    { name: "Event Date", key: "event-date" },
    { name: "Event Date", key: "event-date-desc" },
    { name: "Contract Name", key: "contract-name" },
    { name: "Contract Name", key: "contract-name-desc" }
  ]

  var dateRangeOptions = [
    { label: "All Events", value: "all" },
    { label: "Past Events", value: "past" },
    { label: "Upcoming Events", value: "upcoming" },
    { label: "Custom Range", value: "custom-range" }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);

    const contractFilters = getContractFilters()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })

    // Apply status param
    var appliedStatus = null;
    if (queryStrings.status) {
      appliedStatus = queryStrings.status;
    } else if (contractFilters.status) {
      appliedStatus = contractFilters.status;
    } else {
      appliedStatus = "all"
    }

    // Apply search param
    var appliedSearch = null;
    if (queryStrings.q) {
      appliedSearch = queryStrings.q
    }

    // Apply sort param
    var appliedSort = null;
    if (queryStrings.sort) {
      appliedSort = queryStrings.sort;
    } else if (contractFilters.sort) {
      appliedSort = contractFilters.sort;
    } else {
      appliedSort = "event-date"
    }

    // Apply filters param
    var appliedFilters = null
    if (queryStrings.filters) {
      appliedFilters = queryStrings.filters
    } else if (contractFilters.status) {
      appliedFilters = contractFilters.filters;
    }

    // Set state variables
    setSelectedFilter(appliedStatus)
    setSearchText(appliedSearch)
    setSelectedFilters(appliedFilters)
    setSelectedSortKey(appliedSort)

    // Update URL
    updateUrl(appliedStatus, appliedSearch, appliedFilters, appliedSort)

    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const contractResults = await sendRequest(getContracts("all"));
      setContracts(contractResults)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }
  
  const menuItem = (text, icon, action = () => {}) => {
    return (
      <Menu.Item className="ph-15 pv-10">
        <div onClick={action} className="display-flex flex-middle">
          {icon}<span style={{ marginLeft: 12}}>{text}</span>
        </div>
      </Menu.Item>
    )
  }

  const menu = (item) => {
    if (item.status == ContractStatus.DRAFT) {
      return (
        <Menu>
          { menuItem("Go to Event", <FiCalendar/>, () => handleGoToEvent(item))}
          { menuItem("Edit Contract", <EditOutlined/>, () => handleEditContract(item))}
          { menuItem("Preview / Sign Contract", <EyeOutlined/>, () => handlePreviewContract(item))}
          { menuItem("Remove Contract", <FiTrash/>, () => handleDeleteContract(item))}
        </Menu>
      )
    } else if (item.status == ContractStatus.AWAITING_SEND) {
      return (
        <Menu>
          { menuItem("Go to Event", <FiCalendar/>, () => handleGoToEvent(item))}
          { menuItem("Edit Contract", <EditOutlined/>, () => handleEditContract(item))}
          { menuItem("Revert to Draft", <MdOutlineUndo/>, () => handleRevertContract(item))}
          { menuItem("Send Contract", <FiSend/>, () => handleSendContract(item))}
          { menuItem("Remove Contract", <FiTrash/>, () => handleDeleteContract(item))}
        </Menu>
      )
    } else if (item.status == ContractStatus.AWAITING_SIGNATURE) {
      return (
        <Menu>
          { menuItem("Go to Event", <FiCalendar/>, () => handleGoToEvent(item))}
          { menuItem("Edit Contract", <EditOutlined/>, () => handleEditContract(item))}
          { menuItem("Re-send Contract", <FiSend/>, () => handleSendContract(item))}
          { menuItem("Revert to Draft", <MdOutlineUndo/>, () => handleRevertContract(item))}
          { menuItem("Remove Contract", <FiTrash/>, () => handleDeleteContract(item))}
        </Menu>
      )
    } else if (item.status == ContractStatus.COUNTERSIGN) {
      return (
        <Menu>
          { menuItem("Go to Event", <FiCalendar/>, () => handleGoToEvent(item))}
          { menuItem("Sign Contract", <EditOutlined/>, () => handlePreviewContract(item))}
        </Menu>
      )
    } else if (item.status == ContractStatus.COMPLETED) {
      return (
        <Menu>
          { menuItem("Go to Event", <FiCalendar/>, () => handleGoToEvent(item))}
          { menuItem("View / Download Contract", <EyeOutlined/>, () => handlePreviewContract(item))}
        </Menu>
      )
    }
    return (
      <Menu>
        { menuItem("Go to Event", <FiCalendar/>, () => handleGoToEvent(item))}
        { menuItem("Preview Contract", <EyeOutlined/>, () => handlePreviewContract(item))}
      </Menu>
    )
  };

  const sortMenu = () => {
    return (
      <Menu>
        { menuItem("Event Date (Ascending)", <BsSortAlphaDown/>, () => onSortChange("event-date"))}
        { menuItem("Event Date (Descending)", <BsSortAlphaUpAlt/>, () => onSortChange("event-date-desc"))}
        { menuItem("Contract Name (A-Z)", <BsSortDown/>, () => onSortChange("contract-name"))}
        { menuItem("Contract Name (Z-A)", <BsSortUp/>, () => onSortChange("contract-name-desc"))}
      </Menu>
    )
  };

  const getFilteredItems = () => {
    var allContracts = cloneDeep(contracts);
    var filteredContracts = allContracts;

    // Apply status filter
    if (selectedFilter == "draft") {
      filteredContracts = filteredContracts.filter(x => x.status == ContractStatus.DRAFT)
    } else if (selectedFilter == "outstanding") {
      filteredContracts = filteredContracts.filter(x => [ContractStatus.AWAITING_SEND, ContractStatus.AWAITING_SIGNATURE, ContractStatus.COUNTERSIGN].includes(x.status))
    } else if (selectedFilter == "completed") {
      filteredContracts = filteredContracts.filter(x => x.status == ContractStatus.COMPLETED)
    }

    // Apply search filter
    if (searchText) {
      const lowerSearchText = toLower(searchText)
      
      filteredContracts = filteredContracts.filter(x => {
        // Check event date
        const eventDate = moment(x.event_date, "YYYY-MM-DD")
        var eventDateShort = eventDate.format("MM/DD/YYYY")
        var eventDateShort2 = eventDate.format("M/D/YYYY")
        var eventDateMedium = eventDate.format("MMM D, YYYY")
        var eventDateLong = eventDate.format("MMMM D, YYYY")
        if (accountSettings.date_format == DateFormatTypes.DMY) {
          eventDateShort = eventDate.format("DD/MM/YYYY")
          eventDateShort2 = eventDate.format("D/M/YYYY")
          eventDateMedium = eventDate.format("D MMM, YYYY")
          eventDateLong = eventDate.format("D MMMM, YYYY")
        }

        const matchesEventDateShort = toLower(eventDateShort).includes(lowerSearchText)
        const matchesEventDateShort2 = toLower(eventDateShort2).includes(lowerSearchText)
        const matchesEventDateMedium = toLower(eventDateMedium).includes(lowerSearchText)
        const matchesEventDateLong = toLower(eventDateLong).includes(lowerSearchText)
        const matchesEventName = toLower(x.event_name).includes(lowerSearchText)
        var matchSignerFirstName = false
        var matchSignerLastName = false
        var matchSignerFullName = false
        for (const signer of x.signers) {
          if (!matchSignerFirstName) {
            matchSignerFirstName = toLower(signer.first_name).includes(lowerSearchText)
          }
          if (!matchSignerLastName) {
            matchSignerLastName = toLower(signer.last_name).includes(lowerSearchText)
          }
          if (!matchSignerFullName) {
            matchSignerFullName = toLower(signer.first_name + " " + signer.last_name).includes(lowerSearchText)
          }
        }

        return matchesEventDateShort || matchesEventDateShort2 || matchesEventDateMedium || matchesEventDateLong || matchesEventName || matchSignerFirstName || matchSignerLastName || matchSignerFullName
      })
    }

    // Apply date range filter
    if (selectedFilters && selectedFilters.date_range_type == "upcoming") {
      filteredContracts = filteredContracts.filter(x => {
        return x.event_date >= moment().format("YYYY-MM-DD")
      })
    } else if (selectedFilters && selectedFilters.date_range_type == "past") {
      filteredContracts = filteredContracts.filter(x => {
        return x.event_date < moment().format("YYYY-MM-DD")
      })
    } else if (selectedFilters && selectedFilters.date_range) {
      filteredContracts = filteredContracts.filter(x => {
        const startDate = selectedFilters.date_range.start
        const endDate = selectedFilters.date_range.end
        return x.event_date >= startDate && x.event_date <= endDate
      })
    }

    // Apply event type filter
    if (selectedFilters && isArray(selectedFilters.event_types) && selectedFilters.event_types.length > 0) {
      filteredContracts = filteredContracts.filter(x => {
        return selectedFilters.event_types.includes(x.account_event_type_name)
      })
    }

    // Apply sort
    if (selectedSortKey == "event-date") {
      filteredContracts = sortBy(filteredContracts, "event_date")
    } else if (selectedSortKey == "contract-name") {
      filteredContracts = sortBy(filteredContracts, "event_contract_name")
    } else if (selectedSortKey == "event-date-desc") {
      filteredContracts = sortBy(filteredContracts, "event_date").reverse()
    } else if (selectedSortKey == "contract-name-desc") {
      filteredContracts = sortBy(filteredContracts, "event_contract_name").reverse()
    }

    return filteredContracts;
  }

  const onSearchTextChange = (text) => {
    setSearchText(text)
    updateUrl(selectedFilter, text, selectedFilters, selectedSortKey)
  }

  const onFilterChange = (value) => {
    setSelectedFilter(value)
    updateUrl(value, searchText, selectedFilters, selectedSortKey)
  }

  const onSortChange = (value) => {
    setSelectedSortKey(value)
    updateUrl(selectedFilter, searchText, selectedFilters, value)
  }

  const isValid = (value) => {
    return !isNull(value) && !isUndefined(value) && !isEmpty(value)
  }

  const updateUrl = (status, search, filters, sort) => {
    var url = "/admin/contracts"
    var params = {}

    // Add individual filters
    if (isValid(status)) {
      params.status = status
    }
    if (isValid(search)) {
      params.search = search
    }
    if (isValid(filters)) {
      params.filters = filters
    }
    if (isValid(sort)) {
      params.sort = sort
    }

    // Stringify params
    const paramString = qs.stringify(params)

    if (!isEmpty(params)) {
      url += `?${paramString}`
    }

    navigate(url, { replace: true })
    setContractFilters(status, filters, sort)
  }
  
  const onAddContract = async () => {
    try {
      // Get contract templates
      if (contractTemplates.length == 0) {
        const templates = await sendRequest(getContractTemplates())
        const templateData = templates.map((template) => {
          return {
            value: template.contract_template_id,
            text: template.contract_template_name
          }
        })
        setContractTemplates(templateData)
      }

      // Get events
      if (events.length == 0) {
        const eventResults = await sendRequest(getEvents("upcoming"));
        setEvents(eventResults);
      }

      setNewContractModalVisible(true)
    } catch {}
  }

  const onNewContractSubmit = async (values) => {
    try {
      var template = null;
      if (values["contract_template_id"]) {
        const templateResults = await sendRequest(getContractTemplate(values["contract_template_id"]))
        template = templateResults.content;
      }
      const body = {
        event_contract_name: values["event_contract_name"],
        template: template,
        status: ContractStatus.DRAFT,
        client_user_id: !isUndefined(values["user_id"]) ? values["user_id"] : null,
        admin_user_id: auth.user.user_id
      }
      const results = await sendRequest(createEventContract(selectedContractEvent, body))
      navigate(`/admin/events/${selectedContractEvent}/contracts/${results.event_contract_id}/edit`)
    } catch {
      setNewContractModalVisible(false)
    }
  }

  const onFilterSubmit = async (values) => {
    var filters = {}

    // Get date range type
    const rangeType = values["date_range_type"]
    if (rangeType && rangeType != "all") {
      filters.date_range_type = rangeType
    }

    // Get date range
    const range = values["date_range"]
    if (isArray(range) && range.length == 2 && rangeType == "custom-range") {
      const startDate = range[0].format("YYYY-MM-DD")
      const endDate = range[1].format("YYYY-MM-DD")
      filters.date_range = {
        start: startDate,
        end: endDate
      }
    }

    // Get event types
    const eventTypeValues = values["event_types"]
    if (isArray(eventTypeValues)) {
      filters.event_types = eventTypeValues
    }

    setSelectedFilters(filters)
    updateUrl(selectedFilter, searchText, filters, selectedSortKey)
    setFilterModalVisible(false)
  }

  const onEventChange = (value) => {
    setSelectedContractEvent(value)

    // Find event
    const foundEvent = events.find(x => x.event_id == value)
    if (isArray(foundEvent.clients)) {
      // Populate clients box
      var clientData = foundEvent.clients.map((client) => {
        return {
          value: client.user_id,
          text: `${client.first_name} ${client.last_name}`
        }
      })
      setEventClients(clientData)
    }
  }

  const handleEditContract = (contract) => {
    navigate(`/admin/events/${contract.event_id}/contracts/${contract.event_contract_id}/edit?from=contracts`)
  };

  const handlePreviewContract = (contract) => {
    navigate(`/admin/events/${contract.event_id}/contracts/${contract.event_contract_id}/preview?from=contracts`)
  };

  const handleSendContract = (contract) => {
    navigate(`/admin/events/${contract.event_id}/contracts/${contract.event_contract_id}/send?from=contracts`)
  };

  const handleViewContract = (contract) => {
    navigate(`/admin/events/${contract.event_id}/contracts/${contract.event_contract_id}/preview?from=contracts`)
  };

  const handleGoToEvent = (contract) => {
    navigate(`/admin/events/${contract.event_id}`)
  };

  const handleDeleteContract = (contract) => {
    setSelectedContract(contract)
    setConfirmRemoveVisible(true)
  };

  const handleRevertContract = (contract) => {
    setSelectedContract(contract)
    setConfirmRevertContractModalVisible(true)
  }

  const revertContract = async () => {
    try {
      setConfirmRevertContractModalVisible(false)
      await sendRequest(revertEventContract(selectedContract.event_id, selectedContract.event_contract_id));
      await refreshPage()
    } catch {}
  }

  const onAddFilter = async () => {
    try {
      // Date range type
      var dateRangeType = "all"
      if (selectedFilters && selectedFilters.date_range_type) {
        dateRangeType = selectedFilters.date_range_type
      }
      setDateRangeType(dateRangeType)

      // Date range
      var dateRange = []
      if (selectedFilters && selectedFilters.date_range) {
        const startDate = moment(selectedFilters.date_range.start, "YYYY-MM-DD")
        const endDate = moment(selectedFilters.date_range.end, "YYYY-MM-DD")
        dateRange = [startDate, endDate]
      }

      // Event types
      var eventTypeValues = []
      if (selectedFilters && isArray(selectedFilters.event_types)) {
        eventTypeValues = selectedFilters.event_types
      }

      const values = {
        date_range_type: dateRangeType,
        date_range: dateRange,
        event_types: eventTypeValues
      }
      filterForm.setFieldsValue(values)

      setFilterModalVisible(true)

      // Populate event types if needed
      if (eventTypes.length == 0) {
        const eventTypeResults = await sendRequest(getAccountEventTypes());
        const eventTypeData = eventTypeResults.map((eventType) => {
          return {
            value: eventType.event_type_name,
            label: eventType.event_type_name
          }
        })
        setEventTypes(eventTypeData);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onRemoveDateFilter = () => {
    var filters = cloneDeep(selectedFilters)
    delete filters.date_range_type
    delete filters.date_range
    setSelectedFilters(filters)
    updateUrl(selectedFilter, searchText, filters, selectedSortKey)
  }

  const onRemoveEventTypeFilter = (value) => {
    var filters = cloneDeep(selectedFilters)
    if (isArray(filters.event_types)) {
      filters.event_types = filters.event_types.filter(x => x != value)
    }
    setSelectedFilters(filters)
    updateUrl(selectedFilter, searchText, filters, selectedSortKey)
  }

  const confirmDeleteContract = async () => {
    try {
      await sendRequest(deleteEventContract(selectedContract.event_id, selectedContract.event_contract_id))
      await refreshPage()
      setConfirmRemoveVisible(false)
      notification.success({
        message: 'Success!',
        description: 'Your contract has been deleted.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
      setConfirmRemoveVisible(false)
    }
  }

  const getCountByStatus = (status) => {
    return contracts.filter(x => x.status == status).length
  }

  const getOutstandingContracts = () => {
    return contracts.filter(x => [ContractStatus.AWAITING_SEND, ContractStatus.AWAITING_SIGNATURE, ContractStatus.COUNTERSIGN].includes(x.status)).length
  }

  const getSortKeyDescription = (key) => {
    const sortKeyItem = sortKeys.find(x => x.key == key)
    return !isEmpty(sortKeyItem) ? sortKeyItem.name : ""
  }

  const getSortKeyIcon = (key) => {
    if (key == "event-date" || key == "contract-name") {
      return <FiArrowDown style={{ marginLeft: 2 }} className="fs-12"/>
    } else {
      return <FiArrowUp style={{ marginLeft: 2 }} className="fs-12"/>
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Contracts</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/contracts")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { contracts.length > 0 && (
            <button className="page-title-button" onClick={onAddContract}>New Contract</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderSigner = (signer, index) => {
    return (
      <div key={index}>
        <div className="display-flex flex-middle">
          { signer.signed ? (
            <FiCheck size={14} className="c-green mr-5"/>
          ) : (
            <MdOutlineClose size={14} color="#b5b5b5" className="mr-5"/>
          )}
          {signer.first_name} {signer.last_name}
        </div>
        <div className="c-text-gray fs-10 ml-20">{ signer.signed ? `signed on ${formatDateShort(signer.signed_datetime, accountSettings)}` : ""}</div>
      </div>
    )
  }

  const renderContract = (contract, index) => {
    return (
      <div className="shadow-card-square mt-10" key={index}>
        <div className="b-border ph-20 pv-15">
          <Row align="middle" gutter={[15,15]} wrap={false}>
            { screens.sm && (
              <Col flex={0} onClick={() => handleViewContract(contract)}>
                <div className="card-icon"><FaSignature/></div>
              </Col>
            )}
            { screens.lg ? (
              <Col flex={1} onClick={() => handleViewContract(contract)}>
                <div className="fw-700">{contract.event_contract_name}</div>
                <div className="c-text-gray fs-12">Event Date: { formatEventDateShort(contract.event_date, accountSettings) }</div>
              </Col>
            ) : (
              <Col flex={1} onClick={() => handleViewContract(contract)}>
                <div className="fw-700 mb-5">{contract.event_contract_name}</div>
                <StatusTag status={contract.status} size="small"/>
              </Col>
            )}
            { screens.lg && (
              <Col flex={0}>
                <StatusTag status={contract.status} size="small"/>
              </Col>
            )}
            <Col flex={0}>
              <div className="display-flex" style={{ justifyContent: 'flex-end'}}>
                <Dropdown overlay={menu(contract)} placement="bottomRight" trigger="click">
                  <div className="dots-container">
                    <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
        <div className="p-20">
          <Row gutter={[15,15]}>
            <Col xs={24} sm={12}>
              <div className="fs-10 c-text-gray">EVENT</div>
              <div className="blue-link" onClick={() => handleGoToEvent(contract)}>{ contract.event_name }</div>
              <div className="fs-10 c-text-gray mt-10">CREATED ON</div>
              <div className="">{ formatDateShort(contract.create_datetime, accountSettings) }</div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="fs-10 c-text-gray">SIGNERS</div>
              { isArray(contract.signers) && contract.signers.map((x,i) => renderSigner(x,i))}
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderTab = (text, value) => {
    const isSelected = selectedFilter == value
    const selectedStyle = isSelected ? { borderBottom: "2px solid #536DFE", color: "#536DFE", userSelect: 'none' } : { userSelect: 'none' }
    return (
      <div className={`ph-10 pv-10 mt-15 mr-20 fw-600`} style={selectedStyle} onClick={() => onFilterChange(value)}>
        { text }
      </div>
    )
  }

  const mobileTabMenu = () => {
    return (
      <Menu>
        { primaryTabs.map((x,i) => (
          <Menu.Item key={i}>
            <div onClick={() => onFilterChange(x.key)}>
              { x.name }
            </div>
          </Menu.Item>
        ))}
      </Menu>
    )
  };

  const renderDateRangeFilter = () => {
    if (selectedFilters) {
      if (selectedFilters.date_range_type == "upcoming") {
        return renderFilter("Upcoming Events", 1000, () => onRemoveDateFilter())
      } else if (selectedFilters.date_range_type == "past") {
        return renderFilter("Past Events", 1000, () => onRemoveDateFilter())
      } else if (selectedFilters.date_range) {
        const startDate = formatDateShort(selectedFilters.date_range.start, accountSettings)
        const endDate = formatDateShort(selectedFilters.date_range.end, accountSettings)
        const dateRangeString = `${startDate} - ${endDate}`
        return renderFilter(dateRangeString, 1000, () => onRemoveDateFilter())
      }
    }
  }

  const renderEventTypeFilters = () => {
    if (selectedFilters && isArray(selectedFilters.event_types)) {
      return selectedFilters.event_types.map((x,i) => renderFilter(x,i,() => onRemoveEventTypeFilter(x)))
    }
  }

  const renderFilter = (text, index, action) => {
    return (
      <Col key={index}>
        <span className="filter-button filter-tag"><span onClick={() => onAddFilter()}>{text}</span> <span onClick={action}><MdOutlineClose className="ml-5 fs-16 c-text-gray"/></span></span>
      </Col>
    )
  }

  const renderContractBox = () => {
    const tabRecord = primaryTabs.find(x => x.key == selectedFilter)
    const tabName = !isEmpty(tabRecord) ? tabRecord.name : ""
    return (
      <>
        <div className="shadow-card-square">
          { screens.sm ? (
            <div className="display-flex ph-20 b-border">
              { renderTab("All Contracts", "all") }
              { renderTab("Draft", "draft") }
              { renderTab("Outstanding", "outstanding") }
              { renderTab("Completed", "completed") }
            </div>
          ) : (
            <div className="ph-20 pt-10">
              <Dropdown overlay={mobileTabMenu()} placement="bottomRight" trigger="click">
                <div className="tab-dropdown mt-10">
                  <div className="tab-dropdown--text">{tabName}</div>
                  <div className="tab-dropdown--arrow"><MdOutlineKeyboardArrowDown/></div>
                </div>
              </Dropdown>
            </div>
          )}
          <div className="p-15">
            <div className="display-flex flex-row flex-middle">
              <div className="flex-1">
                <Input className="list-search-input-square" size="large" placeholder="Event name, date, or signers" value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
              </div>
            </div>
            <Row gutter={[5,5]} className="mt-10">
              { renderDateRangeFilter() }
              { renderEventTypeFilters() }
              <Col>
                <span className="filter-button" onClick={() => onAddFilter()}>
                  <MdAdd className="mr-5 fs-16 c-text-gray"/> Add filter
                </span>
              </Col>
              <Col>
                <Dropdown overlay={sortMenu()} placement="bottomRight" trigger="click">
                  <span className="filter-button icon-right">
                    Sort by: {getSortKeyDescription(selectedSortKey)} {getSortKeyIcon(selectedSortKey)} <FiChevronDown className="ml-5 fs-16 c-text-gray"/>
                  </span>
                </Dropdown>
              </Col>
            </Row>
          </div>
        </div>
        { renderContractResults() }
      </>
    )
  }

  const renderContracts = () => {
    if (contracts.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No contracts yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a contract to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onAddContract}>New Contract</button>
        </div>
      )
    } else {
      return renderContractBox()
    }
  }

  const renderContractResults = () => {
    if (getFilteredItems().length > 0) {
      return getFilteredItems().map((contract,i) => renderContract(contract,i));
    } else {
      return (
        <div className="shadow-card mt-10">
          <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
            <div>
              <img src={emptySearchImage} width={200}/>
            </div>
            <div className="fs-18 fw-700 mt-30">
              No contracts found
            </div>
            <div className="fs-14 fw-500 c-text-gray">
              Adjust your filter and try again!
            </div>
          </FadeWhileInView>
        </div>
      )
    }
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Contract</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedContract.event_contract_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteContract()}>Remove Contract</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderNewContractModal = () => {
    var eventData = events.map((event) => {
      return {
        value: event.event_id,
        text: `${event.event_name} (${formatEventDateShort(event.event_date, accountSettings)})`
      }
    })
    return (
      <Modal visible={isNewContractModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Contract</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNewContractModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newContractForm} layout="vertical" name="new-invoice" onFinish={onNewContractSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              <div className="bg-gray p-10">Note: An event will need to be created before adding a contract.</div>
            </Col>
            <Col xs={24}>
              {renderInputField("Contract Name", "event_contract_name", true, false, "Enter a name")}
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Contract Template (optional)", "contract_template_id", "Select a template...", contractTemplates, false) }
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Event", "event_id", "Select an event...", eventData, true, onEventChange) }
            </Col>
            { selectedContractEvent && (
              <Col xs={24}>
                { renderSearchSelectField("Primary Signer", "user_id", "Select client...", eventClients, true) }
              </Col>
            )}
            { contractError && (
              <Col xs={24}>
                <div className="c-red text-center">{ contractError }</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setNewContractModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderFilterModal = () => {
    return (
      <Modal visible={isFilterModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Filters</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setFilterModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={filterForm} layout="vertical" name="contract-filters" onFinish={onFilterSubmit}>
          <Row gutter={[15,15]} className="mt-15">
            <Col xs={24}>
              { renderFormLabel("Event Date Range")}
              <Form.Item name="date_range_type">
                <Select
                  size="large"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Select range..."
                  options={dateRangeOptions}
                  onChange={(value) => setDateRangeType(value)}
                />
              </Form.Item>
            </Col>
            { dateRangeType == "custom-range" && (
              <Col xs={24}>
                <Form.Item 
                  name={"date_range"}
                  >
                  <RangePicker 
                    size="large" 
                    format={getDatePickerFormatMedium(accountSettings)} 
                    ranges={{
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'Past': [moment().startOf('year'), moment()],
                      'Upcoming': [moment(), moment().endOf('year')],
                    }}/>
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              { renderFormLabel("Event Types")}
              <Form.Item name="event_types">
                <Select
                  mode="multiple"
                  size="large"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Select event types..."
                  options={eventTypes}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Apply</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setFilterModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRevertContractModal = () => {
    return (
      <Modal visible={isConfirmRevertContractModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRevertContractModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Revert Contract</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to revert <span className="fw-700">{selectedContract.event_contract_name}</span> to a draft status? This will remove all signatures and allow you to edit the document.</div>
          <button className="primary-button warning" type="button" onClick={() => revertContract()}>Revert to Draft</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRevertContractModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        { contracts.length > 0 && screens.sm && (
          <div className="">
            <Row gutter={[15,15]}>
              <Col xs={24} sm={8}>
                <div className="shadow-card-square mb-15 pv-20 text-center">
                  <div className="fw-700 c-text-gray">Draft</div>
                  <div className="fw-300 fs-40">{ getCountByStatus(ContractStatus.DRAFT) }</div>
                </div>
              </Col>
              <Col xs={24} sm={8}>
                <div className="shadow-card-square mb-15 pv-20 text-center">
                  <div className="fw-700 c-text-gray">Outstanding</div>
                  <div className="fw-300 fs-40">{ getOutstandingContracts() }</div>
                </div>
              </Col>
              <Col xs={24} sm={8}>
                <div className="shadow-card-square mb-15 pv-20 text-center">
                  <div className="fw-700 c-text-gray">Completed</div>
                  <div className="fw-300 fs-40">{ getCountByStatus(ContractStatus.COMPLETED) }</div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        { renderContracts() }
        { renderConfirmRemoveModal() }
        { renderNewContractModal() }
        { renderFilterModal() }
        { renderConfirmRevertContractModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ContractsPage;
