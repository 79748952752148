import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu } from 'antd';
import { getQuestionnaires, getQuestionnaireTemplates, deleteQuestionnaire, createQuestionnaire, getQuestionnaireTemplate } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import { EditOutlined, DeleteOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import { 
  renderInputField,
  renderSearchSelectField
} from '../../../components/formFields'
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BsArrowRight } from "react-icons/bs";
import emptyStateImage from '../../../images/empty-state-icon.png';
import { isEmpty } from "lodash";
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const QuestionnairesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState({});

  useDocumentTitle("Questionnaires")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {

    try {
      const questionResults = await sendRequest(getQuestionnaires());
      setQuestionnaires(questionResults);

      const templateResults = await sendRequest(getQuestionnaireTemplates("active"));
      var templateOptions = []
      const blankOption = {
        value: "blank",
        text: "Blank Questionnaire"
      }
      templateOptions.push(blankOption);
      for (const template of templateResults) {
        const option = {
          value: template.questionnaire_template_id,
          text: template.questionnaire_name
        }
        templateOptions.push(option);
      }
      setTemplates(templateOptions);

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handlePreview(item)}>
            <EyeOutlined style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDuplicate(item)}>
            <CopyOutlined style={{ marginRight: 8}}/> Duplicate
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  
  const onNewClick = () => {
    form.setFieldsValue({ name: "", template_id: "blank" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleEdit = (questionnaire) => {
    navigate(`/admin/questionnaires/${questionnaire.questionnaire_id}/edit`)
  };

  const handlePreview = (questionnaire) => {
    navigate(`/admin/questionnaires/${questionnaire.questionnaire_id}/preview`)
  };

  const handleDuplicate = async (questionnaire) => {
    try {
      const body = {
        questionnaire_name: `Copy of ${questionnaire.questionnaire_name}`,
        internal_questionnaire_name: "",
        sections: questionnaire.sections
      }
      const results = await sendRequest(createQuestionnaire(body))
      navigate(`/admin/questionnaires/${results.questionnaire_id}/edit`)
    } catch {}
  };

  const handleDelete = (questionnaire) => {
    setSelectedQuestionnaire(questionnaire)
    setConfirmRemoveVisible(true)
  };

  const confirmDelete = async () => {
    try {
      const deleteResponse = await sendRequest(deleteQuestionnaire(selectedQuestionnaire.questionnaire_id));
      if (deleteResponse.status == "in-use") {
        setConfirmRemoveVisible(false)
        setRemoveErrorModalVisible(true)
      } else {
        await refreshPage()
        setConfirmRemoveVisible(false)
      }
    } catch(error) {
      setConfirmRemoveVisible(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      var sections = []
      if (values.template_id != "blank") {
        const templateResults = await sendRequest(getQuestionnaireTemplate(values.template_id));
        sections = templateResults.sections;
      }
      const body = {
        questionnaire_name: values.name,
        internal_questionnaire_name: "",
        sections: sections
      }
      const results = await sendRequest(createQuestionnaire(body))
      navigate(`/admin/questionnaires/${results.questionnaire_id}/edit`)
    } catch {
      setNewModalVisible(false);
    }
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Questionnaires</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/questionnaires")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { questionnaires.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Questionnaire</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderQuestionnaire = (questionnaire, index) => {
    const internalName = !isEmpty(questionnaire.internal_questionnaire_name) ? ` (${questionnaire.internal_questionnaire_name})` : ""
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleEdit(questionnaire)}>
          <div className="card-icon"><HiOutlineClipboardList/></div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15" onClick={() => handleEdit(questionnaire)}>
          <div className="fs-14 fw-700">{questionnaire.questionnaire_name} <span className="fs-14 fw-500 c-text-gray">{internalName}</span></div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{questionnaire.sections.length} { questionnaire.sections.length == 1 ? "section" : "sections" }</div>
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={menu(questionnaire)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderQuestionnaireBox = () => {
    return (
      <div className="shadow-card">
        { questionnaires.map((x,i) => renderQuestionnaire(x,i)) }
      </div>
    )
  }

  const renderQuestionnaires = () => {

    if (questionnaires.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No questionnaires yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a questionnaire to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Questionnaire</button>
        </div>
      )
    } else {
      return renderQuestionnaireBox()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderQuestionnaires() }
        { renderModal() }
        { renderConfirmRemoveModal() }
        { renderRemoveErrorModal() }
      </FloatingContainer>
    )
  }

  const renderModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Questionnaire</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-questionnaire" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Name", "name", true)}
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Template", "template_id", "Select a template", templates, true) }
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Questionnaire</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedQuestionnaire.questionnaire_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Remove Questionnaire</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Questionnaire</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center"><span className="fw-700">{selectedQuestionnaire.questionnaire_name}</span> is currently being used and cannot be removed.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default QuestionnairesPage;
