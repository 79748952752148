import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import moment from "moment";
import qs from "qs";
import uniqid from 'uniqid';
import { Row, Col, Modal, Button, Menu, Dropdown, Form, Select, notification, Tooltip, Image, Collapse, Grid } from 'antd';
import { 
  getEvent, 
  sendClientInvitation, 
  getQuestionnaires, 
  updateEvent, 
  getAdminUsers,
  getAccountAdminEventRoles,
  createEventEmployee,
  updateEventEmployee,
  deleteEventEmployee,
  getAccountClientEventRoles,
  getClients,
  assignEventClient,
  createEventClient,
  updateEventClient,
  deleteEventClient,
  getEventWorkflowSteps,
  getWorkflows,
  assignEventWorkflow,
  deleteEventWorkflowStep,
  updateEventTaskCompleted,
  createEventWorkflowStep,
  updateEventWorkflowStep,
  assignEventVenue,
  createEventVenue,
  deleteEventVenue,
  getVenues,
  getV2EventSpecialEvents,
  getEventGenres,
  getSystemGenres,
  getEventFinancialSummary,
  getEventMetadata,
  upsertEventMetadata,
  getAccountEventTypes
} from '../../../api';
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { UserOutlined, DeleteOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdArrowForwardIos, MdOutlineMoreHoriz, MdOutlineEdit, MdCheck, MdInfoOutline, MdOutlineKeyboardArrowDown, MdOutlineLock } from "react-icons/md";
import { FiPlus, FiArrowDown } from "react-icons/fi";
import { cloneDeep, isEmpty, isNull, isUndefined, isArray, startsWith } from "lodash";
import { 
  renderInputField,
  renderDateField,
  renderTimeField,
  renderSearchSelectField,
  renderTextAreaField,
  renderNumberField,
  renderCheckboxField,
  renderPhoneField,
  renderEmailField,
  renderFormLabel,
  renderCurrencyField,
  renderGlobalAddressField,
  renderGlobalSecondAddressField,
  renderGlobalCityField,
  renderGlobalStateField,
  renderGlobalZipField
} from '../../../components/formFields'
import emptyStateImage from '../../../images/empty-document-icon.png';
import { getActionTypeDescription, sortSteps } from '../../../helpers/workflowHelper';
import WorkflowTaskModal from '../../../components/workflowTaskModal';
import AppContext from '../../../app/context';
import EventPlanningTab from './eventSections/eventPlanningTab';
import EventServicesTab from './eventSections/eventServicesTab';
import EventFinancialsTab from './eventSections/eventFinancialsTab';
import EventDocumentsTab from './eventSections/eventDocumentsTab';
import Permissions from '../../../constants/permissions';
import EventPermissions from '../../../constants/eventPermissions';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';
import { hasPermission } from '../../../helpers/permissionHelper';
import { getNumericCurrency, formatCurrencyString } from '../../../helpers/contractHelper';
import { formatDateTimeLong, formatTime, formatEventDateLong, formatDateShort } from '../../../helpers/dateHelper';
import { getLockdownDate } from '../../../helpers/eventHelper';
import { 
  getDefaultCountryCode, 
  setDefaultCountryCode, 
  getDefaultState, 
  getCountryOptions, 
  getDefaultPhoneCountryCode,
  getFormattedPhoneNumber,
  getPhoneNumber
} from '../../../helpers/addressHelper';

const EventPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [playlists, setPlaylists] = useState([]);
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedTab, setSelectedTab] = useState("overview");
  const [selectedPlanningTab, setSelectedPlanningTab] = useState("music");
  const [questionnaires, setQuestionnaires] = useState([]);
  const [eventQuestionnaires, setEventQuestionnaires] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [adminUsers, setAdminUsers] = useState([]);
  const [adminEventRoles, setAdminEventRoles] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [clientEventRoles, setClientEventRoles] = useState([]);
  const [eventWorkflowSteps, setEventWorkflowSteps] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState();
  const [selectedStep, setSelectedStep] = useState({});
  const [isNewStep, setIsNewStep] = useState(false);
  const [selectedClientTab, setSelectedClientTab] = useState("new");
  const [venue, setVenue] = useState({});
  const [selectedVenueTab, setSelectedVenueTab] = useState("new");
  const [venues, setVenues] = useState([]);
  const [isImageVisible, setImageVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [eventGenres, setEventGenres] = useState([]);
  const [systemGenres, setSystemGenres] = useState([]);
  const [eventContracts, setEventContracts] = useState([]);
  const [eventPackages, setEventPackages] = useState([]);
  const [eventAddOns, setEventAddOns] = useState([]);
  const [payments, setPayments] = useState([]);
  const [eventFinancials, setEventFinancials] = useState({});
  const [eventFinancialSummary, setEventFinancialSummary] = useState({});
  const [eventInvoices, setEventInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoiceTotals, setInvoiceTotals] = useState({});
  const [eventMetadata, setEventMetadata] = useState({});
  const [eventTimeline, setEventTimeline] = useState({});
  const [eventTypes, setEventTypes] = useState([]);
  const [primaryTabItems, setPrimaryTabItems] = useState([]);
  const [countries, setCountries] = useState([]);
  const [venueCountryCode, setVenueCountryCode] = useState("US");
  const [venuePhoneCountryCode, setVenuePhoneCountryCode] = useState("US");
  const [clientCountryCode, setClientCountryCode] = useState("US");
  const [clientPhoneCountryCode, setClientPhoneCountryCode] = useState("US");
  const [staffClientVisible, setStaffClientVisible] = useState(false);
  const [isNewInvoicesEnabled, setNewInvoicesEnabled] = useState(false);
  const [isNewInvoicesDisplayed, setNewInvoicesDisplayed] = useState(false);

  // Modals
  const [isConfirmInvitationModalVisible, setConfirmInvitationModalVisible] = useState(false);
  const [isConfirmRemoveEmployeeModalVisible, setConfirmRemoveEmployeeModalVisible] = useState(false);
  const [isConfirmRemoveClientModalVisible, setConfirmRemoveClientModalVisible] = useState(false);
  const [isEditEventModalVisible, setEditEventModalVisible] = useState(false);
  const [isEmployeeSelectionModalVisible, setEmployeeSelectionModalVisible] = useState(false);
  const [isAssignWorkflowModalVisible, setAssignWorkflowModalVisible] = useState(false);
  const [isWorkflowTaskModalVisible, setWorkflowTaskModalVisible] = useState(false);
  const [isClientSelectionModalVisible, setIsClientSelectionModalVisible] = useState(false);
  const [isVenueSelectionModalVisible, setVenueSelectionModalVisible] = useState(false);
  const [isUpdateClientRoleModalVisible, setUpdateClientRoleModalVisible] = useState(false);
  const [isUpdateEmployeeRoleModalVisible, setUpdateEmployeeRoleModalVisible] = useState(false);
  const [isClientDetailsModalVisible, setClientDetailsModalVisible] = useState(false);

  const [specialEventSections, setSpecialEventSections] = useState([]);

  useDocumentTitle("Event Details")
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { auth, setAuth } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const user = auth.user ? auth.user : {}
  const canEditFinancials = hasPermission(user, Permissions.EDIT_EVENT_FINANCIALS);
  const canViewFinancials = canEditFinancials || hasPermission(user, Permissions.VIEW_EVENT_FINANCIALS);
  
  const [editEventForm] = Form.useForm();
  const [employeeForm] = Form.useForm();
  const [newClientForm] = Form.useForm();
  const [existingClientForm] = Form.useForm();
  const [newVenueForm] = Form.useForm();
  const [existingVenueForm] = Form.useForm();
  const [clientRoleForm] = Form.useForm();
  const [employeeRoleForm] = Form.useForm();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const [accountSettings] = useAccountSettings()
  const [hasBetaFeature] = useBetaFeatures()

  const planningTabItems = [
    { name: "Music", key: "music" },
    { name: "Timeline", key: "timeline" },
    { name: "Questionnaires", key: "questionnaires" },
    { name: "Event Template", key: "event-template" }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.tab) {
      setSelectedTab(queryStrings.tab)
    }
    if (queryStrings.section) {
      setSelectedPlanningTab(queryStrings.section)
    }
    if (params.id) {
      refreshPage();
    } else {
      setLoading(false)
    }
    var primaryTabs = [
      { name: "Overview", key: "overview" },
      { name: "Services", key: "services" },
      { name: "Financials", key: "financials" },
      { name: "Documents", key: "documents" },
      { name: "Planning", key: "planning" },
      { name: "Workflow", key: "workflow" }
    ]
    if (!canViewFinancials) {
      primaryTabs = primaryTabs.filter(x => x.key != "financials")
    }
    setPrimaryTabItems(primaryTabs)
  }, []);

  const refreshPage = async () => {

    try {
      const eventResults = await sendRequest(getEvent(params.id, "detail"));
      if (isEmpty(eventResults)) {
        navigate(`/admin/events`)
        return
      }
      setEvent(eventResults);
      setPlaylists(eventResults.playlists)
      setEventQuestionnaires(eventResults.questionnaires)
      setEventTimeline(eventResults.timeline.content ?? [])
      setClients(eventResults.clients ?? [])
      setEmployees(eventResults.employees ?? [])
      setSpecialEventSections(eventResults.special_event_sections ?? [])
      setEventWorkflowSteps(sortSteps(eventResults.workflow_steps ?? []));
      setEventGenres(eventResults.genres ?? [])
      setEventMetadata(eventResults.metadata)
      if (!isNull(eventResults.venue)) {
        setVenue(eventResults.venue)
      }

      await refreshFinancials()

      const questionnaireResults = await sendRequest(getQuestionnaires());
      setQuestionnaires(questionnaireResults);

      const systemGenresResults = await sendRequest(getSystemGenres())
      setSystemGenres(systemGenresResults)

      const betaResults = await hasBetaFeature(BetaFeatures.INVOICES)
      setNewInvoicesEnabled(FeatureFlags.INVOICES || betaResults)

      loadCountries()
    } finally {
      setLoading(false)
    }
  }

  const refreshFinancials = async () => {
    try {
      const results = await sendRequest(getEventFinancialSummary(params.id))
      const oldInvoices = results.invoices ?? []
      const newInvoices = results.invoices_v2 ?? []
      setEventFinancialSummary(results.summary)
      setEventContracts(results.contracts)
      setEventInvoices(oldInvoices)
      setInvoices(newInvoices)
      setEventPackages(results.packages)
      setEventAddOns(results.add_ons)
      setPayments(results.payments)
      setEventFinancials(results.financials)
      setNewInvoicesDisplayed(newInvoices.length > 0 || oldInvoices.length == 0)
      setInvoiceTotals(results.totals)      
    } catch {}
  }
  
  const refreshSpecialEvents = async () => {
    try {
      const eventSpecialEventsResults = await sendRequest(getV2EventSpecialEvents(params.id));
      setSpecialEventSections(eventSpecialEventsResults)
    } catch {}
  }

  const refreshEventGenres = async () => {
    try {
      const eventGenresResults = await sendRequest(getEventGenres(params.id));
      setEventGenres(eventGenresResults.genres ?? [])
    } catch {}
  }

  const refreshEventMetadata = async () => {
    try {
      const eventMetadataResults = await sendRequest(getEventMetadata(params.id))
      setEventMetadata(eventMetadataResults)
    } catch {}
  }

  const loadVenues = async () => {
    try {
      const venueResults = await sendRequest(getVenues());
      const venueData = venueResults.map((venue) => {
        return {
          value: venue.venue_id,
          text: `${venue.venue_name} (${venue.city}, ${venue.state})`
        }
      })
      setVenues(venueData);

    } catch {}
  }

  const loadCountries = async () => {
    const data = getCountryOptions()
    setCountries(data);
  }

  const setClientStateField = async () => {
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      newClientForm.setFieldsValue({ state: state })
    }
  }

  const setVenueStateField = async () => {
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      newVenueForm.setFieldsValue({ state: state })
    }
  }

  const onClientCountryChange = (value) => {
    setClientCountryCode(value)
    setDefaultCountryCode(value)
    setClientStateField()
  }

  const onVenueCountryChange = (value) => {
    setVenueCountryCode(value)
    setDefaultCountryCode(value)
    setVenueStateField()
  }

  const onAssignWorkflow = async () => {
    setAssignWorkflowModalVisible(true)
    try {
      if (workflows.length == 0) {
        const workflowsResults = await sendRequest(getWorkflows());
        setWorkflows(workflowsResults);
      }
    } catch {}
  }

  const handleAssignWorkflow = async () => {
    try {
      if (selectedWorkflow) {
        await sendRequest(assignEventWorkflow(params.id, selectedWorkflow));

        const eventWorkflowStepsResults = await sendRequest(getEventWorkflowSteps(params.id));
        setEventWorkflowSteps(sortSteps(eventWorkflowStepsResults));
      }
      setAssignWorkflowModalVisible(false)
    } catch {}
  }

  const handleRemoveWorkflowStep = async (step) => {
    try {
      await sendRequest(deleteEventWorkflowStep(params.id, step.event_workflow_step_id));

      const eventWorkflowStepsResults = await sendRequest(getEventWorkflowSteps(params.id));
      setEventWorkflowSteps(sortSteps(eventWorkflowStepsResults));
    } catch {}
  }

  const handleSaveWorkflowTask = async () => {
    try {
      if (isNewStep) {
        await sendRequest(createEventWorkflowStep(params.id, selectedStep));
      } else {
        await sendRequest(updateEventWorkflowStep(params.id, selectedStep.event_workflow_step_id, selectedStep));
      }

      const eventWorkflowStepsResults = await sendRequest(getEventWorkflowSteps(params.id));
      setEventWorkflowSteps(sortSteps(eventWorkflowStepsResults));

      setWorkflowTaskModalVisible(false)
    } catch {}
  }

  const onAddWorkflowTask = () => {
    // if (adminUsers.length == 0) {
    //   sendRequest(getAdminUsers()).then(response => {
    //     setAdminUsers(response)
    //   })
    // }
    setIsNewStep(true)
    const newStep = {
      event_workflow_step_id: uniqid(),
      time_unit: "days",
      time_period: "before_event",
      metadata: {},
      assigned_to: 1
    }
    setSelectedStep(newStep)
    setWorkflowTaskModalVisible(true)
  }

  const onEditWorkflowTask = (task) => {
    // if (adminUsers.length == 0) {
    //   sendRequest(getAdminUsers()).then(response => {
    //     setAdminUsers(response)
    //   })
    // }
    setIsNewStep(false)
    setSelectedStep(task)
    setWorkflowTaskModalVisible(true)
  }

  const toggleCompleteWorkflowStep = async (task) => {
    try {
      await sendRequest(updateEventTaskCompleted(params.id, task.event_workflow_step_id, { completed: !task.completed }));

      const eventWorkflowStepsResults = await sendRequest(getEventWorkflowSteps(params.id));
      setEventWorkflowSteps(sortSteps(eventWorkflowStepsResults));
    } catch (error) {

    }
  }

  const onSendInvitation = (client) => {
    setConfirmInvitationModalVisible(true)
    setSelectedClient(client)
  }

  const sendInvitation = () => {
    setConfirmInvitationModalVisible(false)
    sendRequest(sendClientInvitation({ email: selectedClient.email })).then((response) => {
      refreshPage()
    })
  }

  const onEditEvent = async () => {
    
    if (eventTypes.length == 0) {
      const eventTypeResults = await sendRequest(getAccountEventTypes());
      const eventTypeData = eventTypeResults.map((eventType) => {
        return {
          value: eventType.event_type_id,
          text: eventType.event_type_name
        }
      })
      setEventTypes(eventTypeData);
    }
    
    setEditEventModalVisible(true)
    const eventFormData = {
      event_name: event.event_name,
      account_event_type_id: event.account_event_type_id,
      event_date: moment(event.event_date),
      arrival_time: !isEmpty(event.arrival_time) ? moment(event.arrival_time, "H:mm") : null,
      start_time: !isEmpty(event.start_time) ? moment(event.start_time, "H:mm") : null,
      end_time: !isEmpty(event.end_time) ? moment(event.end_time, "H:mm") : null,
      internal_notes: event.internal_notes,
      lockdown_days: event.lockdown_days
    }
    editEventForm.setFieldsValue(eventFormData)
  }

  const onEditEventSubmit = (values) => {

    const body = {
      'event_name': values['event_name'],
      'account_event_type_id': values['account_event_type_id'],
      'event_date': formatDateForDB(values['event_date']),
      'arrival_time': formatTimeForDB(values['arrival_time']),
      'start_time': formatTimeForDB(values['start_time']),
      'end_time': formatTimeForDB(values['end_time']),
      'internal_notes': values['internal_notes'],
      'lockdown_days': values['lockdown_days'],
    }

    sendRequest(updateEvent(event.event_id, body)).then(response => {
      notification.success({
        message: 'Success!',
        description: 'Your changes have been saved.',
        duration: 2
      });
      setEditEventModalVisible(false)
      refreshPage()
    }).catch(() =>{})
  }

  const onAddVenue = () => {
    if (venues.length == 0) {
      loadVenues()
    }
    newVenueForm.resetFields()
    existingVenueForm.resetFields()
    setVenueSelectionModalVisible(true)

    // Default country codes
    const defaultCountryCode = getDefaultCountryCode()
    const countryCode = !isEmpty(defaultCountryCode) ? defaultCountryCode : "US"
    setVenueCountryCode(countryCode)
    setVenuePhoneCountryCode(getDefaultPhoneCountryCode())
    newVenueForm.setFieldsValue({ country: countryCode })

    setVenueStateField()
  }

  const formatDateForDB = (date) => {
    return date.format("YYYY-MM-DD")
  }

  const formatTimeForDB = (date) => {
    if (!isEmpty(date)) {
      return date.format("H:mm")
    }
    return null;
  }

  const getUserPhoneNumber = (user) => {
    if (user.phone_numbers && user.phone_numbers.length > 0) {
      return user.phone_numbers[0].phone_number
    }
    return "";
  }

  // Staff
  const onAddEmployee = () => {
    if (adminUsers.length == 0) {
      sendRequest(getAdminUsers()).then(response => {
        setAdminUsers(response)
      })
    }
    fetchAdminEventRolesIfNeeded()
    employeeForm.resetFields()
    employeeForm.setFieldsValue({ client_visible: true, display_contact_info: true })
    setStaffClientVisible(true)
    setEmployeeSelectionModalVisible(true)
  }

  const fetchAdminEventRolesIfNeeded = () => {
    if (adminEventRoles.length == 0) {
      sendRequest(getAccountAdminEventRoles()).then(response => {
        const adminEventRolesData = response.map((role) => {
          return {
            value: role.admin_event_role_id,
            text: role.role_name
          }
        })
        setAdminEventRoles(adminEventRolesData)
      })
    }
  }

  const onEmployeeSubmit = (values) => {
    const newSelectedEmployees = cloneDeep(employees);
    const employee = newSelectedEmployees.find((x) => x.user_id == values.user_id)
    if (isUndefined(employee)) {
      const body = {
        user_id: values.user_id,
        admin_event_role_id: values.admin_event_role_id,
        wage: getNumericCurrency(values["wage"]),
        client_visible: values.client_visible ?? false,
        display_contact_info: values.display_contact_info ?? false,
      }
      sendRequest(createEventEmployee(event.event_id, body)).then(response => {
        setEmployeeSelectionModalVisible(false)
        refreshPage()
      }).catch(() => setEmployeeSelectionModalVisible(false))
    } else {
      setEmployeeSelectionModalVisible(false)
    }
  }

  const handleRemoveEmployee = (employee) => {
    setSelectedEmployee(employee)
    setConfirmRemoveEmployeeModalVisible(true)
  }

  const handleEditEmployeeRole = (employee) => {
    setSelectedEmployee(employee)
    fetchAdminEventRolesIfNeeded()
    setUpdateEmployeeRoleModalVisible(true)
    setStaffClientVisible(employee.client_visible)
    const formData = {
      admin_event_role_id: employee.admin_event_role_id,
      wage: isNull(employee.wage) ? 0 : employee.wage,
      client_visible: employee.client_visible,
      display_contact_info: employee.display_contact_info
    }
    employeeRoleForm.setFieldsValue(formData)
  }

  const deleteEmployee = () => {
    setConfirmRemoveEmployeeModalVisible(false)
    sendRequest(deleteEventEmployee(event.event_id, selectedEmployee.user_id)).then(() => {
      refreshPage()
    })
  }

  const onEditEmployeeRoleSubmit = async (values) => {
    try {
      const body = {
        ...values,
        client_visible: values.client_visible ?? false,
        display_contact_info: values.display_contact_info ?? false,
        wage: getNumericCurrency(values["wage"])
      }
      await sendRequest(updateEventEmployee(event.event_id, selectedEmployee.user_id, body))
      await refreshPage()
      setUpdateEmployeeRoleModalVisible(false)
    } catch(error) {
      setUpdateEmployeeRoleModalVisible(false)
    }
 }

  // Clients
  const onAddClient = () => {
    if (clientUsers.length == 0) {
      sendRequest(getClients()).then(response => {
        setClientUsers(response)
      })
    }
    fetchClientEventRolesIfNeeded()
    newClientForm.resetFields()
    existingClientForm.resetFields()
    const permissionsValues = {
      view_music: true,
      edit_music: true,
      view_questionnaires: true,
      edit_questionnaires: true,
      view_timeline: true,
      edit_timeline: true,
      view_financials: true,
      view_services: true,
      view_documents: true
    }
    newClientForm.setFieldsValue({ ...permissionsValues, send_invitation: true })
    existingClientForm.setFieldsValue(permissionsValues)

    // Default country codes
    const defaultCountryCode = getDefaultCountryCode()
    const countryCode = !isEmpty(defaultCountryCode) ? defaultCountryCode : "US"
    setClientCountryCode(countryCode)
    setClientPhoneCountryCode(getDefaultPhoneCountryCode())
    newClientForm.setFieldsValue({ country: countryCode })

    setClientStateField()

    setSelectedClientTab("new")
    setIsClientSelectionModalVisible(true)
  }

  const fetchClientEventRolesIfNeeded = () => {
    if (clientEventRoles.length == 0) {
      sendRequest(getAccountClientEventRoles()).then(response => {
        const clientEventRolesData = response.map((role) => {
          return {
            value: role.client_event_role_id,
            text: role.role_name
          }
        })
        setClientEventRoles(clientEventRolesData)
      })
    }
  }

  const onClientSelect = (id) => {
    if (selectedClientTab == "new") {
      newClientForm.submit()
    } else {
      existingClientForm.submit()
    }
  }

  const buildPermissionsArray = (values) => {
    var permissionsArray = []
      if (values["view_music"]) {
        permissionsArray.push(EventPermissions.VIEW_MUSIC)
      }
      if (values["edit_music"]) {
        permissionsArray.push(EventPermissions.EDIT_MUSIC)
      }
      if (values["view_questionnaires"]) {
        permissionsArray.push(EventPermissions.VIEW_QUESTIONNAIRES)
      }
      if (values["edit_questionnaires"]) {
        permissionsArray.push(EventPermissions.EDIT_QUESTIONNAIRES)
      }
      if (values["view_timeline"]) {
        permissionsArray.push(EventPermissions.VIEW_TIMELINE)
      }
      if (values["edit_timeline"]) {
        permissionsArray.push(EventPermissions.EDIT_TIMELINE)
      }
      if (values["view_financials"]) {
        permissionsArray.push(EventPermissions.VIEW_FINANCIALS)
      }
      if (values["view_services"]) {
        permissionsArray.push(EventPermissions.VIEW_SERVICES)
      }
      if (values["view_documents"]) {
        permissionsArray.push(EventPermissions.VIEW_DOCUMENTS)
      }
      return permissionsArray
  }

  const onNewClientSubmit = async (values) => {
    try {
      // Make sure client isn't already added
      const newClients = cloneDeep(clients);
      const client = newClients.find((x) => x.email == values.email)
      if (isUndefined(client)) {
        const body = {
          client_event_role_id: values["client_event_role_id"],
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          phone: getPhoneNumber(values["phone"], clientPhoneCountryCode),
          permissions: buildPermissionsArray(values),
          send_invitation: values["send_invitation"],
          country: values["country"],
          line_1: values["line_1"],
          line_2: values["line_2"],
          city: values["city"],
          state: values["state"],
          zip: values["zip"]
        }
        const results = await sendRequest(createEventClient(event.event_id, body))
        if (results.status == "email-in-use") {
          notification.error({
            message: 'Error!',
            description: 'A user with this email address already exists.',
            duration: 2
          });
          return
        }
        await refreshPage()
      }
      setIsClientSelectionModalVisible(false)
    } catch(error) {
      setIsClientSelectionModalVisible(false)
    }
 }

 const onExistingClientSubmit = async (values) => {
    try {
      // Make sure client isn't already added
      const newClients = cloneDeep(clients);
      const client = newClients.find((x) => x.user_id == values.user_id)
      if (isUndefined(client)) {
        const body = {
          client_event_role_id: values["client_event_role_id"],
          permissions: buildPermissionsArray(values)
        }
        await sendRequest(assignEventClient(event.event_id, values.user_id, body))
        await refreshPage()
      }
      setIsClientSelectionModalVisible(false)
    } catch(error) {
      setIsClientSelectionModalVisible(false)
    }
 }

  const handleRemoveClient = (client) => {
    setSelectedClient(client)
    setConfirmRemoveClientModalVisible(true)
  }

  const handleEditClientRole = (client) => {
    setSelectedClient(client)
    fetchClientEventRolesIfNeeded()
    setUpdateClientRoleModalVisible(true)
    const permissions = isArray(client.permissions) ? client.permissions : []
    const formData = {
      client_event_role_id: client.client_event_role_id,
      view_music: permissions.includes(EventPermissions.VIEW_MUSIC),
      edit_music: permissions.includes(EventPermissions.EDIT_MUSIC),
      view_questionnaires: permissions.includes(EventPermissions.VIEW_QUESTIONNAIRES),
      edit_questionnaires: permissions.includes(EventPermissions.EDIT_QUESTIONNAIRES),
      view_timeline: permissions.includes(EventPermissions.VIEW_TIMELINE),
      edit_timeline: permissions.includes(EventPermissions.EDIT_TIMELINE),
      view_financials: permissions.includes(EventPermissions.VIEW_FINANCIALS),
      view_services: permissions.includes(EventPermissions.VIEW_SERVICES),
      view_documents: permissions.includes(EventPermissions.VIEW_DOCUMENTS)
    }
    clientRoleForm.setFieldsValue(formData)
  }

  const handleViewClientDetails = (client) => {
    setSelectedClient(client)
    setClientDetailsModalVisible(true)
  }

  const deleteClient = () => {
    setConfirmRemoveClientModalVisible(false)
    sendRequest(deleteEventClient(event.event_id, selectedClient.user_id)).then(() => {
      refreshPage()
    })
  }

  const onEditClientRoleSubmit = async (values) => {
    try {
      const body = {
        client_event_role_id: values.client_event_role_id,
        permissions: buildPermissionsArray(values)
      }
      await sendRequest(updateEventClient(event.event_id, selectedClient.user_id, body))
      await refreshPage()
      setUpdateClientRoleModalVisible(false)
      notification.success({
        message: 'Success!',
        description: 'Your updates have been saved.',
        duration: 2
      });
    } catch(error) {
      setUpdateClientRoleModalVisible(false)
    }
 }

  const onVenueSelect = () => {
    if (selectedVenueTab == "new") {
      newVenueForm.submit()
    } else {
      existingVenueForm.submit()
    }
  }
  
  const onNewVenueSubmit = async (values) => {
    try {
      const body = {
        ...values,
        one_time: values['one_time'] ?? false,
        contact_phone: getPhoneNumber(values['contact_phone'], venuePhoneCountryCode),
      }
      await sendRequest(createEventVenue(event.event_id, body))
      await refreshPage()
      setVenueSelectionModalVisible(false)
    } catch(error) {
      setVenueSelectionModalVisible(false)
    }
 }

  const onExistingVenueSubmit = async (values) => {
    try {
      await sendRequest(assignEventVenue(event.event_id, values.venue_id))
      await refreshPage()
      setVenueSelectionModalVisible(false)
    } catch(error) {
      setVenueSelectionModalVisible(false)
    }
  }

  const deleteVenue = async () => {
    try {
      await sendRequest(deleteEventVenue(event.event_id, venue.venue_id))
      await refreshPage()
      setVenueSelectionModalVisible(false)
    } catch(error) {
      setVenueSelectionModalVisible(false)
    }
  }

  const displayPhoto = (photo) => {
    setSelectedImage(photo)
    setImageVisible(true)
  }

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("admin-content--body-main")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("admin-content--body-main")[0].scrollTop
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("admin-content--body-main")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const noThanksSendContract = async () => {
    try {
      await sendRequest(upsertEventMetadata(event.event_id, { contract_next_steps: true }))
      await refreshEventMetadata()
    } catch {}
  }

  const onStaffVisibleToClientChange = (e) => {
    setStaffClientVisible(e.target.checked)
    employeeForm.setFieldsValue({ display_contact_info: e.target.checked })
    employeeRoleForm.setFieldsValue({ display_contact_info: e.target.checked })
  }

  const getWebsite = (website) => {
    if (startsWith(website, "http")) {
      return website
    }
    return `https://${website}`
  }

  const clientMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleViewClientDetails(item)}>
            <UserOutlined style={{ marginRight: 8}}/> View Client Details
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/clients/${item.user_id}/edit`)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> View / Edit Profile
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleEditClientRole(item)}>
            <MdOutlineLock style={{ marginRight: 8}}/> Edit Permissions
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleRemoveClient(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove from Event
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const employeeMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/staff/${item.user_id}/edit`)}>
            <UserOutlined style={{ marginRight: 8}}/> View Profile
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleEditEmployeeRole(item)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Edit Details
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleRemoveEmployee(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove from Event
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const workflowStepMenu = (item, index) => {
    return (
      <Menu>
        { !item.completed && (
          <Menu.Item>
            <div onClick={() => toggleCompleteWorkflowStep(item)}>
              <MdCheck style={{ marginRight: 8}}/> Mark as Complete
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => onEditWorkflowTask(item)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Edit Task
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleRemoveWorkflowStep(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Task
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const venueMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/venues/${venue.venue_id}/edit`)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> View Venue
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => deleteVenue()}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove from Event
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const planningTabMenu = () => {
    return (
      <Menu>
        { planningTabItems.map((x,i) => (
          <Menu.Item key={i}>
            <div onClick={() => onSelectPlanningTab(x.key)}>
              { x.name }
            </div>
          </Menu.Item>
        ))}
      </Menu>
    )
  };

  const mobileTabMenu = () => {
    return (
      <Menu>
        { primaryTabItems.map((x,i) => (
          <Menu.Item key={i}>
            <div onClick={() => onSelectTab(x.key)}>
              { x.name }
            </div>
          </Menu.Item>
        ))}
      </Menu>
    )
  };

  const renderClientCard = (client, index) => {
    var lastLogin = null
    var invitationStatus = null
    var lastInvitationSent = null
    var appDownload = client.login_with_app ? "Yes" : "No"
    var showInvitationButton = true
    var invitationButtonText = ""

    if (client.last_login_datetime) {
      lastLogin = formatDateTimeLong(client.last_login_datetime, accountSettings)
      invitationStatus = "Accepted"
      showInvitationButton = false
    } else if (client.last_invitation_datetime) {
      lastLogin = "N/A"
      invitationStatus = "Pending"
      lastInvitationSent = formatDateTimeLong(client.last_invitation_datetime, accountSettings)
      showInvitationButton = true
      invitationButtonText = "Re-send Invitation"
    } else {
      lastLogin = "N/A"
      invitationStatus = "Not Sent"
      showInvitationButton = true
      invitationButtonText = "Send Invitation"
    }
    var clientPhoto = null;
    if (client.photo) {
      clientPhoto = client.photo;
    } else if (!isNull(client.accounts) && client.accounts.length > 0) {
      const clientAccounts = client.accounts.filter(x => x.account_id == auth.user.account_id)
      if (clientAccounts.length > 0) {
        clientPhoto = clientAccounts[0].internal_photo
      }
    }
    const phoneNumber = getFormattedPhoneNumber(getUserPhoneNumber(client));
    return (
      <div className="b-border p-20" key={index}>
        <Row  gutter={[15,15]} align="middle">
          <Col>
            { clientPhoto ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}} onClick={() => displayPhoto(clientPhoto)}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><UserOutlined/></div>
              </div>
            )}
          </Col>
          <Col flex={1} style={{ lineHeight: 1.4 }}>
            <div className="fs-14 fw-700">{client.first_name} {client.last_name} <span className="fs-14 fw-500 c-text-gray">({client.role_name})</span></div>
            <div>
              <a className="fs-14 fw-500 blue-link" href={"mailto:" + client.email}>{client.email}</a>
            </div>
            { !isEmpty(phoneNumber) && (
              <div>
                <a className="fs-14 fw-500 blue-link" href={"tel:" + getUserPhoneNumber(client)}>{phoneNumber}</a>
              </div>
            )}
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={clientMenu(client, index)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <div className="bg-gray radius-8 mt-15 p-10">
          <div className="fs-12 fw-700">Invitation Status: <span className="fw-500">{invitationStatus}</span></div>
          { lastInvitationSent && (
            <div className="fs-12 fw-700">Invitation Sent: <span className="fw-500">{lastInvitationSent}</span></div>
          )}
          <div className="fs-12 fw-700">Downloaded the app?: <span className="fw-500">{appDownload}</span></div>
          <div className="fs-12 fw-700">Last Login: <span className="fw-500">{lastLogin}</span></div>
        </div>
        { showInvitationButton && (
          <button className="small-primary-button mt-15" onClick={() => onSendInvitation(client)}>
            {invitationButtonText}
          </button>
        )}
      </div>
    )
  }

  const renderEmployeeCard = (employee, index) => {
    const phoneNumber = getFormattedPhoneNumber(getUserPhoneNumber(employee));
    const isCurrentUser = user.user_id == employee.user_id
    const showWage = !isNull(employee.wage) && employee.wage != 0 && (hasPermission(user, Permissions.VIEW_EVENT_FINANCIALS) || hasPermission(user, Permissions.EDIT_EVENT_FINANCIALS) || isCurrentUser)
    return (
      <div className="b-border p-20" key={index}>
        <Row  gutter={[15,15]} align="middle">
          <Col>
            { employee.photo ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${employee.photo})`}} onClick={() => displayPhoto(employee.photo)}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><UserOutlined/></div>
              </div>
            )}
          </Col>
          <Col flex={1} style={{ lineHeight: 1.4 }}>
            <div className="fs-14 fw-700">{employee.first_name} {employee.last_name} <span className="fs-14 fw-500 c-text-gray">({employee.role_name})</span></div>
            <div>
              <a className="fs-14 fw-500 blue-link" href={"mailto:" + employee.email}>{employee.email}</a>
            </div>
            { !isEmpty(phoneNumber) && (
              <div>
                <a className="fs-14 fw-500 blue-link" href={"tel:" + getUserPhoneNumber(employee)}>{phoneNumber}</a>
              </div>
            )}
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={employeeMenu(employee, index)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
          <Col xs={24}>
            <div className="bg-gray radius-8 p-10">
              <div className="fs-12 fw-700">Visble to client: <span className="fw-500">{employee.client_visible ? "Yes" : "No"} {employee.client_visible && !employee.display_contact_info ? "(hide contact info)" : ""}</span></div>
              { showWage && (
                <div className="fs-12 fw-700">Wage: <span className="fw-500">{formatCurrencyString(employee.wage, currency.code)}</span></div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderVenue = () => {
    const showDetailsSection = !!venue.contact_name || !!venue.contact_email || !!venue.contact_phone || !!venue.notes || !!venue.website || !!venue.wifi
    return (
      <div className="shadow-card mb-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Venue</div>
          </Col>
          <Col>
          { isEmpty(venue) && (
            <div className="plus-container mr-5" onClick={() => onAddVenue()}>
              <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
            </div>
          )}
          </Col>
        </Row>
        { !isEmpty(venue) && (
          <div className="p-20">
            <div className="flex-row">
              <div>
                <div className="display-flex">
                  <div className="user-profile-circle"><EnvironmentOutlined/></div>
                </div>
              </div>
              <div className="flex-col justify-content-center flex-1 mh-15">
                <div className="fs-14 fw-700 line-1-2">{venue.venue_name}</div>
                <div className="fs-14 fw-500 c-text-gray line-1-1 mt-2">{venue.address_line_1} {venue.address_line_2}</div>
                <div className="fs-14 fw-500 c-text-gray">{venue.city}, {venue.state} {venue.zip}</div>
              </div>
              <div className="display-flex flex-middle mr-5">
                <Dropdown overlay={venueMenu()} placement="bottomRight" trigger="click">
                  <div className="dots-container">
                    <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                  </div>
                </Dropdown>
              </div>
            </div>
            { showDetailsSection && (
              <div className="bg-gray radius-8 mt-15">
                <Collapse ghost>
                  <Collapse.Panel header={<div className="fs-14 fw-600">View Details</div>} key="1">
                    <Row gutter={[15,15]} className="t-border pt-15" style={{ marginTop: -12 }}>
                        <Col xs={24}>
                          <div className="fs-14 fw-700">Venue Contact</div>
                          {(venue.contact_name || venue.contact_email || venue.contact_phone) ? (
                            <>
                              <div className="fs-14">{ venue.contact_name }</div>
                              <div>
                                <a className="fs-14 fw-500 blue-link" href={"mailto:" + venue.contact_email}>{venue.contact_email}</a>
                              </div>
                              <div>
                                <a className="fs-14 fw-500 blue-link" href={"tel:" + venue.contact_phone}>{getFormattedPhoneNumber(venue.contact_phone)}</a>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="fs-14">--</div>
                            </>
                          )}
                        </Col>
                      { venue.website && (
                        <Col xs={24}>
                          <div className="fs-14 fw-700">Website</div>
                          <div>
                            <a className="fs-14 fw-500 blue-link" href={getWebsite(venue.website)} target="_blank">{venue.website}</a>
                          </div>
                        </Col>
                      )}
                      { venue.wifi && (
                        <Col xs={24}>
                          <div className="fs-14 fw-700">Wi-Fi</div>
                          <div className="fs-14">{ venue.wifi }</div>
                        </Col>
                      )}
                      { venue.notes && (
                        <Col xs={24}>
                          <div className="fs-14 fw-700">Notes</div>
                          <div className="fs-14">{ venue.notes }</div>
                        </Col>
                      )}
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </div>
            )}
          </div>
        )}
        { isEmpty(venue) && (
          <div className="ph-20 pv-30 text-center c-text-gray">No venue assigned</div>
        )}
      </div>
    )
  }

  const renderOverviewNextSteps = () => {
    const noContracts = eventContracts.length == 0;
    const hasClientsNotSent = clients.filter(x => isNull(x.last_invitation_datetime)).length > 0;
    const dontShowContractNextSteps = eventMetadata.contract_next_steps == true;

    if (noContracts && !dontShowContractNextSteps && hasClientsNotSent) {
      return (
        <Col xs={24} md={24}>
          <div className="shadow-card p-15 text-center">
              <div className="fw-700 mb-10 fs-16">Next Steps</div>
              <div className="bg-gray pv-20 ph-15 radius-8">
                <div className="mb-15">1. Create and send a contract for your client to sign.</div>
                <button className="small-primary-button" onClick={() => onSelectTab("documents")}>Create a Contract</button>
                <div className="mt-10">
                  <div className="blue-link" onClick={() => noThanksSendContract()}>No thanks</div>
                </div>  
              </div>
              <div className="bg-gray pv-20 ph-15 radius-8 mt-15">
                <div className="mb-15">2. Send an invitation to your client(s) so they can access the planning center.</div>
                <div className="mt-10 mb-10">
                  <HashLink to={`#client-section`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
                    <button className="small-primary-button display-flex flex-middle"><FiArrowDown style={{ marginRight: 10, fontSize: 18 }}/>Go to Clients</button>
                  </HashLink>
                </div>
              </div>
            </div>
        </Col>
      )
    } else if (noContracts && !dontShowContractNextSteps) {
      return (
        <Col xs={24} md={24}>
          <div className="shadow-card p-15 text-center">
              <div className="fw-700 mb-10 fs-16">Next Steps</div>
              <div className="bg-gray pv-20 ph-15 radius-8">
                <div className="mb-15">Create and send a contract for your client to sign.</div>
                <button className="small-primary-button" onClick={() => onSelectTab("documents")}>Create a Contract</button>
                <div className="mt-10">
                  <div className="blue-link" onClick={() => noThanksSendContract()}>No thanks</div>
                </div>  
              </div>
            </div>
        </Col>
      )
    } else if (hasClientsNotSent) {
      return (
        <Col xs={24} md={24}>
          <div className="shadow-card p-15 text-center">
            <div className="fw-700 mb-10 fs-16">Next Steps</div>
            <div className="bg-gray pv-20 ph-15 radius-8 mt-15">
              <div className="mb-15">Send an invitation to your client(s) so they can access the planning center.</div>
              <div className="mt-10 mb-10">
                <HashLink to={`#client-section`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
                  <button className="small-primary-button display-flex flex-middle"><FiArrowDown style={{ marginRight: 10, fontSize: 18 }}/>Go to Clients</button>
                </HashLink>
              </div>
            </div>
          </div>
        </Col>
      )
    }
  }

  const renderEventPhoto = () => {
    if (!isEmpty(event.event_photo)) {
      return (
        <Col xs={24} md={24}>
          <div className="shadow-card p-20">
            <Image src={event.event_photo} style={{ width: '100%', borderRadius: 8 }}/>
          </div>
        </Col>
      )
    }
    return null
  }
  
  const renderOverviewTab = () => {
    return (
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        <Row gutter={[20,20]}>
          {/* <Col xs={24} md={24}>
            <div className="shadow-card p-15 text-center">
              <div className="fw-700 mb-10 fs-16">Event Sheet</div>
              <div className="">
                <div className="mb-15">Download a copy of the event details</div>
                <div className="" style={{ display: "inline-block"}}>
                  <button className="small-primary-button display-flex flex-middle"><FiDownload style={{ marginRight: 10, fontSize: 18 }}/>Download PDF</button>
                </div>
              </div>
            </div>
          </Col> */}
          { renderOverviewNextSteps() }
          { renderEventPhoto() }
          <Col xs={24} md={24}>
            <div className="shadow-card mb-20">
              <Row align="middle" className="mh-20 pv-15 mb-15 b-border">
                <Col flex={1}>
                  <div className="fs-18 fw-700">Event Details</div>
                </Col>
                <Col>
                  <div className="plus-container mr-5" onClick={() => onEditEvent()}>
                    <MdOutlineEdit style={{ fontSize: 20, color: '#536DFE'}}/>
                  </div>
                </Col>
              </Row>
              <div className="ph-20 pb-20">
                <Row gutter={[15,15]}>
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="fs-14 fw-700">Event Name</div>
                    <div className="fs-14 fw-500">{ event.event_name }</div>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="fs-14 fw-700">Event Type</div>
                    <div className="fs-14 fw-500">{ event.account_event_type_name }</div>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="fs-14 fw-700">Event Date</div>
                    <div className="fs-14 fw-500">{ formatEventDateLong(event.event_date, accountSettings) }</div>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="fs-14 fw-700">Arrival Time</div>
                    <div className="fs-14 fw-500">{ !isEmpty(event.arrival_time) ? formatTime(event.arrival_time, accountSettings) : "--" }</div>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="fs-14 fw-700">Start / End Time</div>
                    <div className="fs-14 fw-500">{ formatTime(event.start_time, accountSettings) } - { formatTime(event.end_time, accountSettings) }</div>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="display-flex flex-middle">
                      <div className="fs-14 fw-700">Lockdown Days</div>
                      <Tooltip title="The amount of days before the event that the client will not be able to make any more changes.">
                        <MdInfoOutline style={{ fontSize: 16, marginLeft: 4, color: '#777'}}/>
                      </Tooltip>
                    </div>
                    <div className="fs-14 fw-500">
                      { event.lockdown_days } { event.lockdown_days == 1 ? "day" : "days" } before the event
                      <div className="c-text-gray fs-12">({getLockdownDate(event.event_date, event.lockdown_days, accountSettings)})</div>
                    </div>
                  </Col>
                  {/* <Col xs={24} sm={12} md={24} lg={12}>
                    <div className="fs-14 fw-700">Event Photo</div>
                    { isEmpty(event.event_photo) && (
                      <div className="fs-14 fw-500">None</div>
                    )}
                    { !isEmpty(event.event_photo) && (
                      <div className="fs-14 fw-500 c-blue" onClick={() => displayPhoto(event.event_photo)}>View Photo</div>
                    )}
                  </Col> */}
                  <Col xs={24}>
                    <div className="fs-14 fw-700">Internal Notes</div>
                    <div className="fs-14 fw-500" style={{ whiteSpace: "pre-line" }}>{ !isEmpty(event.internal_notes) ? event.internal_notes : "--" }</div>
                  </Col>
                </Row>
              </div>
            </div>
            { renderVenue() }
            <div className="shadow-card mb-20" id="client-section">
              <Row align="middle" className="mh-20 pv-15 b-border">
                <Col flex={1}>
                  <div className="fs-18 fw-700">Clients</div>
                </Col>
                <Col>
                  <div className="plus-container mr-5" onClick={() => onAddClient()}>
                    <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
                  </div>
                </Col>
              </Row>
              { clients.map((client,index) => renderClientCard(client,index))}
              { clients.length == 0 && (
                <div className="ph-20 pv-30 text-center c-text-gray">No assigned clients</div>
              )}
            </div>
            <div className="shadow-card mb-20">
              <Row align="middle" className="mh-20 pv-15 b-border">
                <Col flex={1}>
                  <div className="fs-18 fw-700">Assigned Staff</div>
                </Col>
                <Col>
                  <div className="plus-container mr-5" onClick={() => onAddEmployee()}>
                    <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
                  </div>
                </Col>
              </Row>
              { employees.map((client,index) => renderEmployeeCard(client,index))}
              { employees.length == 0 && (
                <div className="ph-20 pv-30 text-center c-text-gray">No assigned staff</div>
              )}
            </div>
          </Col>
        </Row>
        <Image
          width={200}
          style={{ display: 'none' }}
          src={selectedImage}
          preview={{
            visible: isImageVisible,
            onVisibleChange: value => {
              setImageVisible(value);
            },
          }}
        />
      </FloatingContainer>
    )
  }

  const renderPlanningSubTab = (name, key) => {
    const selectedClass = selectedPlanningTab == key ? { borderBottom: '2px solid #536DFE', color: "#222" } : {}
    return (
      <div className="fw-600 c-text-gray fs-14 pb-10 flex-0 nowrap ph-10 mr-10 cursor-default" style={selectedClass} onClick={() => onSelectPlanningTab(key)} key={key}>
        { name }
      </div>
    )
  }

  const renderPlanningTab = () => {
    return <EventPlanningTab 
      event={event} 
      selectedPlanningTab={selectedPlanningTab}
      onSelectPlanningTab={onSelectPlanningTab}
      specialEventSections={specialEventSections} 
      refreshSpecialEvents={refreshSpecialEvents} 
      refreshEventGenres={refreshEventGenres}
      refreshPage={refreshPage} 
      playlists={playlists}
      eventTimeline={eventTimeline}
      questionnaires={questionnaires}
      eventQuestionnaires={eventQuestionnaires}
      eventGenres={eventGenres}
      systemGenres={systemGenres}/>
  }

  const renderServicesTab = () => {
    return <EventServicesTab 
      event={event} 
      eventPackages={eventPackages}
      eventAddOns={eventAddOns}
      refreshPage={refreshPage}/>
  }

  const renderFinancialsTab = () => {
    return <EventFinancialsTab 
      event={event} 
      eventPackages={eventPackages}
      eventAddOns={eventAddOns}
      clients={clients}
      eventContracts={eventContracts}
      payments={payments}
      eventFinancials={eventFinancials}
      eventFinancialSummary={eventFinancialSummary}
      refreshFinancials={refreshFinancials}
      onSelectTab={onSelectTab}
      isNewInvoicesEnabled={isNewInvoicesEnabled && isNewInvoicesDisplayed}
      invoiceTotals={invoiceTotals}/>
  }

  const renderDocumentsTab = () => {
    return <EventDocumentsTab 
      event={event} 
      eventPackages={eventPackages}
      eventAddOns={eventAddOns}
      clients={clients}
      eventContracts={eventContracts}
      payments={payments}
      eventInvoices={eventInvoices}
      invoices={invoices}
      refreshFinancials={refreshFinancials}
      isNewInvoicesEnabled={isNewInvoicesEnabled}
      isNewInvoicesDisplayed={isNewInvoicesDisplayed}
      canViewFinancials={canViewFinancials}/>
  }

  const renderEmptyWorkflow = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="200"/>
        <div className="fs-14 c-text-gray mt-30">A workflow has not been assigned yet.</div>
        <button className="page-title-button mt-20" onClick={() => onAssignWorkflow()}>Assign Workflow</button>
      </div>
    )
  }

  const renderWorkflowTab = () => {
    if (eventWorkflowSteps.length == 0) {
      return (
        <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
          {renderEmptyWorkflow()}
        </FloatingContainer>
      )
    }

    return (
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        <div className="shadow-card">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Tasks</div>
            </Col>
            <Col>
              <div className="plus-container" onClick={() => onAddWorkflowTask()}>
                <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
              </div>
            </Col>
          </Row>
          { eventWorkflowSteps.map((x,i) => renderStep(x,i,event)) }
        </div>
      </FloatingContainer>
    )
  }

  const renderCheckbox = (task) => {
    if (task.completed) {
      return (
        <div className="workflow-step-checkbox selected">
          <MdCheck/>
        </div>
      )
    } else {
      return (
        <div className="workflow-step-checkbox"></div>
      )
    }
  }

  const renderDueText = (task) => {
    const overdueDays = moment.utc().startOf('day').diff(moment.utc(task.due_date), 'days')
    const dueDate = formatDateShort(task.due_date, accountSettings, true)
    if (task.completed) {
      return <div className="c-blue fs-12" style={{ marginTop: 2 }}>Completed!</div>
    } else if (overdueDays == -1) {
      return <div className="c-blue fs-12" style={{ marginTop: 2 }}>Due tomorrow</div>
    } else if (overdueDays < 0) {
      return <div className="c-blue fs-12" style={{ marginTop: 2 }}>Due on {dueDate} ({-overdueDays} days)</div>
    } else if (overdueDays == 0) {
      return <div className="c-blue fs-12" style={{ marginTop: 2 }}>Due today</div>
    }
    return <div className="c-red fs-12" style={{ marginTop: 2 }}>{overdueDays} {overdueDays == 1 ? "day" : "days"} overdue</div>
  }

  const renderStep = (step, index) => {
    return (
      <div className="ph-20 pv-15 flex-row flex-middle b-border" key={index}>
        <div className="flex-0 mr-15" onClick={() => toggleCompleteWorkflowStep(step)}>
          { renderCheckbox(step) }
        </div>
        <div className="flex-1" onClick={() => {}}>
          <div className="fw-500 line-1-3"><span className="fw-700">{ getActionTypeDescription(step.workflow_step_type) }: </span>{ step.description }</div>
          { renderDueText(step) }
        </div>
        <div className="flex-0">
          <Dropdown overlay={workflowStepMenu(step)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const onSelectTab = (tab) => {
    resetNavigation(tab, null)
    setSelectedTab(tab)
    setSelectedPlanningTab(null)
  }

  const onSelectPlanningTab = (tab) => {
    resetNavigation(null, tab)
    setSelectedPlanningTab(tab)
  }

  const resetNavigation = (tab, section) => {
    var mainTab = tab ? tab : selectedTab
    var sectionParam = ""
    if (mainTab == "planning" && !isNull(section)) {
      sectionParam = `&section=${section}`
    }
    navigate(`/admin/events/${event.event_id}?tab=${mainTab}${sectionParam}`, { replace: true })
  }

  const renderTabs = () => {
    if (selectedTab == "overview") {
      return renderOverviewTab()
    } else if (selectedTab == "planning") {
      return renderPlanningTab()
    } else if (selectedTab == "services") {
      return renderServicesTab()
    } else if (selectedTab == "financials") {
      return renderFinancialsTab()
    }  else if (selectedTab == "documents") {
      return renderDocumentsTab()
    } else if (selectedTab == "workflow") {
      return renderWorkflowTab()
    }
  }

  const renderHeader = () => {
    const tabRecord = primaryTabItems.find(x => x.key == selectedTab)
    const tabName = !isEmpty(tabRecord) ? tabRecord.name : ""
    return (
      <div className="">
        <div className="p-20">
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/events")}>
              Events
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Event Details
            </span>
          </div>
          <div className="fw-700 fs-24 mt-5">{event.event_name}</div>
          <div className="fs-16 c-text-gray fw-500">{ formatEventDateLong(event.event_date, accountSettings) }</div>
          { screens.lg ? (
            <div className="tab-slider mt-10">
              { primaryTabItems.map((x,i) => (
                <div className={`tab-slider--tab ${selectedTab == x.key ? "selected" : ""}`} onClick={() => onSelectTab(x.key)} key={i}>
                  {x.name}
                </div>
              ))}
            </div>
          ) : (
            <Dropdown overlay={mobileTabMenu()} placement="bottomRight" trigger="click">
              <div className="tab-dropdown mt-10">
                <div className="tab-dropdown--text">{tabName}</div>
                <div className="tab-dropdown--arrow"><MdOutlineKeyboardArrowDown/></div>
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    )
  }

  const renderEditEventModal = () => {
    return (
      <Modal visible={isEditEventModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ "Edit Event Details" }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEditEventModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          <Form form={editEventForm} layout="vertical" name="edit-event" onFinish={onEditEventSubmit}>
            <Row gutter={[20,10]}>
              <Col xs={24} md={24}>
                { renderInputField("Event Name", "event_name", true) }
              </Col>
              <Col xs={24} md={24}>
                { renderSearchSelectField("Event Type", "account_event_type_id", "", eventTypes, true) }
              </Col>
              <Col xs={12} md={24}>
                { renderDateField("Event Date", "event_date", accountSettings) }
              </Col>
              <Col xs={12} md={8}>
                { renderTimeField("Arrival Time", "arrival_time", false, accountSettings) }
              </Col>
              <Col xs={12} md={8}>
                { renderTimeField("Start Time", "start_time", true, accountSettings) }
              </Col>
              <Col xs={12} md={8}>
              { renderTimeField("End Time", "end_time", true, accountSettings) }
              </Col>
              <Col xs={24} md={24}>
                { renderTextAreaField("Internal Notes (not visible to the client)", "internal_notes", 5) }
              </Col>
              <Col xs={24} md={24}>
                { renderNumberField("Lockdown Days", "lockdown_days", 0, false, "The amount of days before the event that the client will not be able to make any more changes.") }
              </Col>
              <Col xs={24}>
                <div className="bg-gray radius-8 p-10 fs-12"><b>Note:</b> To allow your client to edit their event up until their event date, enter "0" for the lockdown days.</div>
              </Col>
            </Row>
            <div className="admin-modal-footer">
              <button className="page-title-button" type="submit">Save</button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderConfirmInvitationModal = () => {
    return (
      <Modal visible={isConfirmInvitationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmInvitationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Send Invitation</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to send an invitation to <span className="fw-700">{selectedClient.email}</span>?</div>
          <button className="primary-button" type="button" onClick={() => sendInvitation()}>Send</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmInvitationModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveEmployeeModal = () => {
    return (
      <Modal visible={isConfirmRemoveEmployeeModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveEmployeeModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Staff</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to unassign <span className="fw-700">{selectedEmployee.first_name} {selectedEmployee.last_name}</span> from this event?</div>
          <button className="primary-button warning" type="button" onClick={() => deleteEmployee()}>Remove Staff</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveEmployeeModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveClientModal = () => {
    return (
      <Modal visible={isConfirmRemoveClientModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveClientModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Client</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedClient.first_name} {selectedClient.last_name}</span> from this event?</div>
          <button className="primary-button warning" type="button" onClick={() => deleteClient()}>Remove Client</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveClientModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderAssignWorkflowModal = () => {
    return (
      <Modal visible={isAssignWorkflowModalVisible} footer={null} onCancel={() => setAssignWorkflowModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Assign Workflow</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAssignWorkflowModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-15">
            <Select
              style={{ width: "100%" }}
              placeholder={"Select workflow"}
              size='large'
              value={selectedWorkflow}
              onChange={(value) => setSelectedWorkflow(value)}
            >
              { workflows.map((x,i) => (
                 <Select.Option value={x.workflow_id} key={i}>{x.workflow_name}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="text-right">
            <Button className="admin-small-button secondary mr-10" onClick={() => setAssignWorkflowModalVisible(false)}>Cancel</Button>
            <Button className="admin-small-button" onClick={() => handleAssignWorkflow()}>Save</Button>
          </div>
        </div>
      </Modal>
    )
  }

  const renderEmployeeSelectionModal = () => {
    const adminUserData = adminUsers.map((user) => {
      return {
        value: user.user_id,
        text: user.first_name + " " + user.last_name
      }
    })
    return (
      <Modal visible={isEmployeeSelectionModalVisible} footer={null} closable={false} onCancel={() => setEmployeeSelectionModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Assign Staff</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEmployeeSelectionModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={employeeForm} layout="vertical" name="employeeForm" onFinish={onEmployeeSubmit}>
          <Row align="middle" gutter={[20,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Staff", "user_id", "Select staff member", adminUserData, true) }
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Event Role", "admin_event_role_id", "Select event role", adminEventRoles, true) }
            </Col>
            <Col xs={24} md={24}>
              { renderCurrencyField("Wage", "wage", false, currency.code) }
            </Col>
            <Col xs={24}>
                { renderFormLabel("Client Portal") }
                <div className="border radius-8 pv-10 ph-15">
                  { renderCheckboxField("Visible to client", "client_visible", onStaffVisibleToClientChange, "Determines whether or not this assigned staff member is visible in the client portal.") }
                  { renderCheckboxField("Display contact information", "display_contact_info", null, "Determines whether or not this staff member's phone number and email address is visible to the client.", !staffClientVisible) }
                </div>
              </Col>
          </Row>
        </Form>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => employeeForm.submit()}>Add Staff</button>
        </div>
      </Modal>
    )
  }

  const renderNewClientTab = () => {
    return (
      <Form form={newClientForm} layout="vertical" name="contact" onFinish={onNewClientSubmit}>
        <Row gutter={[20,10]}>
          <Col xs={24} md={24}>
            <div className="fw-700">Client Details</div>
          </Col>
          <Col xs={24}>
            { renderSearchSelectField("Role", "client_event_role_id", "Select a role", clientEventRoles, true) }
          </Col>
          <Col xs={24} md={12}>
            { renderInputField("First Name", "first_name", true) }
          </Col>
          <Col xs={24} md={12}>
            { renderInputField("Last Name", "last_name", true) }
          </Col>
          <Col xs={24}>
            { renderInputField("Email", "email", true) }
          </Col>
          <Col xs={24}>
            <div className="bg-gray radius-8 p-10 fs-12"><b>Note:</b> If creating a test event, we recommend entering another email address you have access to, otherwise you will not be able to login with the client.</div>
          </Col>
          <Col xs={24}>
            { renderPhoneField("Phone", "phone", false, false, clientPhoneCountryCode, setClientPhoneCountryCode) }
          </Col>
          <Col xs={24} md={24}>
            <Collapse ghost style={{ marginLeft: -15, marginRight: -15 }} destroyInactivePanel={true}>
              <Collapse.Panel header={<div className="fw-600 c-blue">Address (optional)</div>}>
                <Row align="middle" gutter={[15,15]}>
                    <Col xs={24} md={24}>
                    { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, true, onClientCountryChange) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderGlobalAddressField(clientCountryCode, "line_1", false) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderGlobalSecondAddressField(clientCountryCode, "line_2", false) }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderGlobalCityField(clientCountryCode, "city", false) }
                  </Col>
                  <Col xs={24} md={16}>
                    { renderGlobalStateField(clientCountryCode, "state", false) }
                  </Col>
                  <Col xs={12} md={8}>
                    { renderGlobalZipField(clientCountryCode, "zip", false) }
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col xs={24} md={24}>
            <div className="fw-700">Permissions</div>
          </Col>
          <Col xs={24}>
            <div className="border radius-8 pv-10 ph-15">
              { renderEventPermissionCheckboxes("Music", "view_music", "edit_music")}
              { renderEventPermissionCheckboxes("Questionnaires", "view_questionnaires", "edit_questionnaires")}
              { renderEventPermissionCheckboxes("Timeline", "view_timeline", "edit_timeline")}
              { renderEventPermissionCheckboxes("Services", "view_services")}
              { renderEventPermissionCheckboxes("Financials", "view_financials")}
              { renderEventPermissionCheckboxes("Documents", "view_documents")}
            </div>
          </Col>
          <Col xs={24} md={24}>
            <div className="fw-700">Invitation</div>
          </Col>
          <Col xs={24}>
            { renderCheckboxField("Send invitation immediately", "send_invitation") }
            <div className="bg-gray radius-8 p-10 fs-12"><b>Note:</b> Sending an invitation before creating a contract will give the client access to the planning center before they might be officially booked. If you would like to create a contract first, you can send an invitation later from this page.</div>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderExistingClientTab = () => {
    const clientData = clientUsers.map((client) => {
      return {
        value: client.user_id,
        text: client.first_name + " " + client.last_name
      }
    })
    return (
      <Form form={existingClientForm} layout="vertical" name="existingClient" onFinish={onExistingClientSubmit}>
        <Row gutter={[20,10]}>
          <Col xs={24} md={24}>
            <div className="fw-700">Client Details</div>
          </Col>
          <Col xs={24}>
            { renderSearchSelectField("Client", "user_id", "Select client", clientData, true) }
          </Col>
          <Col xs={24}>
            { renderSearchSelectField("Event Role", "client_event_role_id", "Select event role", clientEventRoles, true) }
          </Col>
          <Col xs={24} md={24}>
            <div className="fw-700">Permissions</div>
          </Col>
          <Col xs={24}>
            <div className="border radius-8 pv-10 ph-15">
              { renderEventPermissionCheckboxes("Music", "view_music", "edit_music")}
              { renderEventPermissionCheckboxes("Questionnaires", "view_questionnaires", "edit_questionnaires")}
              { renderEventPermissionCheckboxes("Timeline", "view_timeline", "edit_timeline")}
              { renderEventPermissionCheckboxes("Services", "view_services")}
              { renderEventPermissionCheckboxes("Financials", "view_financials")}
              { renderEventPermissionCheckboxes("Documents", "view_documents")}
            </div>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderClientSelectionModal = () => {
    return (
      <Modal visible={isClientSelectionModalVisible} footer={null} closable={false} onCancel={() => setIsClientSelectionModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{"Add Client"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setIsClientSelectionModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="tab-slider mt-10 mb-20">
          <div className={`tab-slider--tab ${selectedClientTab == "new" ? "selected" : ""}`} onClick={() => setSelectedClientTab("new")}>
            New Client
          </div>
          <div className={`tab-slider--tab ${selectedClientTab == "existing" ? "selected" : ""}`} onClick={() => setSelectedClientTab("existing")}>
            Existing Client
          </div>
        </div>
        <div style={ { display: selectedClientTab == "new" ? "block" : "none" } }>
          { renderNewClientTab() }
        </div>
        <div style={ { display: selectedClientTab == "existing" ? "block" : "none" } }>
          { renderExistingClientTab() }
        </div>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => onClientSelect()}>Add Client</button>
        </div>
      </Modal>
    )
  }

  const renderNewVenueTab = () => {
    return (
      <Form form={newVenueForm} layout="vertical" name="contact" onFinish={onNewVenueSubmit}>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24} md={24}>
            { renderInputField("Venue Name", "venue_name", true) }
          </Col>
          <Col xs={24} md={24}>
            { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, true, onVenueCountryChange) }
          </Col>
          <Col xs={24} md={24}>
            { renderGlobalAddressField(venueCountryCode, "address_line_1") }
          </Col>
          <Col xs={24} md={24}>
            { renderGlobalSecondAddressField(venueCountryCode, "address_line_2") }
          </Col>
          <Col xs={24} md={24}>
            { renderGlobalCityField(venueCountryCode, "city") }
          </Col>
          <Col xs={24} md={16}>
            { renderGlobalStateField(venueCountryCode, "state") }
          </Col>
          <Col xs={12} md={8}>
            { renderGlobalZipField(venueCountryCode, "zip") }
          </Col>
          <Col xs={24}>
            { renderCheckboxField("One Time Venue", "one_time", null, "Checking this box will hide this venue from the venue list when creating events.") }
          </Col>
          <Col xs={24} md={24}>
            <Collapse ghost style={{ marginLeft: -15, marginRight: -15 }}>
              <Collapse.Panel header={<div className="fw-600 c-blue">Additional Fields</div>}>
                <Row align="middle" gutter={[15,15]}>
                  <Col xs={24} md={24}>
                    { renderInputField("Contact Name", "contact_name") }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderEmailField("Email", "contact_email") }
                  </Col>
                  <Col xs={24} md={24}>
                    {renderPhoneField("Phone", "contact_phone", false, false, venuePhoneCountryCode, setVenuePhoneCountryCode)}
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="b-border mb-15 mt-10"></div>
                    { renderInputField("Website", "website") }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderInputField("Wi-Fi", "wifi") }
                  </Col>
                  <Col xs={24} md={24}>
                    { renderTextAreaField("Notes", "notes", 4) }
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderExistingVenueTab = () => {
    return (
      <Form form={existingVenueForm} layout="vertical" name="contact" onFinish={onExistingVenueSubmit}>
        <div className="">
          {renderSearchSelectField("Venue", "venue_id", "Select a venue", venues, true)}
        </div>
      </Form>
    )
  }

  const renderVenueSelectionModal = () => {
    return (
      <Modal visible={isVenueSelectionModalVisible} footer={null} closable={false} onCancel={() => setVenueSelectionModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{"Add Venue"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setVenueSelectionModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="tab-slider mt-10 mb-20">
          <div className={`tab-slider--tab ${selectedVenueTab == "new" ? "selected" : ""}`} onClick={() => setSelectedVenueTab("new")}>
            New Venue
          </div>
          <div className={`tab-slider--tab ${selectedVenueTab == "existing" ? "selected" : ""}`} onClick={() => setSelectedVenueTab("existing")}>
            Existing Venue
          </div>
        </div>
        <div style={ { display: selectedVenueTab == "new" ? "block" : "none" } }>
          { renderNewVenueTab() }
        </div>
        <div style={ { display: selectedVenueTab == "existing" ? "block" : "none" } }>
          { renderExistingVenueTab() }
        </div>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => onVenueSelect()}>Add Venue</button>
        </div>
      </Modal>
    )
  }

  const renderWorkflowTaskModal = () => {
    return (
      <WorkflowTaskModal
        title={isNewStep ? "New Task" : "Edit Task"}
        isVisible={isWorkflowTaskModalVisible}
        setVisible={setWorkflowTaskModalVisible}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        handleSave={handleSaveWorkflowTask}
        employees={adminUsers}
      />
    )
  }

  const renderEventPermissionCheckboxes = (title, view_id, edit_id) => {
    return (
      <Row gutter={[10]} align="middle">
        <Col xs={12} sm={16}>
          <div className="fw-500">{title}</div>
        </Col>
        <Col xs={6} sm={4}>
          { renderCheckboxField("View", view_id) }
        </Col>
        <Col xs={6} sm={4}>
          { !isUndefined(edit_id) && renderCheckboxField("Edit", edit_id) }
        </Col>
      </Row>
    )
  }

  const renderUpdateClientRoleModal = () => {
    return (
      <Modal visible={isUpdateClientRoleModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">Edit Permissions</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateClientRoleModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          <Form form={clientRoleForm} layout="vertical" name="edit-client-role" onFinish={onEditClientRoleSubmit}>
            <Row gutter={[20,10]}>
              <Col xs={24} md={24}>
                { renderFormLabel("Name") }
                <div className="">{ selectedClient.first_name } { selectedClient.last_name }</div>
              </Col>
              <Col xs={24} md={24}>
                { renderSearchSelectField("Role", "client_event_role_id", "Select a role", clientEventRoles, true) }
              </Col>
              <Col xs={24}>
                { renderFormLabel("Permissions") }
                <div className="border radius-8 pv-10 ph-15">
                  { renderEventPermissionCheckboxes("Music", "view_music", "edit_music")}
                  { renderEventPermissionCheckboxes("Questionnaires", "view_questionnaires", "edit_questionnaires")}
                  { renderEventPermissionCheckboxes("Timeline", "view_timeline", "edit_timeline")}
                  { renderEventPermissionCheckboxes("Services", "view_services")}
                  { renderEventPermissionCheckboxes("Financials", "view_financials")}
                  { renderEventPermissionCheckboxes("Documents", "view_documents")}
                </div>
                <div className="bg-gray radius-8 p-10 fs-12 mt-15"><b>Important Note:</b> After updating permissions, the client will need to either sign out and then sign back in to see the changes or wait an hour for their permissions to automatically refresh.</div>
              </Col>
            </Row>
            <div className="admin-modal-footer">
              <button className="page-title-button" type="submit">Save</button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderUpdateEmployeeRoleModal = () => {
    return (
      <Modal visible={isUpdateEmployeeRoleModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">Edit Assigned Staff</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateEmployeeRoleModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          <Form form={employeeRoleForm} layout="vertical" name="edit-client-role" onFinish={onEditEmployeeRoleSubmit}>
            <Row gutter={[20,10]}>
              <Col xs={24} md={24}>
                { renderFormLabel("Name") }
                <div className="">{ selectedEmployee.first_name } { selectedEmployee.last_name }</div>
              </Col>
              <Col xs={24} md={24}>
                { renderSearchSelectField("Event Role", "admin_event_role_id", "Select event role", adminEventRoles, true) }
              </Col>
              <Col xs={24} md={24}>
                { renderCurrencyField("Wage", "wage", false, currency.code) }
              </Col>
              <Col xs={24}>
                { renderFormLabel("Client Portal") }
                <div className="border radius-8 pv-10 ph-15">
                  { renderCheckboxField("Visible to client", "client_visible", onStaffVisibleToClientChange, "Determines whether or not this assigned staff member is visible in the client portal.") }
                  { renderCheckboxField("Display contact information", "display_contact_info", null, "Determines whether or not this staff member's phone number and email address is visible to the client.", !staffClientVisible) }
                </div>
              </Col>
            </Row>
            <div className="admin-modal-footer">
              <button className="page-title-button" type="submit">Save</button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderEventPermissions = (title, view_id, edit_id) => {
    const permissions = isArray(selectedClient.permissions) ? selectedClient.permissions : []
    return (
      <Row gutter={[10]} align="middle">
        <Col xs={12} sm={16}>
          <div className="fw-500">{title}</div>
        </Col>
        <Col xs={6} sm={4}>
          <div className="display-flex flex-row flex-middle">
            <div className="display-flex mr-5">
              { permissions.includes(view_id) ? (
                <MdCheck size={18} color={"#00b894"}/>
              ) : (
                <MdOutlineClose size={18} color={"#e74c3c"}/>
              )}
            </div>
            View
          </div>
        </Col>
        <Col xs={6} sm={4}>
          { !isUndefined(edit_id) && (
            <div className="display-flex flex-row flex-middle">
              <div className="display-flex mr-5">
                { permissions.includes(edit_id) ? (
                  <MdCheck size={18} color={"#00b894"}/>
                ) : (
                  <MdOutlineClose size={18} color={"#e74c3c"}/>
                )}
              </div>
              Edit
            </div>
          )}
        </Col>
      </Row>
    )
  }

  const renderClientDetailsModal = () => {
    const phoneNumber = getFormattedPhoneNumber(getUserPhoneNumber(selectedClient));
    const clientAddress = isArray(selectedClient.addresses) && selectedClient.addresses.length > 0 ? selectedClient.addresses[0] : {}
    return (
      <Modal visible={isClientDetailsModalVisible} footer={null} closable={false} onCancel={() => setClientDetailsModalVisible(false)} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">Client Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setClientDetailsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[15,15]} className="mt-10">
          <Col xs={24} sm={12} md={24} lg={12}>
            <div className="fs-14 fw-700">First Name</div>
            <div className="fs-14 fw-500">{ selectedClient.first_name }</div>
          </Col>
          <Col xs={24} sm={12} md={24} lg={12}>
            <div className="fs-14 fw-700">Last Name</div>
            <div className="fs-14 fw-500">{ selectedClient.last_name }</div>
          </Col>
          <Col xs={24} sm={12} md={24} lg={12}>
            <div className="fs-14 fw-700">Email</div>
            <div className="fs-14 fw-500">{ selectedClient.email }</div>
          </Col>
          <Col xs={24} sm={12} md={24} lg={12}>
            <div className="fs-14 fw-700">Phone</div>
            <div className="fs-14 fw-500">{!isEmpty(phoneNumber) ? phoneNumber : "--"}</div>
          </Col>
          <Col xs={24}>
            <div className="fs-14 fw-700">Address</div>
            { isEmpty(clientAddress) ? (
              <div className="fs-14 fw-500">--</div>
            ) : (
              <>
                <div className="fs-14 fw-500">{clientAddress.line_1}</div>
                <div className="fs-14 fw-500">{clientAddress.line_2}</div>
                <div className="fs-14 fw-500">{clientAddress.city}, {clientAddress.state} { clientAddress.zip}</div>
              </>
            )}
          </Col>
          <Col xs={24}>
            <div className="fs-14 fw-700">Permissions <span className="fs-14 fw-500 c-text-gray"> (for this event)</span></div>
            <div className="border radius-8 pv-10 ph-15 mt-5">
              { renderEventPermissions("Music", EventPermissions.VIEW_MUSIC, EventPermissions.EDIT_MUSIC)}
              { renderEventPermissions("Questionnaires", EventPermissions.VIEW_QUESTIONNAIRES, EventPermissions.EDIT_QUESTIONNAIRES)}
              { renderEventPermissions("Timeline", EventPermissions.VIEW_TIMELINE, EventPermissions.EDIT_TIMELINE)}
              { renderEventPermissions("Services", EventPermissions.VIEW_SERVICES)}
              { renderEventPermissions("Financials", EventPermissions.VIEW_FINANCIALS)}
              { renderEventPermissions("Documents", EventPermissions.VIEW_DOCUMENTS)}
            </div>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <button className="page-title-button" type="button" onClick={() => setClientDetailsModalVisible(false)}>Close</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <div className="">
        { renderTabs() }
        { renderEditEventModal() }
        { renderConfirmInvitationModal() }
        { renderConfirmRemoveEmployeeModal() }
        { renderConfirmRemoveClientModal() }
        { renderEmployeeSelectionModal() }
        { renderAssignWorkflowModal() }
        { renderWorkflowTaskModal() }
        { renderClientSelectionModal() }
        { renderVenueSelectionModal() }
        { renderUpdateClientRoleModal() }
        { renderUpdateEmployeeRoleModal() }
        { renderClientDetailsModal() }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={170}/>
  );
}

EventPage.propTypes = {};

export default EventPage;