import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Grid } from 'antd';
import qs from "qs";
import { PDFDownloadLink } from '@react-pdf/renderer';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { getEventContract } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { FiCheckCircle, FiArrowDown } from "react-icons/fi";
import ContractPreview from '../../../components/contractPreview';
import ContractStatus from '../../../constants/contractStatus';
import ContractPDF from '../../../pdfs/contractPDF';
import AppContext from '../../../app/context';
import { isArray, isEmpty } from "lodash";
import moment from "moment";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { formatDateMedium } from '../../../helpers/dateHelper';

const PreviewEventContractPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [contract, setContract] = useState({});
  const [isAdminSigner, setAdminSigner] = useState(false);
  const [isAdminSigned, setAdminSigned] = useState(false);
  const [fromPage, setFromPage] = useState("");

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useDocumentTitle("Preview Contract")
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const { auth, setAuth } = useContext(AppContext);

  const eventId = params.event_id;
  const contractId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.from) {
      setFromPage(queryStrings.from)
    }
  }, []);

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("event-contract--content")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("event-contract--content")[0].scrollTop;
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("event-contract--content")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getEventContract(eventId, contractId))
      setContract(results)
      const adminIsSigner = results.signers.filter(x => x.user_id == auth.user.user_id)
      setAdminSigner(adminIsSigner.length > 0)
      const adminHasSigned = results.signers.filter(x => x.user_id == auth.user.user_id && x.signed)
      setAdminSigned(adminHasSigned.length > 0)
    } finally {
      setLoading(false)
    }
  }

  const renderDocument = () => {
    return <ContractPDF contract={contract}/>
  }

  const renderHeaderButton = () => {
    if (contract.status == ContractStatus.DRAFT || contract.status == ContractStatus.AWAITING_SEND) {
      return (
        <button className="small-primary-button" style={{ }} onClick={() => navigate(`/admin/events/${eventId}/contracts/${contractId}/send`)}>Next: Send Contract</button>
      )
    } else if (contract.status == ContractStatus.AWAITING_SIGNATURE) {
      return (
        <button className="small-primary-button" style={{ }} onClick={() => navigate(`/admin/events/${eventId}/contracts/${contractId}/send`)}>Re-Send Contract</button>
      )
    } else if (contract.status == ContractStatus.COMPLETED) {
      return (
        <PDFDownloadLink document={ renderDocument() } fileName={`contract-${moment().format("YYYYMMDD")}.pdf`}>
          <button className="small-primary-button">Download PDF</button>
        </PDFDownloadLink>
      )
    }
    return null;
  }

  const renderHeader = () => {
    var backPath = `/admin/events/${eventId}/contracts/${contractId}/edit`;
    var eventButtonText = "Back to event"
    if (fromPage == "contracts") {
      backPath = `/admin/contracts`
      eventButtonText = "Go to event"
    } else if (fromPage == "edit-contracts") {
      backPath = `/admin/events/${eventId}/contracts/${contractId}/edit?from=contracts`
      eventButtonText = "Go to event"
    } else if (contract.status == ContractStatus.COMPLETED || contract.status == ContractStatus.COUNTERSIGN) {
      backPath = `/admin/events/${eventId}?tab=documents`
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20"> 
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              { contract.event_contract_name }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
            <div className="blue-link mh-20" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>{eventButtonText}</div>
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              { contract.event_contract_name }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-15">
            <div className="">
              { renderHeaderButton()}
            </div>
            <div className="blue-link mt-15" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>{eventButtonText}</div>
          </div>
        </div>
      )
    }
  }

  const renderContent = () => {
    const currentSigner = isArray(contract.signers) ? contract.signers.find(x => x.user_id == auth.user.user_id) : {}
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={850}>
          { contract.status == ContractStatus.COMPLETED && (
            <div className="bg-white border radius-8 mb-20 p-20 c-text-gray hide-print display-flex flex-middle justify-content-center">
              <FiCheckCircle style={{ marginRight: 8, color: "#536DFE"}}/>All parties have signed this contract.
            </div>
          )}
          { (contract.status == ContractStatus.AWAITING_SEND || contract.status == ContractStatus.AWAITING_SIGNATURE) && isAdminSigned && (
            <div className="bg-white border radius-8 mb-20 p-20 c-text-gray hide-print display-flex flex-middle justify-content-center">
              <FiCheckCircle style={{ marginRight: 8, color: "#536DFE"}}/> You have signed this contract on {!isEmpty(currentSigner.signed_datetime) ? formatDateMedium(currentSigner.signed_datetime, accountSettings, true) : "--"}
            </div>
          )}
          { contract.status == ContractStatus.DRAFT && isAdminSigner && (
            <div className="bg-white border radius-8 mb-20 p-20 text-center">
              <div className="fw-700">The preview below is what the client will see when signing the document.</div>
              <div className="mt-5 c-text-gray">If you would like to add your signature before the document is sent to your client, you can scroll to the bottom and do that now. If you choose to wait, you can always come back and sign at any time.</div>
              <HashLink to={`#signature-lines`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
                <button className="small-primary-button mt-15 display-flex flex-middle"><FiArrowDown style={{ marginRight: 10, fontSize: 18 }}/>Add Signature</button>
              </HashLink>
            </div>
          )}
          { contract.status == ContractStatus.AWAITING_SIGNATURE && isAdminSigner && !isAdminSigned && (
            <div className="bg-white border radius-8 mb-20 p-20 text-center">
              <div className="fw-700">The preview below is what the client will see when signing the document.</div>
              <div className="mt-5 c-text-gray">If you would like to add your signature before your client signs, you can scroll to the bottom and do that now. If you choose to wait, you can always come back and sign at any time.</div>
              <HashLink to={`#signature-lines`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
                <button className="small-primary-button mt-15 display-flex flex-middle"><FiArrowDown style={{ marginRight: 10, fontSize: 18 }}/>Add Signature</button>
              </HashLink>
            </div>
          )}
          { contract.status == ContractStatus.COUNTERSIGN && (
            <div className="bg-white border radius-8 mb-20 p-20 text-center">
              <div className="fw-700">This document has been signed by the client.</div>
              <div className="mt-5 c-text-gray">Scroll to the bottom of the page to add your signature and complete the document.</div>
              <HashLink to={`#signature-lines`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
                <button className="small-primary-button mt-15 display-flex flex-middle"><FiArrowDown style={{ marginRight: 10, fontSize: 18 }}/>Add Signature</button>
              </HashLink>
            </div>
          )}
          <div className="shadow-card p-30 mt-15">
            <div>
              <ContractPreview 
                contract={contract} 
                signers={contract.signers} 
                currentSigner={currentSigner}
                reload={refreshPage}
              />
            </div>
          </div>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
    </div>
  );
}

export default PreviewEventContractPage;
