import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { isNull, isArray } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import { formatEventDateLong, formatEventDateShort } from '../helpers/dateHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

const QuestionnairesPDF = (props) => {
  const { event } = props
  const { questionnaires = "", event_name = "", event_date = null, account_settings = {} } = event

  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    section: {
      marginBottom: 10
    },
    sectionHeader: {
      borderBottom: "3px solid #CCC",
      marginBottom: 10
    },
    sectionHeaderText: {
      fontSize: 14,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      paddingBottom: 5
    },
    sectionSubHeader: {
      marginBottom: 10,
      backgroundColor: "#F7F7F7"
    },
    sectionSubHeaderText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 10,
      paddingVertical: 5
    },
    header: {
      marginBottom: 20
    },
    headerText: {
      fontSize: 16,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center"
    },
    subTitleText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center",
      marginTop: 10
    },
    dateText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      textAlign: "center"
    },
    specialEventRow: {
      borderBottom: "1px solid #e8e8e8",
      marginLeft: 15,
      paddingVertical: 10
    },
    specialEventName: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      marginBottom: 2
    },
    songText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      paddingTop: 2
    },
    songTitle: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777"
    },
    notesText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      backgroundColor: "#F7F7F7",
      paddingVertical: 4,
      paddingHorizontal: 5,
      marginLeft: 5,
      marginTop: 3,
      borderRadius: 4
    },
    genreText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      marginTop: 4
    },
    emptyText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
      paddingHorizontal: 5
    },
    questionText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      marginBottom: 2
    },
    answerText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      marginBottom: 2
    },
    questionContainer: {
      marginBottom: 4
    }
  });

  const renderQuestion = (question, index) => {
    if (question.type == "checkbox") {
      var answerArray = question.answer.split("::")
      answerArray = answerArray.filter(x => x != "");
      return (
        <View key={index} style={styles.questionContainer}>
          <Text style={styles.questionText}>{question.label}</Text>
          { answerArray.map((x,i) => (
            <Text style={styles.answerText} key={i}>{x}</Text>
          ))}
          { answerArray.length == 0 && (
            <Text style={styles.answerText}>--</Text>
          )}
        </View>
      )
    } else {
      var answer = ""
      if (!question.answer) {
        answer = "--"
      } else if (question.type == "date") {
        answer = formatEventDateShort(question.answer, account_settings)
      } else {
        answer = question.answer
      }
      return (
        <View key={index} style={styles.questionContainer}>
          <Text style={styles.questionText}>{question.label}</Text>
          <Text style={styles.answerText}>{answer}</Text>
        </View>
      )
    }
  }

  const renderSection = (section, index) => {
    return (
      <View style={styles.section} key={index}>
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>{ section.name }</Text>
        </View>
        { section.questions.map((x,i) => renderQuestion(x,i))}
      </View>
    )
  }

  const renderQuestionnaire = (questionnaire, index) => {
    const sections = isArray(questionnaire.sections) ? questionnaire.sections : []
    return (
      <View style={styles.section} key={index}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{ questionnaire.questionnaire_name }</Text>
        </View>
        { sections.map((x,i) => renderSection(x,i)) }
      </View>
    )
  }

  const renderDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.headerText}>{ event_name }</Text>
            { !isNull(event_date) && (
              <Text style={styles.dateText}>{ formatEventDateLong(event_date, account_settings) }</Text>
            )}
            <Text style={styles.subTitleText}>Questionnaires</Text>
          </View>
          { questionnaires.map((x,i) => renderQuestionnaire(x,i)) }
        </Page>
      </Document>
    )
  }

  return renderDocument()
}

export default QuestionnairesPDF;
