import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import uniqid from 'uniqid';
import { isUndefined, isEmpty, cloneDeep, isNumber, isNull } from "lodash";
import useApi from '../../../hooks/useApi';
import { Input, Dropdown, Menu } from 'antd';
import { getWorkflow, updateWorkflow, createWorkflowStep, updateWorkflowStep, deleteWorkflowStep, getAdminUsers } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import WorkflowTaskModal from '../../../components/workflowTaskModal';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { MdArrowForwardIos, MdOutlineMoreHoriz } from "react-icons/md";
import { FiRefreshCw, FiCheckCircle } from "react-icons/fi";
import emptyStateImage from '../../../images/empty-document-icon.png';
import { getActionTypeDescription, sortSteps, getStepWhenDescription } from '../../../helpers/workflowHelper';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const NewWorkflowPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [workflowName, setWorkflowName] = useState("");
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState({});
  const [isNewStep, setIsNewStep] = useState(false);
  const [employees, setEmployees] = useState([]);

  useDocumentTitle("Workflow Details")
  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);

  const [sendRequest] = useApi()

  const workflowId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isUndefined(workflowId)) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const workflowResults = await sendRequest(getWorkflow(workflowId));
      setWorkflowName(workflowResults.workflow_name)
      setSteps(sortSteps(workflowResults.steps, true));

      // const employeeResults = await sendRequest(getAdminUsers())
      // setEmployees(employeeResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onUpdate = async () => {
    setEditingTitle(false)
    setSaving(true)
    try {
      var body = {
        workflow_name: workflowName,
      }
      await sendRequest(updateWorkflow(workflowId, body))
    } finally {
      setSaving(false)
    }
  }

  const menu = (step, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditStep(step)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Step
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleRemove(step)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Step
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const handleSave = async () => {
    try {
      const timeValueValid = isNumber(selectedStep.time_value) ? true : !isEmpty(selectedStep.time_value)
      if (isEmpty(selectedStep.description) || !timeValueValid || isEmpty(selectedStep.workflow_step_type)) {
        return
      }
      setSaving(true)
      if (isNewStep) {
        var newSteps = cloneDeep(steps)
        const response = await sendRequest(createWorkflowStep(workflowId, selectedStep))
        newSteps.push(response)
        setSteps(sortSteps(newSteps, true))
        await refreshPage()
        setAddEditModalVisible(false);
      } else {
        var newSteps = cloneDeep(steps)
        var newSelectedStep = cloneDeep(selectedStep)
        const response = await sendRequest(updateWorkflowStep(workflowId, newSelectedStep.workflow_step_id, newSelectedStep))
        for (var i = 0; i < newSteps.length; i++) {
          if (newSteps[i].workflow_step_id == response.workflow_step_id) {
            newSteps[i] = response
          }
        }
        setSteps(sortSteps(newSteps, true))
        await refreshPage()
        setAddEditModalVisible(false);
      }
    } finally {
      setSaving(false)
    }
  };

  const handleRemove = async (step) => {
    try {
      setSaving(true)
      var newSteps = cloneDeep(steps)
      await sendRequest(deleteWorkflowStep(workflowId, step.workflow_step_id))
      newSteps = newSteps.filter(x => x.workflow_step_id != step.workflow_step_id)
      setSteps(sortSteps(newSteps, true))
      setAddEditModalVisible(false);
    } finally {
      setSaving(false)
    }
  }

  const handleAddStep = () => {
    setAddEditModalVisible(true)
    setIsNewStep(true)
    const newStep = {
      workflow_step_id: uniqid(),
      time_unit: "days",
      time_period: "before_event",
      metadata: {}
    }
    setSelectedStep(newStep)
  }

  const handleEditStep = (step) => {
    setAddEditModalVisible(true)
    setIsNewStep(false)
    setSelectedStep(step)
  }

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }

  const onKeyPress = (e) => {
    if (e.keyCode == 13) {
     onUpdate()
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/workflows")}>
            Workflows
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className={`header-editable-title mt-5 ${isEditingTitle ? "editing" : ""}`}>
          <div className="header-editable-title--label" onClick={() => startEditingTitle()}>{ workflowName }</div>
          <div className="header-editable-title--input">
            <Input 
              size="large" 
              placeholder="Email" 
              ref={inputRef}
              onBlur={() => onUpdate()} 
              value={workflowName} 
              onChange={(e) => setWorkflowName(e.target.value)} 
              onKeyUp={onKeyPress}
            />
          </div>
        </div>
        { renderSubHeader() }
      </div>
    )
  }

  const renderSubHeader = () => {
    return (
      <div className="flex-row flex-middle c-text-gray flex-1 mt-5">
        { isSaving ? (
          <div className="flex-row flex-middle c-text-gray flex-1">
            <FiRefreshCw style={{ marginRight: 5}}/> Saving changes...
          </div>
        ) : (
          <div className="flex-row flex-middle c-text-gray flex-1">
            <FiCheckCircle style={{ marginRight: 5}}/> All changes saved!
          </div>
        )}
      </div>
    )
  }

  const renderStep = (step, index) => {
    return (
      <div className="ph-20 pv-10 flex-row flex-middle b-border" key={index}>
        <div className="flex-0 mr-15">
          <div className="workflow-step-number">
            {index + 1}
          </div>
        </div>
        <div className="flex-1" onClick={() => handleEditStep(step)}>
          <div className="fw-500 line-1-3"><span className="fw-700">{ getActionTypeDescription(step.workflow_step_type) }: </span>{ step.description }</div>
          {/* { (step.assigned_to == 1 || isNull(step.assigned_to)) ? (
            <div className="fs-12 c-text-gray">Assigned to assigned staff member</div>
          ) : (
            <div className="fs-12 c-text-gray">Assigned to {step.assigned_first_name} {step.assigned_last_name}</div>
          )} */}
          <div className="c-blue fs-12">{ getStepWhenDescription(step) }</div>
        </div>
        <div className="flex-0">
          <Dropdown overlay={menu(step)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="200"/>
        <div className="fs-14 c-text-gray mt-30">Get started by creating your first step.</div>
        <button className="page-title-button mt-20" onClick={handleAddStep}>Add Step</button>
      </div>
    )
  }

  const renderSteps = () => {
    if (steps && steps.length == 0) {
      return renderEmptyState()
    }
    return (
      <>
        <div className="shadow-card">
          { steps && steps.map((x, i) => renderStep(x, i))}
        </div>
        <div className="text-center">
          <button className="page-title-button mt-20" onClick={handleAddStep}>Add Step</button>
        </div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <div className="shadow-card p-30 mb-15 text-center">
          <div className="fs-14 fw-700 mb-5">Important Note</div>
          Worflow steps for sending emails or text messages are not currently automated. The system will simply remind you when these tasks are due and allow you to pre-populate your text message or email when clicking on the task within the app.
          <div className="mt-5">
            <div className="blue-link" onClick={() => navigate("/admin/docs/workflows")}>Learn More</div>
          </div>
        </div>
        { renderSteps() }
        <WorkflowTaskModal
          title={isNewStep ? "New Step" : "Edit Step"}
          isVisible={isAddEditModalVisible}
          setVisible={setAddEditModalVisible}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          handleSave={handleSave}
          employees={employees}
        />
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default NewWorkflowPage;
