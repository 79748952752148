import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Row, Col, Dropdown, Menu, Modal, Grid } from 'antd';
import { cloneDeep, isEmpty } from "lodash";
import { 
  deleteEventQuestionnaire,
  createEventQuestionnaire,
} from '../../../../api';
import useApi from '../../../../hooks/useApi';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import { DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import FloatingContainer from '../../../../components/floatingContainer'
import ShareModal from '../../../../modals/shareModal';
import { MdOutlineClose, MdOutlineIosShare, MdOutlineMoreHoriz, MdCheck, MdOutlineRemoveRedEye, MdOutlineEdit, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FiFileText, FiPlus } from "react-icons/fi";
import { getDatePickerFormat } from '../../../../helpers/dateHelper';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventPlanningTabQuestionnaires= (props) => {

  const { questionnaires = [], event, refreshPage = () => {}, eventQuestionnaires = [], onSelectPlanningTab = () => {}} = props;

  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState({});
  const [isConfirmRemoveQuestionnaireModalVisible, setConfirmRemoveQuestionnaireModalVisible] = useState(false);
  const [isQuestionnaireModalVisible, setIsQuestionnaireModalVisible] = useState(false);
  const [isQuestionnairePreviewModalVisible, setQuestionnairePreviewModalVisible] = useState(false);

  const navigate = useNavigate();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
  }, [])

  const handleRemoveQuestionnaire = (questionnaire) => {
    setSelectedQuestionnaire(questionnaire)
    setConfirmRemoveQuestionnaireModalVisible(true)
  }

  const deleteQuestionnaire = async () => {
    try {
      setConfirmRemoveQuestionnaireModalVisible(false)
      await sendRequest(deleteEventQuestionnaire(event.event_id, selectedQuestionnaire.event_questionnaire_id))
      await refreshPage();
    } catch {}
  }

  const handlePreviewQuestionnaire = (questionnaire) => {
    setQuestionnairePreviewModalVisible(true)
    setSelectedQuestionnaire(questionnaire)
  };

  const handleShareQuestionnaires = (questionnaire) => {
    setSelectedQuestionnaire(questionnaire)
    setShareModalVisible(true)
  };

  const onAddQuestionnaire = async (item) => {
    try {
      const newEventQuestionnaires = cloneDeep(eventQuestionnaires)
      const foundEventQuestionnaire = newEventQuestionnaires.find(x => x.questionnaire_id == item.questionnaire_id)
      if (isEmpty(foundEventQuestionnaire)) {
        await sendRequest(createEventQuestionnaire(event.event_id, { questionnaire_id: item.questionnaire_id }))
        setIsQuestionnaireModalVisible(false)
        await refreshPage()
      } else {
        setIsQuestionnaireModalVisible(false)
      }
    } catch {}
  }
  
  const questionnaireMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/events/${event.event_id}/questionnaires/${item.event_questionnaire_id}`)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handlePreviewQuestionnaire(item)}>
            <MdOutlineRemoveRedEye style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleShareQuestionnaires(item)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share
          </div>
        </Menu.Item>
        <Menu.Item>
          <a href={`${BASE_URL}/questionnaire/${item.event_questionnaire_id}`} target="_blank">
            <PrinterOutlined style={{ marginRight: 8}}/> Print / Download
          </a>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleRemoveQuestionnaire(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderQuestionnaire = (questionnaire, index) => {
    return (
      <div key={index} className="p-10 b-border">
        <Row gutter={[15,15]} align="middle">
          <Col onClick={() => handlePreviewQuestionnaire(questionnaire)}>
            <div className="card-icon"><FiFileText/></div>
          </Col>
          <Col flex={1} onClick={() => handlePreviewQuestionnaire(questionnaire)}>
            <div className="fs-14 fw-700">{questionnaire.questionnaire_name}</div>
            <div className="fs-14 fw-500 c-text-gray line-1-1">{questionnaire.sections.length} { questionnaire.sections.length == 1 ? "section" : "sections" }</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={questionnaireMenu(questionnaire, index)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderPlanningQuestionnairesTab = () => {
    return (
      <div className="shadow-card">
        { eventQuestionnaires.map((x,i) => renderQuestionnaire(x,i))}
        { eventQuestionnaires.length == 0 && (
          <div className="mb-20 pv-50 text-center">
            <div className="ph-20 pb-30 text-center c-text-gray">No questionnaires have been assigned to this event.</div>
            <button className="page-title-button" onClick={() => setIsQuestionnaireModalVisible(true)}>Add Questionnaire</button>
          </div>
        )}
      </div>
    )
  }

  const renderQuestionnaireOption = (item, index) => {
    const questionnaireIdList = eventQuestionnaires.map(x => x.questionnaire_id)
    const alreadyAdded = questionnaireIdList.includes(item.questionnaire_id)
    const borderClass = (index + 1) < questionnaires.length ? "b-border" : "";
    return (
      <div key={index} className={`p-10 ${borderClass}`}>
        <Row gutter={[15,15]} align="middle">
          <Col>
            <div className="card-icon"><FiFileText/></div>
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-700">{item.questionnaire_name}</div>
            <div className="fs-14 fw-500 c-text-gray line-1-1">{item.sections.length} { item.sections.length == 1 ? "section" : "sections" }</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              { alreadyAdded && (
                <MdCheck style={{ fontSize: 24, color: '#00b894'}}/> 
              )}
              { !alreadyAdded && (
                <div className="plus-container" onClick={() => onAddQuestionnaire(item)}>
                  <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderQuestion = (question, index) => {
    var answer = ""
    if (isEmpty(question.answer)) {
      answer = "--"
    } else if (question.type == "checkbox") {
      answer = question.answer.replaceAll(",", ", ")
    } else if (question.type == "date") {
      if (moment(question.answer, "YYYY-MM-DD", true).isValid()) {
        answer = moment(question.answer, "YYYY-MM-DD").format(getDatePickerFormat(accountSettings))
      } else {
        answer = "--"
      }
    } else {
      answer = question.answer
    }

    return (
      <div className="pt-10 ml-5" key={index}>
        <div className="fw-600">{question.label}</div>
        <div className="fw-300 line-breaks">{answer}</div>
      </div>
    )
  }

  const renderSection = (section, index) => {
    const paddingClass = index < selectedQuestionnaire.sections.length - 1 ? "pb-30" : ""
    return (
      <div className={paddingClass} key={index}>
        <div className="fs-18 fw-700 pb-5 bg-gray pv-5 ph-10 radius-8">{ section.name }</div>
        { section.questions.map((x,i) => renderQuestion(x,i))}
      </div>
    )
  }

  const renderQuestionnaireModal = () => {
    return (
      <Modal visible={isQuestionnaireModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ "Add Questionnaire" }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setIsQuestionnaireModalVisible(false)}>
              <MdOutlineClose size={30} color={"#CCC"}/>
            </div>
          </Col>
        </Row>
        <div className="">
          { questionnaires.map((x,i) => renderQuestionnaireOption(x,i))}
          { questionnaires.length == 0 && (
            <div className="text-center p-20">
              <div className="c-text-gray pv-20">There are no questionnaires setup yet.</div>
              <button className="primary-button" style={{ width: 250 }} onClick={() => navigate("/admin/questionnaires")}>Create a Questionnaire</button>
            </div>
          )}
        </div>
      </Modal>
    )
  }

  const renderQuestionnairePreviewModal = () => {
    return (
      <Modal visible={isQuestionnairePreviewModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ selectedQuestionnaire.questionnaire_name }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setQuestionnairePreviewModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          { selectedQuestionnaire.sections && selectedQuestionnaire.sections.map((x,i) => renderSection(x,i))}
          { selectedQuestionnaire.sections && selectedQuestionnaire.sections.length == 0 && (
            <div className="p-30 text-center c-text-gray">No questions yet</div>
          )}
        </div>
      </Modal>
    )
  }

  const renderShareModal = () => {
    return (
      <ShareModal
        isVisible={isShareModalVisible}
        setVisible={setShareModalVisible}
        title="Share Questionnaire"
        link={`/questionnaire/${selectedQuestionnaire.event_questionnaire_id}`}
      />
    )
  }

  const renderConfirmRemoveQuestionnaireModal = () => {
    return (
      <Modal visible={isConfirmRemoveQuestionnaireModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveQuestionnaireModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Questionnaire</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedQuestionnaire.questionnaire_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => deleteQuestionnaire()}>Remove Questionnaire</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveQuestionnaireModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <div className="toolbar-container floating">
        <div className="flex-row flex-middle">
          <div className="blue-link" onClick={() => onSelectPlanningTab(null)}>Planning</div>
          <div className="mh-5 display-flex"><MdOutlineKeyboardArrowRight/></div>
          <div className="fw-600">Questionnaires</div>
        </div>
        <div className="flex-1"></div>
        <div className="toolbar-button" onClick={() => setIsQuestionnaireModalVisible(true)}>
          <FiPlus style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Add { screens.md ? "Questionnaire" : ""}
        </div>
      </div>
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        { renderPlanningQuestionnairesTab() }
      </FloatingContainer>
      { renderShareModal() }
      { renderConfirmRemoveQuestionnaireModal() }
      { renderQuestionnaireModal() }
      { renderQuestionnairePreviewModal() }
    </>
  )
}

export default EventPlanningTabQuestionnaires;
