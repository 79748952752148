import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Dropdown, Menu, Input } from 'antd';
import moment from 'moment';
import { cloneDeep, toLower, isArray } from 'lodash';
import { getPricingGuideRequests, deletePricingGuideRequest } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'
import { formatEventDayMonth } from '../../../helpers/dateHelper';

const BookingRequestsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [selectedBookingRequest, setSelectedBookingRequest] = useState({});

  const [bookingRequests, setBookingRequests] = useState([]);
  const [searchText, setSearchText] = useState("");

  useDocumentTitle("Booking Requests")
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const requestsResults = await sendRequest(getPricingGuideRequests());
      setBookingRequests(requestsResults)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/booking-requests/${item.pricing_guide_request_id}`)}>
            <FiEye style={{ marginRight: 8}}/> View
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const handleDelete = (request) => {
    setSelectedBookingRequest(request)
    setConfirmRemoveVisible(true)
  };

  const confirmDelete = async () => {
    try {
      await sendRequest(deletePricingGuideRequest(selectedBookingRequest.pricing_guide_request_id))
      await refreshPage()
    } finally {
      setConfirmRemoveVisible(false)
    }
  }

  const onSearchTextChange = (text) => {
    setSearchText(text)
  }

  const getFilteredRequests = () => {
    const allRequests = cloneDeep(bookingRequests)
    var filteredRequests = allRequests;

    // Apply search text
    if (searchText != "") {
      filteredRequests = filteredRequests.filter(x => {
        const lowerFirstName = toLower(x.first_name)
        const lowerLastName = toLower(x.last_name)
        const lowerFullName = toLower(x.first_name + " " + x.last_name)

        const matchFirstName = lowerFirstName.includes(toLower(searchText))
        const matchLastName = lowerLastName.includes(toLower(searchText))
        const matchFullName = lowerFullName.includes(toLower(searchText))
        return matchFirstName || matchLastName || matchFullName
      })
    }

    return filteredRequests;
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Booking Requests</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/booking-requests")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
      </Row>
    )
  }

  const renderRequestCard = (request) => {
    const eventDate = moment(request.event_date, "YYYY-MM-DD")
    const eventDay = eventDate.format("dddd").toUpperCase();
    const eventMonthDay = formatEventDayMonth(request.event_date, accountSettings);
    const eventYear = eventDate.format("YYYY");

    const numPackages = isArray(request.selected_services.packages) ? request.selected_services.packages.length : 0
    const numAddOns = isArray(request.selected_services.add_ons) ? request.selected_services.add_ons.length : 0

    return (
      <div className={`admin-event-card`}>
        <div className="admin-event-card-date-box" onClick={() => navigate(`/admin/booking-requests/${request.pricing_guide_request_id}`)}>
          <div className="admin-event-card-date-inner">
            <div className="event-day">{ eventDay }</div>
            <div className="event-date">{ eventMonthDay }</div>
            <div className="event-year">{ eventYear }</div>
          </div>
        </div>
        <div className="admin-event-card--col-2" onClick={() => navigate(`/admin/booking-requests/${request.pricing_guide_request_id}`)}>
          <div className="fw-700 fs-16">{ request.first_name } { request.last_name }</div>
          <div className="c-text-gray fs-14">{numPackages} {numPackages == 1 ? "package" : "packages"}, {numAddOns} {numAddOns == 1 ? "add-on" : "add-ons"}</div>
        </div>
        <div className="admin-event-card--col-3">
          <Dropdown overlay={menu(request)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderRequestResults = () => {
    if (getFilteredRequests().length > 0) {
      return getFilteredRequests().map((request,i) => renderRequestCard(request,i));
    } else {
      return (
        <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
          <div>
            <img src={emptySearchImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No requests found
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Adjust your search and try again!
          </div>
        </FadeWhileInView>
      )
    }
  }

  const renderRequestsBox = () => {
    return (
      <div className="shadow-card">
        <div className="p-15 b-border">
          <div className="display-flex flex-row flex-middle">
            <div className="flex-1">
              <Input className="list-search-input-square" size="large" placeholder="Search requests" value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
            </div>
          </div>
        </div>
        { renderRequestResults() }
      </div>
    )
  }

  const renderBookingRequests = () => {
    if (bookingRequests.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={300}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No booking requests yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Requests will display here when potential clients submit the contact form on a pricing guide.
          </div>
          <button className="small-primary-button mt-30" style={{ width: 200 }} onClick={() => navigate("/admin/pricing-guides")}>Go to Pricing Guides</button>
        </div>
      )
    } else {
      return renderRequestsBox()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderBookingRequests() }
        { renderConfirmRemoveModal() }
      </FloatingContainer>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Booking Request</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this booking request from <span className="fw-700">{selectedBookingRequest.first_name} {selectedBookingRequest.last_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Remove Booking Request</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default BookingRequestsPage;
