import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { isEmpty, get } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import caveatRegular from '../fonts/Caveat/Caveat-Regular.ttf'
import { formatCurrencyString, getNumericCurrency } from '../helpers/contractHelper';
import { formatDateShort } from '../helpers/dateHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

 Font.register({ family: 'Caveat', fonts: [
  { src: caveatRegular },
 ]});

const InvoicePDF = (props) => {
  const { invoice } = props;

  const styles = StyleSheet.create({
    page: {
      paddingTop: 25,
      paddingBottom: 65,
      paddingHorizontal: 25
    },
    row: {
      display: "flex",
      flexDirection: 'row'
    },
    flex0: {
      flex: 0
    },
    flex1: {
      flex: 1
    },
    invoiceCompanyName: {
      fontSize: 14,
      fontFamily: "OpenSans",
      fontWeight: 700,
    },
    invoiceHeader: {
      fontSize: 20,
      fontFamily: "OpenSans",
      textAlign: "right"
    },
    invoiceLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    invoiceData: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
    tableHeaderRow: {
      display: "flex",
      flexDirection: 'row',
      padding: 10,
      backgroundColor: "#F7F7F7",
      borderTop: "1px solid #e8e8e8",
      borderBottom: "1px solid #e8e8e8"
    },
    tableRow: {
      display: "flex",
      flexDirection: 'row',
      padding: 10,
      borderBottom: "1px solid #e8e8e8"
    },
    tableRowNoBorder: {
      display: "flex",
      flexDirection: 'row',
      padding: 10
    },
    tableRowData: {
      fontSize: 10,
      fontFamily: "OpenSans",
      textAlign: 'right'
    },
    tableRowDataLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: 'right'
    },
    tableRowDiscountData: {
      fontSize: 8,
      fontFamily: "OpenSans",
      color: "#536DFE",
      textAlign: 'right'
    },
    tableHeaderLabel: {
      fontSize: 8,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    amountDueBox: {
      backgroundColor: "#F7F7F7",
      padding: 14,
      borderRadius: 4
    },
    amountDueLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: 'center'
    },
    amountDueText: {
      fontSize: 16,
      fontFamily: "OpenSans",
      color: "#536DFE",
      textAlign: 'center',
      marginTop: 2
    }
});

  const getDiscountText = (item) => {
    if (item.discount_type == "PERCENTAGE") {
      return `Discount applied (${item.discount_percentage})`
    } else {
      return "Discount applied"
    }
  }
    
  const renderTableHeader = () => {
    return (
      <View style={[styles.tableHeaderRow, { marginTop: 40 }]}>
        <View style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>ITEM</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={[styles.tableHeaderLabel, { textAlign: "right"}]}>QTY</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={[styles.tableHeaderLabel, { textAlign: "right"}]}>PRICE</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={[styles.tableHeaderLabel, { textAlign: "right"}]}>AMOUNT</Text>
        </View>
      </View>
    )
  }
  
  const renderLabelData = (label, data) => {
    return (
      <View style={styles.row}>
        <View style={{ width: 80 }}>
          <Text style={styles.invoiceLabel}>{label}</Text>
        </View>
        <View style={{  }}>
          <Text style={styles.invoiceData}>{data}</Text>
        </View>
      </View>
    )
  }

  const renderPackageRow = (eventPackage, index) => {
    return (
      <View style={[styles.tableRow]} key={index}>
        <View style={styles.flex1}>
          <Text style={[styles.tableRowData, { textAlign: "left"}]}>{eventPackage.package_name}</Text>
          { eventPackage.discount && (
            <Text style={[styles.tableRowDiscountData, { textAlign: "left", marginLeft: 8 }]}>{ getDiscountText(eventPackage) }</Text>
          )}
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>1</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>{ eventPackage.price }</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>{ eventPackage.price }</Text>
          { eventPackage.discount && (
            <Text style={styles.tableRowDiscountData}>- { eventPackage.discount_amount}</Text>
          )}
        </View>
      </View>
    )
  }

  const renderAddOnRow = (eventAddOn, index) => {
    return (
      <View style={[styles.tableRow]} key={index}>
        <View style={styles.flex1}>
          <Text style={[styles.tableRowData, { textAlign: "left"}]}>{eventAddOn.add_on_name}</Text>
          { eventAddOn.discount && (
            <Text style={[styles.tableRowDiscountData, { textAlign: "left", marginLeft: 8 }]}>{ getDiscountText(eventAddOn) }</Text>
          )}
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>{eventAddOn.quantity}</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>{ eventAddOn.price }</Text>
        </View>
        <View style={{ width: 120 }}>
          { eventAddOn.discount ? (
            <>
              <Text style={styles.tableRowData}>{eventAddOn.sub_total}</Text>
              <Text style={styles.tableRowDiscountData}>- { eventAddOn.discount_total}</Text>
            </>
          ) : (
            <Text style={styles.tableRowData}>{eventAddOn.total}</Text>
          )}
        </View>
      </View>
    )
  }
  
  const renderContent = () => {
    const billToUser = !isEmpty(invoice.bill_to_user) ? invoice.bill_to_user : {}
    const packages = get(invoice, "summary.packages", [])
    const addOns = get(invoice, "summary.add_ons", [])
    const summary = get(invoice, "summary", {})
    const hasTax = getNumericCurrency(summary.tax) != 0
    return (
      <View>
        <View style={styles.row}>
          <View style={styles.flex1}>
            { !isEmpty(invoice.logo) ? (
              <Image source={invoice.logo} style={{ width: 160 }}/>
            ) : (
              <Text style={styles.invoiceCompanyName}>{invoice.company_name}</Text>
            )}
          </View>
          <View style={styles.flex1}>
            <Text style={styles.invoiceHeader}>INVOICE</Text>
          </View>
        </View>
        <View style={[styles.row, { marginTop: 40 }]}>
          <View style={styles.flex1}>
            <Text style={styles.invoiceLabel}>Bill To:</Text>
            <Text style={styles.invoiceData}>{billToUser.first_name} {billToUser.last_name}</Text>
            <Text style={styles.invoiceData}>{billToUser.email}</Text>
          </View>
          <View style={styles.flex1}>
            { renderLabelData("Invoice #:", invoice.invoice_number) }
            { renderLabelData("Invoice Date:", formatDateShort(invoice.invoice_date, invoice.account_settings)) }
            { renderLabelData("Due Date:", formatDateShort(invoice.due_date, invoice.account_settings)) }
          </View>
        </View>
        { renderTableHeader() }
        { packages.map((eventPackage,index) => renderPackageRow(eventPackage, index))}
        { addOns.map((eventAddOn,index) => renderAddOnRow(eventAddOn, index))}
        { hasTax && (
          <>
            <View style={[styles.tableRowNoBorder]}>
              <View style={styles.flex1}></View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowDataLabel, { textAlign: "right"}]}>Subtotal:</Text>
              </View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowData, { textAlign: "right"}]}>{ summary.subtotal }</Text>
              </View>
            </View>
            <View style={[styles.tableRow]}>
              <View style={styles.flex1}></View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowDataLabel, { textAlign: "right"}]}>Tax:</Text>
              </View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowData, { textAlign: "right"}]}>{ summary.tax }</Text>
              </View>
            </View>
          </>
        )}
        <View style={[styles.tableRowNoBorder]}>
          <View style={styles.flex1}></View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>Total:</Text>
          </View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowData}>{ summary.total_price }</Text>
          </View>
        </View>
        <View style={[styles.tableRow]}>
          <View style={styles.flex1}></View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>Payments:</Text>
          </View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowData}>({ summary.total_payments })</Text>
          </View>
        </View>
        <View style={[styles.tableRow]}>
          <View style={styles.flex1}></View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>Balance:</Text>
          </View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>{ summary.balance }</Text>
          </View>
        </View>
        <View style={[styles.row, { marginTop: 40 }]}>
          <View style={styles.flex1}>
            <Text style={styles.invoiceLabel}>Notes:</Text>
            <Text style={[styles.invoiceData, { marginTop: 2, marginRight: 20 }]}>{ invoice.notes }</Text>
          </View>
          <View style={{ width: 160 }}>
            <View style={styles.amountDueBox}>
              <Text style={styles.amountDueLabel}>Amount Due</Text>
              <Text style={styles.amountDueText}>{formatCurrencyString(invoice.amount_due, invoice.currency)}</Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        { renderContent() }
      </Page>
    </Document>
  );
}

export default InvoicePDF;
