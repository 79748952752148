import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import moment from 'moment';
import qs from "qs";
import { isArray, isEmpty, isNull } from 'lodash';
import { Row, Col, Modal, Collapse, Switch } from 'antd';
import AppContext from '../../../app/context';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import ErrorCard from '../../../components/errorCard';
import EventCard from '../../../components/eventCard';
import LoadingSpinner from '../../../components/loading';
import Roles from '../../../constants/roles';
import Permissions from '../../../constants/permissions';
import { hasPermission } from '../../../helpers/permissionHelper';
import { formatEventDateLong, formatTime } from '../../../helpers/dateHelper';
import { 
  getShowAllEventsSummary, 
  getShowAllEventsCalendar, 
  setShowAllEventsSummary, 
  setShowAllEventsCalendar
} from '../../../helpers/eventHelper';
import { getEvents, getEventLogs } from '../../../api';
import { cloneDeep } from 'lodash';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { MdOutlineClose, MdOutlineEventAvailable, MdChecklist, MdManageAccounts } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import checkmarkIcon from '../../../images/checkmark-icon.png';
import welcomeImage from '../../../images/dj-home-preview.png';
import iosDownloadButton from '../../../images/ios-download-button.png';
import androidDownloadButton from '../../../images/android-download-button.png';

const AdminHomePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
  const [isCalendarModalVisible, setCalendarModalVisible] = useState(false);
  const [isWelcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const [showAllCompanyEvents, setShowAllCompanyEvents] = useState(false);
  const [showAllCalendarEvents, setShowAllCalendarEvents] = useState(false);

  useDocumentTitle("Dashboard")
  const navigate = useNavigate();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const { auth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}
  const canViewAllEvents = hasPermission(user, Permissions.VIEW_ALL_EVENTS);
  const canCreateEvents = hasPermission(user, Permissions.CREATE_EVENTS);

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.type == "welcome") {
      setWelcomeModalVisible(true)
    }
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getEvents());
      setEvents(eventResults)

      const shouldShowAllEventsSummary = getShowAllEventsSummary()
      setShowAllCompanyEvents(shouldShowAllEventsSummary)

      const shouldShowAllEventsCalendar = getShowAllEventsCalendar()
      setShowAllCalendarEvents(shouldShowAllEventsCalendar)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const goToMonth = (direction) => {
    const selectedMonthFirstDay = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD")
    let newDate = direction == "forward" ? selectedMonthFirstDay.add(1, "month") : selectedMonthFirstDay.subtract(1, "month");
    let newMonth = newDate.format("MM");
    let newYear = newDate.format("YYYY");
    setCurrentMonth(newMonth)
    setCurrentYear(newYear)
  }

  const closeWelcomeModal = () => {
    setWelcomeModalVisible(false)
    navigate(`/admin/dashboard`, { replace: true })
  }

  const openDownloadAppLink = (platform, closeModal) => {
    if (closeModal) {
      closeWelcomeModal()
    }
    if (platform == "ios") {
      window.open("https://apps.apple.com/us/app/dj-planning-center/id1638751912")
    } else {
      window.open("https://play.google.com/store/apps/details?id=com.djpcapp")
    }
  }

  const addNewEvent = () => {
    setCalendarModalVisible(false)
    navigate(`/admin/events/new?date=${selectedDay}`)
  }

  const goToEvent = (event) => {
    setCalendarModalVisible(false)
    navigate(`/admin/events/${event.event_id}`)
  }

  const displayEventModal = (date) => {
    setSelectedDay(date)
    setCalendarModalVisible(true)
  }

  const getFilteredEvents = (showAll) => {
    var filteredEvents = cloneDeep(events)
    if (canViewAllEvents && !showAll) {
      filteredEvents = filteredEvents.filter(x => {
        if (x.employees) {
          const foundEmployee = x.employees.filter(e => e.user_id == user.user_id).length > 0
          if (foundEmployee) {
            return true
          }
        }
        return false
      })
    }
    return filteredEvents
  }

  const getEventCountForDate = (date) => {
    var filteredEvents = getFilteredEvents(showAllCalendarEvents)
    filteredEvents = filteredEvents.filter((x) => x.event_date == date)
    return filteredEvents.length
  }

  const onShowAllEventsSummaryChange = (value) => {
    setShowAllCompanyEvents(value)
    setShowAllEventsSummary(value)
  }

  const onShowAllEventsCalendarChange = (value) => {
    setShowAllCalendarEvents(value)
    setShowAllEventsCalendar(value)
  }

  const renderBlankCalendarCell = (key) => {
    return <div key={key}></div>
  }

  const renderCalendarCell = (day, key) => {
    const currentDay = day > 9 ? day : `0${day}`
    const currentCellDate = `${currentYear}-${currentMonth}-${currentDay}`
    const numEvents = getEventCountForDate(currentCellDate)
    const isToday = currentCellDate == moment().format("YYYY-MM-DD")

    var selectedClass = ""
    if (numEvents > 0) {
      selectedClass = "booked"
    } else if (isToday) {
      selectedClass = "today"
    }
    return (
      <div key={key}>
        <div className={selectedClass} onClick={() => displayEventModal(currentCellDate)}>{day}</div>
      </div>
    )
  }

  const renderCalendarContent = () => {
    const selectedMonth = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD")
    const firstDayIndex = selectedMonth.format("E");
    const adjustedFirstDayIndex = firstDayIndex == 7 ? 0 : firstDayIndex;
    const daysInMonth = selectedMonth.daysInMonth()
    var numRows = Math.ceil((Number(daysInMonth) + Number(adjustedFirstDayIndex)) / 7);;

    var calendarContent = []
    var currentDayCounter = 1;
    var keyCounter = 1;
    let isFirstRow = true;

    for (let i = 1; i <= numRows; i++) {
      let rowContent = [];
      for (let x = 0; x < 7; x++) {
        // If first row...
        if (isFirstRow) {
          if (x < adjustedFirstDayIndex) {
            // Render blank cell
            rowContent.push(renderBlankCalendarCell(keyCounter));
          } else {
            // Otherwise, render calendar day
            rowContent.push(renderCalendarCell(currentDayCounter, keyCounter));
            currentDayCounter++;
          }
          // Move past first row if it's the last day of the row
          isFirstRow = x == 6 ? false : true;
        } else {
          // If counter is last than or equal to the number of days in the month
          if (currentDayCounter <= daysInMonth) {
            // Render calendar day
            rowContent.push(renderCalendarCell(currentDayCounter, keyCounter));
            currentDayCounter++;
          } else {
            // Otherwise, render blank cell
            rowContent.push(renderBlankCalendarCell(keyCounter));
          }
        }
        keyCounter++;
      }

      calendarContent.push(
        <div className="admin-calendar--week-row" key={i}>
          {rowContent}
        </div>
      );
    }

    return calendarContent
  }

  const renderCalendar = () => {
    const headerText = `${moment(currentMonth, "MM").format("MMMM")} ${currentYear}`
    return (
      <div className="shadow-card admin-calendar">
        <div className="display-flex flex-middle p-10">
          <div className="flex-0">
            <div className="admin-calendar--arrow" onClick={() => goToMonth("back")}><FiChevronLeft/></div>
          </div>
          <div className="flex-1 text-center fw-700">
            {headerText}
          </div>
          <div className="flex-0">
            <div className="admin-calendar--arrow" onClick={() => goToMonth("forward")}><FiChevronRight/></div>
          </div>
        </div>
        <div className="admin-calendar--day-header">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        { renderCalendarContent() }
      </div>
    )
  }

  const renderWelcomeSubtitle = (subtitle, linkText, link) => {
    return (
      <>
        <div className="fs-16 c-text-gray">{subtitle}</div>
        { !isNull(linkText) && (
          <div className="blue-link flex-row flex-middle" onClick={() => navigate(link)}>{ linkText } <BsArrowRight style={{ marginLeft: 5 }}/></div>
        )}
      </>
    )
  }

  const renderWelcomeMessage = () => {
    // Get first event
    const todayDateString = moment().format("YYYY-MM-DD");
    const allEvents = cloneDeep(events)
    const allUpcomingEvents = allEvents.filter(x => x.event_date >= todayDateString)
    var nextEvent = allUpcomingEvents.length > 0 ? allUpcomingEvents[0] : {};
    var hasUpcomingEvent = !isEmpty(nextEvent);
    var daysUntilEvent = 0;
    if (hasUpcomingEvent) {
      const nextEventDate = moment(nextEvent.event_date, "YYYY-MM-DD").startOf('day');
      daysUntilEvent = nextEventDate.diff(moment().startOf('day'), "days")
    }
    if (allEvents.length == 0 && user.role == Roles.OWNER) {
      return renderWelcomeSubtitle("Let's get your account setup.", "Get started", "/admin/docs/setup")
    } else if (!hasUpcomingEvent && canCreateEvents) {
      return renderWelcomeSubtitle("You have no events coming up.", "Create event", "/admin/events/new")
    } else if (!hasUpcomingEvent && !canCreateEvents) {
      return renderWelcomeSubtitle("You have no events coming up.", null, null)
    } else if (hasUpcomingEvent && daysUntilEvent == 0) {
      return renderWelcomeSubtitle(`You have an event today!`, "View event", `/admin/events/${nextEvent.event_id}`)
    } else if (hasUpcomingEvent && daysUntilEvent == 1) {
      return renderWelcomeSubtitle(`Your next event is tomorrow!`, "View event", `/admin/events/${nextEvent.event_id}`)
    } else if (hasUpcomingEvent && daysUntilEvent > 1) {
      return renderWelcomeSubtitle(`Your next event is in ${daysUntilEvent} days.`, "View event", `/admin/events/${nextEvent.event_id}`)
    }
  }

  const renderHeader = () => {
    // Get greeting
    const hour = moment().hour()
    var timeOfDay = ""
    if (hour > 17) {
      timeOfDay = "evening";
    } else if (hour > 12) {
      timeOfDay = "afternoon"
    } else {
      timeOfDay = "morning"
    }
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={40} maxWidth={1000}>
        <div className="fs-30 fw-700">Good {timeOfDay}, {auth.user && auth.user.first_name}!</div>
        { renderWelcomeMessage() }
      </FloatingContainer>
    )
  }

  const renderCalendarModalEvent = (event, index) => {
    const startEndTime = formatTime(event.start_time, accountSettings) + " - " +  formatTime(event.end_time, accountSettings)
    var venueName = ""
    if (isArray(event.venues) && event.venues.length > 0) {
      const venue = event.venues[0]
      if (venue.city) {
        venueName = `${venue.venue_name} (${venue.city}, ${venue.state})`
      } else {
        venueName = venue.venue_name
      }
    } else {
      venueName = event.account_event_type_name
    }
    return (
      <div className="bg-gray radius-8 p-15 mb-15" key={index} onClick={() => goToEvent(event)}>
        <div className="fw-700 fs-16 line-1-2">{ event.event_name }</div>
        <div className="fw-500 fs-14 c-text-gray">{ venueName }</div>
        <div className="fw-500 fs-14 c-text-gray">{ startEndTime }</div>
      </div>
    )
  }

  const renderCalendarModalContent = () => {
    const filteredEvents = getFilteredEvents(showAllCalendarEvents).filter((x) => x.event_date == selectedDay)
    const prefix = showAllCalendarEvents ? "Your company has" : "You have";

    if (filteredEvents.length > 0) {
      return (
        <div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">{`${prefix} ${filteredEvents.length} ${ filteredEvents.length == 1 ? "event" : "events" } on this day.`}</div>
          {filteredEvents.map((x,i) => renderCalendarModalEvent(x,i)) }
        </div>
      )
    } else {
      return (
        <div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">You are available!</div>
          <div className="text-center mt-20 mb-20">
            <img src={checkmarkIcon} width={250}/>
          </div>
        </div>
      )
    }
  }

  const renderCalendarModal = () => {
    return (
      <Modal visible={isCalendarModalVisible} closable={false} footer={null} width={500} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setCalendarModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">{formatEventDateLong(selectedDay, accountSettings)}</div>
          { renderCalendarModalContent() }
          { canCreateEvents && (
            <>
              <button className="primary-button" type="button" onClick={() => addNewEvent()}>New Event</button>
              <div className="text-center mt-15">
                <div className="blue-link" onClick={() => setCalendarModalVisible(false)}>Cancel</div>
              </div>
            </>
          )}
        </div>
      </Modal>
    )
  }

  const renderWelcomeModal = () => {
    return (
      <Modal visible={isWelcomeModalVisible} footer={null} onCancel={() => closeWelcomeModal()} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => closeWelcomeModal()}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="fw-700 fs-18 text-center mt-20 mb-30">Welcome to DJ Planning Center!</div>
          <div className="text-center b-border">
            <img src={welcomeImage} style={{ width: 200}}/>
          </div>
          <div className="fw-500 fs-14 text-center mt-30 mb-20">Our mobile app is the best way to manage your events on the go. Download the app today to get the most out of your experience!</div>
          <div class="text-center">
            <span onClick={() => openDownloadAppLink("android", true)}><img src={androidDownloadButton} style={{ width: 150, marginRight: 10 }}/></span>
            <span onClick={() => openDownloadAppLink("ios", true)}><img src={iosDownloadButton} style={{ width: 150 }}/></span>
          </div>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => closeWelcomeModal()}>Not Now</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderEventQuickLink = () => {
    if (canCreateEvents) {
      return renderQuickLink(<MdOutlineEventAvailable/>, "EVENTS", "Create an event", "/admin/events/new")
    } else {
      return renderQuickLink(<MdOutlineEventAvailable/>, "EVENTS", "View my events", "/admin/events")
    }
  }

  const renderAccountQuickLink = () => {
    if (user.role == Roles.OWNER) {
      return renderQuickLink(<MdManageAccounts/>, "ACCOUNT", "Setup my account", "/admin/docs/setup")
    } else {
      return renderQuickLink(<MdManageAccounts/>, "ACCOUNT", "Manage my account", "/admin/account")
    }
  }

  const renderQuickLink = (icon, subtitle, title, link) => {
    return (
      <Col xs={24} md={24} lg={12} xl={8}>
        <div className="gray-hover-card p-20" onClick={() => navigate(link)}>
          <div className="quick-link-row">
            <div className="quick-link-col-1">
              <div className="quick-link-icon">{icon}</div>
            </div>
            <div className="quick-link-col-2">
              <div className="fs-10 fw-600 c-text-gray">{subtitle}</div>
              <div className="fs-16 fw-700">{title}</div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const renderEventSection = () => {
    const todayDateString = moment().format("YYYY-MM-DD");
    const thisYear = moment().format("YYYY");
    var allEvents = getFilteredEvents(showAllCompanyEvents)
    // Only get this year's events
    const eventsThisYear = allEvents.filter(x => {
      const year = moment(x.event_date, "YYYY-MM-DD").format("YYYY")
      return year == thisYear
    })
    const upcomingEventsThisYear = eventsThisYear.filter(x => x.event_date >= todayDateString)
    const completedEventsThisYear = eventsThisYear.filter(x => x.event_date < todayDateString)
    const allFutureEvents = allEvents.filter(x => x.event_date >= todayDateString)
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={40} maxWidth={1000}>
        <div className="flex-row flex-middle mv-10 ph-5">
          <div className="flex-0">
            <div className="fs-24 fw-700">Events</div>
          </div>
          { canViewAllEvents ? (
            <div className="flex-1 text-right">
              <div style={{ display: 'inline-block'}}>
                <div className="flex-row flex-middle">
                  <Switch size="small" checked={showAllCompanyEvents} onChange={() => onShowAllEventsSummaryChange(!showAllCompanyEvents)} style={{ marginRight: 8 }}/> Show all company events
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1 text-right">
              <div style={{ display: 'inline-block'}}>
                <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/events")}>View all events <BsArrowRight style={{ marginLeft: 5 }}/></div>
              </div>
            </div>
          )}
        </div>
        <Row gutter={[20,20]} className="mt-10">
          <Col xs={12} xl={6}>
            <div className="border radius-8 p-15">
              <div className="fs-16 fw-700">Completed events</div>
              <div className="fs-10 fw-600 c-text-gray">THIS YEAR</div>
              <div className="fs-50 fw-300">{completedEventsThisYear.length}</div>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="border radius-8 p-15">
              <div className="fs-16 fw-700">Remaining events</div>
              <div className="fs-10 fw-600 c-text-gray">THIS YEAR</div>
              <div className="fs-50 fw-300">{upcomingEventsThisYear.length}</div>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="border radius-8 p-15">
              <div className="fs-16 fw-700">Total events</div>
              <div className="fs-10 fw-600 c-text-gray">THIS YEAR</div>
              <div className="fs-50 fw-300">{eventsThisYear.length}</div>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="border radius-8 p-15">
              <div className="fs-16 fw-700">Future events</div>
              <div className="fs-10 fw-600 c-text-gray">ALL BOOKINGS</div>
              <div className="fs-50 fw-300">{allFutureEvents.length}</div>
            </div>
          </Col>
        </Row>
        { canViewAllEvents && (
          <div className="flex-1 text-right mt-10">
            <div style={{ display: 'inline-block'}}>
              <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/events")}>View all events <BsArrowRight style={{ marginLeft: 5 }}/></div>
            </div>
          </div>
        )}
      </FloatingContainer>
    )
  }

  const renderContent = () => {
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <>
        { renderHeader() }
        <FloatingContainer className="ph-20 bg-white" verticalPadding={0} maxWidth={1000}>
          <div className="b-border"></div>
        </FloatingContainer>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={40} maxWidth={1000}>
          <div className="fs-24 fw-700">Quick links</div>
          <Row gutter={[20,20]} className="mt-10">
            { renderQuickLink(<MdChecklist/>, "TASKS", "View my tasks", "/admin/tasks")}
            { renderEventQuickLink() }
            { renderAccountQuickLink() }
          </Row>
        </FloatingContainer>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={0} maxWidth={1000}>
          <div className="b-border"></div>
        </FloatingContainer>
        { renderEventSection() }
        {/* <FloatingContainer className="ph-20 bg-white" verticalPadding={0} maxWidth={1000}>
          <div className="b-border"></div>
        </FloatingContainer>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={40} maxWidth={1000}>
          <div className="fs-24 fw-700">Client Activity</div>
          <div className="flex-row flex-end mv-10 ph-5">
            <div className="flex-1">
              <div className="fs-10 fw-600 c-text-gray">RECENT</div>
            </div>
            <div className="flex-1 text-right">
              <div style={{ display: 'inline-block'}}>
                <div className="blue-link flex-row flex-1 flex-middle">View all activity <BsArrowRight style={{ marginLeft: 5 }}/></div>
              </div>
            </div>
          </div>
          <div className="t-border b-border pv-10 mb-10">
            <div className="fs-14 fw-500">Mary made an update to the Must Play playlist!</div>
            <div className="fs-10 fw-500 c-text-gray">3 days ago</div>
          </div>
        </FloatingContainer> */}
        <FloatingContainer className="ph-20 bg-white" verticalPadding={0} maxWidth={1000}>
          <div className="b-border"></div>
        </FloatingContainer>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={40} maxWidth={1000}>
          <div className="fs-24 fw-700">Check Availability</div>
          <Row gutter={[15,15]} className="mt-10">
            <Col xs={24} lg={16} xl={12}>
              { renderCalendar() }
            </Col>
            { canViewAllEvents && (
              <Col xs={24}>
                <div className="flex-row flex-middle">
                  <Switch size="small" checked={showAllCalendarEvents} onChange={() => onShowAllEventsCalendarChange(!showAllCalendarEvents)} style={{ marginRight: 8 }}/> Show all company events
                </div>
              </Col>
            )}
          </Row>
        </FloatingContainer>
        <FloatingContainer className="bg-gray text-center" verticalPadding={80}>
          <div className="fs-24 fw-700 line-1-2">Download the app.</div>
          <div className="mt-30">
            <a href="https://play.google.com/store/apps/details?id=com.djpcapp" target="_blank">
              <img src={androidDownloadButton} height={50}/>
            </a>
            <a href="https://apps.apple.com/us/app/dj-planning-center/id1638751912" target="_blank">
              <img src={iosDownloadButton} height={50} className="ml-10"/>
            </a>
          </div>
        </FloatingContainer>
        { renderCalendarModal() }
        { renderWelcomeModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default AdminHomePage;
