import React, { useEffect, useState } from "react";
import qs from "qs";
import { isEmpty, get, isUndefined, isNull } from 'lodash';
import { useLocation, useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import PaymentProcessorStatusTypes from '../../../constants/paymentProcessorStatusTypes';
import { Form, Row, Col, Modal, notification, Tag, Checkbox, Alert, Tooltip } from 'antd';
import { 
  getAccountDetails, 
  upsertAccountFinancials, 
  getPayPalSettings,
  getPayPalSignupLink, 
  getPayPalSellerStatus, 
  createStripeAccount, 
  getStripeAccountLink,
  getStripeSettings,
  getCurrencies,
  updateAccountCurrency,
  updatePayPalPaymentMethods,
  deleteStripeAccount,
  deletePayPalSettings,
  getManualPaymentMethods,
  createManualPaymentMethod,
  updateManualPaymentMethod,
  deleteManualPaymentMethod,
  getAccountSettings,
  updateStripeBankTransfer,
  getLegacyInvoiceSummary
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import StatusTag from '../../../components/statusTag';
import { MdArrowForwardIos, MdOutlineClose, MdCheck } from "react-icons/md";
import {
  renderTextAreaField,
  renderURLField,
  renderSearchSelectField,
  renderInputField,
  renderCheckboxField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';
import payPalLogo from '../../../images/paypal-logo.png';
import stripeLogo from '../../../images/stripe-logo.png';
import { LoadingOutlined } from '@ant-design/icons';
import { getCountryOptions } from '../../../helpers/addressHelper';
import { FiCheck, FiX } from "react-icons/fi";

const CompanyInvoicesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isGeneratingLink, setGeneratingLink] = useState(false);
  const [isProcessingStripeLink, setProcessingStripeLink] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [accountSettings, setAccountSettings] = useState({});
  const [isUpdatePaymentsModalVisible, setUpdatePaymentsModalVisible] = useState(false);
  const [isAddManualModalVisible, setAddManualModalVisible] = useState(false);
  const [isConfirmRemovePaymentMethodModalVisible, setConfirmRemovePaymentMethodModalVisible] = useState(false);
  const [isEnableBankTransferModelVisible, setEnableBankTransferModelVisible] = useState(false);
  const [isConfirmDisableBankTransferModalVisible, setConfirmDisableBankTransferModalVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedManualPaymentMethod, setSelectedManualPaymentMethod] = useState({});
  const [legacyInvoiceSummary, setLegacyInvoiceSummary] = useState({});
  const [isNewInvoicesEnabled, setNewInvoicesEnabled] = useState(false);
  const [includeLink, setIncludeLink] = useState(false);
  // PayPal
  const [payPalSettings, setPayPalSettings] = useState({});
  const [payPalAllowCredit, setPayPalAllowCredit] = useState(false);
  const [payPalAllowVenmo, setPayPalAllowVenmo] = useState(false);
  const [isConfirmDisconnectPayPalModalVisible, setConfirmDisconnectPayPalModalVisible] = useState(false);
  // Stripe
  const [stripeSettings, setStripeSettings] = useState({});
  const [isConnectStripeModalVisible, setConnectStripeModalVisible] = useState(false);
  const [isConfirmDisconnectStripeModalVisible, setConfirmDisconnectStripeModalVisible] = useState(false);
  const [isBankTransferAllowed, setBankTransferAllowed] = useState(false);
  const [isBankTransferAvailable, setBankTransferAvailable] = useState(false);
  const [isBankTransferEnabled, setBankTransferEnabled] = useState(false);
  const [bankTransferTitle, setBankTransferTitle] = useState("Bank transfer");
  const [bankTransferFee, setBankTransferFee] = useState("");
  // Manual Payment Methods
  const [manualPaymentMethods, setManualPaymentMethods] = useState([]);

  useDocumentTitle("Invoice Settings")
  const navigate = useNavigate();
  const location = useLocation()
  const [_, setCurrency] = useCurrency()
  const [hasBetaFeature] = useBetaFeatures()
  const [updatePaymentsForm] = Form.useForm();
  const [connectStripeForm] = Form.useForm();
  const [manualPaymentMethodForm] = Form.useForm();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
    checkForRedirectQueryStrings()

    // var id = "paypal-js"
    // var script = null
    // if (!document.getElementById(id)) {
    //   script = document.createElement("script");
    //   script.id = id
    //   script.async = true
    //   script.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
    //   var ref = document.getElementsByTagName("script")[0]
    //   ref.parentNode.insertBefore(script, ref)
    //   // document.body.appendChild(script);
    // }

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);

  const checkForRedirectQueryStrings = async () => {
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    // if (queryStrings["merchantIdInPayPal"]) {

    // }
    // const statusResponse = await sendRequest(getPayPalSellerStatus())
    // setPayPalSettings(statusResponse)
  }

  const refreshPage = async () => {
    try {
      const data = getCountryOptions()
      setCountries(data);

      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)

      const accountSettingsResults = await sendRequest(getAccountSettings())
      setAccountSettings(accountSettingsResults)

      const legacySummaryResults = await sendRequest(getLegacyInvoiceSummary())
      setLegacyInvoiceSummary(legacySummaryResults)

      const betaResults = await hasBetaFeature(BetaFeatures.INVOICES)
      const isInvoicesEnabled = FeatureFlags.INVOICES || betaResults
      setNewInvoicesEnabled(isInvoicesEnabled)

      if (isInvoicesEnabled) {
        const payPalSettingsResponse = await sendRequest(getPayPalSellerStatus())
        console.log(payPalSettingsResponse)
        setPayPalSettings(payPalSettingsResponse)
        setPayPalAllowCredit(payPalSettingsResponse.allow_credit)
        setPayPalAllowVenmo(payPalSettingsResponse.allow_venmo)

        const stripeSettingsResponse = await sendRequest(getStripeSettings())
        setStripeSettings(stripeSettingsResponse)

        var available = false
        if (accountDetailsResults.currency == "USD") {
          const usBankStatus = get(stripeSettingsResponse, "capabilities.us_bank_account_ach_payments", "inactive")
          available = usBankStatus == "active"
          setBankTransferTitle("Bank transfer (ACH)")
          setBankTransferFee("0.8% (capped at $5.00)")
          setBankTransferAllowed(true)
        } else if (accountDetailsResults.currency == "GBP") {
          const bacsStatus = get(stripeSettingsResponse, "capabilities.bacs_debit_payments", "inactive")
          available = bacsStatus == "active"
          setBankTransferTitle("Bank debit")
          setBankTransferFee("")
          setBankTransferAllowed(true)
        }

        setBankTransferAvailable(available)
        setBankTransferEnabled(stripeSettingsResponse.bank_transfers_enabled ?? false)

        const manualPaymentMethodsResponse = await sendRequest(getManualPaymentMethods())
        setManualPaymentMethods(manualPaymentMethodsResponse)

        const currencyResults = await sendRequest(getCurrencies())
        const items = currencyResults.map((x,i) => {
          return {
            text: `${x.name} (${x.symbol})`,
            value: x.code
          }
        })
        setCurrencies(items)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const savePaymentInformation = async (values) => {
    try {
      const body = {
        payment_link: values.payment_link,
        default_invoice_notes: values.default_invoice_notes
      }
      await sendRequest(upsertAccountFinancials(accountDetails.account_id, body))
      await refreshPage()
      setUpdatePaymentsModalVisible(false)
    } catch (error) {
      setUpdatePaymentsModalVisible(false)
    }
  }

  const isValidLink = (link) => {
    return !isUndefined(link) && !isNull(link) && link != ""
  }

  const saveManualPaymentMethod = async (values) => {
    try {
      const body = {
        ...values,
        link: includeLink && isValidLink(values.link) ? values.link : null
      }
      if (isEmpty(selectedManualPaymentMethod)) {
        await sendRequest(createManualPaymentMethod(body))
      } else {
        await sendRequest(updateManualPaymentMethod(selectedManualPaymentMethod.payment_method_id, body))
      }
      await refreshPage()
      setAddManualModalVisible(false)
    } catch (error) {
      setAddManualModalVisible(false)
    }
  }

  const onStripeConnectContinue = async (values) => {
    try {
      if (isProcessingStripeLink) { return }

      setProcessingStripeLink(true)

      // Update currency (if needed)
      if (accountDetails.currency != values["currency"]) {
        await sendRequest(updateAccountCurrency({ currency: values["currency"] }))
        setCurrency({})
      }

      // Create stripe account (if needed)
      await sendRequest(createStripeAccount({ country: values["country"], currency: values["currency"] }))

      // Get account link for onboarding
      const accountLinkResults = await sendRequest(getStripeAccountLink())

      // Redirect to account link
      window.location.href = accountLinkResults.url
    } catch (error) {
      displayError("Error", "Something went wrong. Please try again.")
      setProcessingStripeLink(false)
    }
  }

  const startUpdatePaymentInformation = () => {
    updatePaymentsForm.setFieldsValue( { payment_link: accountDetails.payment_link, default_invoice_notes: accountDetails.default_invoice_notes })
    setUpdatePaymentsModalVisible(true)
  }

  const displayError = (title, desc) => {
    notification.error({
      message: 'Error!',
      description: 'Something went wrong. Please try again.',
      duration: 2
    });
  }

  const generateSignupLink = async () => {
    try {
      if (isGeneratingLink) { return }

      setGeneratingLink(true)

      const results = await sendRequest(getPayPalSignupLink())

      document.getElementById("pay-pal-button").href = `${results.link}&displayMode=minibrowser`
      document.getElementById("pay-pal-button").click()

      setGeneratingLink(false)
    } catch (error) {
      displayError("Error", "Something went wrong. Please try again.")
      setGeneratingLink(false)
    }
  }

  const onConnectStripe = async () => {
    try {
      if (stripeSettings.status == PaymentProcessorStatusTypes.PROCESSING) {
        setProcessingStripeLink(true)

        // Get account link for onboarding
        const accountLinkResults = await sendRequest(getStripeAccountLink())

        window.location.href = accountLinkResults.url
        return
      } else {
        connectStripeForm.resetFields()
        connectStripeForm.setFieldsValue({ country: accountSettings.country, currency: accountDetails.currency })
        setConnectStripeModalVisible(true)
      }
    } catch (error) {
      displayError("Error", "Something went wrong. Please try again.")
      setProcessingStripeLink(false)
    }
  }

  const onDisconnectStripe = async () => {
    try {
      await sendRequest(deleteStripeAccount())
      refreshPage()
      setConfirmDisconnectStripeModalVisible(false)
    } catch {
      setConfirmDisconnectStripeModalVisible(false)
    }
  }

  const onDisconnectPayPal = async () => {
    try {
      await sendRequest(deletePayPalSettings())
      refreshPage()
      setConfirmDisconnectPayPalModalVisible(false)
    } catch {
      setConfirmDisconnectPayPalModalVisible(false)
    }
  }

  const onRemovePaymentMethod = (item) => {
    setSelectedManualPaymentMethod(item)
    setConfirmRemovePaymentMethodModalVisible(true)
  }

  const onConfirmRemovePaymentMethod = async () => {
    try {
      // TODO: Validate payment method is not in use
      await sendRequest(deleteManualPaymentMethod(selectedManualPaymentMethod.payment_method_id))
      refreshPage()
      setConfirmRemovePaymentMethodModalVisible(false)
    } catch {
      setConfirmRemovePaymentMethodModalVisible(false)
    }
  }

  const onConfirmDisableBankTransfer = async () => {
    try {
      await toggleBankTransfer(false)
      setConfirmDisableBankTransferModalVisible(false)
    } catch {
      setConfirmDisableBankTransferModalVisible(false)
    }
  }

  const onPayPalCreditCheckboxUpdate = async (value) => {
    try {
      setPayPalAllowCredit(value)
      await sendRequest(updatePayPalPaymentMethods({ allow_credit: value, allow_venmo: payPalAllowVenmo }))
      refreshPage()
    } catch {}
  }

  const onPayPalVenmoCheckboxUpdate = async (value) => {
    try {
      setPayPalAllowVenmo(value)
      await sendRequest(updatePayPalPaymentMethods({ allow_credit: payPalAllowCredit, allow_venmo: value }))
      refreshPage()
    } catch {}
  }

  const onAddManualPaymentMethod = () => {
    setSelectedManualPaymentMethod({})
    setAddManualModalVisible(true)
    setIncludeLink(false)
    manualPaymentMethodForm.resetFields()
  }

  const onEddManualPaymentMethod = (item) => {
    setSelectedManualPaymentMethod(item)
    setAddManualModalVisible(true)
    const shouldIncludeLink = isValidLink(item.link)
    setIncludeLink(shouldIncludeLink)
    manualPaymentMethodForm.setFieldsValue({ payment_method_name: item.payment_method_name, instructions: item.instructions, include_link: shouldIncludeLink, link: item.link })
  }

  const onToggleBankTransfer = (value) => {
    if (!isBankTransferAvailable) {
      setEnableBankTransferModelVisible(true)
      return
    } else if (!value) {
      setConfirmDisableBankTransferModalVisible(true)
      return
    }
    toggleBankTransfer(value)
  }

  const toggleBankTransfer = async (value) => {
    try {
      await sendRequest(updateStripeBankTransfer({ bank_transfers_enabled: value }))
      refreshPage()
    } catch {
      // TODO: error
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Invoices & Payments
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Invoices & Payments</div>
      </div>
    )
  }

  const renderPaymentsModal = () => {
    return (
      <Modal visible={isUpdatePaymentsModalVisible} footer={null} onCancel={() => setUpdatePaymentsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Invoice Settings</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdatePaymentsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updatePaymentsForm} layout="vertical" name="verify" onFinish={savePaymentInformation}>
            { isNewInvoicesEnabled && (
              <div className="bg-gray p-10 mb-10 mt-10">
                The settings below are only used for legacy invoices. Please use Invoice Templates and Payment Methods for all future invoices.
              </div>
            )}
            <div className="mt-10 mb-1">
              { renderURLField("Payment Link", "payment_link", false, false, "https://www.example.com") }
            </div>
            <div className="mt-10 mb-1">
              { renderTextAreaField("Default Notes", "default_invoice_notes", 3, false, "Thank you for your business!") }
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdatePaymentsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderManualPaymentMethodsModal = () => {
    const isNew = isEmpty(selectedManualPaymentMethod)
    return (
      <Modal visible={isAddManualModalVisible} footer={null} onCancel={() => setAddManualModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isNew ? "Add" : "Edit"} Manual Payment Method</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddManualModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={manualPaymentMethodForm} layout="vertical" name="verify" onFinish={saveManualPaymentMethod}>
            <div className="mt-20">
              { renderInputField("Payment Method Name", "payment_method_name", true, false, "ex. Check, E-Transfer, etc.") }
            </div>
            <div className="mt-10 mb-10">
              { renderTextAreaField("Payment Instructions", "instructions", 3, true, "Instructions for payment") }
            </div>
            <div className="mt-10 mb-10">
              { renderCheckboxField("Include Link", "include_link", () => setIncludeLink(!includeLink), "Allows you to enter a generic payment link that will be used when a client chooses this payment type.")}
            </div>
            { includeLink && (
              <div className="mt-10 mb-10">
                <div className="bg-gray p-10">Enter a URL below if you would like to navigate your client to a generic link when they choose this payment type.</div>
                { renderURLField("Link", "link", false, false, "https://www.example.com") }
              </div>
            )}
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setAddManualModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderInvoiceSection = () => {
    return (
      <div className="shadow-card p-20 mb-20">
        <Row className="b-border pb-15 mb-15">
          <Col flex={1}>
            { isNewInvoicesEnabled ? (
              <div className="fs-20 fw-700 line-1">Invoice Settings <StatusTag status="LEGACY" size="medium" className="yellow ml-10" /></div>
            ) : (
              <div className="fs-20 fw-700 line-1">Invoice Settings</div>
            )}
          </Col>
          <Col>
            <div className="blue-link" onClick={() => startUpdatePaymentInformation()}>Edit</div>
          </Col>
        </Row>
        { isNewInvoicesEnabled && (
          <div className="bg-gray p-10 mb-10">
            The settings below are only used for legacy invoices. Please use Invoice Templates and Payment Methods for all future invoices.
          </div>
        )}
        <div className="fs-14 fw-700">Payment Link</div>
        <div className="fs-14 mb-15">
          { accountDetails.payment_link ? accountDetails.payment_link : "--"}
        </div>
        <div className="fs-14 fw-700">Default Notes</div>
        <div className="fs-14 mb-15 line-breaks">
          { accountDetails.default_invoice_notes ? accountDetails.default_invoice_notes : "--"}
        </div>
      </div>
    )
  }

  // PAYPAL SECTIONS
  const renderPayPalSignUpButton = () => {
    if (isGeneratingLink) {
      return (
        <button className="small-primary-button" style={{ width: 100 }}><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="small-primary-button" style={{ width: 100 }} onClick={generateSignupLink}>Connect</button>
      )
    }
  }

  const renderPayPalSection = () => {
    if (payPalSettings.status == PaymentProcessorStatusTypes.CONNECTED) {
      return (
        <>
          <div className="border p-20 radius-8 mt-15">
            <Row align="middle">
              <Col flex={1}>
                <img src={payPalLogo} width={100}/>
              </Col>
              <Col flex={0}>
                <div className={`status-tag green medium`}>CONNECTED</div>
              </Col>
              <Col xs={24} className="">
                <div className="mt-15">Accept PayPal, credit cards, and debit cards via PayPal.</div>
                <div className="fw-700 mt-30">Payment methods:</div>
                <div className="display-flex flex-middle mt-5"><FiCheck style={{ marginRight: 5 }}/>Credit / debit cards</div>
                <div className="display-flex flex-middle mt-5"><FiCheck style={{ marginRight: 5 }}/>PayPal checkout</div>
                <div className="fw-700 mt-30">Merchant ID:</div>
                <div className="mt-5">{payPalSettings.merchant_id}</div>
                {/* { stripeSettings.status != PaymentProcessorStatusTypes.CONNECTED && (
                  <div className="mt-5">
                    <Checkbox checked={payPalAllowCredit} onChange={(e) => onPayPalCreditCheckboxUpdate(e.target.checked)}>Accept credit/debit cards</Checkbox>
                  </div>
                )}
                <div className="mt-5">
                  <Checkbox checked={payPalAllowVenmo} onChange={(e) => onPayPalVenmoCheckboxUpdate(e.target.checked)}>Accept Venmo</Checkbox>
                </div> */}
                {/* <div className="mt-5">
                  <Tooltip title="Accepting PayPal payments is required when connecting your PayPal account.">
                    <Checkbox checked={payPalAllowVenmo} onChange={(e) => {}} disabled={true}>Accept PayPal</Checkbox>
                  </Tooltip>
                </div> */}
                <div className="mt-20">
                  <div className="blue-link" onClick={() => setConfirmDisconnectPayPalModalVisible(true)}>Disconnect</div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    } else if (payPalSettings.status == PaymentProcessorStatusTypes.PROCESSING) {
      return (
        <>
          <div className="border p-20 radius-8 mt-15">
            <Row align="middle">
              <Col flex={1}>
                <img src={payPalLogo} width={100}/>
              </Col>
              <Col flex={0}>
                <div className={`status-tag yellow medium`}>PENDING</div>
              </Col>
              <Col xs={24} className="">
                <div className="mt-15">Please complete your account setup in PayPal to start accepting payments.</div>
                { payPalSettings.merchant_id && (
                  <>
                    <div className="fw-700 mt-30">Merchant ID:</div>
                    <div className="mt-5">{payPalSettings.merchant_id}</div>
                  </>
                )}
                <div className="mt-20">
                  <div className="blue-link" onClick={() => setConfirmDisconnectPayPalModalVisible(true)}>Disconnect</div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (
        <div className="border p-20 radius-8 mt-15">
          <Row align="middle">
            <Col flex={1}>
              <img src={payPalLogo} width={100}/>
            </Col>
            <Col flex={0}>
              { renderPayPalSignUpButton() }
            </Col>
            <Col xs={24} className="">
              <div className="mt-15">Accept PayPal, credit cards, and debit cards via PayPal.</div>
            </Col>
          </Row>
        </div>
      )
    }
  }

  // STRIPE SECTIONS
  const renderStripeSignUpButton = () => {
    if (isProcessingStripeLink) {
      return (
        <button className="small-primary-button" style={{ width: 100 }}><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="small-primary-button" style={{ width: 100 }} onClick={onConnectStripe}>Connect</button>
      )
    }
  }

  const renderStripeContinueButton = () => {
    if (isProcessingStripeLink) {
      return (
        <button className="small-primary-button" style={{ width: 150 }}><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="small-primary-button" style={{ width: 150 }} onClick={onConnectStripe}>Continue Setup</button>
      )
    }
  }

  const renderStripeBankTransfer = () => {
    if (!isBankTransferAllowed) {
      return null
    }

    if (isBankTransferAvailable && isBankTransferEnabled) {
      return (
        <Row className="mt-5">
          <Col xs={16} sm={12} md={10}>
            <div className="display-flex flex-middle"><FiCheck style={{ marginRight: 5 }}/>{bankTransferTitle}</div>
            <div className="ml-30">
              <div className="blue-link" onClick={() => onToggleBankTransfer(!isBankTransferEnabled)}>Disable</div>
            </div>
          </Col>
          <Col xs={8} sm={12} md={14}>
            <span className="mr-10">{bankTransferFee}</span>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className="mt-5">
          <Col xs={16} sm={12} md={10}>
            <div className="display-flex flex-middle c-text-gray"><FiX style={{ marginRight: 5 }}/>{bankTransferTitle}</div>
          </Col>
          <Col xs={8} sm={12} md={14}>
            <div className="blue-link" onClick={() => onToggleBankTransfer(!isBankTransferEnabled)}>Enable</div>
          </Col>
        </Row>
      )
    }
  }

  const renderStripeSection = () => {
    if (stripeSettings.status == PaymentProcessorStatusTypes.CONNECTED) {
      return (
        <>
          <div className="border p-20 radius-8 mt-15">
            <Row align="middle">
              <Col flex={1}>
                <img src={stripeLogo} width={80} style={{ marginLeft: -5 }}/>
              </Col>
              <Col flex={0}>
                <div className={`status-tag green medium`}>CONNECTED</div>
              </Col>
              <Col xs={24}>
                <div className="mt-10">
                  { isBankTransferAllowed ? "Accept credit cards, debit cards, and bank transfers via Stripe." : "Accept credit cards and debit cards via Stripe."}
                </div>
                <div className="fw-700 mt-30">Payment methods:</div>
                <Row className="mt-5">
                  <Col xs={16} sm={12} md={10}>
                    <div className="display-flex flex-middle"><FiCheck style={{ marginRight: 5 }}/>Credit / debit cards</div>
                    {/* <div className="ml-30 c-text-gray">2.9% + 30¢</div> */}
                  </Col>
                  <Col xs={8} sm={12} md={14}>
                    <div>2.9% + 30¢</div>
                  </Col>
                </Row>
                { renderStripeBankTransfer() }
                <div className="fw-700 mt-30">Connected account:</div>
                <div className="mt-5">{stripeSettings.account_name ?? "--"} {stripeSettings.email ? `(${stripeSettings.email})` : ""}</div>
                <div className="mt-20">
                <div className="blue-link" onClick={() => window.open("https://dashboard.stripe.com", "_blank")}>Stripe Dashboard</div> | <div className="blue-link" onClick={() => setConfirmDisconnectStripeModalVisible(true)}>Disconnect</div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    } else if (stripeSettings.status == PaymentProcessorStatusTypes.PROCESSING) {
      return (
        <>
          <div className="border p-20 radius-8 mt-15">
            <Row align="middle">
              <Col flex={1}>
                <img src={stripeLogo} width={80} style={{ marginLeft: -5 }}/>
                <div className={`status-tag yellow medium`}>PROCESSING</div>
              </Col>
              <Col flex={0}>
                { renderStripeContinueButton() }
              </Col>
              <Col xs={24}>
                <div className="mt-15 bg-gray p-10 radius-8">
                  Your account setup is still in progress. Please continue setup or visit your Stripe account to address the remaining items below.
                </div>
                <div className="mt-10">
                  <div className="display-flex">
                    { stripeSettings.details_submitted ? (
                      <MdCheck size={22} color={"#00b894"}/>
                    ) : (
                      <MdOutlineClose size={22} color={"#e74c3c"}/>
                    )}
                    <span className="ml-5">Details submitted</span>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="display-flex">
                    { stripeSettings.charges_enabled ? (
                      <MdCheck size={22} color={"#00b894"}/>
                    ) : (
                      <MdOutlineClose size={22} color={"#e74c3c"}/>
                    )}
                    <span className="ml-5">Charges enabled</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="border p-20 radius-8">
            <Row align="middle">
              <Col flex={1}>
                <img src={stripeLogo} width={80} style={{ marginLeft: -5 }}/>
              </Col>
              <Col flex={0}>
                { renderStripeSignUpButton() }
              </Col>
              <Col xs={24}>
                <div className="mt-10">Accept credit cards, debit cards, and bank transfers via Stripe.</div>
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }

  const renderManualPaymentMethodsSection = () => {
    if (manualPaymentMethods.length == 0) {
      return (
        <>
          <div className="border p-20 radius-8 mt-15">
            <Row align="middle">
              <Col flex={1}>
                <b>MANUAL PAYMENTS</b>
              </Col>
              <Col flex={0}>
                <button className="small-primary-button" style={{ width: 100 }} onClick={onAddManualPaymentMethod}>Add</button>
              </Col>
              <Col xs={24}>
                <div className="mt-10">Arrange a manual payment outside of DJ Planning Center with your clients.</div>
              </Col>
            </Row>
          </div>
      </>
      )
    } else {
      return (
        <>
          <div className="border p-20 radius-8 mt-15">
            <Row align="middle">
              <Col flex={1}>
                <b>MANUAL PAYMENTS</b>
              </Col>
              <Col flex={0}>
                <div className={`status-tag green medium`}>ACTIVE</div>
              </Col>
              <Col xs={24}>
                <div className="mt-10">The following manual payment methods will display as options on an invoice.</div>
                <div className="fw-700 mt-20 pb-10">Payment methods:</div>
                { manualPaymentMethods.map((x,i) => (
                  <div className="p-15 bg-gray mb-10">
                    <Row className="">
                      <Col flex={1}>
                        <div className="fw-600">{x.payment_method_name}</div>
                        <div className="c-text-gray mt-5 line-breaks">{x.instructions}</div>
                      </Col>
                      <Col flex={0}>
                        <div className="blue-link" onClick={() => onEddManualPaymentMethod(x)}>Edit</div> | <div className="blue-link" onClick={() => onRemovePaymentMethod(x)}>Remove</div>
                      </Col>
                    </Row>
                    { isValidLink(x.link) && (
                      <div className="fw-600 t-border pt-10 mt-10">Link: <a href={x.link} target="_blank" className="c-text-gray fw-500">{x.link}</a></div>
                    )}
                  </div>
                ))}
                <div className="blue-link ml-15 mt-10" onClick={() => onAddManualPaymentMethod(true)}>+ Add payment method</div>
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }

  const renderConnectStripeModal = () => {
    return (
      <Modal visible={isConnectStripeModalVisible} footer={null} onCancel={() => setConnectStripeModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Connect Stripe</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setConnectStripeModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={connectStripeForm} layout="vertical" name="verify" onFinish={onStripeConnectContinue}>
            <div className="mt-10 mb-10 p-10 bg-gray radius-8">
              Confirm your country and currency before proceeding.
            </div>
            <div className="mt-10 mb-10">
              { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, true) }
            </div>
            <div className="mt-10 mb-10">
              {renderSearchSelectField("Currency", "currency", "Select a currency...", currencies, true)}
            </div>
            <button className="primary-button" type="submit">{isProcessingStripeLink ? <LoadingOutlined/> : "Continue"}</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setConnectStripeModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderConfirmDisconnectStripeModal = () => {
    return (
      <Modal visible={isConfirmDisconnectStripeModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmDisconnectStripeModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Disconnect Stripe?</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to disconnect your Stripe account?</div>
          <button className="primary-button warning" type="button" onClick={() => onDisconnectStripe()}>Disconnect Stripe</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmDisconnectStripeModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmDisconnectPayPalModal = () => {
    return (
      <Modal visible={isConfirmDisconnectPayPalModalVisible} closable={false} footer={null} width={500} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmDisconnectPayPalModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Disconnect PayPal</div>
          <div className="bg-gray radius-8 p-15 mt-20">
            <div className="fw-700 fs-14 text-center mb-15">
              Directions for disconnecting your PayPal account:
            </div>
            <div className="fw-500 fs-14">
              <ol>
                <li>Login to your PayPal account and click on your icon in the top-right corner</li>
                <li>Under "Account Settings" select "API Access"</li>
                <li>Under REST API Integration, select "Manage REST API apps and credentials"</li>
                <li>Select the app you would like to revoke and remove permissions from the platform</li>
              </ol>
            </div>
          </div>
          <div className="text-center fs-14 mt-20 mb-10">
            Disconnecting your PayPal account will prevent you from offering PayPal services and products on your website. Do you wish to continue?
          </div>
          <button className="primary-button warning" type="button" onClick={() => onDisconnectPayPal()}>Disconnect PayPal</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmDisconnectPayPalModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemovePaymentMethodModal = () => {
    return (
      <Modal visible={isConfirmRemovePaymentMethodModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemovePaymentMethodModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Payment Method</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this payment method from your account?</div>
          <button className="primary-button warning" type="button" onClick={() => onConfirmRemovePaymentMethod()}>Remove</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemovePaymentMethodModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderEnableBankTransferModal = () => {
    return (
      <Modal visible={isEnableBankTransferModelVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setEnableBankTransferModelVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Enable Bank Transfers</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">To enable bank transfers, login to Stripe account and configure your account.</div>
          <button className="primary-button" type="button" onClick={() => setEnableBankTransferModelVisible(false)}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderConfirmDisableBankTransferModal = () => {
    return (
      <Modal visible={isConfirmDisableBankTransferModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmDisableBankTransferModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Disable Bank Transfer</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to disable bank transfers? Clients will no longer have this payment option when paying an invoice.</div>
          <button className="primary-button warning" type="button" onClick={() => onConfirmDisableBankTransfer()}>Disable</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmDisableBankTransferModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderPaymentMethodsSection = () => {
    return (
      <div className="shadow-card p-20 mb-20">
        <Row className="b-border pb-15 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Payment Methods</div>
          </Col>
          <Col>
            <div className="blue-link" onClick={() => navigate("/admin/docs/payment-methods")}>Learn More</div>
          </Col>
        </Row>
        { stripeSettings.status == PaymentProcessorStatusTypes.DISCONNECTED && payPalSettings.status == PaymentProcessorStatusTypes.DISCONNECTED && manualPaymentMethods.length == 0 && (
          <div className="mb-15">
            Add payment methods to start collecting payments from your clients.
          </div>
        )} 
        { renderStripeSection() }
        { renderPayPalSection() }
        <a id="pay-pal-button" href="" style={{ display: 'none' }}></a>
        { renderManualPaymentMethodsSection() }
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          { isNewInvoicesEnabled && renderPaymentMethodsSection() }
          { (isNewInvoicesEnabled || legacyInvoiceSummary.active) && renderInvoiceSection() }
        </FloatingContainer>
        { renderPaymentsModal() }
        { renderConnectStripeModal() }
        { renderConfirmDisconnectStripeModal() }
        { renderConfirmDisconnectPayPalModal() }
        { renderManualPaymentMethodsModal() }
        { renderConfirmRemovePaymentMethodModal() }
        { renderEnableBankTransferModal() }
        { renderConfirmDisableBankTransferModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default CompanyInvoicesPage;
