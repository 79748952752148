import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep, isArray } from "lodash";
import useApi from '../../../hooks/useApi';
import NumberFormat from 'react-number-format';
import { Row, Col, Input, Select, Modal, Form, Grid, Switch, Checkbox, notification } from 'antd';
import {
  getInvoiceTemplate,
  updateIvoiceTemplate
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useCurrency from '../../../hooks/useCurrency';
import { MdArrowForwardIos, MdOutlineClose } from "react-icons/md";
import { getNumericCurrency, getCurrencySymbol, formatCurrencyString } from '../../../helpers/contractHelper';

const InvoiceTemplatePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [invoiceTemplate, setInvoiceTemplate] = useState({});
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [notes, setNotes] = useState("");
  const [isTipsActive, setTipsActive] = useState(false);
  const [isPaymentRemindersActive, setPaymentRemindersActive] = useState(false);
  const [isPaymentScheduleActive, setPaymentScheduleActive] = useState(false);
  const [is7DaysBeforeReminderActive, set7DaysBeforeReminderActive] = useState(true);
  const [is3DaysBeforeReminderActive, set3DaysBeforeReminderActive] = useState(true);
  const [isDueDateReminderActive, setDueDateReminderActive] = useState(true);
  const [is3DaysAfterReminderActive, set3DaysAfterReminderActive] = useState(true);
  const [is7DaysAfterReminderActive, set7DaysAfterReminderActive] = useState(true);
  const [is14DaysAfterReminderActive, set14DaysAfterReminderActive] = useState(true);
  const [dueDateOption, setDueDateOption] = useState("7_DAYS_BEFORE_EVENT");
  const [paymentSchedulePayments, setPaymentSchedulePayments] = useState([]);
  const [isZeroAmountModalVisible, setZeroAmountModalVisible] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [currency] = useCurrency()

  const [form] = Form.useForm();
  const [sendRequest] = useApi()

  const templateId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getInvoiceTemplate(templateId));
      setInvoiceTemplate(results)
      setTemplateName(results.invoice_template_name)
      setNotes(results.notes)
      setTipsActive(results.tips ?? false)
      setDueDateOption(results.due_date_helper)
      const reminders = isArray(results.payment_reminders) ? results.payment_reminders : []
      if (reminders.length > 0) {
        setPaymentRemindersActive(true)
        set7DaysBeforeReminderActive(reminders.includes(-7))
        set3DaysBeforeReminderActive(reminders.includes(-3))
        setDueDateReminderActive(reminders.includes(0))
        set3DaysAfterReminderActive(reminders.includes(3))
        set7DaysAfterReminderActive(reminders.includes(7))
        set14DaysAfterReminderActive(reminders.includes(14))
      }
      const payments = isArray(results.payment_schedules) ? results.payment_schedules : []
      setPaymentSchedulePayments(payments)
      setPaymentScheduleActive(results.payment_schedule)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onPaymentReminderToggle = (value) => {
    setPaymentRemindersActive(value)
  }

  const onPaymentScheduleToggle = (value) => {
    setPaymentScheduleActive(value)
    if (value) {
      onAddPaymentSchedule()
    } else {
      setPaymentSchedulePayments([])
    }
  }

  const onFinalDueDateOptionChange = (value) => {
    setDueDateOption(value)
  }

  const onAmountTypeChange = (value, index) => {
    var newPayments = cloneDeep(paymentSchedulePayments);
    newPayments[index].amount_type = value
    setPaymentSchedulePayments(newPayments)
  }

  const onDueDateOptionChange = (value, index) => {
    var newPayments = cloneDeep(paymentSchedulePayments);
    newPayments[index].due_date_helper = value
    setPaymentSchedulePayments(newPayments)
  }

  const onAddPaymentSchedule = () => {
    var newPayments = cloneDeep(paymentSchedulePayments);
    const count = newPayments.length
    var defaultAmountType = "CUSTOM"
    var defaultDueDateHelper = "TODAY"
    if (count == 0) {
      defaultAmountType = "DEPOSIT"
      defaultDueDateHelper = "TODAY"
    } else if (count == 1) {
      defaultAmountType = "CUSTOM"
      defaultDueDateHelper = "WITHIN_7_DAYS"
    } else if (count == 2) {
      defaultAmountType = "CUSTOM"
      defaultDueDateHelper = "WITHIN_14_DAYS"
    }
    const payment = {
      amount: 0,
      amount_type: defaultAmountType,
      due_date_helper: defaultDueDateHelper,
      final_payment: false
    }
    newPayments.push(payment)
    setPaymentSchedulePayments(newPayments)
  }

  const onRemovePaymentSchedule = (index) => {
    var newPaymentSchedulePayments = cloneDeep(paymentSchedulePayments)
    newPaymentSchedulePayments.splice(index, 1);
    setPaymentSchedulePayments(newPaymentSchedulePayments)
  }

  const onPaymentScheduleAmountChange = (index, value) => {
    var newPayments = cloneDeep(paymentSchedulePayments);
    newPayments[index].amount = getNumericCurrency(value);
    setPaymentSchedulePayments(newPayments)
  }

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }

  const saveName = async () => {
    setEditingTitle(false)
  }

  const onKeyPress = (e) => {
    if (e.keyCode == 13) {
      saveName()
    }
  }

  const onSave = async () => {

    // Check if due dates are valid
    // var isDatesValid = true
    // for (const [index, payment] of paymentSchedulePayments.entries()) {
    //   const isBeforeFinal = isBeforeFinalDueDate(payment.due_date)
    //   const isBeforeNext = isBeforeNextPaymentDate(payment.due_date, index)
    //   if (!isBeforeFinal || !isBeforeNext) {
    //     isDatesValid = false
    //   }
    // }

    // if (!isDatesValid) {
    //   setDateValidModalVisible(true)
    //   return
    // }

    // Check if any amounts are missing
    var isAnyAmountZero = false
    for (const payment of paymentSchedulePayments) {
      if (payment.amount_type == "CUSTOM" && payment.amount == 0) {
        isAnyAmountZero = true
      }
    }
    if (isAnyAmountZero) {
      setZeroAmountModalVisible(true)
      return
    }

    var newPaymentSchedulePayments = cloneDeep(paymentSchedulePayments)

    var paymentReminders = []
    if (isPaymentRemindersActive) {
      if (is7DaysBeforeReminderActive) {
        paymentReminders.push(-7)
      }
      if (is3DaysBeforeReminderActive) {
        paymentReminders.push(-3)
      }
      if (isDueDateReminderActive) {
        paymentReminders.push(0)
      }
      if (is3DaysAfterReminderActive) {
        paymentReminders.push(3)
      }
      if (is7DaysAfterReminderActive) {
        paymentReminders.push(7)
      }
      if (is14DaysAfterReminderActive) {
        paymentReminders.push(14)
      }
    }

    try {
      const body = {
        invoice_template_name: templateName,
        due_date_helper: dueDateOption,
        notes: notes,
        payment_schedule: isPaymentScheduleActive,
        payment_schedules: newPaymentSchedulePayments,
        tips: isTipsActive,
        payment_reminders: paymentReminders
      }
      await sendRequest(updateIvoiceTemplate(invoiceTemplate.invoice_template_id, body))
      navigate(`/admin/invoice-templates`)
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/invoice-templates")}>
            Invoice Templates
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos /></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <Row gutter={[15,15]}>
          <Col xs={18}>
            <div className={`header-editable-title mt-5 ${isEditingTitle ? "editing" : ""}`}>
              <div className="header-editable-title--label" onClick={() => startEditingTitle()}>{templateName}</div>
              <div className="header-editable-title--input">
                <Input
                  size="large"
                  placeholder="Email"
                  ref={inputRef}
                  onBlur={() => saveName()}
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  onKeyUp={onKeyPress}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="text-right">
            <button className="page-title-button" onClick={onSave}>Save</button>
          </Col>
        </Row>
      </div>
    )
  }

  const renderPaymentScheduleRow = (item, index) => {
    return (
      <Row className="bg-gray p-10 mt-10" gutter={[15,15]} key={index}>
        <Col xs={20} md={3} className="pt-15">
          {index + 1} of {paymentSchedulePayments.length + 1}
        </Col>
        { index > 0 && !screens.md && (
          <Col xs={4} style={{ alignSelf: 'center'}}>
            <div className="display-flex" style={{ justifyContent: 'flex-end'}} onClick={() => onRemovePaymentSchedule(index)}>
              <MdOutlineClose size={20} color={"#CCC"}/>
            </div>
          </Col>
        )}
        <Col xs={24} md={7}>
          { item.amount_type == "DEPOSIT" && (
            <Select size="large" value={item.amount_type} style={{ width: "100%" }} onChange={(value) => onAmountTypeChange(value, index)}>
              { renderAmountOptions() }
            </Select>
          )}
          { item.amount_type == "CUSTOM" && (
            <>
              <NumberFormat 
                className="ant-input ant-input-lg" 
                displayType={'input'} 
                thousandSeparator={true} 
                prefix={getCurrencySymbol(currency.code)}
                decimalScale={2} 
                fixedDecimalScale={true}
                placeholder={`${getCurrencySymbol(currency.code)}0.00`}
                value={item.amount}
                onChange={(e) => onPaymentScheduleAmountChange(index, e.target.value)}
              />
              { index == 0 && (
                <div className="blue-link ml-5" onClick={() => onAmountTypeChange("DEPOSIT", index)}>Select deposit</div>
              )}
            </>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Select size="large" value={item.due_date_helper} style={{ width: "100%" }} onChange={(value) => onDueDateOptionChange(value, index)}>
            { renderDateOptions() }
          </Select>
          {/* { renderPaymentScheduleDateError(item.due_date, index) } */}
        </Col>
        { index > 0 && screens.md && (
          <Col xs={2} style={{ alignSelf: 'center'}}>
            <div className="display-flex" style={{ justifyContent: 'flex-end'}} onClick={() => onRemovePaymentSchedule(index)}>
              <MdOutlineClose size={20} color={"#CCC"}/>
            </div>
          </Col>
        )}
      </Row>
    )
  }

  const renderFinalPaymentScheduleRow = () => {
    const totalPaymentSchedules = paymentSchedulePayments.length + 1
    return (
      <Row className="bg-gray p-10 mt-10" gutter={[20,20]} align="middle">
        <Col xs={24} md={3}>
          {totalPaymentSchedules} of {totalPaymentSchedules}
        </Col>
        <Col xs={24} md={7}>
          <div className="c-text-gray">Automatically calculated</div>
        </Col>
        <Col xs={24} md={12} className="fs-16">
          <Select size="large" value={dueDateOption} style={{ width: "100%" }} onChange={(value) => onFinalDueDateOptionChange(value)}>
            { renderDateOptions() }
          </Select>
        </Col>
      </Row>
    )
  }

  const renderPaymentSchedule = () => {
    return (
      <>
        { screens.md ? (
          <Row gutter={[15,15]} className="b-border mt-20 pb-10 c-text-gray fs-12 fw-700 pl-10">
            <Col xs={3}>
              PAYMENT
            </Col>
            <Col xs={7} className="pl-10">
              AMOUNT
            </Col>
            <Col xs={13} className="pl-10">
              DUE
            </Col>
          </Row>
        ) : (
          <Row gutter={[15,15]} className="b-border mt-20 pb-10 c-text-gray fs-12 fw-700 pl-10">
            <Col xs={24}>
              PAYMENT / AMOUNT / DUE
            </Col>
          </Row>
        )}
        { paymentSchedulePayments.map((x,i) => renderPaymentScheduleRow(x,i))}
        { renderFinalPaymentScheduleRow() }
        { paymentSchedulePayments.length < 3 && (
          <div className="blue-link mt-15" onClick={() => onAddPaymentSchedule()}>+ Add payment</div>
        )}
      </>
    )
  }

  const renderAmountOptions = () => {
    return (
      <>
        <Select.Option value="DEPOSIT">Deposit</Select.Option>
        <Select.Option value="CUSTOM">Custom</Select.Option>
      </>
    )
  }

  const renderDateOptions = () => {
    return (
      <>
        <Select.Option value="TODAY">On issue date</Select.Option>
        <Select.Option value="WITHIN_7_DAYS">Within 7 days</Select.Option>
        <Select.Option value="WITHIN_14_DAYS">Within 14 days</Select.Option>
        <Select.Option value="WITHIN_21_DAYS">Within 21 days</Select.Option>
        <Select.Option value="WITHIN_30_DAYS">Within 30 days</Select.Option>
        <Select.Option value="WITHIN_60_DAYS">Within 60 days</Select.Option>
        <Select.Option value="WITHIN_90_DAYS">Within 90 days</Select.Option>
        <Select.Option value="ON_EVENT_DATE">On event date</Select.Option>
        <Select.Option value="7_DAYS_BEFORE_EVENT">7 days before event</Select.Option>
        <Select.Option value="14_DAYS_BEFORE_EVENT">14 days before event</Select.Option>
        <Select.Option value="21_DAYS_BEFORE_EVENT">21 days before event</Select.Option>
        <Select.Option value="30_DAYS_BEFORE_EVENT">30 days before event</Select.Option>
        <Select.Option value="60_DAYS_BEFORE_EVENT">60 days before event</Select.Option>
        <Select.Option value="90_DAYS_BEFORE_EVENT">90 days before event</Select.Option>
      </>
    )
  }

  const renderZeroAmountModal = () => {
    return (
      <Modal visible={isZeroAmountModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setZeroAmountModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Invalid Amounts</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">All amounts in the payment schedule must be greater than {formatCurrencyString(0, currency.code)}.</div>
          <button className="primary-button" type="button" onClick={() => setZeroAmountModalVisible(false)}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <div className="fs-20 fw-700">Invoice Template</div>
        <div className="c-text-gray mt-5 mb-15">Set default values below for an invoice. This template will display as an option when creating a new invoice.</div>
        <div className={`shadow-card-square ${paddingClass} mt-15`}>
          <div className="fs-20 fw-700 mb-10">Notes</div>
          <Row className="mt-10" gutter={[20, 20]}>
            <Col xs={24} md={24}>
              <Input.TextArea rows={4} placeholder={"Add optional notes, terms, etc."} value={notes} onChange={(e) => setNotes(e.target.value)} />
            </Col>
          </Row>
        </div>

        <div className={`shadow-card-square ${paddingClass} mt-15`}>
          <div className="fs-20 fw-700">Tips</div>
          <div className="c-text-gray mt-5 mb-15">Give your clients an option to leave you a tip.</div>
          <Switch checked={isTipsActive} onChange={(value) => setTipsActive(value)} /> <span className="ml-10 fw-600">{isTipsActive ? "On" : "Off"}</span>

          <div className="fs-20 fw-700 mt-30 pt-30 t-border">Payment Reminders</div>
          <div className="c-text-gray mt-5 mb-15">Send automatic reminders to your clients when each payment is due.</div>
          <Switch checked={isPaymentRemindersActive} onChange={(value) => onPaymentReminderToggle(value)} /> <span className="ml-10 fw-600">{isPaymentRemindersActive ? "On" : "Off"}</span>
          {isPaymentRemindersActive && (
            <Row align="middle" className="mt-15" gutter={[15, 15]}>
              <Col flex={0}>
                <Checkbox checked={is7DaysBeforeReminderActive} onChange={(e) => set7DaysBeforeReminderActive(e.target.checked)}>7 days before</Checkbox>
              </Col>
              <Col flex={0}>
                <Checkbox checked={is3DaysBeforeReminderActive} onChange={(e) => set3DaysBeforeReminderActive(e.target.checked)}>3 days before</Checkbox>
              </Col>
              <Col flex={0}>
                <Checkbox checked={isDueDateReminderActive} onChange={(e) => setDueDateReminderActive(e.target.checked)}>On due date</Checkbox>
              </Col>
              <Col flex={0}>
                <Checkbox checked={is3DaysAfterReminderActive} onChange={(e) => set3DaysAfterReminderActive(e.target.checked)}>3 days after</Checkbox>
              </Col>
              <Col flex={0}>
                <Checkbox checked={is7DaysAfterReminderActive} onChange={(e) => set7DaysAfterReminderActive(e.target.checked)}>7 days after</Checkbox>
              </Col>
              <Col flex={0}>
                <Checkbox checked={is14DaysAfterReminderActive} onChange={(e) => set14DaysAfterReminderActive(e.target.checked)}>14 days after</Checkbox>
              </Col>
            </Row>
          )}
        </div>

        <div className={`shadow-card-square ${paddingClass} mt-15`}>
          <Row className="" gutter={[20, 15]}>
            <Col xs={24} md={24}>
              <div className="fs-20 fw-700 mb-5">Payment Schedule</div>
              <div className="mb-5 c-text-gray">Use a payment schedule to collect deposits/retainers or setup installments.</div>
            </Col>
            <Col xs={24} md={24}>
              <Switch checked={isPaymentScheduleActive} onChange={(value) => onPaymentScheduleToggle(value)} /> <span className="ml-10 fw-600">{isPaymentScheduleActive ? "On" : "Off"}</span>
            </Col>
            {isPaymentScheduleActive && (
              <Col xs={24} md={24}>
                { renderPaymentSchedule() }
              </Col>
            )}
          </Row>

          {!isPaymentScheduleActive && (
            <>
              <div className="fs-20 fw-700 mt-30 pt-30 t-border">Due Date</div>
              <div className="c-text-gray mt-5 mb-15">The date the final payment is due.</div>

              <Row align="middle">
                <Col xs={24} sm={12}>
                  <Select size="large" value={dueDateOption} style={{ width: "100%" }} placeholder="Select an option..." onChange={(value) => onFinalDueDateOptionChange(value)}>
                    {renderDateOptions()}
                  </Select>
                </Col>
              </Row>
            </>
          )}
        </div>
        { renderZeroAmountModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} />
  );
}

export default InvoiceTemplatePage;