import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getAccountDetails, updateSubscription } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import PlanCard from '../../../components/planCard';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import FadeDownWhileInView from '../../../components/fadeDownWhileInView'
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import AppContext from '../../../app/context';
import { LoadingOutlined } from '@ant-design/icons';
import { formatDateMedium } from '../../../helpers/dateHelper';

const ChangePlanPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});
  const [isConfirmChangeModalVisible, setConfirmChangeModalVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();

  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onSelectPlan = async (plan) => {
    setSelectedPlan(plan)
    setConfirmChangeModalVisible(true)
  }

  const onChangePlan = async () => {
    try {
      setSubmitting(true)
      await sendRequest(updateSubscription({ plan: selectedPlan }));
      setSubmitting(false)
      setConfirmChangeModalVisible(false)
      navigate("/admin/account")
    } catch {
      setSubmitting(false)
      setConfirmChangeModalVisible(false)
    }
  }

  const renderConfirmModal = () => {
    const planName = selectedPlan == "BASIC" ? "Basic Plan" : "Premium Plan"
    const nextBillingDate = accountDetails.next_billing_datetime ? formatDateMedium(accountDetails.next_billing_datetime, accountSettings) : ""
    return (
      <Modal visible={isConfirmChangeModalVisible} footer={null} onCancel={() => setConfirmChangeModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Confirm Plan Change</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to change your subscription to the <b>{planName}</b>?</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center bg-gray p-10 radius-8">By clicking the button below, you agree that the price for the new plan will be prorated for the remaining of this month and be added to (if upgrading) or subtracted from (if downgrading) your next bill on <b>{nextBillingDate}</b>.</div>
          { renderConfirmButton() }
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmChangeModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderSelectButton = (plan) => {
    if (accountDetails.plan && accountDetails.plan.plan_id == plan) {
      return <button className="primary-button secondary">Current Plan</button>
    } else {
      return (
        <button className="primary-button" onClick={() => onSelectPlan(plan)}>Select</button>
      )
    }
  }

  const renderConfirmButton = () => {
    if (isSubmitting) {
      return (
        <button className="primary-button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" type="button" onClick={() => onChangePlan()}>Change Plan</button>
      )
    }
  }

  // const renderSaleHeader = () => {
  //   const promoCodeRecord = promoCodes.filter(x => x.plan_id == "BASIC")
  //   if (promoCodeRecord.length > 0) {
  //     return (
  //       <FadeDownWhileInView delay={0.2} className="shadow-card p-15 mb-20">
  //         <div className="bg-gray radius-8 p-15">
  //           <div className="fs-14 fw-700 line-1-2 text-center c-blue mb-10">Limited time only!</div>
  //           <div className="fs-18 fw-700 line-1-2 text-center mb-10">{promoCodeRecord[0].description}</div>
  //           <div className="fs-12 fw-500 line-1-2 text-center c-text-gray">Promo code <b>{promoCodeRecord[0].promo_code}</b> automatically applied at checkout</div>
  //         </div>
  //       </FadeDownWhileInView>
  //     )
  //   }
  //   return null;
  // }

  const renderContent = () => {
    var standardPrice = "$35"
    var premiumPrice = "$60"
    var standardPlanId = "STANDARD"
    var premiumPlanId = "PREMIUM_V2"
    if (accountDetails.account_id < 1000) {
      standardPrice = "$19.99"
      premiumPrice = "$39.99"
      standardPlanId = "BASIC"
      premiumPlanId = "PREMIUM"
    }

    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        <FadeDownWhileInView className="fs-24 fw-700 text-center mb-30">Select your new plan</FadeDownWhileInView>
        {/* { renderSaleHeader() } */}
        <Row gutter={[20,20]}>
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={0.4}>
              <PlanCard plan="STANDARD" price={standardPrice} discountPrice={""} button={renderSelectButton(standardPlanId)}/>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={0.6}>
              <PlanCard plan="PREMIUM" price={premiumPrice} discountPrice={""} button={renderSelectButton(premiumPlanId)}/>
            </FadeDownWhileInView>
          </Col>
        </Row>
        <FadeDownWhileInView delay={1.0} className="text-center mt-30">
          <div className="blue-link" onClick={() => navigate("/admin/account")}>Cancel</div>
        </FadeDownWhileInView>
        { renderConfirmModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default ChangePlanPage;
