import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { isArray, isEmpty } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import { formatEventDateLong, formatTime } from '../helpers/dateHelper';
import { getFormattedPhoneNumber } from '../helpers/addressHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

const EventSheetPDF = (props) => {
  const { event = {} } = props

  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    row: {
      flexDirection: 'row'
    },
    col: {
      flex: 1
    },
    section: {
      marginBottom: 20
    },
    sectionContainer: {
      marginBottom: 20
    },
    sectionHeader: {
      borderBottom: "3px solid #CCC",
      marginBottom: 10
    },
    sectionHeaderText: {
      fontSize: 14,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      paddingBottom: 5
    },
    sectionSubHeader: {
      marginBottom: 10,
      backgroundColor: "#F7F7F7"
    },
    sectionSubHeaderText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 10,
      paddingVertical: 5
    },
    header: {
      marginBottom: 20
    },
    headerText: {
      fontSize: 16,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center"
    },
    subTitleText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center",
      marginTop: 10
    },
    dateText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      textAlign: "center"
    },
    specialEventRow: {
      borderBottom: "1px solid #e8e8e8",
      marginLeft: 15,
      paddingVertical: 10
    },
    specialEventName: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      marginBottom: 2
    },
    songText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      paddingTop: 2
    },
    songTitle: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777"
    },
    notesText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      backgroundColor: "#F7F7F7",
      paddingVertical: 4,
      paddingHorizontal: 5,
      marginLeft: 5,
      marginTop: 3,
      borderRadius: 4
    },
    genreText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      marginTop: 4
    },
    emptyText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
      paddingHorizontal: 5
    },
    labelText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    dataText: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
  });

  const getEventDate = () => {
    return event.event_date ? formatEventDateLong(event.event_date, event.account_settings) : ""
  }

  const getStartEndTime = () => {
    if (event.start_time && !event.end_time) {
      return formatTime(event.start_time, event.account_settings)
    } else if (event.start_time && event.end_time) {
      return formatTime(event.start_time, event.account_settings) + " - " + formatTime(event.end_time, event.account_settings)
    }
    return "--"
  }

  const getUserPhoneNumber = (user) => {
    if (user.phone_numbers && user.phone_numbers.length > 0) {
      return user.phone_numbers[0].phone_number
    }
    return "";
  }

  const renderLabelItem = (label, data) => {
    return (
      <View style={{ paddingLeft: 10, marginBottom: 10 }}>
        <Text style={styles.labelText}>{label}</Text>
        <Text style={styles.dataText}>{data}</Text>
      </View>
    )
  }

  const renderDocumentEventDetails = () => {
    const internalNotes = event.account_details ? event.account_details.internal_notes : ""
    const lockdownDays = event.account_details ? event.account_details.lockdown_days : ""
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Overview</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            { renderLabelItem("Event Name", event.event_name) }
            { renderLabelItem("Event Date", getEventDate()) }
            { renderLabelItem("Lockdown Days", `${lockdownDays} days before the event`) }
          </View>
          <View style={styles.col}>
            { renderLabelItem("Event Type", event.account_event_type_name) }
            { renderLabelItem("Start / End Time", getStartEndTime()) }
          </View>
        </View>
        { renderLabelItem("Internal Notes", internalNotes) }
      </View>
    )
  }

  const renderDocumentVenue = () => {
    const venue = event.venue ? event.venue : {}
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Venue</Text>
        </View>
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>{venue.venue_name}</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <View style={{ paddingLeft: 10, marginBottom: 10 }}>
              <Text style={styles.labelText}>Address</Text>
              <Text style={styles.dataText}>{venue.address_line_1} {venue.address_line_2}</Text>
              <Text style={styles.dataText}>{venue.city}, {venue.state} {venue.zip}</Text>
            </View>
          </View>
          <View style={styles.col}>
            <View style={{ paddingLeft: 10, marginBottom: 10 }}>
              <Text style={styles.labelText}>Venue Contact</Text>
              <Text style={styles.dataText}>{!isEmpty(venue.contact_name) ? venue.contact_name : "--"}</Text>
              <Text style={styles.dataText}>{venue.contact_email}</Text>
              <Text style={styles.dataText}>{getFormattedPhoneNumber(venue.contact_phone)}</Text>
            </View>
          </View>
        </View>
        { renderLabelItem("Venue Notes", venue.notes) }
      </View>
    )
  }

  const renderClient = (client, index) => {
    const address = isArray(client.addresses) && client.addresses.length > 0 ? client.addresses[0] : {}
    return (
      <View style={{ marginBottom: 10 }} wrap={false} key={index}>
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>{client.first_name} {client.last_name}</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            { renderLabelItem("Email", client.email) }
            { renderLabelItem("Phone", getFormattedPhoneNumber(getUserPhoneNumber(client))) }
          </View>
          <View style={styles.col}>
            { renderLabelItem("Title", client.role_name) }
            { address.line_1 && (
              <View style={{ paddingLeft: 10, marginBottom: 10 }}>
                <Text style={styles.labelText}>Address</Text>
                <Text style={styles.dataText}>{address.line_1} {address.line_2}</Text>
                <Text style={styles.dataText}>{address.city}, {address.state} {address.zip}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    )
  }

  const renderClients = () => {
    const clients = isArray(event.clients) ? event.clients : []
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Clients</Text>
        </View>
        { clients.map((x,i) => renderClient(x,i)) }
        { clients.length == 0 && (
          <Text style={styles.emptyText}>No clients are assigned to this event</Text>
        )}
      </View>
    )
  }

  const renderAssignedEmployees = () => {
    const employees = isArray(event.employees) ? event.employees : []
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Assigned Staff</Text>
        </View>
        { employees.map((x,i) => renderClient(x,i)) }
        { employees.length == 0 && (
          <Text style={styles.emptyText}>No staff members are assigned to this event</Text>
        )}
      </View>
    )
  }

  const renderPackage = (eventPackage, index) => {
    const borderStyle = index > 0 ? { borderTop: "1px solid #e8e8e8", paddingTop: 10 } : {}
    return (
      <View style={[{ paddingLeft: 10, marginBottom: 10 }, borderStyle]} key={index}>
        <Text style={styles.dataText}>{eventPackage.package_name}</Text>
      </View>
    )
  }

  const renderAddOn = (eventAddOn, index) => {
    const borderStyle = index > 0 ? { borderTop: "1px solid #e8e8e8", paddingTop: 10 } : {}
    return (
      <View style={[{ paddingLeft: 10, marginBottom: 10 }, borderStyle]} key={index}>
        <View style={styles.row}>
          <View style={styles.col}>
           <Text style={styles.dataText}>{eventAddOn.add_on_name}</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.dataText}>Qty: {eventAddOn.quantity}</Text>
          </View>
        </View>
      </View>
    )
  }

  const renderServices = () => {
    const packages = isArray(event.packages) ? event.packages : []
    const add_ons = isArray(event.add_ons) ? event.add_ons : []
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Services</Text>
        </View>
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>Packages</Text>
        </View>
        { packages.map((x,i) => renderPackage(x,i)) }
        { packages.length == 0 && (
          <Text style={styles.emptyText}>No packages are assigned to this event</Text>
        )}
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>Add-Ons</Text>
        </View>
        { add_ons.map((x,i) => renderAddOn(x,i)) }
        { add_ons.length == 0 && (
          <Text style={styles.emptyText}>No add-ons are assigned to this event</Text>
        )}
      </View>
    )
  }

  const renderDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.headerText}>{ event.event_name }</Text>
            <Text style={styles.dateText}>{ getEventDate() }</Text>
            <Text style={styles.subTitleText}>Event Sheet</Text>
          </View>
          { renderDocumentEventDetails() }
          { renderDocumentVenue() }
          { renderClients() }
          { renderAssignedEmployees() }
          { renderServices() }
        </Page>
      </Document>
    )
  }

  return renderDocument()
}

export default EventSheetPDF;
