import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import clientPermissionsImage from '../../../images/client-permissions.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsClientPermissionsPage = () => {

  useDocumentTitle("Client Permissions | Documenation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Client Permissions</div>
        <div className="fw-500 fs-16 c-text-gray">Control what your clients are able to view and edit.</div>
         <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Overview</div>
        This purpose of this feature is to give you control over what your clients are able to view and edit on the website and in the app. For example, if you would like to add a friend, family member, or wedding planner to the event but do not want them to have the ability to edit anything, you can assign them view-only access.
        <br/><br/>
        Here are the permissions currently available:
        <ul>
          <li className="mb-15 mt-15">
            <b>Music (View)</b> 
            <br/>
            The client will be able to view the music selections for the event, including special events, favorite genres, and playlists.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: If the client is not assigned this permission, the navigation item will not appear for them on the website and the page will be blocked in the app.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Music (Edit)</b> 
            <br/>
            The client will be able to edit the music selections for the event, including special events, favorite genres, and playlists.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: The client will also need to have the "View" permission for music in order to edit.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Questionnaires (View)</b> 
            <br/>
            The client will be able to view the answers to the questionnaires.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: If the client is not assigned this permission, the navigation item will not appear for them on the website and the page will be blocked in the app.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Questionnaires (Edit)</b> 
            <br/>
            The client will be able to edit the answers to the questionnaires.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: The client will also need to have the "View" permission for questionnaires in order to edit.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Timeline (View)</b> 
            <br/>
            The client will be able to view the timeline for the event.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: If the client is not assigned this permission, the navigation item will not appear for them on the website and the page will be blocked in the app.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Timeline (Edit)</b> 
            <br/>
            The client will be able to add sections and items to the timeline.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: The client will also need to have the "View" permission for the timeline in order to edit.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Services & Financials (View)</b> 
            <br/>
            The client will be able to see the packages and add-ons for the event along with pricing and total cost.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: If the client is not assigned this permission, the navigation item will not appear for them on the website or on the Menu tab in the app.</div>
          </li>
          <li className="mb-15 mt-15">
            <b>Documents (View)</b> 
            <br/>
            The client will be able to see any contracts for the event with a link to sign if they are listed as a signer on the contract.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10 fs-12">Note: If the client is not assigned this permission, the navigation item will not appear for them on the website or on the Menu tab in the app.</div>
          </li>
        </ul>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Assigning Client Permissions</div>
        <ol>
          <li className="mb-15 mt-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and create a <b>New Event</b>.
          </li>
          <li className="mb-15">
            Enter the details of the event.
          </li>
          <li className="mb-15">
            In the <b>Clients</b> section, select <b>Add Client</b>.
          </li>
          <li className="mb-15">
            Select either the <b>New Client</b> or <b>Existing Client</b> tab, fill in the client details, and checkmark the permissions you would like to assign in the <b>Permissions</b> section of the modal.
            <div className="mt-15">
              <Image src={clientPermissionsImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Select the <b>Save</b> button.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/events")}>Go to Events</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Modifying Client Permissions</div>
        <ol>
          <li className="mb-15 mt-15">
            Navigate to a specific event on the website.
          </li>
          <li className="mb-15">
            Scroll to the <b>Clients</b> section.
          </li>
          <li className="mb-15">
            Click the gray options icon and select <b>Edit Permissions</b>.
          </li>
          <li className="mb-15">
            Update the checkboxes in the <b></b>
            <div className="mt-15">
              <Image src={clientPermissionsImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Select the <b>Save</b> button.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/events")}>Go to Events</button>
        </div>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsClientPermissionsPage;
